import { useState } from 'react';
import { RowSectionTitle } from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import { OriginProps } from '../../../../data';

interface DataSourceProps {
  originData: OriginProps[];
  arrowDirection: string;
  changeArrowDirection: () => void;
  type?: string;
}

const DataSource = ({
  originData,
  arrowDirection,
  changeArrowDirection,
  type,
}: DataSourceProps) => {
  return (
    <div
      className={
        type === 'detail'
          ? 'browser-bg-FFFFFF'
          : 'browser-bg-FFFFFF padding-b-24'
      }
      style={{
        width: '100%',
        backgroundColor: '#fff',
      }}
    >
      <div
        className="flex-column gap-8 "
        style={{ marginBottom: type === 'detail' ? '108px' : '0' }}
      >
        <RowSectionTitle>
          <TextSectionTitle
            arrow={true}
            arrowDirection={arrowDirection}
            onClick={changeArrowDirection}
          >
            {''}
          </TextSectionTitle>
        </RowSectionTitle>
        {arrowDirection === 'up' ? (
          <div className="padding-16 flex-column">
            <div
              className="flex-row bg-light-gray-100 border-b-171E26 border-t-171E26 font-medium f-size-14 flex-center"
              style={{ padding: '8px 0' }}
            >
              <div className="w-72 flex-center flex-row">
                <div className="color-text-tertiary">구분</div>
              </div>
              <div className="w-125 flex-center flex-row">
                <div className=" color-text-tertiary">기준일</div>
              </div>
              <div className="flex-center flex-1 flex-row">
                <div className="color-text-tertiary">출처</div>
              </div>
            </div>
            <div>
              {originData &&
                originData.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex-row border-b-171E26 font-medium f-size-12 "
                      style={{
                        padding: '8px',
                      }}
                    >
                      <div className="w-72 flex-center flex-row">
                        <div className="color-text-primary text-center">
                          {item.category}
                        </div>
                      </div>
                      <div className="w-125 flex-center flex-row">
                        <div className="color-text-primary text-center">
                          {item.createdTime}
                        </div>
                      </div>
                      <div className="flex-center flex-1 flex-row">
                        <div className="color-text-primary text-center">
                          {item.origin}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : // <div style={{ height: '16px' }}></div>
        null}
      </div>
    </div>
  );
};
export default DataSource;
