import { useEffect, useState } from 'react';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import './ReportDetail.css';
// import DoughnutChart3 from './components/DoughnutChart3';
// import useDidMountEffect from '../../../../hooks/UseDidMountEffect';
import { styled } from 'styled-components';
import { PyeongRegionDataProps, LegalDongNameProps } from '../../data';
import { transNum } from '../../../../function/function';

interface LineChartDataItem {
  month: string;
  price: number;
  pricePerPyeong: number;
  count: number;
}

const LandPrice = ({
  data,
  type,
  legalDongName,
}: {
  data: PyeongRegionDataProps;
  type: string;
  legalDongName: LegalDongNameProps;
}) => {
  const BoxGreen2 = ({
    children,
  }: {
    children: JSX.Element[] | JSX.Element;
  }) => (
    <div
      className="gap-10 border-radius-8 bg-primary-100"
      style={{ flex: '1', padding: '16px 0 16px 16px' }}
    >
      {children}
    </div>
  );
  const TextSectionTitle = ({
    children,
    warn,
    onClick,
    arrow,
    arrowDirection,
  }: {
    children: string | JSX.Element | any;
    warn?: boolean;
    onClick?: any;
    arrow?: boolean;
    arrowDirection?: string;
  }) => {
    return (
      <div className="font-bold f-size-18 line-h-26 color-text-primary center-y flex-row gap-8">
        {children}{' '}
        {warn && (
          <img
            className="hover"
            src="/asset/images/warn/Warn24.svg"
            alt=""
            onClick={onClick}
          />
        )}
        {arrow && (
          <img
            className="hover"
            src={`/asset/images/arrow/Arrow${
              arrowDirection === 'down' ? 'Bottom' : 'Top'
            }24-light-gray.svg`}
            alt=""
            onClick={onClick}
          />
        )}
      </div>
    );
  };
  const RowSectionTitle = ({ children }: { children: JSX.Element }) => (
    <div className={`padding-24-16-8`}>{children}</div>
  );

  const CustomYAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor="end" fill="#666">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );

  const [areaType, setAreaType] = useState('P');
  const [selectedRegion, setSelectedRegion] = useState('emd');
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lineChartData, setLineChartData] = useState<LineChartDataItem[]>([]);
  const emd = legalDongName.dong;
  const sig = legalDongName.gugun;
  const ctp = legalDongName.sido;
  const addressName = [emd, sig, ctp];

  const { emdList, sigList, ctpList }: any = data.list || {};

  const handleTabClick = (idx: number) => {
    setSelectedTab(idx);
    setSelectedRegion(['emd', 'sig', 'ctp'][idx]);
    setActiveIndex(idx);

    // const selectedRegionIndex = barData.findIndex(
    //   (item) => item.name === address[idx],
    // );
  };

  const handleBarClick = (data: any, index: number) => {
    setSelectedTab(index);
    setSelectedRegion(['emd', 'sig', 'ctp'][index]);

    // const selectedRegionIndex = barData.findIndex(
    //   (item) => item.name === address[index - 1],
    // );

    setActiveIndex(index);
    // console.log(data, index);
    // if (index !== 0) {
    //   console.log(data, index);
    //   setActiveIndex(index);
    //   const tabIndex = address.findIndex((el) => el === data.name);
    //   console.log(tabIndex);
    //   setSelectedTab(tabIndex);
    //   setSelectedRegion(['emd', 'sig', 'ctp'][tabIndex]);
    // }
  };

  const barData = [
    {
      name: emd,
      uv:
        areaType === 'P'
          ? data.last?.emd?.last1year?.pricePerPyeong
          : data.last?.emd?.last1year?.price,
      vl:
        data.last?.emd?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    data.last?.emd?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(data.last?.emd?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
    {
      name: sig,
      uv:
        areaType === 'P'
          ? data.last?.sig?.last1year?.pricePerPyeong
          : data.last?.sig?.last1year?.price,
      vl:
        data.last?.sig?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    data.last?.sig?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(data.last?.sig?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
    {
      name: ctp,
      uv:
        areaType === 'P'
          ? data.last?.ctp?.last1year?.pricePerPyeong
          : data.last?.ctp?.last1year?.price,
      vl:
        data.last?.ctp?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    data.last?.ctp?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(data.last?.ctp?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
  ];

  //라인 차트 데이터  만들기

  const formatDataForLineChart = (list: any) => {
    return (list ? list : []).map((item: any) => {
      return {
        month: !item.month ? '' : item.month,
        price: item.price === 'No data' ? 0 : item.price,
        pricePerPyeong: !item.pricePerPyeong ? 0 : item.pricePerPyeong,
        count: !item.count ? 0 : item.count,
        prepare1month: !item.prepare1month ? 0 : item.prepare1month,
        prepare1monthPerPyeong: !item.prepare1monthPerPyeong
          ? 0
          : item.prepare1monthPerPyeong,
        prepare1monthRate: !item.prepare1monthRate ? 0 : item.prepare1monthRate,
        prepare1year: !item.prepare1year ? 0 : item.prepare1year,
        prepare1yearPerPyeong: !item.prepare1yearPerPyeong
          ? 0
          : item.prepare1yearPerPyeong,
        prepare1yearRate: !item.prepare1yearRate ? 0 : item.prepare1yearRate,
        prepareMax: !item.prepareMax ? 0 : item.prepareMax,
        prepareMaxPerPyeong: !item.prepareMaxPerPyeong
          ? 0
          : item.prepareMaxPerPyeong,
        prepareMaxRate: !item.prepareMaxRate ? 0 : item.prepareMaxRate,
        up: !item.up ? 0 : item.up,
        upRate: !item.upRate ? 0 : item.upRate,
        down: !item.down ? 0 : item.down,
        downRate: !item.downRate ? 0 : item.downRate,
        etc: !item.etc ? 0 : item.etc,
        etcRate: !item.etcRate ? 0 : item.etcRate,
      };
    });
  };

  const lineChartDataArray = {
    emd: formatDataForLineChart(emdList),
    sig: formatDataForLineChart(sigList),
    ctp: formatDataForLineChart(ctpList),
  };

  useEffect(() => {
    if (selectedRegion === 'emd') {
      setLineChartData(emdList);
    } else if (selectedRegion === 'sig') {
      setLineChartData(sigList);
    } else {
      setLineChartData(ctpList);
    }
  }, [selectedRegion]);

  const handlePrevData = () => {
    setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextData = () => {
    setSelectedIndex((prevIndex) =>
      Math.min(
        prevIndex + 1,
        lineChartDataArray[selectedRegion as keyof typeof lineChartDataArray]
          .length - 1,
      ),
    );
  };

  const selectedData =
    lineChartDataArray[selectedRegion as keyof typeof lineChartDataArray][
      selectedIndex
    ];

  type Region = 'ctp' | 'sig' | 'emd';
  type Period = 'last1year' | 'last3month';

  const renderPriceAndTransactionInfo = (period: Period) => {
    const lastYearData = data.last[selectedRegion as Region][period];
    if (
      lastYearData?.pricePerPyeong === null ||
      lastYearData?.pricePerPyeong === undefined
    ) {
      return <p>사례 없음</p>;
    }

    const priceLabel = type === 'L' ? '토지단가' : '단독주택단가';
    const unit = areaType === 'P' ? '(평) ' : '(m²) ';
    const price =
      areaType === 'P'
        ? transNum(lastYearData.pricePerPyeong, true, true)
        : transNum(lastYearData.price, true, true);
    const transactionCount = Number(lastYearData.count).toLocaleString();

    return (
      <>
        <p>
          {priceLabel}
          {unit}
          {price}원
        </p>
        <p>거래량 {transactionCount}건</p>
      </>
    );
  };

  return (
    <div>
      <AnalysisInfoContainer>
        {/* 지역 탭 */}
        <div className="flex-column">
          <div className="pyeongregion-title-box">
            <RowSectionTitle>
              <TextSectionTitle>
                {type === 'L' ? '지역별 토지단가' : '지역별 단독주택단가'}
                {areaType === 'P' ? '(평)' : '(m²)'}
              </TextSectionTitle>
            </RowSectionTitle>

            <div className="padding-24-16-8">
              <div
                className="switch-p-m hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-1-e2e2e2 font-medium f-size-14 line-h-20 color-text-primary"
                onClick={() =>
                  setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                }
                style={{ borderRadius: '4px', gap: '4px' }}
              >
                <img src="/asset/images/icon/rotation.svg" alt="" />
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
          </div>
          <div className="of-x-auto relative flex-row">
            <div className="flex-row padding-12 gap-8">
              <div className="border-radius-100 flex-row gap-5">
                {addressName &&
                  addressName.map((el: any, idx: any) => (
                    <div
                      key={idx}
                      className={`padding-6-16 border-radius-100 hover no-wrap ${
                        selectedTab === idx ? 'active-tab' : ''
                      }`}
                      style={{
                        border: '1px solid #F0F0F0',
                        color: selectedTab === idx ? '#fff' : '#171e268f',
                        backgroundColor:
                          selectedTab === idx ? '#1A2129' : '#fff',
                      }}
                      onClick={() => {
                        handleTabClick(idx);
                      }}
                    >
                      {el}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* 최신 평당가 박스*/}

          <div className="padding-8-16 gap-8 flex-row">
            <BoxGreen2>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                최근 1년
              </div>
              <div
                className="font-bold f-size-13 line-h-24 "
                style={{ color: '#1E9B6B' }}
              >
                {renderPriceAndTransactionInfo('last1year')}
              </div>
            </BoxGreen2>
            <BoxGreen2>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                최근 3개월
              </div>
              <div
                className="font-bold f-size-13 line-h-24 "
                style={{ color: '#1E9B6B' }}
              >
                {renderPriceAndTransactionInfo('last3month')}
              </div>
            </BoxGreen2>
          </div>
        </div>
        {/* 막대그래프*/}
        <div className={` flex-column`}>
          {' '}
          <div className="flex-column w-100per">
            <div
              className="tota tota1"
              style={{ padding: '16px 16px 0px 16px' }}
            >
              <div className="frame-2609336">
                <div className="data.last-title">
                  {ctp + ' ' + sig + ' ' + emd}
                </div>
              </div>
              <div className="_3-km">
                최근 1년 평균 실거래가({areaType === 'P' ? '평' : 'm²'})
              </div>
            </div>
            <div className="frame-2609304" style={{ marginBottom: '24px' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  margin={{ top: 40 }}
                  width={window.innerWidth}
                  height={3000}
                  data={barData}
                >
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    style={{ fontSize: '12px' }}
                  />
                  <Bar
                    dataKey="uv"
                    fill="#09CE7D"
                    onClick={handleBarClick}
                    radius={[10, 10, 0, 0]}
                    barSize={60}
                  >
                    <LabelList
                      dataKey="vl"
                      offset={12}
                      position="top"
                      fill="#000"
                      fontSize="13"
                    />
                    {barData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        cursor="pointer"
                        fill={index === activeIndex ? '#09CE7D' : '#EAF8F2'}
                        onClick={() => handleBarClick(entry, index)}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            {/* 라인그래프 */}
            <ResponsiveContainer width="100%" height={180}>
              <LineChart
                className="flex-medium f-size-11 center-x"
                data={lineChartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                onClick={(nextState, event) => {
                  const clickedLabel = nextState?.activeLabel;
                  const chartData = lineChartData;
                  const selectedIndex =
                    chartData.length -
                    1 -
                    chartData.findIndex(
                      (item: any) => item.month === clickedLabel,
                    );

                  if (selectedIndex !== -1) {
                    setSelectedIndex(selectedIndex);
                  }
                }}
              >
                {areaType === 'P' ? (
                  <Line
                    type="monotone"
                    dataKey="pricePerPyeong"
                    stroke="#53df89"
                  />
                ) : (
                  <Line type="monotone" dataKey="price" stroke="#53df89" />
                )}
                <XAxis
                  dataKey="month"
                  ticks={[
                    lineChartData[0]?.month || '',
                    lineChartData[6]?.month || '',
                    lineChartData[12]?.month || '',
                    lineChartData[18]?.month || '',
                    lineChartData[lineChartData.length - 1]?.month || '',
                  ]}
                  interval={0}
                />
                <YAxis tick={<CustomYAxisTick />} />
                <Tooltip
                  content={({ payload, label, active }) => {
                    if (active && payload && payload.length) {
                      const dataItem = payload[0].payload;
                      const priceKey =
                        areaType === 'P' ? 'pricePerPyeong' : 'price';
                      const priceUnit = areaType === 'P' ? '평' : 'm²';
                      return (
                        <div className="custom-tooltip">
                          <p>{`${label}`}</p>
                          <p>{`거래량: ${dataItem.count ?? 0}건`}</p>
                          <p
                            style={{ color: '#53df89' }}
                          >{`토지단가(${priceUnit}): ${transNum(
                            dataItem[priceKey],
                            true,
                            true,
                          )}원`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </AnalysisInfoContainer>
    </div>
  );
};

export default LandPrice;

// div className="padding-b-24 browser-bg-FFFFFF" 를 styled compo로
const AnalysisInfoContainer = styled.div`
  font-family: Pretendard-Medium;
  padding-bottom: 24px;
  @media (min-width: 1024px) {
    .browser-bg-FFFFFF {
      background-color: #ffffff;
    }
  }
`;

// <div className="padding-16 flex-column gap-16">
// <div className="frame-2609340">
//   <div className="frame-2609338">
//     <div className="monthly">
//       <div className="frame-2608953">
//         <img
//           src="/asset/images/arrow/arrow_left_calendar_black.svg"
//           alt="왼쪽화살표"
//           onClick={handlePrevData}
//         />
//         <div className="frame-26089532">
//           <div className="_2023-122">{selectedData?.month}</div>
//         </div>
//         <img
//           src="/asset/images/arrow/arrow_right_calendar_black.svg"
//           alt="오른쪽화살표"
//           onClick={handleNextData}
//         />
//       </div>
//     </div>
//     {selectedData?.pricePerPyeong === 0 ? (
//       <div className="no-data-message">
//         <img
//           src="/asset/images/warn/WarnWithGrayCircle.svg"
//           alt=""
//         />
//         <p>실거래가 없습니다.</p>
//       </div>
//     ) : (
//       <div className="frame-2608355">
//         <div className="frame-2609341">
//           <div className="list">
//             <div className="text">
//               {priceType === 'L'
//                 ? '평균 토지단가'
//                 : '평균 단독주택단가'}
//               {areaType === 'P' ? '(평)' : '(m²)'}
//             </div>
//             <div className="text2">
//               {Math.round(
//                 areaType === 'P'
//                   ? selectedData?.pricePerPyeong
//                   : selectedData?.price,
//               ).toLocaleString()}
//               원{areaType === 'P' ? '/평' : '/m²'}
//             </div>
//           </div>
//           <div className="list">
//             <div className="text">
//               전월 대비{areaType === 'P' ? '(평)' : '(m²)'}
//             </div>
//             <div
//               className="text3"
//               style={{
//                 color:
//                   selectedData?.prepare1monthPerPyeong === 0
//                     ? '#171e26'
//                     : selectedData?.prepare1monthPerPyeong > 0
//                       ? '#EC4F4F'
//                       : '#4B47FC',
//               }}
//             >
//               {Math.abs(
//                 Math.round(
//                   areaType === 'P'
//                     ? selectedData?.prepare1monthPerPyeong
//                     : selectedData?.prepare1month,
//                 ),
//               ).toLocaleString()}
//               {selectedData?.prepare1monthPerPyeong > 0
//                 ? '원 상승 '
//                 : selectedData?.prepare1monthPerPyeong < 0
//                   ? '원 하락 '
//                   : '원 '}
//               {`(${selectedData?.prepare1monthRate > 0 ? '+' : ''}${
//                 Math.round(selectedData?.prepare1monthRate * 10) /
//                 10
//               }%)`}
//             </div>
//           </div>
//           <div className="list">
//             <div className="text">
//               전년 동기 대비{areaType === 'P' ? '(평)' : '(m²)'}
//             </div>
//             <div
//               className="text4"
//               style={{
//                 color:
//                   selectedData?.prepare1yearPerPyeong === 0
//                     ? '#171e26'
//                     : selectedData?.prepare1yearPerPyeong > 0
//                       ? '#EC4F4F'
//                       : '#4B47FC',
//               }}
//             >
//               {Math.abs(
//                 Math.round(
//                   areaType === 'P'
//                     ? selectedData?.prepare1yearPerPyeong
//                     : selectedData?.prepare1year,
//                 ),
//               ).toLocaleString()}
//               {selectedData?.prepare1yearPerPyeong > 0
//                 ? '원 상승 '
//                 : selectedData?.prepare1yearPerPyeong < 0
//                   ? '원 하락 '
//                   : '원 '}
//               {`(${selectedData?.prepare1yearRate > 0 ? '+' : ''}${
//                 Math.round(selectedData?.prepare1yearRate * 10) /
//                 10
//               }%)`}
//             </div>
//           </div>
//           <div className="list">
//             <div className="text">
//               최고가 대비{areaType === 'P' ? '(평)' : '(m²)'}
//             </div>
//             <div
//               className="text4"
//               style={{
//                 color:
//                   selectedData?.prepareMaxPerPyeong === 0
//                     ? '#171e26'
//                     : selectedData?.prepareMaxPerPyeong > 0
//                       ? '#EC4F4F'
//                       : '#4B47FC',
//               }}
//             >
//               {/* {Math.abs(
//             Math.round(selectedData?.prepareMaxPerPyeong)
//           ).toLocaleString()} */}
//               {Math.abs(
//                 Math.round(
//                   areaType === 'P'
//                     ? selectedData?.prepareMaxPerPyeong
//                     : selectedData?.prepareMax,
//                 ),
//               ).toLocaleString()}
//               {selectedData?.prepareMaxPerPyeong > 0
//                 ? '원 상승 '
//                 : selectedData?.prepareMaxPerPyeong < 0
//                   ? '원 하락 '
//                   : '원 '}
//               {`(${selectedData?.prepareMaxRate > 0 ? '+' : ''}${
//                 Math.round(selectedData?.prepareMaxRate * 10) / 10
//               }%)`}
//             </div>
//           </div>
//         </div>
//         <div className="flex-row center-y space-between w-100per">
//           <div className="center-y" style={{ height: '132px' }}>
//             <DoughnutChart3 list={dougunutData} />
//           </div>
//           <div
//             className="flex-column gap-8"
//             style={{ width: '180px' }}
//           >
//             <div className="flex-row space-between f-size-14 font-medium color-text-primary">
//               <span>총 거래량</span>
//               <span>{selectedData?.count}건</span>
//             </div>
//             {dougunutData.map((el, index) => (
//               <div className="flex-row space-between" key={index}>
//                 <div className="flex-row center-y gap-8">
//                   <div
//                     style={{
//                       width: '8px',
//                       height: '8px',
//                       borderRadius: '100%',
//                       background: el.color,
//                     }}
//                   ></div>
//                   <div
//                     className="font-medium f-size-14 line-h-20 color-text-tertiary"
//                     style={{ width: '55px' }}
//                   >
//                     {el.text}
//                   </div>
//                 </div>
//                 <div
//                   className="font-medium f-size-14 line-h-20 color-text-primary"
//                   style={{ textAlign: 'right' }}
//                 >
//                   {el.count}건 ({el.rate.toFixed(1)}%)
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     )}
//   </div>
// </div>
// </div>
