import { useEffect, useState } from 'react';
import { ButtonSelect1M } from '../../components/Button';
import { RowTableValueBold } from '../../components/Row';
import {
  formatToKoreanCurrency,
  transNum,
} from '../../../../../../function/function';
import { Tooltip2 } from '../../components/Tooltip';
import { SurroundListProps } from '../../../../data';
import Pagination from 'react-js-pagination';

const SurroundingTrade = ({
  surroundList,
}: {
  surroundList: SurroundListProps[];
}) => {
  const [onToolTip, setOnToolTip] = useState(false);
  const [pyeong2, setPyeong2] = useState(true);
  const [tab, setTab] = useState(0);
  const [realCurrentPage, setRealCurrentPage] = useState<number>(1);
  const [salesCurrentPage, setSalesCurrentPage] = useState<number>(1);
  const [auctionCurrentPage, setAuctionCurrentPage] = useState<number>(1);
  const [pauctionCurrentPage, setPAuctionCurrentPage] = useState<number>(1);
  const [groupedByType, setGroupedByType] = useState({
    realDeal: [] as SurroundListProps[],
    sales: [] as SurroundListProps[],
    auction: [] as SurroundListProps[],
    pAuction: [] as SurroundListProps[],
  });

  const grouping = (
    surroundList: SurroundListProps[],
  ): { [key: string]: SurroundListProps[] } => {
    return surroundList.reduce(
      (acc, curr) => {
        if (!acc[curr.type]) {
          acc[curr.type] = [];
        }
        acc[curr.type].push(curr);
        return acc;
      },
      {} as { [key: string]: SurroundListProps[] },
    );
  };
  useEffect(() => {
    const groupTypes = grouping(surroundList);
    setGroupedByType({
      realDeal: groupTypes.realDealPrice || [],
      sales: groupTypes.farmLandBank || [],
      auction: groupTypes.auction || [],
      pAuction: groupTypes.pAuction || [],
    });
  }, [surroundList]);

  const cropBtnArr = [
    { children: `실거래 ${groupedByType.realDeal.length}`, selected: 0 },
    { children: `농지은행 ${groupedByType.sales.length}`, selected: 1 },
    { children: `경매 ${groupedByType.auction.length}`, selected: 2 },
    { children: `공매 ${groupedByType.pAuction.length}`, selected: 3 },
  ];

  const handlePageChange = (pageNumber: any) => {
    if (tab === 0) {
      setRealCurrentPage(pageNumber);
    } else if (tab === 1) {
      setSalesCurrentPage(pageNumber);
    } else if (tab === 2) {
      setAuctionCurrentPage(pageNumber);
    } else if (tab === 3) {
      setPAuctionCurrentPage(pageNumber);
    }
    // 페이지 변경에 따른 데이터 로딩 등의 작업을 수행할 수 있음
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div
        className="flex-row space-between w-100per center-y padding-x-16 relative"
        style={{ paddingTop: '16px' }}
      >
        <div className=" flex-row">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary ">
            주변 가격 사례
          </div>
          <img
            src="/asset/images/warn/Warn24.svg"
            className="hover"
            onClick={() => setOnToolTip(true)}
            alt=""
          />
        </div>
        <div
          onClick={() => {
            setPyeong2(!pyeong2);
          }}
          className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
        >
          <img src="/asset/images/arrow/Refresh20.svg" alt="" />
          <div>{pyeong2 ? '㎡' : '평'}</div>
        </div>
        <Tooltip2
          bottom="-40"
          on={onToolTip}
          off={() => setOnToolTip(false)}
          text="더 많은 주변 거래 사례는 고객센터에 문의 바랍니다."
        />
      </div>

      {/* </div> */}
      {/* <div className="padding-8-16 flex-row gap-8" style={{ transform: `translateX(-${calculatePathWidth()}px)`, whiteSpace: "nowrap"}}>
          {renderButtons()}
        </div> */}
      <div
        className="padding-8-16-12 flex-row gap-8 of-x-auto w-100per"
        style={{ whiteSpace: 'nowrap' }}
      >
        {cropBtnArr.map(
          (el, index) =>
            el && ( // null이 아닌 경우에만 렌더링합니다.
              <ButtonSelect1M
                key={index}
                price
                selected={tab === el.selected}
                onClick={() => {
                  setTab(el.selected);
                  // console.log("ef", pAuction.length);
                }}
              >
                {el.children}
              </ButtonSelect1M>
            ),
        )}
      </div>

      <div className="padding-16">
        {/* {data.landTradePrice === null || data.houseTradePrice === null ? (
            <RowTableValueBold
              title="거래이력"
              value="최근 5년 내 거래 이력이 없습니다"
            />
          ) : ( */}
        {tab === 0 ? (
          groupedByType.realDeal.length > 0 ? (
            groupedByType.realDeal
              .slice(0 + (realCurrentPage - 1) * 3, realCurrentPage * 3)
              .map((item: any) => {
                return (
                  <div className="flex-row w-100per space-between">
                    <div className="m-b1-16-m color-text-tertiary">
                      반경 {Math.round(item.distance)}m
                    </div>
                    <div className="flex-column end-x padding-b-24">
                      <div className="m-b1-16-m color-dark-secondary text-end">
                        {item.legaldong}
                      </div>
                      <div className="w-b1-18-b color-text-tertiary text-end">
                        {transNum(Number(item.totalPrice))}원
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {Math.round(
                          pyeong2
                            ? item.perPyeongPrice
                            : item.perPyeongPrice / 3.3058,
                        ).toLocaleString()}
                        원/{pyeong2 ? '평' : '㎡'}
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {item.date}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              className="text-center color-dark-primary"
              style={{ fontFamily: 'Pretendard-medium' }}
            >
              거래 사례가 없습니다
            </div>
          )
        ) : tab === 1 ? (
          groupedByType.sales.length > 0 ? (
            groupedByType.sales
              .slice(0 + (salesCurrentPage - 1) * 3, salesCurrentPage * 3)
              .map((item: any) => {
                return (
                  <div className="flex-row w-100per space-between">
                    <div className="m-b1-16-m color-text-tertiary">
                      반경 {Math.round(item.distance)}m
                    </div>
                    <div className="flex-column end-x padding-b-24">
                      <div className="m-b1-16-m color-dark-secondary text-end">
                        {item.legaldong}
                      </div>
                      <div className="w-b1-18-b color-text-tertiary text-end">
                        {transNum(Number(item.price))}원
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {Math.round(
                          pyeong2
                            ? item.perPyeongPrice
                            : item.perPyeongPrice / 3.3058,
                        ).toLocaleString()}
                        원/{pyeong2 ? '평' : '㎡'}
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {item.date}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              className="text-center color-dark-primary"
              style={{ fontFamily: 'Pretendard-medium' }}
            >
              거래 사례가 없습니다
            </div>
          )
        ) : tab === 2 ? (
          groupedByType.auction.length > 0 ? (
            groupedByType.auction
              .slice(0 + (auctionCurrentPage - 1) * 3, auctionCurrentPage * 3)
              .map((item: any) => {
                return (
                  <div className="flex-row w-100per space-between">
                    <div className="m-b1-16-m color-text-tertiary">
                      반경 {Math.round(item.distance)}m
                    </div>
                    <div className="flex-column end-x padding-b-24">
                      <div className="m-b1-16-m color-dark-secondary text-end">
                        {item.legaldong}
                      </div>
                      <div className="w-b1-18-b color-text-tertiary text-end">
                        {transNum(Number(item.price))}원
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {Math.round(
                          pyeong2
                            ? item.perPyeongPrice
                            : item.perPyeongPrice / 3.3058,
                        ).toLocaleString()}
                        원/{pyeong2 ? '평' : '㎡'}
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {item.date}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              className="text-center color-dark-primary"
              style={{ fontFamily: 'Pretendard-medium' }}
            >
              거래 사례가 없습니다
            </div>
          )
        ) : groupedByType.pAuction.length > 0 ? (
          groupedByType.pAuction
            .slice(0 + (pauctionCurrentPage - 1) * 3, pauctionCurrentPage * 3)
            .map((item: any) => {
              return (
                <div className="flex-row w-100per space-between">
                  <div className="m-b1-16-m color-text-tertiary">
                    반경 {Math.round(item.distance)}m
                  </div>
                  <div className="flex-column end-x padding-b-24">
                    <div className="m-b1-16-m color-dark-secondary text-end">
                      {item.legaldong}
                    </div>
                    <div className="w-b1-18-b color-text-tertiary text-end">
                      {transNum(Number(item.price))}원
                    </div>
                    <div className="m-b2-14-m color-text-tertiary text-end">
                      {Math.round(
                        pyeong2
                          ? item.perPyeongPrice
                          : item.perPyeongPrice / 3.3058,
                      ).toLocaleString()}
                      원/{pyeong2 ? '평' : '㎡'}
                    </div>
                    <div className="m-b2-14-m color-text-tertiary text-end">
                      {item.date}
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <div
            className="text-center color-dark-primary"
            style={{ fontFamily: 'Pretendard-medium' }}
          >
            거래 사례가 없습니다
          </div>
        )}
        <div
          className="padding-16 flex-column gap-16 no-wrap"
          style={{ minWidth: '296px', fontFamily: 'Pretendard-medium' }}
        >
          {tab === 0
            ? groupedByType.realDeal.length > 0 && (
                <Pagination
                  activePage={realCurrentPage}
                  itemsCountPerPage={3}
                  totalItemsCount={groupedByType.realDeal.length}
                  pageRangeDisplayed={5} // 보여줄 페이지 범위
                  onChange={handlePageChange} // 페이지 변경 핸들러 설정
                  firstPageText="처음" // 처음 페이지 버튼 텍스트
                  lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                  activeClass="active" // 활성 페이지 클래스 이름
                  itemClass="page-item" // 페이지 아이템 클래스 이름
                  linkClass="page-link" // 페이지 링크 클래스 이름
                />
              )
            : tab === 1
              ? groupedByType.sales.length > 0 && (
                  <Pagination
                    activePage={salesCurrentPage}
                    itemsCountPerPage={3}
                    totalItemsCount={groupedByType.sales.length}
                    pageRangeDisplayed={5} // 보여줄 페이지 범위
                    onChange={handlePageChange} // 페이지 변경 핸들러 설정
                    firstPageText="처음" // 처음 페이지 버튼 텍스트
                    lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                    activeClass="active" // 활성 페이지 클래스 이름
                    itemClass="page-item" // 페이지 아이템 클래스 이름
                    linkClass="page-link" // 페이지 링크 클래스 이름
                  />
                )
              : tab === 2
                ? groupedByType.auction.length > 0 && (
                    <Pagination
                      activePage={auctionCurrentPage}
                      itemsCountPerPage={3}
                      totalItemsCount={groupedByType.auction.length}
                      pageRangeDisplayed={5} // 보여줄 페이지 범위
                      onChange={handlePageChange} // 페이지 변경 핸들러 설정
                      firstPageText="처음" // 처음 페이지 버튼 텍스트
                      lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                      activeClass="active" // 활성 페이지 클래스 이름
                      itemClass="page-item" // 페이지 아이템 클래스 이름
                      linkClass="page-link" // 페이지 링크 클래스 이름
                    />
                  )
                : tab === 3
                  ? groupedByType.pAuction.length > 0 && (
                      <Pagination
                        activePage={pauctionCurrentPage}
                        itemsCountPerPage={3}
                        totalItemsCount={groupedByType.pAuction.length}
                        pageRangeDisplayed={5} // 보여줄 페이지 범위
                        onChange={handlePageChange} // 페이지 변경 핸들러 설정
                        firstPageText="처음" // 처음 페이지 버튼 텍스트
                        lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                        activeClass="active" // 활성 페이지 클래스 이름
                        itemClass="page-item" // 페이지 아이템 클래스 이름
                        linkClass="page-link" // 페이지 링크 클래스 이름
                      />
                    )
                  : ''}
        </div>
      </div>
    </div>
  );
};

// const SurroundingTrade = ({
//   data,
//   data2,
//   surroundList,
// }: {
//   data: any;
//   data2: any;
//   surroundList: SurroundListProps[];
// }) => {
//   const [tab2, setTab2] = useState(1);
//   console.log('surroundList', surroundList);

//   const groupedByType: { [key: string]: SurroundListProps[] } =
//     surroundList.reduce(
//       (acc, curr) => {
//         if (!acc[curr.type]) {
//           acc[curr.type] = [];
//         }
//         acc[curr.type].push(curr);
//         return acc;
//       },
//       {} as { [key: string]: SurroundListProps[] },
//     );

//   console.log('groupedByType', groupedByType);

//   return (
//     <div className="flex-column" style={{ width: '100%' }}>
//       <div className="padding-8-16 flex-row gap-8">
//         <ButtonSelect1M
//           onClick={() => {
//             setTab2(1);
//           }}
//           selected={tab2 === 1}
//         >
//           거래 이력
//         </ButtonSelect1M>
//         <ButtonSelect1M
//           onClick={() => {
//             setTab2(2);
//           }}
//           selected={tab2 === 2}
//         >
//           주변 거래사례
//         </ButtonSelect1M>
//       </div>
//       {tab2 === 1 ? (
//         <div className="padding-16">
//           {data.landTradePrice === null ? (
//             <RowTableValueBold
//               title=""
//               value="최근 5년 내 거래 이력이 없습니다"
//             />
//           ) : (
//             <RowTableValueBold
//               title=""
//               value={
//                 <div className="flex-column end-y">
//                   <div className="font-medium f-size-16 line-h-24 color-secondary">
//                     {formatToKoreanCurrency(data.landTradePrice)}원
//                   </div>
//                   <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
//                     {Math.round(
//                       data.landTradePrice / (data2.farmArea * 0.3025),
//                     ).toLocaleString()}
//                     원/평
//                   </div>
//                   <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
//                     {data.landTradeDate &&
//                       `${data.landTradeDate.slice(
//                         0,
//                         4,
//                       )}-${data.landTradeDate.slice(
//                         4,
//                         6,
//                       )}-${data.landTradeDate.slice(6, 8)}`}
//                   </div>
//                 </div>
//               }
//             />
//           )}
//         </div>
//       ) : (
//         <div className="flex-column flex-1">
//           {/* <div className="padding-16-16-0 flex-row ">
//                         <div className="font-bold f-size-16 line-h-24 color-text-secondary">
//                           주변 거래사례
//                         </div>
//                         <img
//                           src="/asset/images/warn/Warn24.svg"
//                           className="hover"
//                           onClick={() => setOnToolTip(true)}
//                           alt=""
//                         />
//                       </div> */}
//           <div className="padding-16 flex-column gap-16">
//             {data.surroundings &&
//               data.surroundings.length > 0 &&
//               data.surroundings.map((el: any) => {
//                 let arr = el.address.split(' ');
//                 let num = arr.pop();
//                 let num2 = '';
//                 for (let i = 1; i < num.length; i++) {
//                   num2 = num2 + '*';
//                 }
//                 const address = arr.join(' ') + ' ' + num.slice(0, 1) + num2;
//                 return (
//                   <div className="flex-row space-between">
//                     <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
//                       반경 {Math.round(el.distance)}m
//                     </div>
//                     <div className="flex-column end-y">
//                       <div className="font-medium f-size-16 line-h-24 color-secondary">
//                         {address}
//                       </div>
//                       <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
//                         {Math.round(el.price_per_pyeong).toLocaleString()}
//                         원/평
//                       </div>
//                       <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
//                         {el.landTradeDate.slice(0, 4)}-
//                         {el.landTradeDate.slice(4, 6)}-
//                         {el.landTradeDate.slice(6, 8)}
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

export default SurroundingTrade;
