export const TextSectionTitle = ({
  children,
  warn,
  onClick,
  arrow,
  arrowDirection,
}: {
  children: string | JSX.Element | any;
  warn?: boolean;
  onClick?: any;
  arrow?: boolean;
  arrowDirection?: string;
}) => {
  return (
    <div
      className="font-bold f-size-18 line-h-26 color-text-primary center-y flex-row gap-8"
      style={{ justifyContent: 'space-between' }}
    >
      {children} <div onClick={onClick}>데이터 출처 보기</div>
      {arrow && (
        <img
          className="hover"
          src={`/asset/images/arrow/Arrow${
            arrowDirection === 'down' ? 'Bottom' : 'Top'
          }24-light-gray.svg`}
          alt=""
          onClick={onClick}
        />
      )}
    </div>
  );
};

export const TextTabTitle = ({
  children,
  selected,
}: {
  children: string;
  selected: boolean;
}) => (
  <div
    className="font-bold f-size-18 line-h-26"
    style={{ color: selected ? '#171E26' : '#171E2666' }}
  >
    {children}
  </div>
);

export const TextSectionTitle2 = ({
  children,
  warn,
  onClick,
}: {
  children: string;
  warn?: boolean;
  onClick?: any;
}) => (
  <div className="font-bold f-size-16 line-h-24 color-text-primary center-y">
    {children}{' '}
    {warn ? (
      <img
        className="hover"
        src="/asset/images/warn/Warn24.svg"
        alt=""
        onClick={onClick}
      />
    ) : (
      ''
    )}
  </div>
);


