import { useEffect, useState } from 'react';
import { TopNav2 } from '../newsRoom/NewsRoom.style';
// import { Helmet } from 'react-helmet';

import { Button2 } from './components/Button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DeletePopUp from './DeletePopUp';

const Delete = () => {
  const [onPopUp, setOnPopUp] = useState(false);
  const [socialDelete, setSocialDelete] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [checkedStates, setCheckedStates] = useState(Array(9).fill(false));
  const [state, setState] = useState({
    reason: '',
    password: '',
  });
  const [showInput, setShowInput] = useState(false);
  const [focused, setFocused] = useState(false);
  const handleFocusBlur = (isFocused: any) => {
    setFocused(isFocused);
  };
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();

  function CheckImageWithText(props: { children: string }) {
    return (
      <div className="withdrawal-notice">
        <div className="withdrawal-notice-dot">
          <img src="/asset/images/icon/Ellipse.svg" alt="" />
        </div>
        {props.children}
      </div>
    );
  }

  const handleCheckboxClick = (index: number) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);

    const reasons = [
      '(필수) 유의사항을 확인하였으며 동의합니다.',
      '서비스 사용을 잘 안하게 돼요.',
      '서비스 사용이 어려워요.',
      '다른 유사 서비스를 이용 중이에요.',
      '서비스 이용 금액이 비싼 것 같아요.',
      '혜택(무료 포인트, 쿠폰)이 너무 적어요.',
      '개인정보를 삭제하고 싶어요.',
      '다른 계정이 있어요.',
      '기타',
    ];

    const selectedReasons = newCheckedStates.reduce((acc, isChecked, idx) => {
      // if (isChecked) {
      //   console.log('reasons[idx]', reasons[idx]);
      //   //   if (reasons[idx] === '기타') {
      //   //     reasons[idx] = `${reasons[idx]}: ${inputValue}`;
      //   //     acc.push(reasons[idx]);
      //   //   } else {
      //   //     acc.push(reasons[idx]);
      //   //   }
      // }
      if (isChecked) {
        if (reasons[idx] === '기타' && inputValue) {
          acc.push(`${reasons[idx]}: ${inputValue}`);
        } else if (reasons[idx] !== '기타') {
          acc.push(reasons[idx]);
        }
      }

      return acc;
    }, []);

    setSelectedReasons(selectedReasons);

    setState((prevState) => ({
      ...prevState,
      reason: selectedReasons.join('\n'),
    }));

    if (index === 8) {
      setShowInput(newCheckedStates[8]);
    }
  };

  return (
    <div
      className="flex-column w-100per center-y browser-space-between h-100per "
      style={{
        backgroundColor: 'white',
        boxShadow: '0 0 20px hsla(0, 0%, 51%, 0.15)',
      }}
    >
      <TopNav2>
        <span
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src="/asset/images/arrow/arrow-left-gray.svg"
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
        </span>
        회원탈퇴
      </TopNav2>
      {onPopUp && (
        <DeletePopUp
          setOnPopUp={setOnPopUp}
          selectedReasons={selectedReasons}
        />
      )}
      <div
        className={`flex-column w-100per padding-t-32 padding-b-24 center-y  gap-24`}
      >
        {/* {socialDelete && <DeletePopUp2 />}
        {!isMobile(window.innerWidth) && (
          <div className="flex-start w-100per" style={{ marginTop: '48px' }}>
            <div className="font-bold f-size-26 line-h-34 color-dark-primary ">
              회원탈퇴
            </div>
          </div>
        )} */}
        <div className="flex-column gap-32 w-100per padding-x-16">
          <div className="font-bold f-size-18 line-h-26 color-dark-primary w-100per text-start">
            {localStorage.getItem('name')}님<br />
            <span style={{ color: 'rgba(30, 155, 107, 1)' }}>
              정말 떠나시겠어요?
            </span>
          </div>
        </div>
        <div className="flex-column gap-32 w-100per gap-16 padding-x-16">
          <div
            className="box padding-16"
            style={{
              backgroundColor: 'rgba(112, 115, 124, 0.05)',
              borderRadius: '8px',
            }}
          >
            <CheckImageWithText>
              탈퇴 시 사용자의 모든 정보가 사라지고 복구가 불가능합니다.
            </CheckImageWithText>
            <CheckImageWithText>
              단, 탈퇴 고객의 개인정보는 회원에게 개인정보 보관기간에 대해
              별도의 동의를 얻은 경우, 또는 관련 법령에서 정한 정보보관 의무
              기간 동안 안전하게 보관합니다.
            </CheckImageWithText>
            <CheckImageWithText>
              보유하고 계신 트랜스파머의 보유 포인트, 쿠폰, 내 보고서 등은 자동
              소멸되어 재가입하실 경우에도 복원되지 않습니다.
            </CheckImageWithText>
            <CheckImageWithText>
              자세한 사항은 개인정보처리 방침을 확인해 주세요.
            </CheckImageWithText>
            <CheckImageWithText>
              탈퇴 최종 확인은 휴대폰 번호 인증으로 진행됩니다.
            </CheckImageWithText>
          </div>
        </div>

        <div className="flex-column gap-16 w-100per padding-x-16">
          <div
            className="padding-0-16-0 flex w-100per pointer"
            style={{
              gap: '8px',
              color: '#222222',
              fontSize: '16px',
              fontFamily: 'Pretendard-medium',
              fontWeight: '500',
              lineHeight: '24px',
              wordWrap: 'break-word',
            }}
            onClick={() => handleCheckboxClick(0)}
          >
            <img
              src={`/asset/images/icon/${checkedStates[0] ? 'check_box_on.png' : 'check_box_off.png'}`}
              alt=""
            />{' '}
            (필수) 유의사항을 확인하였으며 동의합니다.
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          height: '8px',
          backgroundColor: '#171E261A',
        }}
      ></div>
      <div
        className="flex-column w-100per h-100per padding-24-16 "
        style={{ marginBottom: '120px' }}
      >
        <div className="flex-column w-100per gap-24 h-100per ">
          <div className="flex-column gap-8 ">
            <div className="font-bold f-size-18 line-h-26 color-dark-primary w-100per text-start">
              <span
                className="font-bold f-size-18 line-h-26 color-dark-primary"
                style={{ color: 'rgba(30, 155, 107, 1)' }}
              >
                떠나시는 이유
              </span>
              가 궁금해요.
            </div>
            <div className="font-medium f-size-16 line-h-26 color-888B90">
              더 발전하는 서비스가 될 수 있도록 노력하겠습니다.
            </div>
          </div>

          <div className="flex-column w-100per h-100per">
            <div
              className="box2"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
                gap: '24px',
                backgroundColor: 'rgba(112, 115, 124, 0.05)',
                borderRadius: '8px',
                height: '100%',
              }}
            >
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(1)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[1] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                서비스 사용을 잘 안하게 돼요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(2)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[2] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                서비스 사용이 어려워요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(3)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[3] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                다른 유사 서비스를 이용 중이에요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(4)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[4] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                서비스 이용 금액이 비싼 것 같아요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(5)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[5] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                혜택(무료 포인트, 쿠폰)이 너무 적어요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(6)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[6] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                개인정보를 삭제하고 싶어요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(7)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[7] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                다른 계정이 있어요.
              </div>
              <div
                className="box2Text"
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(8)}
              >
                <img
                  src={`/asset/images/icon/${checkedStates[8] ? 'check_box_on.png' : 'check_box_off.png'}`}
                  alt=""
                />{' '}
                기타
              </div>
              {showInput && (
                <input
                  className={`customdelete-input ${focused ? 'focused' : ''}`}
                  onFocus={() => handleFocusBlur(true)}
                  onBlur={() => handleFocusBlur(false)}
                  type="text"
                  placeholder="다른 이유가 있다면 알려주세요."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBox>
        <div className=" flex border-t-171E26 w-100per gap-8">
          <Button2
            style={{ color: 'rgba(152, 155, 162, 1)' }}
            on={false}
            onClick={() => {
              navigate('/account');
            }}
            description="취소"
            normal={true}
          />
          <Button2
            on={checkedStates[0]}
            onClick={() => {
              if (!checkedStates[0]) {
                alert('필수 항목에 동의해야 합니다.');
              } else {
                const updatedSelectedReasons = [...selectedReasons];

                // 기존 '기타' 항목 제거
                const otherReasonIndex = updatedSelectedReasons.findIndex(
                  (reason) => reason.startsWith('기타:'),
                );
                if (otherReasonIndex !== -1) {
                  updatedSelectedReasons.splice(otherReasonIndex, 1);
                }

                if (checkedStates[8] && inputValue) {
                  const otherReason = `기타: ${inputValue}`;
                  // '기타'가 이미 선택되어 있다면 중복 방지
                  if (!updatedSelectedReasons.includes(otherReason)) {
                    updatedSelectedReasons.push(otherReason);
                  }
                }

                // 최종적으로 setSelectedReasons로 업데이트하고 팝업 표시
                setSelectedReasons(updatedSelectedReasons);
                setOnPopUp(true);
              }
            }}
            description="탈퇴하기"
            normal={true}
          />
        </div>
      </BottomBox>
    </div>
  );
};

export default Delete;

const BottomBox = styled.div`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, white 20%);
  padding: 24px 16px;
  padding-top: 40px;
`;
