import { useEffect, useState } from 'react';
import { RowSectionTitle, RowBold1 } from '../report/Row';
import { TextSectionTitle } from '../report/Text';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Tooltip2 } from '../common/tooltip';
// import DoughnutChart2 from '../mobile/DoughnutChart2';
// import UserService from '../../../../service/checkAccount/CheckAccount';
import React from 'react';
import { Slider } from '@mui/material';
// import arrowTop from './../../../../asset/images/arrow/arrowTopGray.svg';
// import arrowBot from './../../../../asset/images/arrow/arrowBotGray.svg';
import { ICostAnalysis, ILandInfo } from '../../Main';
// import HouseService from '../../../../service/house/landApi';
// import Spinner from '../browser/Spinner';
import { ButtonSelectorBox } from '../report/Button';
import { BoxGreen1, BoxTable2 } from '../report/Box';
import { utilTransNum } from '../../../../../../../utilities/utils';
import { useDidMountEffect } from '../../../../../../../utilities/hook';
import DoughnutChart2 from '../../../../landIncome/components/DoughnutChart2';
import { styled } from 'styled-components';
// import SignController from '../../../../controller/sign/SignController';
// import useDidMountEffect from '../../../../hooks/UseDidMountEffect';

const ReportDetail8 = ({
  data,
  report,
  isStart,
  type,
  mypage,
  report2,
  totalPeriod,
  setTotalPeriod,
  loanInfo,
  setLoanInfo,
  ownHouseYN,
  updateCostInfo,
}: {
  data: ICostAnalysis;
  type: string;
  report: any;
  isStart: boolean;
  mypage?: boolean;
  report2?: any;
  totalPeriod: any;
  setTotalPeriod: any;
  loanInfo: any;
  setLoanInfo: any;
  ownHouseYN: string;
  updateCostInfo: any;
}) => {
  let maxLoanCost =
    data.houseCostLoanInfo?.totalHouseCost > 75000000
      ? 75000000
      : data.houseCostLoanInfo?.totalHouseCost;
  const [ownMoney, setOwnMoney] = useState(
    (data.houseCostLoanInfo?.ownMoney * 100) /
      data.houseCostLoanInfo?.totalHouseCost,
  );
  const [totalLoanCost, setTotalLoanCost] = useState(
    100 -
      (data.houseCostLoanInfo?.ownMoney * 100) /
        data.houseCostLoanInfo?.totalHouseCost,
  );
  // const [ownMoney, setOwnMoney] = useState();
  // const [totalLoanCost, setTotalLoanCost] = useState(20);

  const [moving, setMoving] = useState(false);

  const dummy = [
    {
      color: '#09CE7D',
      text: '자기자금',
      rate: ownMoney / 100,
      value: (ownMoney * data.houseCostLoanInfo?.totalHouseCost) / 100,
    },
    {
      color: '#1E9B6B',
      text: '융자자금',
      rate: 1 - ownMoney / 100,
      value: (totalLoanCost * maxLoanCost) / 100,
    },
  ];
  const [sliderType, setSlierType] = useState(0);

  const dummy2 = [
    { title: '원리금균등', type: 'T' },
    { title: '원금균등', type: 'P' },
    { title: '만기일시', type: 'B' },
  ];
  const [tab, setTab] = useState(1);
  const [onToolTip, setOnToolTip] = useState(false);
  const [onToolTip2, setOnToolTip2] = useState(false);
  const [onToolTip3, setOnToolTip3] = useState(false);
  const [onToolTip4, setOnToolTip4] = useState(false);
  const [onToolTip5, setOnToolTip5] = useState(false);
  const [onSpinner, setOnSpinner] = useState(false);
  const [open, setOpen] = useState(true);
  const temp = `1. 원리금균등 분할상환 : 매달 원금 + 이자금액을 균등하게 납부하는 방법\n2. 원금균등 분할상환 : 매달 원금을 균등하게 납부하고 이에 따른 이자는 점차 줄어드는 방법\n3. 만기일시 상환 : 대출기간동안 이자만 납부하고 원금은 만기에 일시상환하는 방법`;
  const obj = {
    P: '원금균등',
    T: '원리금균등',
    B: '만기일시',
  };
  let tbList =
    data.houseCostLoanInfo &&
    data.houseCostLoanInfo.loanPlan1Year.map((el: any, i: any) => {
      return [
        i + 1,
        `${el.total}원`,
        `${el.principal}원`,
        `${el.interest}원`,
        `${el.principalSum}원`,
        `${el.remainSum}원`,
      ];
    });
  let tbList2 =
    data.houseCostLoanInfo &&
    data.houseCostLoanInfo.loanPlan.map((el: any, i: any) => {
      return [
        i + 1,
        `${el.total}원`,
        `${el.principal}원`,
        `${el.interest}원`,
        `${el.principalSum}원`,
        `${el.remainSum}원`,
      ];
    });
  const table = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList,
  };
  const table2 = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList2,
  };

  useEffect(() => {
    console.log('tab', tab);
  }, [tab]);

  const [tempRetentionPeriod, setTempRetentionPeriod] = useState(
    loanInfo.retentionPeriod,
  );
  const [tempRate, setTempRate] = useState(loanInfo.loanRate);
  const [name, setName] = useState('');

  useEffect(() => {
    // SignController.getName(setName);
  }, []);
  useEffect(() => {
    setTotalPeriod(
      Number(tempRetentionPeriod) + Number(loanInfo.redemptionPeriod),
    );
  }, [tempRetentionPeriod, loanInfo.redemptionPeriod]);
  useEffect(() => {
    console.log('total', totalPeriod);
  }, [totalPeriod]);

  // useDidMountEffect(() => {
  //   report2(
  //     "H",
  //     (totalLoanCost / 100) * data.houseCostLoanInfo?.totalHouseCost,
  //     loanInfo?.loanRate,
  //     loanInfo?.loanType,
  //     totalPeriod,
  //     loanInfo?.retentionPeriod,
  //     "N"
  //   );
  // }, []);
  // useEffect(() => {
  //   setTotalPeriod(
  //     data.houseCostLoanInfo?.loanInfo.retentionPeriod +
  //       data.houseCostLoanInfo?.loanInfo.redemptionPeriod
  //   );
  // }, [loanInfo]);
  // const patchLoan = async (loanType?: any, totalLoanCost?: any) => {
  //   try {
  //     report2()
  //     if (!isStart) {
  //       return;
  //     }
  //     setOnSpinner(true);
  //     await HouseService.saveHouseLoan(type, data2.pnuCode, {
  //       loanCost:
  //         data.houseCostLoanInfo?.totalHouseCost > 75000000
  //           ? sliderType === 0
  //             ? data.houseCostLoanInfo?.totalHouseCost -
  //               (totalLoanCost * data.houseCostLoanInfo?.totalHouseCost) / 100
  //             : (totalLoanCost * data.houseCostLoanInfo?.totalHouseCost) / 100
  //           : sliderType === 0
  //           ? data.houseCostLoanInfo?.totalHouseCost -
  //             (totalLoanCost * maxLoanCost) / 100
  //           : (totalLoanCost * maxLoanCost) / 100,

  //       loanRate: loanInfo.loanRate,
  //       loanType: loanType,
  //       retentionPeriod: loanInfo.retentionPeriod,
  //       redemptionPeriod: totalPeriod - loanInfo.retentionPeriod,
  //     });
  //     await report();
  //     setOnSpinner(false);
  //   } catch (err) {
  //     console.log(err);
  //     setOnSpinner(false);
  //   }
  // };

  // useDidMountEffect(() => {
  //   patchLoan(loanInfo.loanType);
  // }, [totalLoanCost]);

  // useDidMountEffect(() => {
  //   patchLoan();
  // }, [data.houseCostLoanInfo?.loanInfo.loanType]);

  useEffect(() => {
    // setLoanInfo(data.houseCostLoanInfo?.loanInfo);
    setTotalPeriod(
      data.houseCostLoanInfo?.loanInfo.redemptionPeriod +
        Number(data.houseCostLoanInfo?.loanInfo.retentionPeriod),
    );
    setOwnMoney(
      (data.houseCostLoanInfo?.ownMoney * 100) /
        data.houseCostLoanInfo?.totalHouseCost,
    );
    setTotalLoanCost(
      ((data.houseCostLoanInfo?.totalHouseCost -
        data.houseCostLoanInfo?.ownMoney) /
        data.houseCostLoanInfo?.totalHouseCost) *
        100,
    );
  }, [data.houseCostLoanInfo?.loanInfo]);

  return (
    <div className="padding-b-24 browser-bg-FFFFFF relative">
      {/* {onSpinner && <Spinner />} */}
      <RowSectionTitle>
        <TextSectionTitle
          warn
          onClick={() => {
            setOnToolTip(!onToolTip);
          }}
        >
          6. 주택매입비 대출 진단
        </TextSectionTitle>
      </RowSectionTitle>
      <div style={{ position: 'absolute', zIndex: '1' }}>
        <Tooltip2
          top="-5"
          left="15"
          on={onToolTip}
          off={() => setOnToolTip(false)}
          text={
            <div className="text-left no-wrap">
              본 대출 진단은 참고용으로만 제공됩니다. 트랜스파머㈜ 는 <br />
              실제 대출 승인 여부를 보증하지 않으며, 이로 인해 발생하는 <br />
              어떠한 책임도 지지 않습니다.
            </div>
          }
        />
      </div>
      <div
        className="flex-column"
        style={{
          overflow: 'hidden',
          transition: 'all 0.5s',
          maxHeight: !open ? 0 : '2000px',
        }}
      >
        <div className="padding-16-16-0">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            대출 신청 금액 설정
          </div>
        </div>
        <div className="padding-8-16">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            ※ 본 주택매입비 대출 진단은 귀농 창업 및 주택구입자금 대출(연이자율
            2%)에 한합니다.
          </div>
        </div>
        <div className="padding-16-16-8">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            1) 자부담금 설정
          </div>
        </div>
        <div className="padding-8-16">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            총 주택매입비를 참고하여 <br />
            예상 자부담금과 대출금 비율을 설정하세요.
          </div>
        </div>
        <div className="flex-row space-between center-y padding-8-16-16">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            총 주택매입비
          </div>
          <div className="font-medium f-size-16 line-h-24 color-text-secondary">
            {utilTransNum(data.houseCostLoanInfo?.totalHouseCost)}원
          </div>
        </div>
        <div
          className="flex-row w-100per gap-40 center-y h-100per padding-x-16 "
          style={{ height: '180px' }}
        >
          <DoughnutChart2 list={dummy} />
          <div className="flex-column w-100per gap-8 ">
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-400 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  자부담금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[0].rate * 1000) / 10}%
              </div>
            </div>
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-500 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  대출금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[1].rate * 1000) / 10}%
              </div>
            </div>
          </div>
        </div>
        {[
          {
            title: '자부담금',
            price: ownMoney,
          },
          {
            title: '대출금',
            price: totalLoanCost,
          },
        ].map((el) => (
          <div className="flex-center flex-column padding-8-16-24 gap-30">
            <div className="flex-column flex-center">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                {el.title}
              </div>
              <div className="font-bold f-size-16 line-h-24 color-text-primary ">
                {utilTransNum(
                  el.title === '대출금'
                    ? (totalLoanCost / 100) *
                        data.houseCostLoanInfo?.totalHouseCost
                    : (el.price * data.houseCostLoanInfo?.totalHouseCost) / 100,
                )}
                원
              </div>
            </div>
            <div className="flex-column w-100per">
              <Slider
                value={el.price}
                onChange={(e: any, value: any) => {
                  el.title === '자부담금' ? setSlierType(0) : setSlierType(1);
                  if (el.title === '자부담금') {
                    if (
                      data.houseCostLoanInfo?.totalHouseCost -
                        (data.houseCostLoanInfo?.totalHouseCost * value) /
                          100 <=
                      maxLoanCost
                    ) {
                      setOwnMoney(value);
                      setTotalLoanCost(100 - value);
                    }
                  } else {
                    if (
                      (data.houseCostLoanInfo?.totalHouseCost * value) / 100 <=
                      maxLoanCost
                    ) {
                      setTotalLoanCost(value);
                      setOwnMoney(100 - value);
                    }
                  }
                }}
                onChangeCommitted={(e: any, value: any) => {
                  if (el.title === '자부담금') {
                    if (
                      data.houseCostLoanInfo?.totalHouseCost -
                        (data.houseCostLoanInfo?.totalHouseCost * value) /
                          100 <=
                      maxLoanCost
                    ) {
                      setOwnMoney(value);
                      setTotalLoanCost(100 - value);
                    }
                  } else {
                    if (
                      (data.houseCostLoanInfo?.totalHouseCost * value) / 100 <=
                      maxLoanCost
                    ) {
                      setTotalLoanCost(value);
                      setOwnMoney(100 - value);
                    }
                  }
                  setLoanInfo((prev: any) => {
                    return {
                      ...prev,
                      loanCost:
                        (totalLoanCost / 100) *
                        data.houseCostLoanInfo?.totalHouseCost,
                    };
                  });
                  // report2(
                  //   "H",
                  //   (totalLoanCost / 100) *
                  //     data.houseCostLoanInfo?.totalHouseCost,
                  //   loanInfo.loanRate,
                  //   loanInfo.loanType,
                  //   totalPeriod,
                  //   loanInfo.retentionPeriod,
                  //   "N"
                  // );
                }}
                marks={(() => {
                  let arr = [];
                  for (let i = 10; i < 100; i += 10) {
                    arr.push({ value: i });
                  }
                  return arr;
                })()}
                valueLabelDisplay={'on'}
                slotProps={{ valueLabel: { style: { display: 'none' } } }}
                valueLabelFormat={(value: number, index: number) => (
                  <div
                    className="speech-bubble font-bold f-size-11 line-h-12 border-radius-2"
                    style={{
                      backgroundColor: '#2A3746',
                      color: 'white',
                      padding: '4px 5px',
                      marginLeft: 0,
                      marginBottom: 0,
                    }}
                  >
                    {Math.round(value)}
                    {/* {Math.round(el.title === "대출금" ? 100 - ownMoney : value)} */}
                    %
                  </div>
                )}
                step={1}
                tabIndex={10}
                // getAriaValueText={valuetext}
                sx={{
                  height: '8px',
                  '& .MuiSlider-thumb': {
                    color: '#166F4D',
                  },
                  '& .MuiSlider-track': { color: '#1E9B6B' },
                  '& .MuiSlider-rail': { color: '#F0F0F0' },
                  '& .MuiSlider-active': {
                    color: 'green',
                  },
                  '& .Mui-active': {
                    boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                  },
                  '& .MuiSlider-valueLabel': { background: 'none' },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#FFFFFF',
                    height: 8,
                  },
                }}
                min={0}
                max={100}
              />
              <div className="flex-row w-100per">
                {[
                  { 1: '0%', 2: '최소', 3: 'start-y' },
                  { 1: '50%', 2: '기준', 3: 'center-y' },
                  { 1: '100%', 2: '최대', 3: 'end-y' },
                ].map((el) => (
                  <div className={'flex-column flex-1 ' + el['3']}>
                    <div className="font-medium f-size-12 line-h-14 color-text-secondary">
                      {el['1']}
                    </div>
                    <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                      {el['2']}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="padding-16-16-8">
          <div className="font-bold f-size-16 line-h-24 color-secondary">
            2) 대출 조건 설정
          </div>
        </div>
        <div className="flex-column gap-16 padding-8-16">
          <div className="flex-row gap-24">
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출 신청 금액
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  readOnly
                  value={utilTransNum(
                    (totalLoanCost * data.houseCostLoanInfo?.totalHouseCost) /
                      100,
                  )}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  원
                </div>
              </div>
            </div>
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                연이자율
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-50per"
                  placeholder="입력해주세요"
                  value={Math.round(tempRate * 100)}
                  type="number"
                  onChange={(e: any) => {
                    setTempRate(e.target.value / 100);
                  }}
                  onBlur={(e: any) =>
                    // report2(
                    //   "H",
                    //   (totalLoanCost / 100) *
                    //     data.houseCostLoanInfo?.totalHouseCost,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // )
                    setLoanInfo((prev: any) => {
                      return {
                        ...prev,
                        loanRate: tempRate,
                      };
                    })
                  }
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row gap-24">
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-50per"
                  placeholder="입력해주세요"
                  value={totalPeriod}
                  type="number"
                  onChange={(e: any) => setTotalPeriod(e.target.value)}
                  onBlur={(e: any) =>
                    // report2(
                    //   "H",
                    //   (totalLoanCost / 100) *
                    //     data.houseCostLoanInfo?.totalHouseCost,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // )
                    {
                      if (totalPeriod >= loanInfo.retentionPeriod) {
                        setLoanInfo((prev: any) => {
                          return {
                            ...prev,
                            redemptionPeriod:
                              totalPeriod - loanInfo.retentionPeriod,
                          };
                        });
                      }
                    }
                  }
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                거치기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-50per"
                  placeholder="입력해주세요"
                  type="number"
                  value={tempRetentionPeriod}
                  onChange={(e: any) => setTempRetentionPeriod(e.target.value)}
                  onBlur={(e: any) =>
                    // report2(
                    //   "H",
                    //   (totalLoanCost / 100) *
                    //     data.houseCostLoanInfo?.totalHouseCost,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // )
                    {
                      setLoanInfo((prev: any) => {
                        return {
                          ...prev,
                          retentionPeriod: Number(tempRetentionPeriod),
                        };
                      });
                    }
                  }
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column gap-8">
            <div className="flex-row center-y">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                상환방식
              </div>
              <img
                src="/asset/images/warn/Warn24.svg"
                alt=""
                className="hover"
                onClick={() => {
                  setOnToolTip2(!onToolTip2);
                }}
              />
            </div>

            <div className="flex-row gap-12">
              {dummy2.map((item, index) => {
                return (
                  <div
                    className={`padding-6-8  border-radius-8 w-100per flex-center pointer ${
                      loanInfo.loanType === item.type
                        ? `bg-primary-600`
                        : `bg-light-gray-200`
                    }`}
                    onClick={() => {
                      setLoanInfo((prev: any) => {
                        return { ...prev, loanType: item.type };
                      });
                    }}
                  >
                    <div
                      className={`font-bold f-size-12 line-h-24 ${
                        loanInfo.loanType === item.type
                          ? `color-white`
                          : `color-disabled`
                      }`}
                    >
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Tooltip2
        left="10"
        on={onToolTip2}
        off={() => setOnToolTip2(false)}
        text={temp}
      />
      <div className="w-100epr center-x padding-t-16 ">
        <div
          className="flex-center w-32 h-32 bg-light-gray-200 border-radius-999 pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {/* <img className="w-14 h-8" src={!open ? arrowBot : arrowTop} /> */}
        </div>
      </div>

      <div className="padding-16 ">
        <div
          style={{ background: '#171e2610', width: '100%', height: '1px' }}
        ></div>
      </div>

      <div style={{ padding: '24px 16px 8px' }}>
        <ButtonBox>
          <TabButton onClick={() => setTab(1)} active={tab === 1}>
            <div>대출 적격 여부</div>
          </TabButton>
          <TabButton onClick={() => setTab(2)} active={tab === 2}>
            <div>1년 상환계획</div>
          </TabButton>
          <TabButton onClick={() => setTab(3)} active={tab === 3}>
            <div>전체 상환계획</div>
          </TabButton>
        </ButtonBox>
      </div>
      {tab === 1 ? (
        <div className="flex-column">
          {[data.houseCostLoanInfo?.loanInfo].map((el: any, i: any) => (
            <div className="flex-column">
              {/* <div className="padding-16-16-8">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  \ 진단
                </div>
              </div> */}

              {[
                {
                  title: '1) 신청금액',
                  case: true,
                  onClick: () => {
                    setOnToolTip3(!onToolTip3);
                  },
                  tooltipText:
                    ownHouseYN === 'Y'
                      ? '신축(개축, 재축 포함) 2억원, 증축·대수선 1억원 내'
                      : '세대당 7천5백만원 한도 이내 대출 가능. \n(단, 단독주택 및 부속건축물을 합한 연면적이 150㎡(45.4평) 이하일 경우 가능)',
                  on: onToolTip3,
                },
                {
                  title: '2) 시공면적',
                  case: data.eligibility.constructionArea <= 150,
                  onClick: () => {
                    setOnToolTip4(!onToolTip4);
                  },
                  tooltipText: '연면적 150m² 이하',
                  on: onToolTip4,
                },
                {
                  title: '3) 농촌지역 거주',
                  case: data.eligibility.liveInFarmYN === 'Y',
                  onClick: () => {
                    setOnToolTip5(!onToolTip5);
                  },
                  tooltipText:
                    '농촌이란 다음의 어느 하나에 해당하는 지역을 말한다.\n1. 읍･면의 지역\n\n2. 지방자치법 :  주거･상업･공업지역 외의 용도지역\n\n3. 지방자치법 : 가. 도시지역의 녹지지역 중 생산･보전녹지지역  나. 관리지역 중 생산･보전관리지역 다. 농림･자연환경보전지역',
                  on: onToolTip5,
                },
                {
                  title: (
                    <div
                      className="font-bold f-size-14 line-h-20 color-text-secondary flex-row gap-4 center-y"
                      style={{ wordBreak: 'keep-all' }}
                    >
                      <span className="font-bold f-size-16 line-h-24">4)</span>
                      귀농 농업창업 및 주택구입 지원사업원 대상
                    </div>
                  ),
                  case: data.eligibility.returnToFarmYN === 'Y',
                  onClick: () => {},
                },
              ].map((el, i) => (
                <div className="padding-16-16-8">
                  <RowBold1
                    title={el.title}
                    warn={i === 3 ? false : true}
                    green={el.case}
                    value={el.case ? '적격' : '부적격'}
                    onClick={el.onClick}
                  />
                  <Tooltip2 text={el.tooltipText} on={el.on} off={el.onClick} />
                </div>
              ))}

              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    {[
                      true,
                      data.eligibility.constructionArea <= 150,
                      data.eligibility.liveInFarmYN === 'Y',
                      data.eligibility.returnToFarmYN === 'Y',
                    ].filter((el) => el).length === 4 ? (
                      <>
                        {name}님의 대출 적격 요건은 <br />
                        <span className="color-primary-500">
                          모든 항목에서 부합합니다.
                        </span>
                      </>
                    ) : (
                      <>
                        {name}님은{' '}
                        <span className="color-primary-500">
                          {4 -
                            [
                              true,
                              data.eligibility.constructionArea <= 150,
                              data.eligibility.liveInFarmYN === 'Y',
                              data.eligibility.returnToFarmYN === 'Y',
                            ].filter((el) => el).length}
                          개 항목
                        </span>
                        에서
                        <br />
                        대출 적격 요건을 충족하지 못하여
                        <br />
                        <span className="color-primary-500">
                          부적격 항목의 기준을 달성해야 합니다.
                        </span>
                      </>
                    )}
                  </div>
                </BoxGreen1>
              </div>
            </div>
          ))}
        </div>
      ) : tab === 2 ? (
        <div className="flex-column">
          {/* <div className="padding-16-16-8">
        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
          향후 1년 상환 계획
        </div>
      </div> */}
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.houseCostLoanInfo?.loanInfo.loanType as 'P']}
            </div>
          </div>
          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '224px' }}
          >
            <BoxTable2 table={table} />
          </div>
        </div>
      ) : tab === 3 ? (
        <div className="flex-column">
          {/* <div className="padding-16-16-8">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              전체 상환 계획
            </div>
          </div> */}
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.houseCostLoanInfo?.loanInfo.loanType as 'P']}
            </div>
          </div>

          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '224px' }}
          >
            <BoxTable2 table={table2} />
          </div>
          <div className="flex- row padding-12-16 gap-8 end-x">
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#09CE7D' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-primary-400">
                이자
              </div>
            </div>
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#869CB6' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
                납입원금
              </div>
            </div>
          </div>
          <div
            className="of-x-auto"
            style={{
              width: `${
                window.innerWidth ? window.innerWidth - 16 : 365 - 16
              }px`,
            }}
          >
            <AreaChart
              className="flex-medium f-size-11 center-x"
              width={window.innerWidth ? window.innerWidth - 16 : 365 - 16}
              height={181}
              data={data.houseCostLoanInfo.loanPlan}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey="round" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="interest"
                name="이자"
                fill="#09CE7D"
              />
              <Area
                type="monotone"
                dataKey="principal"
                name="납입원금"
                fill="#869CB6"
              />
            </AreaChart>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

interface TabButtonProps {
  active: boolean;
}

const ButtonBox = styled.div`
  padding: 2px;
  border-radius: 8px;
  background: var(--Alias-Color-Text-White-Gray-LightGray-200, #f0f0f0);
  border: 1px solid var(--Alias-Color-Text-White-Gray-LightGray-200, #f0f0f0);
  display: flex;
  flex-direction: row;
`;

const TabButton = styled.div<TabButtonProps>`
  border-radius: 8px;
  flex: 1;
  cursor: pointer;
  background: ${({ active }) => (active ? '#fff' : 'transparent')};
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  font-size: 16px;
  color: ${({ active }) => (active ? '#222222' : '#171e2666')};
  padding: 8.5px 4px;
  text-align: center;
`;

export default ReportDetail8;
