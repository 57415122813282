export const TodayDate = () => {
  const today = new Date();

  const year = String(today.getFullYear()).slice(-2);
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}.${month}.${day}`;

  return formattedDate;
};

export const formatPercentage = (value?: number): string => {
  if (typeof value !== 'number' || isNaN(value)) {
    return ''; // Handle invalid input gracefully
  }

  return `${value.toFixed(1)}%`;
};

export const formatManWons = (value: number) => {
  let unit, divisor, decimalPlaces, price;

  if (value >= 100000000) {
    // 1억 이상인 경우
    unit = '억원';
    divisor = 10000000; // 억 단위로 나누기
    decimalPlaces = 1;
  } else {
    // 1억 미만인 경우
    unit = '만원';
    divisor = 1000; // 만 단위로 나누기
    decimalPlaces = value >= 10000000 ? 0 : 1; // 1천만 이상은 소수점 없음
  }

  // 1천만원 이상일 경우에 ','를 넣도록 처리
  const dividedValue = (Math.round(Number(value) / divisor) / 10).toFixed(
    decimalPlaces,
  );
  price = parseFloat(dividedValue).toLocaleString() + unit;

  return price;
};

// 만원 단위 숫자를 한글로 변환하는 함수(억 + 만 단위)
export const formatToKoreanCurrency = (value: any) => {
  if (typeof value !== 'number') {
    value = Number(value);
  }
  const eok = Math.floor(value / 100000000); // 억 단위
  const man = Math.round((value % 100000000) / 10000); // 만 단위 (반올림)

  let result = '';

  if (eok > 0) {
    result += `${eok.toLocaleString()}억 `;
  }

  if (man > 0) {
    result += `${man.toLocaleString()}만원`;
  }

  return result.trim();
};

export const transNum = (num: number | null, trim?: any, price?: any) => {
  if (num === null) {
    return '-';
  }
  if (typeof num !== 'number') {
    return 0;
  }

  // 음수를 양수로 변환
  num = Math.abs(num);

  if (num >= 100000000) {
    return `${(Math.round(num / 10000000) / 10).toLocaleString()}${
      trim ? '' : ' '
    }억`;
  } else if (num >= 10000) {
    return price
      ? `${(Math.round(num / 1000) / 10).toLocaleString()}${trim ? '' : ' '}만`
      : `${Math.round(num / 10000).toLocaleString()}${trim ? '' : ' '}만`;
  } else {
    return price
      ? `${(num / 10000).toFixed(1).toLocaleString()}${trim ? '' : ' '}만`
      : `${Math.round(num).toLocaleString()}${trim ? '' : ' '}`;
  }
};

// 기존 음수 출력 transNum 함수

export const transNum2 = (num: number | null, trim?: any, price?: any) => {
  if (num === null) {
    return '-';
  }
  if (typeof num !== 'number') {
    return 0;
  }

  if (num >= 100000000) {
    return `${(Math.round(num / 10000000) / 10).toLocaleString()}${
      trim ? '' : ' '
    }억`;
  } else if (num >= 10000) {
    return price
      ? `${(Math.round(num / 1000) / 10).toLocaleString()}${trim ? '' : ' '}만`
      : `${Math.round(num / 10000).toLocaleString()}${trim ? '' : ' '}만`;
  } else if (num <= -100000000) {
    return `${(
      -1 *
      (Math.round((num * -1) / 10000000) / 10)
    ).toLocaleString()}${trim ? '' : ' '}억`;
  } else if (num <= -10000) {
    return price
      ? `${(Math.round(num / 1000) / 10).toLocaleString()}${trim ? '' : ' '}만`
      : `${Math.round(num / 10000).toLocaleString()}${trim ? '' : ' '}만`;
  } else {
    return price
      ? `${(num / 10000).toFixed(1).toLocaleString()}${trim ? '' : ' '}만`
      : `${Math.round(num).toLocaleString()}${trim ? '' : ' '}`;
  }
};
