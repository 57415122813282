import styled from 'styled-components';
import {
  XAxis,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { AnalysisProps } from '../../data';
import {
  formatManWons,
  formatToKoreanCurrency,
} from '../../../../function/function';

interface DummyData {
  name: string;
  price: number;
  gapOfPrice?: number; // 중위값과의 차이
  labelPrice: number;
}

interface AnalysisData {
  data: AnalysisProps;
  pyeong: number;
  price: number;
  selectedType: string;
}

const PropertyChart = ({ data, pyeong, price, selectedType }: AnalysisData) => {
  const analysisData = selectedType === 'building' ? data?.house : data?.land;
  const chartDataSet: DummyData[] = [
    {
      name: '해당 매물',
      price:
        analysisData.price - analysisData.avg > 0
          ? analysisData.avg
          : analysisData.price,
      gapOfPrice:
        analysisData.price - analysisData.avg > 0
          ? analysisData.price - analysisData.avg
          : 0,
      labelPrice: analysisData.price,
    },
    {
      name: '하위 평당 추정가',
      price: analysisData.low_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.low_avg,
    },

    {
      name: '기준 평당 추정가',
      price: analysisData.avg,
      gapOfPrice: 0,
      labelPrice: analysisData.avg,
    },
    {
      name: '상위 평당 추정가',
      price: analysisData.high_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.high_avg,
    },
  ];
  const medianValue = analysisData.avg;

  const distances = {
    high: Math.abs(analysisData.price - analysisData.high_avg),
    avg: Math.abs(analysisData.price - analysisData.avg),
    low: Math.abs(analysisData.price - analysisData.low_avg),
  };

  const closestAverage = Math.min(distances.high, distances.avg, distances.low);

  const renderDescription = () => {
    if (closestAverage === distances.high)
      return (
        <Description>
          해당 매물은
          <TextRed> 상위 추정가</TextRed>에 근접해요
        </Description>
      );
    if (closestAverage === distances.avg)
      return (
        <Description>
          해당 매물은 <TextGreen> 추정가</TextGreen>에 근접해요
        </Description>
      );
    return (
      <Description>
        해당 매물은
        <TextBlue> 하위 추정가</TextBlue>에 근접해요
      </Description>
    );
  };

  return (
    <>
      <TAnalyzeChartBox>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={328}
            height={176}
            data={chartDataSet}
            margin={{ top: 25, right: 20 }}
          >
            <XAxis
              axisLine={false} // X축 선을 제거
              tickLine={false} // X축 tick을 제거
              dataKey="name"
              interval={0}
              tick={({ x, y, payload }) => {
                const isHighlighted = payload.value === '해당 매물';
                const padding = isHighlighted ? 0 : -4;

                // 긴 텍스트에 대해 줄바꿈 처리를 위한 분리 로직
                const lines: string[] = payload.value.includes('추정가')
                  ? [payload.value.slice(0, -3), '추정가']
                  : [payload.value];
                return (
                  <g transform={`translate(${x + padding},${y})`}>
                    <text
                      dy={16}
                      fill={
                        isHighlighted ? '#222222' : 'rgba(55, 56, 60, 0.61)'
                      }
                      fontSize="11px"
                      fontFamily="Pretendard-Medium"
                      fontWeight="500"
                      textAnchor="middle"
                      // style={{
                      //   whiteSpace: 'nowrap',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      // }}
                    >
                      {/* {payload.value} */}
                      {lines.map((line, index) => (
                        <tspan
                          key={index}
                          x={lines.length > 1 ? 4 : 0}
                          dy={index === 0 ? 8 : 11}
                        >
                          {line}
                        </tspan>
                      ))}
                    </text>
                  </g>
                );
              }}
            />
            <Bar dataKey="price" barSize={40} stackId="a" radius={[0, 0, 4, 4]}>
              {chartDataSet.map((entry, index) => {
                // 색상 결정
                let fillColor = '#D2D8DF'; // 기본 색상 (검정색)
                if (entry.name === '해당 매물' && entry.gapOfPrice === 0) {
                  fillColor = '#1E9B6B';
                  return (
                    <Cell key={`cell-${index}`} fill={fillColor} radius={4} />
                  );
                }
                if (entry.name === '해당 매물') {
                  fillColor = '#1E9B6B';
                  return <Cell key={`cell-${index}`} fill={fillColor} />;
                } else {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.name === '기준 평당 추정가'
                          ? '#484F55'
                          : '#D2D8DF'
                      }
                      radius={4}
                    />
                  );
                }
              })}
            </Bar>
            <Bar
              dataKey="gapOfPrice"
              barSize={40}
              stackId="a"
              radius={[4, 4, 0, 0]}
              fill="#25805E"
            >
              <LabelList
                dataKey="labelPrice"
                position="top"
                content={({ x, y, value, index }) => {
                  if (index !== undefined) {
                    return (
                      <text
                        x={Number(x) + 20}
                        y={Number(y) - 8}
                        fill="#000"
                        textAnchor="middle"
                        fontSize="14"
                        fontFamily="Pretendard-Bold"
                      >
                        {formatManWons(Number(value))}
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>
            <ReferenceLine
              y={medianValue}
              stroke="rgba(55, 56, 60, 0.28)"
              strokeDasharray="3 3"
              label={{
                position: 'insideRight',
                value: '기준',
                fontSize: 12,
                fontFamily: 'Pretendard-Medium',
                dx: 20,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </TAnalyzeChartBox>
      <AveragePriceListBox>
        <PriceBox>
          <AveragePriceList>
            <PriceName>
              <img src="/asset/images/icon/dot_price.svg"></img>
              <div>매도희망가</div>
            </PriceName>
            <MaxPrice>{formatToKoreanCurrency(price)}</MaxPrice>
          </AveragePriceList>
          <ListDivider></ListDivider>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_low_avg.svg"></img>
              <div>하위 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {closestAverage === distances.low && <BlueTag>근접</BlueTag>}
              {formatToKoreanCurrency(analysisData.low_avg * pyeong)}~
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_avg.svg"></img>
              <div>기준 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {closestAverage === distances.avg && <GreenTag>근접</GreenTag>}
              {formatToKoreanCurrency(analysisData.avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_high_avg.svg"></img>
              <div>상위 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {closestAverage === distances.high && <RedTag>근접</RedTag>}
              {'~' + formatToKoreanCurrency(analysisData.high_avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
        </PriceBox>
        {renderDescription()}
      </AveragePriceListBox>
    </>
  );
};

export default PropertyChart;

const TAnalyzeChartBox = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 180px;
`;

const AveragePriceListBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
  gap: 13px;
`;

const PriceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
`;
const Description = styled.div`
  width: 100%;
  font-size: 14px;
  font-family: Pretendard-Medium;
  color: #616161;
  text-align: center;
  margin-top: 8px;
  padding: 8px 0;
  background-color: #eff2f5;
  border-radius: 8px;
`;
const TextRed = styled.span`
  color: #ec4f4f;
  line-height: 20px;
`;
const TextBlue = styled.span`
  color: #358cff;
  line-height: 20px;
`;
const TextGreen = styled.span`
  color: #1e9b6b;
  line-height: 20px;
`;

const AveragePriceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  width: 100%;
  color: #222222;
`;

const AveragePriceContent = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
const MaxPrice = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e2e4;
`;

const TextGray = styled.div`
  display: flex;
  font-size: 13px;
  color: #616161;
  gap: 4px;
`;

const RedTag = styled.span`
  padding: 3px 4px;
  border-radius: 4px;
  background-color: #ffeded;
  color: rgba(236, 79, 79, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
`;
const GreenTag = styled.span`
  padding: 3px 4px;
  border-radius: 4px;
  background-color: #eaf8f2;
  color: rgba(30, 155, 107, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
`;
const BlueTag = styled.span`
  padding: 3px 4px;
  border-radius: 4px;
  background-color: #ebeffa;
  color: rgba(53, 140, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
`;

const PriceName = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
`;
