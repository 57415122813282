import styled from 'styled-components';
import colors from '../../color';
import ListTitle from './components/ListTitle';
import ListCategory from './components/ListCategory';
import SelectTypeBox from './components/SelectTypeBox';
import Interaction from '../../service/interaction';
import { LikeProps } from './data';
import ListImg from './components/ListImg';
import { useNavigate } from 'react-router-dom';

interface InterestingItemsProps {
  data: LikeProps[];
  sort: string;
  setSort: (value: string) => void;
  setIsChanged: (value: boolean) => void;
}

const InterestingItems = ({
  data,
  sort,
  setSort,
  setIsChanged,
}: InterestingItemsProps) => {
  const navigate = useNavigate();

  const disLike = async (int_idx: number) => {
    try {
      const res = await Interaction.disLike(int_idx);
      setIsChanged(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAnalysisType = (
    c_idx: number,
    analysisType: string,
    pnu: string,
    type: string,
  ) => {
    let encodeType = type === '토지' ? 'L' : 'H';
    if (!c_idx) {
      navigate(
        `/detail?type=${encodeType}&pnu=${pnu}&analysisType=${analysisType}`,
      );
      return;
    } else {
      navigate(`/detail?c_idx=${c_idx}&analysisType=${analysisType}`);
    }
  };

  const navigeToDetail = (item: any) => {
    const encodeType = item.type === '토지' ? 'L' : 'H';
    if (item.c_idx !== null) {
      return navigate(`/detail?c_idx=${item.c_idx}`);
    } else {
      return navigate(`/detail?type=${encodeType}&pnu=${item.pnu}`);
    }
  };

  return (
    <Container>
      <TitleBox>
        <Title>관심 매물</Title>
        <FilterBox>
          <SelectTypeBox value={sort} onChange={setSort}></SelectTypeBox>
        </FilterBox>
      </TitleBox>
      <ListCardWrapper>
        {Array.isArray(data) && data.length > 0
          ? data.map((item, index) => (
              <ListCardBox key={index}>
                <ListCard>
                  <ListImgBox
                    onClick={() => {
                      navigeToDetail(item);
                    }}
                  >
                    <ListImg
                      img={item.img}
                      lat={item.lat}
                      lng={item.lng}
                      geom={item.geom}
                      price={item.price || 0}
                      category={item.category || ''}
                      area={item.pyeong || 0}
                      pyeong={item.pyeong || 0}
                      bidcount={item.bidCount ?? 0}
                      saleType={item.saleType || ''}
                      dealdate={item.dealDate || ''}
                    ></ListImg>
                    {item.is_safe_to_sell === 'Y' && (
                      <SafeImg src="/asset/images/icon/safe.svg" />
                    )}
                    <HeartImg
                      src="/asset/images/icon/red-heart.svg"
                      onClick={() => {
                        if (window.confirm('찜을 취소하시겠습니까?')) {
                          disLike(item.int_idx);
                          window.alert('찜이 취소되었습니다.');
                        }
                      }}
                    />
                  </ListImgBox>
                  <ListContentBox>
                    <ListContentInfo
                      onClick={() => {
                        navigeToDetail(item);
                      }}
                    >
                      <ListTitle
                        saleType={item.saleType}
                        type={item.type}
                        caseNo={item.caseno || ''}
                        court={item.court || ''}
                        price={item.price || 0}
                        dealdate={item.dealDate || ''}
                        address={item.address}
                        zoning={item.zoning}
                        data={item}
                      />
                      <ListCategory
                        unit={'pyeong'}
                        type={item.type}
                        saleType={item.saleType}
                        category={item.category}
                        pyeong={item.pyeong}
                        zoningBadge={item.zoningBadge || ''}
                        bidCount={item.bidCount || 0}
                        approvalDate={Number(item.approvalDate || '0')}
                        option={item.is_option}
                        road={''}
                      />
                      <RealDealBox>
                        <div>최근 한달 간 주변 실거래 발생</div>
                        {item.count === 0 ? (
                          <div>
                            <RedText>없음</RedText> 건
                          </div>
                        ) : (
                          <div>
                            <RedText>{item.count}</RedText> 건
                          </div>
                        )}
                      </RealDealBox>
                    </ListContentInfo>

                    <ButtonWrapper>
                      {item.category === '대'||item.category === '임야' || item.type === '건물' ? (
                        <>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                item.c_idx ?? 0,
                                'sale',
                                item.pnu ?? '',
                                item.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  item.a_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              가격분석
                            </div>
                            {item.a_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                item.c_idx ?? 0,
                                'house',
                                item.pnu ?? '',
                                item.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  item.d_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              주택 견적분석
                            </div>
                            {item.d_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                        </>
                      ) : (
                        <>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                item.c_idx ?? 0,
                                'sale',
                                item.pnu ?? '',
                                item.type ?? '',
                              )
                            }
                          >
                            <div>{item.category}</div>
                            <div> {item.type}</div>
                            <div
                              style={{
                                color:
                                  item.a_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              가격분석
                            </div>
                            {item.a_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                item.c_idx ?? 0,
                                'land',
                                item.pnu ?? '',
                                item.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  item.b_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              경작소득 분석
                            </div>
                            {item.b_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                item.c_idx ?? 0,
                                'pension',
                                item.pnu ?? '',
                                item.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  item.c_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              농지연금 분석
                            </div>
                            {item.c_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                        </>
                      )}
                    </ButtonWrapper>
                  </ListContentBox>
                </ListCard>
              </ListCardBox>
            ))
          : null}
      </ListCardWrapper>
      {data.length === 0 && (
        <NoLIst>
          <img
            src="/asset/images/gif/mychon-favorite.gif"
            alt=""
            style={{ width: '240px', height: '184px' }}
          />
          <div>
            <div>저장하신 관심 매물이 없어요</div>
            <NoListText>
              <img src="/asset/images/icon/heart-outline-gray.svg" alt="" />를
              눌러 관심 매물을 추가해 보세요
            </NoListText>
          </div>
        </NoLIst>
      )}
    </Container>
  );
};

export default InterestingItems;

const ListCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: ${colors.lineNeutral};
  align-self: center;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  // align-items: center;
  border-radius: 8px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  background-color: #f5f5f5;
  padding: 12px 16px;
`;

const RedText = styled.span`
  color: #ec4f4f;
  font-weight: 700;
`;

const RealDealBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  gap: 8px;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ListContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${colors.white};
  border-bottom-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-bottom-right-radius: 8px; /* 오른쪽 위 모서리 */
`;

const ListContentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`;

const ListImgBox = styled.div`
  width: 100%;
  height: 166px;
  background-color: white;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */
  position: relative;
  border-color: none;
  outline: none;
  cursor: pointer;
`;

export const SafeImg = styled.img`
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1;
`;

export const HeartImg = styled.img`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
`;

interface ButtonProps {
  active: boolean;
}

const Container = styled.div`
  width: 100%;
  padding: 24px 16px 72px;
  background-color: #f5f5f5;
  flex-grow: 1;
  margin-bottom: 54px;
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding-bottom: 16px;
`;
const Title = styled.span`
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
const FilterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 10px 0;
  padding-bottom: 26px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const BottonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

const Button = styled.span<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 9999px;
  color: ${(props) => (props.active ? colors.white : colors.labelNeutral)};
  background-color: ${(props) =>
    props.active ? colors.labelNormal : colors.white};
  border: ${(props) =>
    props.active ? 'none' : `1px solid ${colors.lineNeutral}`};
  cursor: pointer;
`;

const FilterSelect = styled.select`
  border: none;
  color: ${colors.labelNeutral};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

const ListCardBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-radius: 8px;
`;

const ListCard = styled.div`
  width: 100%;
`;

const NoLIst = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
  text-align: center;
`;

const NoListText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
