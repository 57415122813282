import styled from 'styled-components';
import {
  XAxis,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { AnalysisProps } from '../../data';
import {
  formatManWons,
  formatToKoreanCurrency,
} from '../../../../function/function';

interface DummyData {
  name: string;
  price: number;
  gapOfPrice?: number; // 중위값과의 차이
  labelPrice: number;
}

interface AnalysisData {
  data: AnalysisProps;
  pyeong: number;
  selectedType: string;
}

const AuctionChart = ({ data, pyeong, selectedType }: AnalysisData) => {
  const analysisData = selectedType === 'building' ? data?.house : data?.land;

  const chartDataSet: DummyData[] = [
    {
      name: '감정가',
      price:
        (analysisData.appraisedPrice ?? 0) - analysisData.avg > 0
          ? analysisData.avg
          : (analysisData.appraisedPrice ?? 0),
      gapOfPrice:
        (analysisData.appraisedPrice ?? 0) - analysisData.avg > 0
          ? (analysisData.appraisedPrice ?? 0) - analysisData.avg
          : 0,
      labelPrice: analysisData.appraisedPrice ?? 0,
    },
    {
      name: '최저입찰가',
      price:
        (analysisData.lowestPrice ?? 0) - analysisData.avg > 0
          ? analysisData.avg
          : (analysisData.lowestPrice ?? 0),
      gapOfPrice:
        (analysisData.lowestPrice ?? 0) - analysisData.avg > 0
          ? (analysisData.lowestPrice ?? 0) - analysisData.avg
          : 0,
      labelPrice: analysisData.lowestPrice ?? 0,
    },
    {
      name: '하위 평당 추정가',
      price: analysisData.low_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.low_avg,
    },
    {
      name: '기준 평당 추정가',
      price: analysisData.avg,
      gapOfPrice: 0,
      labelPrice: analysisData.avg,
    },
    {
      name: '상위 평당 추정가',
      price: analysisData.high_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.high_avg,
    },
  ];
  // 중위값을 계산하거나 데이터에서 추출
  const medianValue = analysisData.avg;
  console.log('chartDataSet', chartDataSet);
  return (
    <>
      <TAnalyzeChartBox>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={328}
            height={180}
            data={chartDataSet}
            margin={{ top: 25, right: 20 }}
          >
            <XAxis
              axisLine={false} // X축 선을 제거
              tickLine={false} // X축 tick을 제거
              dataKey="name"
              interval={0}
              tick={({ x, y, payload }) => {
                const isHighlighted =
                  payload.value === '감정가' || payload.value === '최저입찰가';
                const padding = isHighlighted ? 0 : -4;

                // 긴 텍스트에 대해 줄바꿈 처리를 위한 분리 로직
                const lines: string[] = payload.value.includes('추정가')
                  ? [payload.value.slice(0, -3), '추정가']
                  : [payload.value];
                return (
                  <g transform={`translate(${x + padding},${y})`}>
                    <text
                      dy={16}
                      fill={
                        isHighlighted ? '#222222' : 'rgba(55, 56, 60, 0.61)'
                      } // 감정가, 최저가일 때 빨간색으로 표시
                      fontSize="11px"
                      fontFamily="Pretendard-Medium"
                      fontWeight="500"
                      textAnchor="middle"
                      // style={{
                      //   whiteSpace: 'wrap',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      // }}
                    >
                      {/* {payload.value} */}
                      {lines.map((line, index) => (
                        <tspan
                          key={index}
                          x={lines.length > 1 ? 4 : 0}
                          dy={index === 0 ? 8 : 11}
                        >
                          {line}
                        </tspan>
                      ))}
                    </text>
                  </g>
                );
              }}
            />
            <Bar dataKey="price" barSize={40} stackId="a" radius={[0, 0, 4, 4]}>
              {chartDataSet.map((entry, index) => {
                // 색상 결정
                let fillColor = '#D2D8DF'; // 기본 색상 (검정색)
                if (entry.name === '감정가' && entry.gapOfPrice === 0) {
                  fillColor = '#3385FF'; // 파란색
                  return (
                    <Cell key={`cell-${index}`} fill={fillColor} radius={4} />
                  );
                } else if (entry.name === '감정가') {
                  fillColor = '#3385FF'; // 파란색
                  return <Cell key={`cell-${index}`} fill={fillColor} />;
                } else if (
                  entry.name === '최저입찰가' &&
                  entry.gapOfPrice === 0
                ) {
                  fillColor = '#EC4F4F';
                  return (
                    <Cell key={`cell-${index}`} fill={fillColor} radius={4} />
                  );
                } else if (entry.name === '최저입찰가') {
                  fillColor = '#EC4F4F';
                  return <Cell key={`cell-${index}`} fill={fillColor} />;
                } else {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.name === '기준 평당 추정가'
                          ? '#484F55'
                          : '#D2D8DF'
                      }
                      radius={4}
                    />
                  );
                }
              })}
            </Bar>
            <Bar
              dataKey="gapOfPrice"
              barSize={40}
              stackId="a"
              radius={[4, 4, 0, 0]}
            >
              {chartDataSet.map((entry, index) => {
                let fillColor = '#D2D8DF'; // 기본 색상

                if (entry.name === '감정가') {
                  fillColor = 'rgba(54, 116, 200, 1)'; // 파란색
                } else if (entry.name === '최저입찰가') {
                  fillColor = '#B9494A';
                }
                return <Cell key={`cell-${index}`} fill={fillColor} />;
              })}
              <LabelList
                dataKey="labelPrice"
                position="top"
                content={({ x, y, value, index }) => {
                  if (index !== undefined) {
                    // index가 undefined가 아닌지 확인

                    return (
                      <text
                        x={Number(x) + 20}
                        y={Number(y) - 8}
                        fill="#000"
                        textAnchor="middle"
                        fontSize="14"
                        fontFamily="Pretendard-Bold"
                      >
                        {formatManWons(Number(value))}
                      </text>
                    );
                  }
                }}
              />
            </Bar>

            {/* 중간값을 표시하는 ReferenceLine */}
            <ReferenceLine
              y={medianValue} // 중간값 설정
              stroke="rgba(55, 56, 60, 0.28)" // 선 색상
              strokeDasharray="3 3" // 점선 스타일
              label={{
                position: 'insideRight',
                value: '기준',
                fontSize: 12,
                fontFamily: 'Pretendard-Medium',
                dx: 20,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </TAnalyzeChartBox>
      <AveragePriceListBox>
        <AveragePriceList>
          <PriceTitle>
            <img src="/asset/images/icon/dot_appraisedPrice.svg" alt="" />
            <div>감정가</div>
          </PriceTitle>
          <MaxPrice>
            <AveragePriceContent>
              {formatToKoreanCurrency(
                analysisData.appraisedPrice ?? 0 * pyeong,
              )}
            </AveragePriceContent>
          </MaxPrice>
        </AveragePriceList>
        <AveragePriceList>
          <PriceTitle>
            <img src="/asset/images/icon/dot_lowestPrice.svg" alt="" />
            <div>최저입찰가</div>
          </PriceTitle>
          <AveragePriceContent>
            {formatToKoreanCurrency(analysisData.lowestPrice ?? 0 * pyeong)}
          </AveragePriceContent>
        </AveragePriceList>
        <ListDivider></ListDivider>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_low_avg.svg" alt="" />
            <div>하위 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            {formatToKoreanCurrency(analysisData.low_avg * pyeong)}~
          </AveragePriceContent>
        </AveragePriceList>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_avg.svg" alt="" />
            <div>기준 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            {formatToKoreanCurrency(analysisData.avg * pyeong)}
          </AveragePriceContent>
        </AveragePriceList>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_high_avg.svg" alt="" />
            <div>상위 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            ~{formatToKoreanCurrency(analysisData.high_avg * pyeong)}
          </AveragePriceContent>
        </AveragePriceList>
      </AveragePriceListBox>
    </>
  );
};

export default AuctionChart;

const TAnalyzeChartBox = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 180px;
`;

const AveragePriceListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #fafafa;
`;

const AveragePriceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #222;
  font-size: 13px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
  flex: 1;
`;
const TextGray = styled.div`
  display: flex;

  color: #616161;
  gap: 4px;
`;

const AveragePriceContent = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  line-height: 24px;
  word-wrap: break-word;
  align-self: center;
`;

const MaxPrice = styled.div`
  color: #ec4f4f;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e2e4;
`;

const PriceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`;
