import styled from 'styled-components';
import colors from '../../color';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Search.css';
import PopularList from './components/PopularList';
import NoLogin from './components/NoLogin';
import NoResult from './components/NoResult';
import LatestSearchList from './components/LatestSearchList';
import SearchService from '../../service/Search';
import { useMapContext } from '../../contexts/MapContext';
import { isLogin } from '../../utilities/auth';
import { Helmet } from 'react-helmet';
import NewsearchResult from './components/NewSearchResult';
import { set } from 'lodash';
import { getZoomLevelByPNU } from './components/ZoomLevel';

interface TopSearchBoxProps {
  padding: string; // padding 값을 string 타입으로 정의
}

interface SearchKeywordList {
  address: string;
  code: string;
  type: string;
}
interface RecentSearchList {
  id: number;
  code: string;
  search_term: string;
}
interface PopularSearchList {
  address: string;
  code: string;
  rank: string;
  status: string;
}

const NewSearch = () => {
  const [searchRegion, setSearchRegion] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <>
      <Helmet>
        <title> {searchRegion} 토지 주택 매물 찾기 | 트랜스파머</title>
        <meta
          name="description"
          content="전국 일반 매물, 지자체 매물, 경매, 공매물 검색 - 안심할 수 있는 가격대 매물만 보기 기능을 제공합니다 ."
        />
        <meta
          name="keywords"
          content="토지 매매, 땅매매, 땅값, 땅 시세, 토지 시세, 농지,  주택매매,  토지실거래가, 토지이음, 토지투자,  땅투자, 경매, 유휴지, 캠핑장 사업, 태양광 발전 임대,  창고임대 사업, 펜션 사업"
        />
        <meta
          property="og:title"
          content="트랜스파머 | 누구나 토지 투자를 똑똑하게"
        />
        <meta
          property="og:description"
          content="전국 토지 매물 시세 분석과 토지에 적합한 투자 상품을 제공합니다"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta property="og:url" content="https://www.transfarmer.co.kr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <SearchMain parameters={{ setSearchRegion, inputValue, setInputValue }} />
    </>
  );
};

export const SearchMain = ({ parameters }: { parameters?: any }) => {
  const { setSearchRegion, inputValue, setInputValue, isDetail, onClick } =
    parameters;
  const navigate = useNavigate();
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // 검색 결과 여부
  const [firstAddress, setFirstAddress] = useState({
    address: '',
    code: '',
  });
  const [keywordAddress, setKeywordAddress] = useState<string>('');
  const [searchList, setSearchList] = useState<SearchKeywordList[]>([]);
  const [recentSearchList, setRecentSearchList] = useState<RecentSearchList[]>(
    [],
  );
  const [popularList, setPopularList] = useState<PopularSearchList[]>([]);
  const {
    setLatLng,
    setZoomLevel,
    setGeomData,
    latLng,
    setIs_exist,
    setPolygonCenter,
  } = useMapContext();
  const nav = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstAddress({
      address: '',
      code: '',
    });
    if (e.target.value.length === 0) {
      setIsSearchResult(false);
    }
    setInputValue(e.target.value);
  };

  const [debouncedSearch, setDebouncedSearch] = useState<string>('');

  // console.log(inputValue);
  // console.log(searchList);
  // 입력이 변경될 때마다 `debouncedSearch` 업데이트를 0.5초 지연
  useEffect(() => {
    // if (inputValue === '') {
    //   setSearchList([]);
    // }
    const timer = setTimeout(() => {
      setDebouncedSearch(inputValue);
    }, 300);

    // `query`가 변경되면 이전 타이머를 취소하여 새로운 타이머 설정
    return () => clearTimeout(timer);
  }, [inputValue]);

  // `debouncedSearch`가 변경될 때 API 호출
  useEffect(() => {
    if (debouncedSearch) {
      const fetchSearchResults = async () => {
        await searchApi(inputValue, '5', '0'); // 입력값으로 API 호출
      };
      fetchSearchResults();
    } else {
      // 입력값이 없을 경우 검색 결과 초기화
      setSearchList([]);
    }
  }, [debouncedSearch]);

  const searchApi = async (address: string, limit: string, offset: string) => {
    try {
      const res = await SearchService.searchTerm(address, limit, offset);
      setSearchList(res);
      setFirstAddress({
        address: res[0].address,
        code: res[0].code,
      });
      setKeywordAddress(res[0].address.split(' ').slice(1).join(' '));
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      setSearchList([]);
    }
  };

  useEffect(() => {
    if (inputValue !== '' && keywordAddress !== '') {
      const addressArray = keywordAddress.split(' ');
      const index = addressArray.findIndex((word) => word.includes(inputValue));
      const slicedArray = addressArray.slice(0, index + 1);

      if (index !== -1) {
        setSearchRegion(slicedArray.join(' '));
      }
    } else {
      setSearchRegion('');
    }
  }, [inputValue, keywordAddress]);

  useEffect(() => {
    if (searchList.length > 0) {
      setIsSearchResult(true);
    } else {
      setIsSearchResult(false);
    }
  }, [searchList]);

  const LogSearch = async () => {
    try {
      const data = {
        address: firstAddress.address,
        code: firstAddress.code,
      };
      const res = await SearchService.saveSearchKeyword(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getCoord = async (pnu: string) => {
    try {
      const res = await SearchService.coord(pnu);
      if (res.length > 0) {
        setLatLng({ lat: res[0].lat, lng: res[0].lng });
        setPolygonCenter([res[0].lat, res[0].lng]);
        setGeomData(res[0].geom);
        setZoomLevel(getZoomLevelByPNU(pnu));
        setIs_exist(res[0].is_exist);
        nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const getRecentSearchList = async () => {
    try {
      const res = await SearchService.recentSearchList();
      setRecentSearchList(res);
    } catch (e) {
      console.log(e);
    }
  };
  const getPopularSearchList = async () => {
    try {
      const res = await SearchService.popularList();
      setPopularList(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getRecentSearchList();
    getPopularSearchList();
  }, []);

  const [isFocus, setIsFocus] = useState(false);

  const renderBeforSearchContent = () => {
    if (isLogin()) {
      return inputValue.length > 0 && searchList.length < 1 ? (
        <>
          <NoResult type="noResult_noCheck" />
          {recentSearchList && recentSearchList.length > 0 ? (
            <LatestSearchList
              latestSearchItemList={recentSearchList}
              func={getRecentSearchList}
            />
          ) : (
            <NoResult type="search" />
          )}
        </>
      ) : (
        <>
          {recentSearchList && recentSearchList.length > 0 ? (
            <LatestSearchList
              latestSearchItemList={recentSearchList}
              func={getRecentSearchList}
            />
          ) : (
            <NoResult type="search" />
          )}
        </>
      );
    } else {
      return inputValue.length > 0 && searchList.length < 1 ? (
        <>
          <NoResult type="noResult_noCheck" />
        </>
      ) : (
        <NoLogin />
      );
    }
  };
  return (
    <Container>
      <TopSearchBox padding="8px 16px">
        <ArrowLeft
          onClick={
            onClick
              ? onClick
              : () => navigate(`/?lat=${latLng?.lat}&lng=${latLng?.lng}`)
          }
        />
        <InputBox>
          <SearchInput>
            <SearchBar
              onFocus={() => {
                setIsFocus(true);
              }}
              onBlur={() => {
                setIsFocus(false);
              }}
              placeholder="내 땅 찾고 평생 소득분석 무료"
              value={inputValue}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  inputValue.length > 0 &&
                  firstAddress.code !== ''
                ) {
                  LogSearch();
                  getCoord(firstAddress.code);
                }
              }}
              onChange={handleInputChange}
              style={{ width: `${inputValue.length + 1}ch` }}
            />
          </SearchInput>
          <img
            src="/asset/images/close/close-gray-circle.svg"
            alt=""
            onClick={() => {
              setInputValue('');
              setKeywordAddress('');
            }}
            style={{ cursor: 'pointer' }}
          />
        </InputBox>
      </TopSearchBox>
      {isSearchResult && (!isDetail || isFocus) ? (
        <NewsearchResult
          data={searchList}
          inputValue={inputValue}
          isDetail={isDetail}
        />
      ) : isDetail ? (
        <></>
      ) : (
        <div className="SearchContainer">
          {renderBeforSearchContent()}
          <PopularList popularSearchList={popularList} />
        </div>
      )}
    </Container>
  );
};

const ArrowLeft = styled.img`
  content: url('/asset/images/arrow/arrow-left-gray.svg');
  alt: 'logo';
  cursor: pointer;
`;

const Container = styled.div`
  max-width: 720px;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  z-index: 201;
`;

const TopSearchBox = styled.div<TopSearchBoxProps>`
  width: 100%;
  padding: ${({ padding }) => padding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 3;
  max-width: inherit;
`;

const InputBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${colors.lightGray100};
  border-radius: 9999px;
  box-sizing: border-box;
  overflow: hidden;
`;

const SearchInput = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  flex: 1; /* 남은 공간을 차지하게 함 */
  display: flex; /* 내부 요소들을 flexbox로 정렬 */
  color: ${colors.labelAssistive};
  box-sizing: border-box;
  overflow: hidden;
`;

const SearchBar = styled.input`
  flex: 1;
  max-width: 100%;
  background-color: ${colors.lightGray100};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  &:focus {
    outline: none; /* 포커스 시 기본 테두리 제거 */
    border: none; /* 포커스 시 테두리 없애기 */
  }
  &::placeholder {
    color: ${colors.labelAssistive}; /* 원하는 색상으로 설정 */
  }
`;

export default NewSearch;
