import styled from 'styled-components';
import colors from '../../../../color';

interface MapTypeButtonProps {
  active?: boolean;
  backgroundcolor?: string;
  mapType?: string;
  isListData?: boolean;
}

interface MapProps {
  height: number;
  width?: number;
}

export const NaverMap = styled.div<MapProps>`
  width: ${({ width }) => width}px;
  max-width: 720px;
  height: ${({ height }) => height}px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const MapItemLeft = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MapTypeBoxTop = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

export const MapTypeButton = styled.div<MapTypeButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 6px 12px;
  font-family: 'Pretendard-Medium';
  font-size: 11px;
  line-height: 12px;
  color: ${({ active }) =>
    active ? colors.labelNeutral : colors.labelAlternative};
  cursor: pointer;
`;

export const MapTypeButtonSingle = styled.div<MapTypeButtonProps>`
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-family: 'Pretendard-Medium';
  font-size: 11px;
  color: ${({ active }) =>
    active ? colors.labelNeutral : colors.labelAlternative};
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

export const MapItemRight = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MapMarkerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PriceModeButtonBox = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  overflow: hidden;
`;
export const PriceModeButton = styled.div<MapTypeButtonProps>`
  padding: 10px;
  border-radius: 8px;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${({ active }) => (active ? colors.white : colors.labelAlternative)};
  background-color: ${({ active, backgroundcolor }) =>
    active ? backgroundcolor : 'transparent'};
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`;

export const LocationRegion = styled.div<MapTypeButtonProps>`
  position: absolute;
  bottom: ${({ isListData }) => (isListData ? '120px' : '90px')};
  left: 12px;
  z-index: 10;
  background-color: white;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 8px 12px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;

export const BrokerModal = styled.div<MapTypeButtonProps>`
  position: absolute;
  bottom: ${({ isListData }) => (isListData ? '168px' : '138px')};
  left: 12px;
  z-index: 10;
  cursor: pointer;
  display: flex;
`;

export const RegionTextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RegionText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNeutral};
`;

export const CustomScale = styled.div<MapTypeButtonProps>`
  position: absolute;
  bottom: ${({ isListData }) => (isListData ? '120px' : '90px')};
  right: 13px;
  z-index: 10;
  border-bottom: 1.5px solid
    ${({ mapType }) => (mapType === 'HYBRID' ? 'white' : 'black')};
  padding: 0 12px;
`;
export const CustomScaleLeft = styled.div<MapTypeButtonProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 5px;
  background-color: ${({ mapType }) =>
    mapType === 'HYBRID' ? 'white' : 'black'};
`;
export const CustomScaleRight = styled.div<MapTypeButtonProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 5px;
  background-color: ${({ mapType }) =>
    mapType === 'HYBRID' ? 'white' : 'black'};
`;

export const CustomScaleText = styled.span<MapTypeButtonProps>`
  font-family: 'Pretendard-Medium';
  font-size: 8px;
  color: ${({ mapType }) => (mapType === 'HYBRID' ? 'white' : 'black')};
`;

export const NoDataLand = styled.div`
  font-family: Pretendard-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.white};
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 100%;
  z-index: 3000;
  padding: 0 12px;
  div {
    display: flex;
    justify-content: center;
    padding: 14px 16px;
    border-radius: 12px;
    width: 100%;
    background-color: rgba(80.73, 84.51, 86.19, 0.92);
  }
`;

export const S = {
  NaverMap,
  MapItemLeft,
  MapTypeBoxTop,
  MapTypeButton,
  MapTypeButtonSingle,
  MapItemRight,
  MapMarkerBox,
  PriceModeButtonBox,
  PriceModeButton,
  LocationRegion,
  RegionTextBox,
  RegionText,
  CustomScale,
  CustomScaleLeft,
  CustomScaleRight,
  CustomScaleText,
  NoDataLand,
  BrokerModal,
};
