import { useEffect, useState } from 'react';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import { TrendDataProps } from '../../data';
import colors from '../../../../color';

interface TabItemProps {
  selected: boolean;
}

interface ItemRowProps {
  highlight: boolean;
}

interface StatChangeProps {
  increase: boolean;
  decrease: boolean;
}

const PriceTrend = ({ data }: { data: TrendDataProps }) => {
  const [monthTab, setMonthTab] = useState(0);
  useEffect(() => {}, [monthTab]);

  const table = [
    [
      '1km 이내 ',
      monthTab === 0
        ? data.last3.radius1 === null
          ? '사례 없음'
          : Number(data.last3.radius1) === 0
            ? '가격 변화 없음'
            : Number(data.last3.radius1).toFixed(1)
        : monthTab === 1
          ? data.last6.radius1 === null
            ? '사례 없음'
            : Number(data.last6.radius1) === 0
              ? '가격 변화 없음'
              : Number(data.last6.radius1).toFixed(1)
          : monthTab === 2
            ? data.last12.radius1 === null
              ? '사례 없음'
              : Number(data.last12.radius1) === 0
                ? '가격 변화 없음'
                : Number(data.last12.radius1).toFixed(1)
            : '',
    ],
    [
      '2km 이내 ',
      monthTab === 0
        ? data.last3.radius2 === null
          ? '사례 없음'
          : Number(data.last3.radius2) === 0
            ? '가격 변화 없음'
            : Number(data.last3.radius2).toFixed(1)
        : monthTab === 1
          ? data.last6.radius2 === null
            ? '사례 없음'
            : Number(data.last6.radius2) === 0
              ? '가격 변화 없음'
              : Number(data.last6.radius2).toFixed(1)
          : monthTab === 2
            ? data.last12.radius2 === null
              ? '사례 없음'
              : Number(data.last12.radius2) === 0
                ? '가격 변화 없음'
                : Number(data.last12.radius2).toFixed(1)
            : '',
    ],
    [
      '3km 이내',
      monthTab === 0
        ? data.last3.radius3 === null
          ? '사례 없음'
          : Number(data.last3.radius3) === 0
            ? '가격 변화 없음'
            : Number(data.last3.radius3).toFixed(1)
        : monthTab === 1
          ? data.last6.radius3 === null
            ? '사례 없음'
            : Number(data.last6.radius3) === 0
              ? '가격 변화 없음'
              : Number(data.last6.radius3).toFixed(1)
          : monthTab === 2
            ? data.last12.radius3 === null
              ? '사례 없음'
              : Number(data.last12.radius3) === 0
                ? '가격 변화 없음'
                : Number(data.last12.radius3).toFixed(1)
            : '',
    ],
  ];
  const CustomYAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={5}
        textAnchor="end"
        color="rgba(23,30,38,0.56)"
        fontSize={11}
        fontFamily="Pretendard-Medium"
        // fontWeight={400}
      >
        {payload.value.toLocaleString()}
      </text>
    </g>
  );

  const tickLabels = [
    data.list[2].month,
    data.list[5].month,
    data.list[8].month,
    data.list[data.list.length - 1].month,
  ];

  return (
    <PriceTrendContainer>
      <PriceFluctuationContainer>
        <Tota>
          <Title>실거래가(원/평) 변동률</Title>
        </Tota>
        <OfXAuto>
          <FlexRow>
            <Tab>
              {['최근 3개월', '최근 6개월', '최근 12개월'].map((el, idx) => (
                <TabItem
                  key={idx}
                  selected={monthTab === idx}
                  onClick={() => setMonthTab(idx)}
                >
                  {el}
                </TabItem>
              ))}
            </Tab>
          </FlexRow>
        </OfXAuto>
        <Frame>
          <FlexColumn>
            <FlexColumnHeader>
              <FlexColumnHeaderContnet>거리</FlexColumnHeaderContnet>
              <FlexColumnHeaderContnet>
                평당 실거래가 평균
              </FlexColumnHeaderContnet>
            </FlexColumnHeader>
            {table.map((item, index) => (
              <ItemRow key={index} highlight={index === 3}>
                <StatBox>{item[0]}</StatBox>
                <StatBox>
                  <PriceStateBox>
                    {Number(item[1]) > 0 ? (
                      <StateContent
                        increase={Number(item[1]) > 0}
                        decrease={Number(item[1]) < 0}
                      >
                        상승중
                      </StateContent>
                    ) : Number(item[1]) < 0 ? (
                      <StateContent
                        increase={Number(item[1]) > 0}
                        decrease={Number(item[1]) < 0}
                      >
                        하락중
                      </StateContent>
                    ) : (
                      ''
                    )}
                    <StatChange
                      increase={Number(item[1]) > 0}
                      decrease={Number(item[1]) < 0}
                    >
                      {Number(item[1]) > 0 && '+'}
                      {Number(item[1]) > 0 || Number(item[1]) < 0
                        ? item[1] + '%'
                        : item[1]}
                    </StatChange>
                  </PriceStateBox>
                </StatBox>
              </ItemRow>
            ))}
          </FlexColumn>
        </Frame>
      </PriceFluctuationContainer>
      <PriceTransitionContainer>
        <Tota>
          <Title>실거래가(원/평) 추이</Title>
          <div>3km 이내 기준</div>
        </Tota>

        {data.list[0]?.price ? (
          <ResponsiveContainer width="100%" height={180}>
            <LineChart
              data={data.list}
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
            >
              <Line
                type="monotone"
                dataKey="pricePerPyeong"
                stroke="#53df89"
                dot={false}
              />
              <CartesianGrid stroke="#ccc" strokeWidth={1} vertical={false} />
              <XAxis
                dataKey="month"
                ticks={tickLabels}
                tick={{
                  color: 'rgba(23,30,38,0.56)',
                  fontSize: 11,
                  fontFamily: 'Pretendard-Medium',
                }}
                tickLine={false}
                axisLine={false} // X축 선을 제거
                interval={0}
                padding={{ right: 20 }}
              />
              <YAxis
                tick={<CustomYAxisTick />}
                tickLine={false}
                axisLine={false} // X축 선을 제거
              />
              <Tooltip
                content={({ payload, label, active }) => {
                  if (active && payload && payload.length) {
                    const dataItem = payload[0].payload;
                    return (
                      <div className="custom-tooltip">
                        <p>{`${label}`}</p>
                        <p>{`거래량: ${dataItem.count}건`}</p>
                        <p style={{ color: '#53df89' }}>
                          {`평당가: ${Math.round(
                            dataItem.pricePerPyeong,
                          ).toLocaleString()}원`}
                        </p>
                      </div>
                    );
                  }

                  return null;
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <NodataMessage>실거래가 없습니다.</NodataMessage>
        )}
      </PriceTransitionContainer>
    </PriceTrendContainer>
  );
};

export default PriceTrend;

const PriceTrendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 32px;
  width: 100%;
`;

const PriceFluctuationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Tota = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: rgba(23, 30, 38, 0.56);
  font-size: 14px;
  font-family: Pretendard-Medium;
  line-height: 20px;
  word-wrap: break-word;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${colors.labelNormal};
  font-size: 16px;
  font-family: Pretendard-Bold;
  line-height: 24px;
`;

const OfXAuto = styled.div`
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px rgba(112, 115, 124, 0.16) solid;
`;

const TabItem = styled.div<TabItemProps>`
  padding: 6px 16px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#222222' : 'rgba(55,56,60,0.28)')};
  border-bottom: ${(props) => (props.selected ? '1px solid #222222' : null)};
  font-size: 14px;
  font-family: Pretendard-Medium;
  line-height: 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Frame = styled.div`
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexColumnHeader = styled.div`
  display: flex;
`;

const FlexColumnHeaderContnet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 2px;
  color: rgba(23, 30, 38, 0.56);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding: 8px 16px;
  background-color: #f7f8f9;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
  border-top: 1px solid rgba(23, 30, 38, 0.1);
  height: 36px;
`;

const ItemRow = styled.div<ItemRowProps>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
  // background-color: ${(props) =>
    props.highlight ? '#f7f8f9' : 'transparent'};
  height: 36px;
`;

const StatBox = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 2px;
  color: rgba(23, 30, 38, 0.56);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const StatChange = styled.div<StatChangeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  padding: 4px 8px;
  border-radius: 999px;
  background-color: ${(props) =>
    props.increase ? '#EC4F4F' : props.decrease ? '#4B47FC' : 'transparent'};
  color: ${(props) =>
    props.increase || props.decrease ? '#fff' : '#171e268f'};
  font-size: ${(props) => (props.increase || props.decrease ? 12 : 14)}px;
  font-family: Pretendard-Medium;
  font-weight: ${(props) => (props.increase || props.decrease ? 700 : 500)};
  line-height: 14px;
  word-wrap: break-word;
`;

const StateContent = styled.div<StatChangeProps>`
  color: ${(props) =>
    props.increase
      ? '#EC4F4F'
      : props.decrease
        ? '#4B47FC'
        : 'rgba(23, 30, 38, 0.56)'};
`;

const PriceStateBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const LandChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 180px;
`;

const PriceTransitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NodataMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Pretendard-Medium;
  color: ${colors.labelAlternative};
  padding: 16px;
`;
