import styled from 'styled-components';
import colors from '../../color';
import ListCategory from './components/ListCategory';
import ListTitle from './components/ListTitle';
import ListImg from './components/ListImg';
import { useNavigate } from 'react-router-dom';
import { LikeProps } from './data';

/**
 *
 * api가 나오지 않아서 더미데이터로 되어 있음
 * 상세페이지에 들어갔는지 확인할 수 없어서
 * 일단 자물쇠를 다풀어놓음
 * 만약 리스트를 조회할 때 상세페이지에 들어갔는지 확인할 수 있으면
 * 상세페이지 접속 여부에 따라 자물쇠 아이콘 변경이 필요함
 */
interface LatestSearchItemProps {
  data: LikeProps[];
}

const LatestSearchItem = ({ data }: LatestSearchItemProps) => {
  const navigate = useNavigate();

  const handleAnalysisType = (
    c_idx: number,
    analysisType: string,
    pnu: string,
    type: string,
  ) => {
    let encodeType = type === '토지' ? 'L' : 'H';
    if (!c_idx) {
      navigate(
        `/detail?type=${encodeType}&pnu=${pnu}&analysisType=${analysisType}`,
      );
      return;
    } else {
      navigate(`/detail?c_idx=${c_idx}&analysisType=${analysisType}`);
    }
  };

  const navigeToDetail = (item: any) => {
    const encodeType = item.type === '토지' ? 'L' : 'H';
    if (item.c_idx !== null) {
      return navigate(`/detail?c_idx=${item.c_idx}`);
    } else {
      return navigate(`/detail?type=${encodeType}&pnu=${item.pnu}`);
    }
  };

  return (
    <Container>
      <TitleBox>
        <Title>최근 본 주소</Title>
      </TitleBox>
      <ListCardWrapper>
        {data && data.length > 0
          ? data.map((data, index) => (
              <ListCardBox key={index}>
                <ListCard>
                  <ListImgBox
                    onClick={() => {
                      navigeToDetail(data);
                    }}
                  >
                    <ListImg
                      img={data.img}
                      lat={data.lat}
                      lng={data.lng}
                      geom={data.geom}
                      price={data.price || 0}
                      category={data.category || ''}
                      area={data.pyeong || 0}
                      pyeong={data.pyeong || 0}
                      bidcount={data.bidCount ?? 0}
                      saleType={data.saleType || ''}
                      dealdate={data.dealDate || ''}
                    ></ListImg>
                    {data.is_safe_to_sell === 'Y' && (
                      <SafeImg src="/asset/images/icon/safe.svg" />
                    )}
                    {/* {data.int_idx !== 0 ? (
                      <HeartImg src="/asset/images/icon/red-heart.svg" />
                    ) : null} */}
                  </ListImgBox>
                  <ListContentBox>
                    <ListContentInfo
                      onClick={() => {
                        navigeToDetail(data);
                      }}
                    >
                      <ListTitle
                        saleType={data.saleType}
                        type={data.type}
                        caseNo={data.caseno || ''}
                        court={data.court || ''}
                        price={data.price || 0}
                        dealdate={data.dealDate || ''}
                        address={data.address}
                        zoning={data.zoning}
                        data={data}
                      />
                      <ListCategory
                        unit={'pyeong'}
                        type={data.type}
                        saleType={data.saleType}
                        category={data.category}
                        pyeong={data.pyeong}
                        zoningBadge={data.zoningBadge || ''}
                        bidCount={data.bidCount || 0}
                        approvalDate={Number(data.approvalDate || '0')}
                        option={data.is_option}
                        road={''}
                      />
                      <RealDealBox>
                        <div>최근 한달 간 주변 실거래 발생</div>
                        {data.count === 0 ? (
                          <div>
                            <RedText>없음</RedText> 건
                          </div>
                        ) : (
                          <div>
                            <RedText>{data.count}</RedText> 건
                          </div>
                        )}
                      </RealDealBox>
                    </ListContentInfo>
                    <ButtonWrapper>
                      {data.category === '대' ||
                      data.category === '임야' ||
                      data.type === '건물' ? (
                        <>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                data.c_idx ?? 0,
                                'sale',
                                data.pnu ?? '',
                                data.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  data.a_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              가격분석
                            </div>
                            {data.a_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                data.c_idx ?? 0,
                                'house',
                                data.pnu ?? '',
                                data.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  data.d_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              주택 견적분석
                            </div>
                            {data.d_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                        </>
                      ) : (
                        <>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                data.c_idx ?? 0,
                                'sale',
                                data.pnu ?? '',
                                data.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  data.a_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              가격분석
                            </div>
                            {data.a_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                data.c_idx ?? 0,
                                'land',
                                data.pnu ?? '',
                                data.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  data.b_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              경작소득 분석
                            </div>
                            {data.b_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                          <Divider></Divider>
                          <ButtonBox
                            onClick={() =>
                              handleAnalysisType(
                                data.c_idx ?? 0,
                                'pension',
                                data.pnu ?? '',
                                data.type ?? '',
                              )
                            }
                          >
                            <div
                              style={{
                                color:
                                  data.c_type === 'Y'
                                    ? '#222'
                                    : 'rgba(55, 56, 60, 0.28)',
                              }}
                            >
                              농지연금 분석
                            </div>
                            {data.c_type === 'Y' ? (
                              <img
                                src="/asset/images/icon/lockOff-selected.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/asset/images/icon/lockOn-noSelected.svg"
                                alt=""
                              />
                            )}
                          </ButtonBox>
                        </>
                      )}
                    </ButtonWrapper>
                  </ListContentBox>
                </ListCard>
              </ListCardBox>
            ))
          : null}
      </ListCardWrapper>
      {data.length === 0 && (
        <NoLIst>
          <img src="/asset/images/gif/mychon-recent.svg" alt="" />
          <NoListTextBox>
            <div>최근 본 주소지가 없어요</div>
            <NoListText>
              전국매물 탭에서 원하는 지역의 매물을 찾아보세요
            </NoListText>
          </NoListTextBox>
          <GotoProperty
            onClick={() => {
              navigate('/property');
            }}
          >
            전국매물 보러가기
          </GotoProperty>
        </NoLIst>
      )}
    </Container>
  );
};

export default LatestSearchItem;

const ListCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color: ${colors.lineNeutral};
  align-self: center;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  // align-items: center;
  border-radius: 8px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  background-color: #f5f5f5;
  padding: 12px 16px;
`;

const RedText = styled.span`
  color: #ec4f4f;
  font-weight: 700;
`;

const RealDealBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  gap: 8px;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ListContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${colors.white};
  border-bottom-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-bottom-right-radius: 8px; /* 오른쪽 위 모서리 */
`;

const ListContentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`;

const ListImgBox = styled.div`
  width: 100%;
  height: 166px;
  background-color: white;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */
  position: relative;
  border-color: none;
  outline: none;
  cursor: pointer;
`;

export const SafeImg = styled.img`
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1;
`;

export const HeartImg = styled.img`
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
`;

interface ButtonProps {
  active: boolean;
}

const Container = styled.div`
  width: 100%;
  padding: 24px 16px 72px;
  background-color: #f5f5f5;
  flex-grow: 1;
  margin-bottom: 54px;
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;
const Title = styled.span`
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
const FilterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 10px 0;
  padding-bottom: 26px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const BottonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

const Button = styled.span<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 9999px;
  color: ${(props) => (props.active ? colors.white : colors.labelNeutral)};
  background-color: ${(props) =>
    props.active ? colors.labelNormal : colors.white};
  border: ${(props) =>
    props.active ? 'none' : `1px solid ${colors.lineNeutral}`};
  cursor: pointer;
`;

const FilterSelect = styled.select`
  border: none;
  color: ${colors.labelNeutral};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

const ListCardBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-radius: 8px;
`;

const ListCard = styled.div`
  width: 100%;
`;

const NoLIst = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
  text-align: center;
`;

const NoListTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NoListText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;

const GotoProperty = styled.button`
  width: 146px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary500};
  border: 1px solid ${colors.primary500};
  border-radius: 8px;
  background: none;
  font-family: Pretendard-Medium;
  font-size: 16px;
  cursor: pointer;
`;
