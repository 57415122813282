import { Slider } from '@mui/material';
import { Title } from '../Content';
// import '../../../../../asset/styles/SaleInfo.css';
// import '../../../../../Main.css';

import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
// import Slider from '@mui/material/Slider';

const Pyeong = ({
  title,
  pyeongValue,
  setPyeongValue,
  minPyeongValue,
  maxPyeongValue,
  currentHouse,
  landInfo,
  landPyeong,
  selectedDesignFloor,
  stdFloorAreaRatio,
  stdBuildingToLandRatio,
  selectedDesignIdx,
  sliderstdBuildingToLandRatio,
  setSliderstdBuildingToLandRatio,
  sliderstdFloorAreaRatio,
  setSliderstdFloorAreaRatio,
}: any) => {
  maxPyeongValue =
    maxPyeongValue < minPyeongValue ? minPyeongValue : maxPyeongValue;

  const TableSubTitle = ({ title }: any) => (
    <div
      className="font-medium f-size-14 line-h-20 color-text-tertiary nowrap"
      style={{ flex: 0.5 }}
    >
      {title}
    </div>
  );

  const TableItemContent = ({ content }: any) => (
    <div
      className="font-bold f-size-14 line-h-20 color-text-secondary nowrap"
      style={{ flex: 0.5 }}
    >
      {content}
    </div>
  );

  // useEffect(() => {
  //   setSliderstdBuildingToLandRatio(
  //     Math.round((minPyeongValue / landPyeong) * 100)
  //   );
  //   setSliderstdFloorAreaRatio(Math.round((minPyeongValue / landPyeong) * 100));
  // }, [minPyeongValue, landPyeong]);

  useEffect(() => {
    if (selectedDesignFloor === 1) {
      setSliderstdBuildingToLandRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
      setSliderstdFloorAreaRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 2 / 10,
      );
      setSliderstdFloorAreaRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
    }
  }, [selectedDesignIdx]);
  useEffect(() => {
    if (selectedDesignFloor === 1) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 2 / 10,
      );
    }
    if (selectedDesignFloor === 1) {
      setSliderstdFloorAreaRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdFloorAreaRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    }
  }, [pyeongValue, landPyeong]);

  console.log('pyeongValue', pyeongValue);

  return (
    <div className="flex-column report_result_box bg-white  border-radius-8 gap-8">
      <Title text={'2. 평형'} />
      <div className="flex-column gap-24">
        <DescriptionWrap>
          <DescriptionWrapper>
            <Description>
              선택하신 설계안의 평형이 기본값으로 자동 설정되며, 원하시는 다른
              평형으로 값을 조정할 수 있습니다.
            </Description>
          </DescriptionWrapper>
        </DescriptionWrap>
        <div className="flex-column w-100per padding-x-16">
          <div className="w-100per flex-row space-between ">
            <div className="font-bold f-size-12 line-h-14 color-text-primary flex-1">
              {Math.round(minPyeongValue * 10) / 10}평 (기본)
            </div>
            <div
              className="font-bold f-size-12 line-h-14 color-text-primary flex-1 center-x"
              style={{ textAlign: 'center' }}
            >
              {Math.round(
                ((Number(minPyeongValue) + Number(maxPyeongValue)) * 10) / 2,
              ) / 10}
              평
            </div>
            <div
              className="font-bold f-size-12 line-h-14 color-text-primary flex-1 end-x"
              style={{ textAlign: 'end' }}
            >
              {Math.round(maxPyeongValue * 10) / 10}평
            </div>
          </div>
          <Slider
            value={parseFloat(pyeongValue.toFixed(2))}
            onChange={(e: any) => {
              setPyeongValue(e.target.value);
            }}
            valueLabelDisplay="auto"
            max={maxPyeongValue}
            min={minPyeongValue}
            // getAriaValueText={valuetext}
            sx={{ color: '#1E9B6B', height: '8px' }}
          />
        </div>
        <div
          className=" flex-column w-100per gap-8 padding-x-16"
          style={{ flexWrap: 'wrap' }}
        >
          {[
            {
              title: '층수',
              content: `지상 ${currentHouse.floor}층` || '지상 2층',
            },

            {
              title: '용적률',
              content:
                sliderstdFloorAreaRatio === 0 ||
                sliderstdFloorAreaRatio === Infinity ||
                !sliderstdFloorAreaRatio
                  ? '0%'
                  : sliderstdFloorAreaRatio + '%',
            },
            {
              title: '건폐율',
              content:
                sliderstdBuildingToLandRatio === 0 ||
                sliderstdBuildingToLandRatio === Infinity ||
                !sliderstdBuildingToLandRatio
                  ? '0%'
                  : sliderstdBuildingToLandRatio + '%',
            },
          ].map((el) => (
            <div
              className="flex-row w-100per gap-8 center-y"
              style={{ width: 'fit-content' }}
            >
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                •
              </div>
              <TableSubTitle title={el.title} />
              <TableItemContent content={el.content} />
            </div>
          ))}
        </div>
        <div style={{ paddingInline: '16px' }}>
          <div style={{ borderBottom: '1px solid #70737C29' }}></div>
        </div>
      </div>
      <div style={{ padding: '24px 16px' }}>
        <FooterText>
          맞춤 설계와 견적 분석 서비스는 건축 환경의 일부를 가정하여 분석됩니다.
          건축 방식 : 신축 (리모델링 제외)
        </FooterText>
      </div>
    </div>
  );
};

const DescriptionWrap = styled.div`
  padding: 0 16px;
`;

const DescriptionWrapper = styled.div`
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  background: #f5f5f5;
`;

const Description = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #616161;
`;

const FooterText = styled.div`
  //styleName: Mo/Body/S-14-Medium;
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #171e268f;
`;

export default Pyeong;
