import './Consulting.css';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { isLogin } from '../../../utilities/auth';
import { PromotionTopNav } from '../component/pageTop/PageTop';
import { Personal } from './component/Personal';
import { Corporate } from './component/Corporate';
import { Team } from './component/Team';
import { Article } from './component/Article';
import colors from '../../../color';
import ScrollToTopButton from '../../components/toTopBtn/ScrollToTopButton';

const Consulting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const tab2 = params.tab;
  const [tab, setTab] = useState(tab2 || 'personal');

  useEffect(() => {
    sessionStorage.setItem('landingType', 'consulting');
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          토지전문 농업·금융 데이터 분석팀의 맞춤형 토지 컨설팅을 합리적
          가격으로 받아보세요
        </title>
        <meta
          name="description"
          content="토지 투자 손실 최소화, 수익 극대화를 위한 토지·농업·금융 데이터 융합의 전문 분석과 사업 타당성 분석-트랜스파머 토지 투자 컨설팅"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keyword"
          content="부동산,
      부동산경매,
      농업경영체 등록,
      부동산 전망,
      토지매매,
      부동산투자,
      땅매매,
      임야 매매,
      토지경매,
      토지가격,
      농지매매,
      부동산 조각투자,
      조각투자,
      토지시세,
      부동산재테크,
      토지상속,
      토지증여,
      토지투자,
      지분투자,
      농지상속,
      땅 투자,
      농지경매,
      농지투자,
      전답매매,
      농업컨설팅,
      임야투자,
      토지컨설팅,
      임야매입 
      "
        />
        <link
          rel="canonical"
          href="https://www.transfarmer.co.kr/promotion/트랜스파머-맞춤-토지-컨설팅"
        />
        {/* 오픈그래프 영역 */}
        <meta property="og:title" content="트랜스파머" />
        <meta
          property="og:description"
          content="정확한 토지 분석과 투자 솔루션"
        />
        <meta property="og:image" content="/asset/images/logo/og-img2.png" />
        <meta
          property="og:url"
          content="https://www.transfarmer.co.kr/promotion/전원주택-설계와-건축비-분석을-AI로-간편하게"
        />
      </Helmet>
      <div className="consulting-wrap">
        <PromotionTopNav />
        {/* body1 */}
        <div className="consulting-section01">
          <div className="section01-content">
            <div className="section01-text">
              토지에 대한 모든 질문 <br />
              한 걸음 더, <br />
              정확하고 정직한 분석으로{' '}
            </div>
            <img
              style={{ width: '30px', height: '30px' }}
              src="/asset/images/arrow/ArrowBottom12White.svg"
              alt=""
            />
            <div className="comment-box">
              <div className="comment-inner">
                <div>만족도</div>
                <StarRatings
                  rating={5}
                  starDimension="18.77px"
                  starSpacing="1px"
                  starRatedColor="#00C474"
                />
              </div>
              <div className="comment-text">
                <div>전문가의 검증된 분석 보고서</div>
                <div
                  style={{
                    fontFamily: 'GmarketSansMedium',
                    fontSize: '20px',
                    lineHeight: '29px',
                    color: '#FFFFFF',
                  }}
                >
                  “표지의 박사 실명 기재부터 <br />
                  내용까지 모두 만족스럽습니다”
                </div>
              </div>
              <div className="comment">
                <div>ra*** , 소송 진행 중 농지 분석 의뢰 고객</div>
              </div>
            </div>
          </div>
        </div>
        {/* body2 */}
        <div className="consulting-section02">
          <div className="tabBox">
            <div className="tabBox-inner">
              <div
                className="tabButton"
                onClick={() => {
                  setTab('personal');
                }}
                style={{
                  backgroundColor:
                    tab === 'personal'
                      ? colors.primary500
                      : 'rgba(246, 246, 246, 1)',

                  color:
                    tab === 'personal' ? '#FFFFFF' : 'rgba(23, 30, 38, 0.78)',
                }}
              >
                개인용
              </div>
              <div
                className="tabButton"
                onClick={() => {
                  setTab('enterprise');
                }}
                style={{
                  backgroundColor:
                    tab === 'enterprise'
                      ? colors.primary500
                      : 'rgba(246, 246, 246, 1)',

                  color:
                    tab === 'enterprise' ? '#FFFFFF' : 'rgba(23, 30, 38, 0.78)',
                }}
              >
                기업용
              </div>
            </div>
          </div>
          <div className="section02-content-container">
            {tab === 'personal' ? <Personal /> : <Corporate />}
          </div>
        </div>
        <div
          style={{ height: '16px', backgroundColor: '#F7F8F9', width: '100%' }}
        ></div>
        {/* body3 */}
        <Team />
        <Article />
        <div
          className="guideandnotice-wrapper"
          style={{
            marginBottom: !isLogin() ? '80px' : '0',
          }}
        >
          <div className="guideandnotice-inner">
            <div>안내 및 유의사항</div>
            <div className="guideandnotice-text">
              트랜스파머가 제공하는 보고서는 전문가의 노하우를 바탕으로 제공되는
              토지 가격 및 솔루션 상담 서비스 성격의 자료이며, 투자 수익 또는
              손해를 예측하는 데 있어서는 참고 자료로서만 활용하시길 바랍니다.
              상담 건과 관련해 본 보고서의 내용에 대한 의사결정 책임은 의뢰한
              고객에 있으며, 고객 자신의 판단과 책임하에 이루어져야 하며
              트랜스파머가 투자 권유 또는 보장을 하는 것으로 해석되어서는 안
              됩니다. ‘Transfarmer의 가격 분석 컨설팅 보고서'는 향후
              경제상황이나 부동산 정책 등 제반 가정 변화에 따라 그 내용이나
              결과가 달라질 수 있음에 유의 바랍니다{' '}
            </div>
          </div>
        </div>
        {!isLogin() && (
          <div className="consulting-cta-box">
            <div className="consulting-cta-inner">
              <div>
                토지 거래 전 <br />{' '}
                <span className="consulting-cta-text-green">
                  토지분석이 먼저!
                </span>
              </div>

              <div
                className="consulting-cta-button"
                onClick={() => {
                  navigate('/signup');
                }}
              >
                <div>
                  회원 10%할인 <br />
                  토지분석 시작하기
                </div>
              </div>
            </div>
          </div>
        )}
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default Consulting;
