import { ConsultingBtn } from './Button';

export const Corporate = () => {
  return (
    <div className="personal-wrapper">
      <div className="personal-title-box">
        <div>기업 토지 분석</div>
        <div className="personal-text">
          투자 손실 최소화, 수익 최대화를 위한 <br /> 토지/농지에 대한 정확한
          분석과 사업 타당성 분석, <br />
          트랜스파머가 최적의 의사결정을 도와드리겠습니다.
        </div>
      </div>

      <div className="link-to-form-box">
        <div className="link-to-form-card">
          <div className="link-to-form-card-text-Box">
            <div>토지 투자 분석 컨설팅</div>
            <div className="link-to-form-card-sctipt-box">
              절대 손해보지 않는 토지거래 <br />
              쉽고 명확한 가격으로 <br />
              분석 리포트 진행하세요.
            </div>
          </div>
          <img src="/asset/images/promotion/consulting_img3.png" alt="" />
          <div className="link-to-form-button-box">
            <div
              className="link-to-form-button-item"
              style={{ borderBottom: '1px solid rgba(23, 30, 38, 0.2)' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/JuFvS8PxWkG5qtY88 ');
                }}
              >
                <div>
                  토지분석부터 매입
                  <br /> 가격 및 전망 분석
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">비용 150만원</span>
              </div>
            </div>
            <div
              className="link-to-form-button-item"
              style={{ borderBottom: '1px solid rgba(23, 30, 38, 0.2)' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/umYidv4A6yxfeQjs6');
                }}
              >
                <div>
                  토지 부실채권 분석 및 <br /> M&A 컨설팅 <br />
                  <span style={{ fontSize: '12px', lineHeight: '14px' }}>
                    (농장·축사·스마트팜)
                  </span>
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">비용 150만원</span>
              </div>
            </div>

            <div
              className="link-to-form-button-item"
              style={{ padding: '24px 16px 0 16px' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/mp9zrwk4yfTNL5hi7');
                }}
              >
                <div>
                  토지 활용 방안별 <br /> 수익 시뮬레이션
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">분석비용 협의</span>
              </div>
            </div>
          </div>
        </div>
        <div className="link-to-form-card">
          <div className="link-to-form-card-text-Box">
            <div>
              농지/농장 개발 및 <br /> 운영 컨설팅
            </div>
            <div className="link-to-form-card-sctipt-box">
              토지의 숨겨진 가치를 콕! <br />
              토지 분석을 통한 <br />
              다양한 조합의 수익 솔루션 제공
            </div>
          </div>
          <img src="/asset/images/promotion/consulting_img4.png" alt="" />
          <div className="link-to-form-button-box">
            <div
              className="link-to-form-button-item"
              style={{ borderBottom: '1px solid rgba(23, 30, 38, 0.2)' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/TqzYLSrM8nu4D7Kb6');
                }}
              >
                <div>
                  농지 및 농업 환경 분석 <br />
                  작물 적합성, 리스크 분석
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">비용 150만원</span>
              </div>
            </div>
            <div
              className="link-to-form-button-item"
              style={{ borderBottom: '1px solid rgba(23, 30, 38, 0.2)' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/Zf3Nb5ai2v5oKuVS8');
                }}
              >
                <div>
                  스마트팜 견적 등 <br />
                  시설 타당성 분석
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">비용 150만원</span>
              </div>
            </div>
            <div
              className="link-to-form-button-item"
              style={{ padding: '24px 16px 0 16px' }}
            >
              <div
                className="link-to-form-button-row"
                onClick={() => {
                  window.open('https://forms.gle/cA8awzCo3zXAWj437');
                }}
              >
                <div>
                  농업 관제 시스템 구축 및 <br />
                  운영 컨설팅
                </div>
                <ConsultingBtn />
              </div>
              <div className="amount-info">
                <span>컨설팅 + 분석 보고서 + 브리핑 제공</span>
                <span className="amount-info-text-green">분석비용 협의</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pay-script-box">
        <div className="pay-script-box-inner">
          <div className="pay-script">
            <div className="text-dot"></div>
            <p>토지 분석 컨설팅은 유료 서비스로 진행됩니다.</p>
          </div>
          <div className="pay-script">
            <div className="text-dot"></div>
            <p>
              사전 비대면 요구분석 미팅과 분석 보고서 브리핑 미팅(대면/비대면)이
              각 1회 진행됩니다.
            </p>
          </div>
          <div className="pay-script">
            <div className="text-dot"></div>
            <p>분석 소요일은 협의 후 1주일 이내(영업일 기준)입니다.</p>
          </div>
          <div className="pay-script">
            <div className="text-dot"></div>
            <p>부가세가 포함된 금액이며, 세금계산서 발행이 가능합니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
