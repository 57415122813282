import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class UserService {
  static info = async () => {
    const result = await request.post('users/info', {});
    return result.data;
  };
  static update = async (data: any) => {
    const result = await request.post('users/update', data);
    return result.data;
  };
  static checkEmail = async (data: any) => {
    const result = await request.post('users/check/email', data);
    return result.data;
  };
  static signUp = async (data: any) => {
    const result = await request.post('users/sign', data);
    return result.data;
  };
  static resetPassword = async (data: any) => {
    const result = await request.post('users/reset/password', data);
    return result.data;
  };
  static findId = async (name: string, phone: string) => {
    const result = await request.get(
      `users/find/email?name=${name}&phone=${phone}`,
    );
    return result.data;
  };
}
