import {
  Container,
  TopTextBox,
  TextGreen,
  ContentBox,
  Card,
  CardContent,
  CardTextBox,
  CardTitle,
  Description,
  CardTagBox,
  Tag,
  Button,
  DescriptionBig,
  SafetyBox,
  SafetyTextBox,
  SafetyDescription,
  DescriptionTitle,
} from './Brokerage.styles';
import PageTopNav from '../components/pageTop/PageTop';
import BottomNavigation from '../components/navigation/Navigation';
import Enroll from './enroll/Enroll';
import { useState } from 'react';

const Brokerage = () => {
  const [isEnroll, setIsEnroll] = useState(false);
  const [saleType, setSaleType] = useState('sell');
  return (
    <Container>
      {isEnroll ? (
        <Enroll saleType={saleType} />
      ) : (
        <>
          <PageTopNav title="맞춤 중개" />
          <TopTextBox>
            <div>
              <p>지역 전담 중개사가</p>
              <p>
                <TextGreen>직접</TextGreen> 찾아드리고,
              </p>
              <p>팔아드리겠습니다.</p>
            </div>
            <Card>
              <SafetyBox>
                <SafetyTextBox>
                  <CardTitle>
                    <img
                      style={{
                        width: '123px',
                        height: '32px',
                      }}
                      src="/asset/images/icon/Label-safe.png"
                      alt=""
                    />
                  </CardTitle>
                  <div>
                    지역 전담 중개사를 통해 거래 성사 시 <br />
                    안심거래 축하금을 드립니다.
                  </div>
                </SafetyTextBox>
                <SafetyDescription>
                  <DescriptionTitle>
                    <div>기준</div>
                    <div>지급액</div>
                  </DescriptionTitle>
                  <div>
                    <div> 지역별(시/군/구) 최초 거래인 경우</div>
                    <div>
                      매수인/매도인 각 <span>10만원</span>
                    </div>
                  </div>
                </SafetyDescription>
              </SafetyBox>
            </Card>
          </TopTextBox>
          <ContentBox>
            <Card>
              <CardContent>
                <CardTextBox>
                  <CardTitle>
                    <img src="/asset/images/sellTag.svg" alt="" />
                    <span>팔아드려요, 매물!</span>
                  </CardTitle>
                  <Description>
                    <DescriptionBig>무료로 매물등록 해 드려요.</DescriptionBig>
                    신뢰할 수 있는 지역 전담 파트너 중개사가 <br />
                    안심 매매를 책임집니다.
                  </Description>
                  <Description>
                    <DescriptionBig>“이 가격에도 안팔린다면”</DescriptionBig>
                    경쟁력을 더해 높은 가격으로 판매해 드려요.
                  </Description>
                </CardTextBox>
                <CardTagBox>
                  <Tag>#펜션·캠핑장</Tag>
                  <Tag>#양식장</Tag>
                  <Tag>#식물공장</Tag>
                  <Tag>#나무테크</Tag>
                </CardTagBox>
              </CardContent>
              <Button
                onClick={() => {
                  // setIsEnroll(true);
                  // setSaleType('sell');
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdWf3RuAaxp_aBFtGNxGTMJo19a2ImWaC0qX1m8EjNkd5cgnw/viewform',
                    '_blank',
                  );
                }}
              >
                <span>신청하기</span>
                <img
                  src="/asset/images/arrow/arrow-right-white-small.svg"
                  alt=""
                />
              </Button>
            </Card>
            <Card>
              <CardContent>
                <CardTextBox>
                  <CardTitle>
                    <img src="/asset/images/findTag.svg" alt="" />
                    <span>찾아드려요, 매물!</span>
                  </CardTitle>
                  <Description>
                    고객의 계획에 필요한 토지·주택, <br />
                    최적의 가격, 입지로 찾아드려요.
                  </Description>
                </CardTextBox>
                <CardTagBox>
                  <Tag>#귀촌계획</Tag>
                  <Tag>#귀농계획</Tag>
                  <Tag>#사업용토지</Tag>
                </CardTagBox>
              </CardContent>
              <Button
                onClick={() => {
                  // setIsEnroll(true);
                  // setSaleType('find');
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdthXp9yiNt3AwWgs9Bm_kgUjgfKoyBSvTZIPFF5JVHi6DGfg/viewform',
                    '_blank',
                  );
                }}
              >
                <span>신청하기</span>
                <img
                  src="/asset/images/arrow/arrow-right-white-small.svg"
                  alt=""
                />
              </Button>
            </Card>
          </ContentBox>
          <BottomNavigation />
        </>
      )}
    </Container>
  );
};

export default Brokerage;
