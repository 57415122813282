import { useEffect, useState } from 'react';
import {
  ModalContainer,
  ModalInner,
  ModalTitle,
  OptionBox,
  Section,
  SectionLabel,
  OptionItem,
  SectionBox,
  OptionItemBg,
  SelectedItemBox,
  SelectedLabel,
  SelectedValue,
  OptionRow,
  OptionRowTitle,
  OptionItemIcon,
  ScrollBox,
  ApplyBtnBox,
  ResetBtn,
  ResetBtnText,
  ApplyBtn,
} from './FilterModal.styles';
import colors from '../../../../color';
import UnitSwitch from '../../../components/unitSwitch/UnitSwitch';
import {
  areaOption,
  categoryOption,
  houseOption,
  landOption,
  priceOption,
  purposeOption,
  saleTypeOption,
  targetOption,
  themeOption,
  yearOption,
} from './FilterOption';
import { useFilter } from '../../../../contexts/FilterContext';
import { usePropertyFilter } from '../../../../contexts/PropertyContext';
import { useMapContext } from '../../../../contexts/MapContext';

interface FilterOptions {
  target: string;
  sale_type: string[];
  category: string[];
  purpose: string[];
  price: [string, string];
  area: [string, string];
  year: [string, string];
  theme: string[];
}

interface FilterModalProps {
  menu: string;
  setFilterModalOn: (value: boolean) => void;
  smallDevice: boolean;
  deviceHeight: number;
  setShowSnackBar: (value: boolean) => void;
}

const FilterModal = ({
  menu,
  setFilterModalOn,
  smallDevice,
  deviceHeight,
  setShowSnackBar,
}: FilterModalProps) => {
  const [tempFilterOption, setTempFilterOption] = useState<FilterOptions>({
    target: '',
    sale_type: [],
    category: [],
    purpose: [],
    price: ['', ''],
    area: ['', ''],
    year: ['', ''],
    theme: [],
  });
  const [showAllPurpose, setShowAllPurpose] = useState(false);
  const [unit, setUnit] = useState('평');
  const { filterOption, setFilterOption } = useFilter();
  const { propertyFilterOption, setPropertyFilterOption } = usePropertyFilter();
  const { mode } = useMapContext();
  const innerWidth = window.innerWidth - 32;

  const isModalType = window.location.pathname === '/';

  useEffect(() => {
    if (isModalType) {
      setTempFilterOption(filterOption);
    } else {
      setTempFilterOption(propertyFilterOption);
    }
  }, [filterOption, propertyFilterOption]);

  const handleApplyClick = () => {
    if (isModalType) {
      setFilterOption(tempFilterOption);
      setFilterModalOn(false);
      setShowSnackBar(true);
    } else {
      setPropertyFilterOption(tempFilterOption);
      setFilterModalOn(false);
      setShowSnackBar(true);
    }
  };

  // useEffect(() => {
  //   let newTarget = '전체';

  //   if (menu === '찾아줘 토지') {
  //     newTarget = '토지';
  //   } else if (menu === '찾아줘 주택') {
  //     newTarget = '주택';
  //   }
  //   setTempFilterOption((prev) => ({
  //     ...prev,
  //     target: newTarget,
  //   }));
  // }, [menu]);

  const handleModalTitle = () => {
    return menu === '지도'
      ? '어떤 매물을 찾을까요?'
      : menu === '찾아줘 토지'
        ? '어떤 토지를 찾을까요?'
        : '어떤 주택을 찾을까요?';
  };

  const handleSelect = (option: string, key: keyof typeof filterOption) => {
    setTempFilterOption((prev: any) => {
      if (option === '전체') {
        return { ...prev, [key]: ['전체'] };
      } else {
        const filtered = prev[key].filter((item: string) => item !== '전체');
        if (filtered.includes(option)) {
          return {
            ...prev,
            [key]: filtered.filter((item: string) => item !== option),
          };
        } else {
          return { ...prev, [key]: [...filtered, option] };
        }
      }
    });
  };

  const updateMinMax = (
    option: string,
    key: keyof typeof filterOption,
    options: { label: string; value: number | string }[],
    isYear?: boolean,
  ) => {
    setTempFilterOption((prev: any) => {
      const optionIndex = options.findIndex((item) => item.label === option);
      const minIndex = options.findIndex((item) => item.label === prev[key][0]);
      const maxIndex = options.findIndex((item) => item.label === prev[key][1]);

      if (prev[key][0] === '' && prev[key][1] === '') {
        if (option === (isYear ? '신축' : '최소')) {
          return { ...prev, [key]: [option, ''] };
        } else {
          return { ...prev, [key]: ['', option] };
        }
      } else if (prev[key][0] === '') {
        if (optionIndex > maxIndex) {
          return { ...prev, [key]: [prev[key][1], option] };
        }
        return { ...prev, [key]: [option, prev[key][1]] };
      } else if (prev[key][1] === '') {
        return { ...prev, [key]: [prev[key][0], option] };
      } else {
        if (option === (isYear ? '신축' : '최소')) {
          return { ...prev, [key]: [option, ''] };
        } else {
          return { ...prev, [key]: ['', option] };
        }
      }
    });
  };

  const handleSelectedPrice = (value: string) => {
    if (value === '최소') {
      return '최소 금액';
    }
    if (value === '20억~') {
      return '20억 이상';
    } else {
      return value;
    }
  };

  const changeUnit = (value: string, selected?: boolean) => {
    if (value === '') {
      return '';
    }
    if (value === '최소') {
      if (unit === '평' && selected) {
        return '최소 평수';
      }
      if (unit === '㎡' && selected) {
        return '최소 면적';
      } else {
        return '최소';
      }
    }
    if (value === '5000~' && unit === '평') {
      if (selected) {
        return '5000평 이상';
      }
      return '5000평~';
    }
    if (value === '5000~' && unit === '㎡') {
      if (selected) {
        return `${Math.floor(5000 * 3.3058)}㎡ 이상`;
      }
      return `${Math.floor(5000 * 3.3058)}㎡~`;
    }
    if (unit === '평') {
      return `${Number(value)}평`;
    } else {
      return `${Math.floor(Number(value) * 3.3058)}㎡`;
    }
  };

  const handleReset = () => {
    setTempFilterOption({
      target: '전체',
      sale_type: [],
      category: [],
      purpose: [],
      price: ['', ''],
      area: ['', ''],
      year: ['', ''],
      theme: [],
    });
  };

  const randerThemeOption = () => {
    if (tempFilterOption.target === '전체') {
      return (
        <ScrollBox>
          <Section>
            <SectionLabel>테마</SectionLabel>
            <OptionBox
              style={{
                width: 'fit-content',
                gap: '24px',
                flexWrap: 'nowrap',
                paddingRight: '16px',
              }}
            >
              {Object.keys(themeOption).map((categoryKey, index) => (
                <OptionRow key={index}>
                  <OptionRowTitle>{categoryKey}</OptionRowTitle>
                  {themeOption[categoryKey as keyof typeof themeOption].map(
                    (option, index) => (
                      <OptionItemIcon
                        key={index}
                        selected={tempFilterOption.theme.includes(option.text)}
                        onClick={() => handleSelect(option.text, 'theme')}
                      >
                        <img
                          src={`/asset/images/icon/${option.icon}.svg`}
                          alt=""
                        />
                        <span style={{ whiteSpace: 'nowrap' }}>
                          {option.text}
                        </span>
                      </OptionItemIcon>
                    ),
                  )}
                </OptionRow>
              ))}
            </OptionBox>
          </Section>
        </ScrollBox>
      );
    }
    if (tempFilterOption.target === '토지') {
      return (
        <Section>
          <SectionLabel>테마</SectionLabel>
          <OptionBox
            style={{
              gap: '8px',
              flexDirection: 'column',
            }}
          >
            {landOption.map((option, index) => (
              <OptionItemIcon
                key={index}
                selected={tempFilterOption.theme.includes(option.text)}
                onClick={() => handleSelect(option.text, 'theme')}
              >
                <img src={`/asset/images/icon/${option.icon}.svg`} alt="" />
                <span style={{ whiteSpace: 'nowrap' }}>{option.text}</span>
              </OptionItemIcon>
            ))}
          </OptionBox>
        </Section>
      );
    } else {
      return (
        <Section>
          <SectionLabel>테마</SectionLabel>
          <OptionBox
            style={{
              gap: '8px',
              flexDirection: 'column',
            }}
          >
            {houseOption.map((option, index) => (
              <OptionItemIcon
                key={index}
                selected={tempFilterOption.theme.includes(option.text)}
                onClick={() => handleSelect(option.text, 'theme')}
              >
                <img src={`/asset/images/icon/${option.icon}.svg`} alt="" />
                <span style={{ whiteSpace: 'nowrap' }}>{option.text}</span>
              </OptionItemIcon>
            ))}
          </OptionBox>
        </Section>
      );
    }
  };

  return (
    <ModalContainer>
      <ModalInner smallDevice={smallDevice}>
        <ModalTitle>
          <span>{handleModalTitle()}</span>
          <img
            src="/asset/images/close/close-gray.svg"
            alt="Close"
            onClick={() => {
              setFilterModalOn(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </ModalTitle>
        <SectionBox
          style={{
            height: smallDevice ? `${deviceHeight - 62 - 84}px` : '556px',
          }}
        >
          {menu === '지도' && (
            <Section>
              <SectionLabel>대상</SectionLabel>
              <OptionBox>
                {targetOption.map((option, index) => (
                  <OptionItem
                    key={index}
                    selected={tempFilterOption.target === option}
                    onClick={() => {
                      if (option === '전체') {
                        setTempFilterOption((prev: any) => ({
                          ...prev,
                          target: '전체',
                        }));
                      } else if (option === '토지') {
                        setTempFilterOption((prev: any) => ({
                          ...prev,
                          target: '토지',
                          purpose: [],
                          year: ['', ''],
                        }));
                      } else {
                        setTempFilterOption((prev: any) => ({
                          ...prev,
                          target: '주택',
                          category: [],
                        }));
                      }
                    }}
                    style={
                      option === '전체'
                        ? { borderRadius: '8px 0 0 8px', flex: 1 }
                        : option === '주택'
                          ? { borderRadius: '0  8px 8px 0', flex: 1 }
                          : { flex: 1 }
                    }
                  >
                    {option}
                  </OptionItem>
                ))}
              </OptionBox>
            </Section>
          )}
          {!isModalType && (
            <Section>
              <SectionLabel>유형</SectionLabel>
              <OptionBox style={{ gap: '8px' }}>
                {saleTypeOption.map((option, index) => (
                  <OptionItem
                    key={index}
                    selected={tempFilterOption.sale_type.includes(option)}
                    onClick={() => handleSelect(option, 'sale_type')}
                    style={{
                      borderRadius: '8px',
                      width: `calc((100% - 16px) / 3)`,
                    }}
                  >
                    {option}
                  </OptionItem>
                ))}
              </OptionBox>
            </Section>
          )}

          {tempFilterOption.target !== '주택' && mode !== '농지연금' && (
            <Section>
              <SectionLabel>지목</SectionLabel>
              <OptionBox style={{ gap: '8px' }}>
                {categoryOption.map((option, index) => (
                  <OptionItem
                    key={index}
                    selected={tempFilterOption.category.includes(option)}
                    onClick={() => handleSelect(option, 'category')}
                    style={{
                      borderRadius: '8px',
                      width: `calc((100% - 16px) / 3)`,
                    }}
                  >
                    {option}
                  </OptionItem>
                ))}
              </OptionBox>
            </Section>
          )}

          {tempFilterOption.target !== '토지' && (
            <Section>
              <SectionLabel>
                <SectionLabel>용도</SectionLabel>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <span
                    style={{
                      color: colors.labelAlternative,
                      fontSize: '14px',
                      fontFamily: 'Pretendard-Medium',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    전체 용도 보기
                  </span>
                  <img
                    src={`/asset/images/checkbox/${showAllPurpose ? 'checkon.svg' : 'checkoff.svg'}`}
                    alt=""
                    onClick={() => setShowAllPurpose(!showAllPurpose)}
                  />
                </div>
              </SectionLabel>
              <OptionBox style={{ gap: '8px' }}>
                {(showAllPurpose
                  ? purposeOption
                  : purposeOption.slice(0, 6)
                ).map((option, index) => (
                  <OptionItem
                    key={index}
                    selected={tempFilterOption.purpose.includes(option)}
                    onClick={() => handleSelect(option, 'purpose')}
                    style={{
                      borderRadius: '8px',
                      width: `calc((100% - 16px) / 3)`,
                    }}
                  >
                    {option}
                  </OptionItem>
                ))}
              </OptionBox>
            </Section>
          )}

          <Section>
            <SectionLabel>
              <SectionLabel>가격</SectionLabel>
            </SectionLabel>
            <OptionBox>
              {priceOption.map((option, index) => {
                const minIndex = priceOption.findIndex(
                  (item) => item.label === tempFilterOption.price[0],
                );
                const maxIndex = priceOption.findIndex(
                  (item) => item.label === tempFilterOption.price[1],
                );

                const isSelected =
                  index >= minIndex &&
                  index <= maxIndex &&
                  minIndex !== -1 &&
                  maxIndex !== -1;

                return (
                  <OptionItemBg
                    key={index}
                    selected={isSelected}
                    onClick={() => {
                      updateMinMax(option.label, 'price', priceOption);
                    }}
                    style={{
                      width: `calc((100%) / 4)`,
                      borderRadius:
                        option.label === '최소'
                          ? '8px 0 0 0'
                          : option.label === '2천만'
                            ? '0 8px 0 0'
                            : option.label === '5억'
                              ? '0 0 0 8px'
                              : option.label === '20억~'
                                ? '0 0 8px 0'
                                : '0',
                    }}
                  >
                    {option.label}
                  </OptionItemBg>
                );
              })}
            </OptionBox>

            <OptionBox style={{ gap: '8px', alignItems: 'center' }}>
              <SelectedItemBox>
                <SelectedLabel>최소</SelectedLabel>
                <SelectedValue>
                  {handleSelectedPrice(tempFilterOption.price[0])}
                </SelectedValue>
              </SelectedItemBox>
              <span
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '14px',
                  color: colors.labelAlternative,
                }}
              >
                ~
              </span>
              <SelectedItemBox>
                <SelectedLabel>최대</SelectedLabel>
                <SelectedValue>
                  {handleSelectedPrice(tempFilterOption.price[1])}
                </SelectedValue>
              </SelectedItemBox>
            </OptionBox>
          </Section>
          <Section>
            <SectionLabel>
              <SectionLabel>면적</SectionLabel>
              <UnitSwitch unit={unit} setUnit={setUnit} />
            </SectionLabel>
            <OptionBox>
              {areaOption.map((option, index) => {
                // 최소 및 최대 값의 인덱스를 계산
                const minIndex = areaOption.findIndex(
                  (item) => item.label === tempFilterOption.area[0],
                );
                const maxIndex = areaOption.findIndex(
                  (item) => item.label === tempFilterOption.area[1],
                );

                // 현재 항목이 선택된 범위 내에 있는지 확인
                const isSelected =
                  index >= minIndex &&
                  index <= maxIndex &&
                  minIndex !== -1 &&
                  maxIndex !== -1;

                return (
                  <OptionItemBg
                    key={index}
                    selected={isSelected}
                    onClick={() => {
                      updateMinMax(option.label, 'area', areaOption);
                    }}
                    style={{
                      width: `calc((100% ) / 3)`,
                      borderRadius:
                        option.label === '최소'
                          ? '8px 0 0 0'
                          : option.label === '50'
                            ? '0 8px 0 0'
                            : option.label === '4000'
                              ? '0 0 0 8px'
                              : option.label === '5000~'
                                ? '0 0 8px 0'
                                : '0',
                    }}
                  >
                    {changeUnit(option.label)}
                  </OptionItemBg>
                );
              })}
            </OptionBox>
            <OptionBox style={{ gap: '8px', alignItems: 'center' }}>
              <SelectedItemBox>
                <SelectedLabel>최소</SelectedLabel>
                <SelectedValue>
                  {changeUnit(tempFilterOption.area[0], true)}
                </SelectedValue>
              </SelectedItemBox>
              <span
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '14px',
                  color: colors.labelAlternative,
                }}
              >
                ~
              </span>
              <SelectedItemBox>
                <SelectedLabel>최대</SelectedLabel>
                <SelectedValue>
                  {changeUnit(tempFilterOption.area[1], true)}
                </SelectedValue>
              </SelectedItemBox>
            </OptionBox>
          </Section>
          {tempFilterOption.target !== '토지' && (
            <Section>
              <SectionLabel>
                <SectionLabel>건축연도</SectionLabel>
              </SectionLabel>
              <OptionBox>
                {yearOption.map((option, index) => {
                  // 최소 및 최대 값의 인덱스를 계산
                  const minIndex = yearOption.findIndex(
                    (item) => item.label === tempFilterOption.year[0],
                  );
                  const maxIndex = yearOption.findIndex(
                    (item) => item.label === tempFilterOption.year[1],
                  );

                  // 현재 항목이 선택된 범위 내에 있는지 확인
                  const isSelected =
                    index >= minIndex &&
                    index <= maxIndex &&
                    minIndex !== -1 &&
                    maxIndex !== -1;

                  return (
                    <OptionItemBg
                      key={index}
                      selected={isSelected}
                      onClick={() => {
                        updateMinMax(option.label, 'year', yearOption, true);
                      }}
                      style={{
                        width: `calc((100%) / 4)`,
                        borderRadius:
                          option.label === '신축'
                            ? '8px 0 0 0'
                            : option.label === '3년'
                              ? '0 8px 0 0'
                              : option.label === '30년'
                                ? '0 0 0 8px'
                                : option.label === '100년~'
                                  ? '0 0 8px 0'
                                  : '0',
                      }}
                    >
                      {option.label}
                    </OptionItemBg>
                  );
                })}
              </OptionBox>
              <OptionBox style={{ gap: '8px', alignItems: 'center' }}>
                <SelectedItemBox>
                  <SelectedLabel>최소</SelectedLabel>
                  <SelectedValue>{tempFilterOption.year[0]}</SelectedValue>
                </SelectedItemBox>
                <span
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '14px',
                    color: colors.labelAlternative,
                  }}
                >
                  ~
                </span>
                <SelectedItemBox>
                  <SelectedLabel>최대</SelectedLabel>
                  <SelectedValue>{tempFilterOption.year[1]}</SelectedValue>
                </SelectedItemBox>
              </OptionBox>
            </Section>
          )}
          {/* {randerThemeOption()} */}
        </SectionBox>
        <ApplyBtnBox>
          <ResetBtn onClick={handleReset}>
            <img src="/asset/images/icon/reset.svg" alt="" />
            <ResetBtnText>초기화</ResetBtnText>
          </ResetBtn>
          <ApplyBtn
            onClick={() => {
              handleApplyClick();
            }}
          >
            적용
          </ApplyBtn>
        </ApplyBtnBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default FilterModal;
