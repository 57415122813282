import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const LoginMoreInfo = () => {
  const navigate = useNavigate();
  return (
    <LoginInfoBox>
      <MyTeraBox
        onClick={() => {
          navigate('/mychon');
        }}
      >
        <div>마이촌</div>
        <img src="/asset/images/arrow/arrow-right-gray-big.svg" alt="" />
      </MyTeraBox>
      <MyInfo
        onClick={() => {
          navigate('/myinfo');
        }}
      >
        내 정보
      </MyInfo>
    </LoginInfoBox>
  );
};
export default LoginMoreInfo;

const LoginInfoBox = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: space-between;
  flx-direction: row;
`;

const MyTeraBox = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Bold;
  line-height: 26px;
  word-wrap: break-word;
  cursor: pointer;
`;

const MyInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 10px;
  border-radius: 4px;
  background: rgba(112, 115, 124, 0.08);
  justify-content: center;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  line-height: 20px;
  word-wrap: break-word;
  cursor: pointer;
`;
