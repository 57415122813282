// import DatePicker from "./components/DatePicker";

import './report.css';
import React from 'react';

interface BoxCardProps {
  title: string;
  value: string | JSX.Element | number;
  underLine?: boolean;
  mypage?: boolean;
  mypage2?: boolean;
  onClick?: () => void;
}

export const BoxCard1: React.FC<BoxCardProps> = ({
  title,
  value,
  underLine,
}) => (
  <div
    className={`box_card   start-y`}
    style={{ borderBottom: underLine ? '1px solid #171E261A' : 'none' }}
  >
    <div className={`title w-100per`}>{title}</div>
    <div className={`text w-100per`}>{value}</div>
  </div>
);

export const BoxCard2: React.FC<BoxCardProps> = ({ title, value, onClick }) => (
  <div className="box_card center-y">
    <div className="flex-row gap-2">
      <div className="title">{title}</div>
      <img
        src="/asset/images/warn/Warn24.svg"
        alt=""
        onClick={onClick}
        className="hover"
      />
    </div>
    <div className="text text-center">{value}</div>
  </div>
);

export const BoxGreen1 = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => (
  <div
    className="padding-16 gap-10 border-radius-8 bg-primary-100"
    // style={{ flex: "1" }}
  >
    {children}
  </div>
);
export const BoxGreen2 = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => (
  <div
    className="gap-10 border-radius-8 bg-primary-100"
    style={{ flex: '1', padding: '16px 0 16px 16px' }}
  >
    {children}
  </div>
);

export const BoxTable = ({
  table,
}: {
  table: {
    th: Array<string | number | undefined>;
    tb: Array<Array<string | number | undefined>>;
  };
}) => {
  return (
    <table className="box-table" style={{ textAlign: 'center', width: '100%' }}>
      <thead>
        <tr>
          {table.th.map((el) => (
            <th className="color-text-secondary w-50per">{el}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.tb.map((el) => (
          <tr>
            {el.map((el) => (
              <td className="color-text-secondary w-50per">{el}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface BoxTable2Props {
  table: {
    th: Array<string | number>;
    tb: Array<Array<string | number | JSX.Element>>;
  };
  onPrice?: boolean;
  onClick?: () => void;
  onClick2?: () => void;
}

interface BoxTable2Props {
  table: {
    th: Array<string | number>;
    tb: Array<Array<string | number | JSX.Element>>;
  };
  onPrice?: boolean;
  onClick?: () => void;
  onClick2?: () => void;
}

export const BoxTable2: React.FC<BoxTable2Props> = ({
  table,
  onPrice,
  onClick,
  onClick2,
}) => (
  <table
    className="box-table"
    style={{ width: '100%', borderCollapse: 'collapse' }}
  >
    <thead>
      <tr>
        {table.th.map((el, i) => (
          <th key={`th-${i}`} className="color-text-primary no-wrap">
            {el}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {table.tb.map((row, i) => (
        <tr key={`row-${i}`} className="border-b-1-171E2610">
          {row.map((cell, y) => (
            <td
              key={`cell-${i}-${y}`}
              className="color-text-primary no-wrap"
              style={{
                textAlign: 'center',
              }}
            >
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
//===============================================
interface TableProps {
  th: Array<string | number | undefined>;
  tb: Array<Array<string | number | undefined>>;
}

interface BoxTableProps {
  table: TableProps;
  right?: boolean;
  right2?: boolean;
}

const BoxTableCommon: React.FC<BoxTableProps> = ({ table, right, right2 }) => (
  <table className="box-table">
    <thead>
      <tr>
        {table.th.map((el, i) => (
          <th
            key={i}
            className={`${i === 1 && right ? 'text-right' : ''}`}
            style={{
              fontFamily: 'Pretendard-Bold',
              color: i === 1 ? '#171E26' : '#171E26C7',
              padding: i === 0 ? '4px 16px' : '4px 16px 4px 0',
            }}
          >
            {el}
          </th>
        ))}
      </tr>
    </thead>
    <tbody style={{ maxHeight: '148px' }}>
      {table.tb.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell, cellIndex) => (
            <td
              key={cellIndex}
              style={{
                fontFamily: 'SpoQaHanSansNeoMedium',
                color: cellIndex === 1 ? '#171E26' : '#171E26C7',
                padding: '4px 16px',
                textAlign: cellIndex === 1 ? 'right' : 'center',
              }}
            >
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const BoxTable3: React.FC<BoxTableProps> = ({
  table,
  right2,
  right,
}) => <BoxTableCommon table={table} right={right} />;

export const BoxTable3N: React.FC<BoxTableProps> = ({ table, right }) => (
  <BoxTableCommon table={table} right={right} />
);

export const BoxTable4 = ({
  table,
  temp,
}: {
  table: {
    th: Array<string | number | undefined>;
    tb: Array<Array<string | number | undefined>>;
    tf: Array<string | number | undefined>;
  };
  temp?: any;
}) => (
  <table className="box-table">
    <thead>
      <tr>
        {table.th.map((el, i) => (
          <th
            key={i}
            className="padding-8"
            style={{
              color: '#171E26C7',
              width: temp ? (i === 1 ? '20%' : '40%') : '33%',
              textAlign: i > 0 ? 'right' : 'start',
            }}
          >
            {el}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {table.tb.map((el, i) => (
        <tr key={i} style={{ borderBottom: '1px solid #171e2610' }}>
          {el.map((el, i) => (
            <td
              key={i}
              className="padding-8 no-wrap"
              style={{
                textOverflow: 'initial',
                color: i >= 1 ? '#171E26' : '#171E26C7',
                width: '33%',
                textAlign: i > 0 ? 'right' : 'start',
                fontSize: i > 1 ? '0.75rem' : '0.875rem',
              }}
            >
              {el}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        {table.tf.map((el, i) => (
          <td
            key={i}
            className="padding-8 font-bold no-wrap"
            style={{
              color: i === 1 ? '#171E26' : '#171E26C7',
              width: i === 1 ? '20%' : '40%',
              textAlign: i > 0 ? 'right' : 'start',
            }}
          >
            {el}
          </td>
        ))}
      </tr>
    </tfoot>
  </table>
);

//=============================================

export const BoxTable3M = ({
  table,
}: {
  table:
    | {
        th: Array<any>;
        tb: Array<Array<any>>;
      }
    | any;
}) => (
  <div className="border-none of-x-scroll">
    <div
      className="bg-light-gray border-radius-8-th"
      style={{ width: 'fit-content' }}
    >
      <div className="flex-row padding-4-8">
        {table.th.map((el: any, i: any) => (
          <div
            className="border-none font-bold f-size-14 line-h-20 no-wrap center-x"
            style={{
              color: i === 1 ? '#171E26' : '#171E26C7',
              minWidth: el.mw,
            }}
          >
            {el.value}
          </div>
        ))}
      </div>
    </div>
    <div
      style={{ width: 'fit-content', overflowY: 'auto', maxHeight: '148px' }}
    >
      {table.tb.map((el: any) => (
        <div className="flex-row padding-x-8">
          {el.map((el: any, i: any) => (
            <div
              className="border-none font-medium f-size-14 line-h-20 no-wrap center-x"
              style={{
                padding: '4px',
                color: i === 1 ? '#171E26' : '#171E26C7',
                // justifyContent: i > 1 ? 'right' : 'center',
                minWidth: el.mw,
              }}
            >
              {el.value}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

function BoxDateSelector(
  title: string,
  close: any,
  datePicker: any,
  onClick: () => void,
  setDate: any,
  cDate: any,
) {
  return (
    <div className="box_date_selector">
      <div className="top">
        <div className="content">
          <div className="title">
            {title}
            {/* <img src={closeImg} onClick={close} className='hover' /> */}
          </div>
          <div className="picker">{datePicker()}</div>
        </div>
        <div className="bottom mobile">
          <div className="flex-1 center-x color-text-tertiary" onClick={close}>
            취소
          </div>
          <div className="div" />
          <div className="flex-1 center-x color-primary-500" onClick={onClick}>
            선택
          </div>
        </div>
        <div className="bottom">
          <div className="ok" onClick={onClick}>
            선택
          </div>
          <div className="cancel" onClick={close}>
            <div className="">취소</div>
          </div>
        </div>
      </div>
    </div>
  );
}
//=====================================
export const BoxTable7 = ({
  table,
  onPrice,
  onClick,
  onClick2,
}: {
  table: {
    th: Array<string | number>;
    tb: Array<Array<string | number | JSX.Element>>;
  };
  onPrice?: any;
  onClick?: any;
  onClick2?: any;
}) => (
  <table
    className="box-table"
    style={onPrice ? { width: '100%' } : { width: '578px' }}
  >
    <thead>
      <tr>
        {table.th.map((el, i) => (
          <th className="color-text-primary no-wrap">
            {onPrice && i === 2 ? (
              <div className="flex-row center-y">
                {el}
                <img
                  src="/asset/images/warn/Warn24.svg"
                  className="hover"
                  style={{ justifySelf: 'center', alignSelf: 'center' }}
                  onClick={onClick}
                  alt=""
                />
              </div>
            ) : (
              el
            )}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {table.tb.map((el, i) => (
        <React.Fragment key={i}>
          <tr className="border-b-1-171E2610">
            {el.map((cell, y) => (
              <td
                key={y}
                className="color-text-parimary no-wrap"
                style={{
                  fontSize: y === 1 ? '0.75rem' : '0.875rem',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {onPrice && i === 4 && y === 1 ? (
                  <div className="flex-row center-y center-x">
                    {cell}
                    <img
                      src="/asset/images/warn/Warn24.svg"
                      className="hover"
                      style={{ justifySelf: 'center', alignSelf: 'center' }}
                      onClick={onClick2}
                      alt=""
                    />
                  </div>
                ) : (
                  // Add line breaks after spaces or whitespace
                  typeof cell === 'string' && cell.replace(/ /g, '\n')
                )}
              </td>
            ))}
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  </table>
);

interface TableProps {
  th: Array<string | number | undefined>;
  tb: Array<Array<string | number | undefined>>;
}

interface BoxTableProps {
  table: TableProps;
  right?: boolean;
  right2?: boolean;
  right3?: boolean;
}

export const BoxTableSelected: React.FC<BoxTableProps> = ({
  table,
  right,
  right2,
  right3,
}) => {
  return (
    <table className="box-table box-table-center">
      <thead>
        <tr>
          {table.th.map((el, i) => (
            <th key={i}>{el}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.tb.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                style={{
                  textWrap: 'nowrap',
                  color: cellIndex === 0 ? '#171e268f' : '#171E26',
                  textAlign: cellIndex !== 0 ? 'right' : 'center',
                  padding: cellIndex !== 0 ? '0 16px 0 0' : '0 16px',
                }}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const BoxTable9 = ({
  table,
  onPrice,
  onClick,
  onClick2,
}: {
  table: {
    th: Array<string | number | JSX.Element>;
    tb: Array<Array<string | number | JSX.Element>>;
  };
  onPrice?: any;
  onClick?: any;
  onClick2?: any;
}) => (
  <table
    className="custom_box-table"
    style={onPrice ? { width: '100%' } : { width: '578px' }}
  >
    <thead>
      <tr>
        {table.th.map((el, i) => (
          <th
            className={`color-text-primary no-wrap${
              i === 2 ? 'special-color' : ''
            }`}
            style={i === 0 ? { width: '7%' } : {}}
          >
            {onPrice && i === 2 ? (
              <div className="center-y special-color">
                {el}
                <img
                  src="/asset/images/warn/Warn24.svg"
                  className="hover"
                  style={{ justifySelf: 'left', alignSelf: 'left' }}
                  onClick={onClick}
                  alt=""
                />
              </div>
            ) : (
              el
            )}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {table.tb.map((el, i) => (
        <tr className="border-b-1-171E2610">
          {el.map((el, y) => (
            <td className="color-text-parimary no-wrap">
              {onPrice && i === 4 && y === 1 ? (
                <div className="flex-row center-y center-x">
                  {el}
                  <img
                    src="/asset/images/warn/Warn24.svg"
                    className="hover"
                    style={{ justifySelf: 'center', alignSelf: 'center' }}
                    onClick={onClick2}
                    alt=""
                  />
                </div>
              ) : (
                el
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
