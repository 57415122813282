const getToken = () => {
  let token = localStorage.getItem('accessToken');
  if (token == null) {
    token = '';
  }
  return token;
};

export const isLogin = () => {
  if (getToken() === '') {
    return false;
  }
  return true;
};

export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('name');
  localStorage.removeItem('point');
  localStorage.removeItem('profileImg');
  localStorage.removeItem('email');
  localStorage.removeItem('phone');
  localStorage.removeItem('category');
  localStorage.removeItem('interest_area');
  localStorage.removeItem('birth');
  localStorage.removeItem('farm_id');
  localStorage.removeItem('loginType');
  alert('로그아웃 되었습니다.');
};
