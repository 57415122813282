import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState } from 'react';
import BoardService from '../../../service/board';
import { useNavigate } from 'react-router-dom';

const Notice = () => {
  const [recentNotice, setRecentNotice] = useState({
    articleIdx: 0,
    title: '',
    createTime: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const GetNoticeList = async () => {
      try {
        const res = await BoardService.list('A', 10, 0);
        setRecentNotice(res.result.list[0]);
      } catch (error) {
        alert(error);
      }
    };

    GetNoticeList();
  }, []);

  return (
    <NoticeContainer
      onClick={() => {
        navigate(`/notice/detail/${recentNotice.articleIdx}`);
      }}
    >
      <NoticeTextBox>
        <img src="/asset/images/icon/megaphone_icon_01 1.svg" alt=""></img>
        <div>{recentNotice && recentNotice.title}</div>
      </NoticeTextBox>
      <img src="/asset/images/arrow/arrow-right-gray.svg" alt=""></img>
    </NoticeContainer>
  );
};

export default Notice;

const NoticeContainer = styled.div`
  width: 100%;
  height: 24px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: ${colors.white};
  cursor: pointer;
`;

const NoticeTextBox = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;
