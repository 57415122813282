import { useState } from 'react';
import styled from 'styled-components';
import { Button } from './components/Button';
import { set } from 'lodash';
import AuthService from '../../service/auth';
import { useNavigate } from 'react-router-dom';

export const DeletePopUp = ({
  selectedReasons,
  setOnPopUp,
}: {
  setOnPopUp: any;
  selectedReasons: string[];
}) => {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phone'));
  const [certificationNumber, setCertificationNumber] = useState('');
  const [showCertificationNumber, setShowCertificationNumber] = useState(false);
  // const [changePhone, setChangePhone] = useState(false);
  const [showDeletePopUp2, setShowDeletePopUp2] = useState(false);

  const sendSms = async () => {
    const data = {
      email: localStorage.getItem('email') ?? '',
      phone: phoneNumber || '',
      type: 'E',
    };
    try {
      const result = await AuthService.sendSms(data);
      if (result) {
        alert('인증번호가 발송되었습니다.');
        setShowCertificationNumber(true);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || '';
      if (errorMessage.includes('email must be an email')) {
        alert('이메일 형식이 아닙니다.');
        return;
      } else {
        console.log('error', error.response.data.message);
        alert(`${error.response.data.message}`);
      }
    }
  };

  const withDraw = async () => {
    const data = {
      reason: selectedReasons,
    };
    try {
      const result = await AuthService.withDraw(data);
      if (result) {
        // setShowDeletePopUp2(true);
        setOnPopUp(false);
        alert('탈퇴가 완료되었습니다.');
        localStorage.clear();
        navigate('/');
      }
    } catch (error: any) {
      console.log('error', error.response.data.message);
      alert(`${error.response.data.message}`);
    }
  };

  const verifySms = async () => {
    const data = {
      email: localStorage.getItem('email') ?? '',
      phone: phoneNumber || '',
      type: 'E',
      name: localStorage.getItem('name') ?? '',
      authCode: certificationNumber,
    };
    try {
      const result = await AuthService.verifySms(data);
      if (result) {
        setShowDeletePopUp2(true);
        withDraw();
      }
    } catch (error: any) {
      console.log('e', error.response.data.message);
      alert('인증번호가 일치하지 않습니다.');
    }
  };

  return (
    <div
      id="post-meta-col"
      className="flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 w-312 flex-center flex-column">
        <div className="flex-column gap-24 w-100per">
          <div className="flex-column gap-8 flex-center">
            <div className="m-b1-16-b">휴대폰번호 인증</div>
            <div
              className="m-b2-14-m "
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              안전한 회원탈퇴를 위해 핸드폰 번호를
              <div style={{ alignSelf: 'center' }}>입력해 주세요.</div>
            </div>
          </div>
          <div
            className="flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16 gap-2"
            style={{ backgroundColor: 'rgba(247, 248, 252, 1)' }}
          >
            <PhoneNumberText>핸드폰 번호</PhoneNumberText>
            <div className="flex-row space-between w-100per">
              {phoneNumber && (
                <Inputbox
                  width={150}
                  className=""
                  disabled={true}
                  value={phoneNumber}
                  onKeyDown={handleKeyDown}
                  placeholder="010 0000 0000"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
              )}
              <VerifyBtn
                onClick={() => {
                  sendSms();
                }}
              >
                {!showCertificationNumber ? '인증번호 받기' : '재전송'}
              </VerifyBtn>
            </div>
          </div>
          {showCertificationNumber && (
            <div
              className="flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16 gap-2"
              style={{ backgroundColor: 'rgba(247, 248, 252, 1)' }}
            >
              <PhoneNumberText>인증 번호</PhoneNumberText>
              <div className="flex-row space-between w-100per">
                <Inputbox
                  width={''}
                  className=""
                  value={certificationNumber}
                  onKeyDown={handleKeyDown}
                  placeholder="인증번호 6자리를 입력해주세요"
                  onChange={(e) => {
                    setCertificationNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          )}

          <div className="p flex w-100per gap-8">
            <Button
              on={
                showCertificationNumber
                  ? certificationNumber.length > 5
                    ? true
                    : false
                  : false
              }
              description="탈퇴하기"
              onClick={() => {
                certificationNumber.length > 5 && verifySms();
              }}
            />
            <Button
              on={false}
              description="취소"
              onClick={() => {
                setOnPopUp(false);
              }}
            />
          </div>
        </div>
      </div>
      {/* <>{showDeletePopUp2 && window.alert('탈퇴가 완료되었습니다.')}</> */}
    </div>
  );
};

export default DeletePopUp;

const Inputbox = styled.input`
  display: flex;
  background-color: rgba(250, 250, 250, 1);
  font-size: 17px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26px;
  color: black;
  border: none;
  width: ${(props) => `${props.width}px` || '100%'};
  &::placeholder {
    font-family: Pretendard-medium;
    line-height: 30px;
    color: rgba(136, 139, 144, 1);
    font-size: 17px;
  }
`;
const handleKeyDown = (event: any) => {
  if (event.keyCode === 32) {
    event.preventDefault();
  }
};

const VerifyBtn = styled.div`
  display: flex;
  background-color: #ededef;
  width: 98px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-family: 'SpoqaHanSansNeoMedium';
  font-size: 13px;
  line-height: 16px;
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const PhoneNumberText = styled.div`
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const PhoneNumber = styled.div`
  color: rgba(55, 56, 60, 0.28);
  font-size: 17px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
`;
