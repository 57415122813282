import styled from 'styled-components';

export const ConsultingBtn = () => {
  return (
    <ButtonWrap>
      <div>신청</div>
      <img
        style={{ width: '30px' }}
        src="/asset/images/arrow/arrow-right-white-small.svg"
        alt=""
      />
    </ButtonWrap>
  );
};

const ButtonWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(30, 155, 107, 1);
  color: #ffffff;
  width: 80px;
  height: 40px;
  font-family: Pretendard-Bold;
  font-size: 16px;
  border-radius: 100px;
  white-space: nowrap;
`;
