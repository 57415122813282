export const Team = () => {
  return (
    <div className="team-wrapper">
      <div className="team-title-box">
        <div>국내 유일 토지·농업 ·금융 데이터 융합 전문 분석팀 소개</div>
        <div className="team-script">
          국내 유일 토지·농업·데이터 융합 <br />
          전문 분석팀이 함께합니다.{' '}
        </div>
      </div>
      <div className="team-profile-card-box">
        <div className="team-profile-card">
          <img
            style={{ width: '164px' }}
            src="/asset/images/promotion/person1.svg"
            alt=""
          />
          <div className="team-profile-comment">
            <div>
              모든 보고서는 직접 분석, <br /> 검토하여 책임 하에 서명하여 <br />
              제공할 것을 약속드립니다.
            </div>
            <div className="team-profile-record-box">
              <div className="team-profile-name">
                김기현 <span>CEO</span>
              </div>
              <div className="team-profile-school">
                성균관대 경영학/한양대 경영학(Ph D.)
              </div>
              <div className="team-profile-record">
                성균관대 경영학 <br />
                한양대 경영학 박사(Ph.D) <br />
                <br />
                글로벌컨설팅 펌 삼정KPMG <br />
                전략컨설팅본부 이사 <br />
                <br />
                한양대학교 스마트시티 공학과 겸임교수 <br />
                DAMA(국제데이터매니지먼트협회) 한국 대표
              </div>
            </div>
          </div>
        </div>
        <div className="team-profile-card">
          <img
            style={{ width: '164px' }}
            src="/asset/images/promotion/person2.svg"
            alt=""
          />
          <div className="team-profile-comment">
            <div>
              작은 토지라도 귀하게, <br /> 시간, 비용은 최소화하고 <br />{' '}
              토지가치는 최대화하도록
            </div>
            <div className="team-profile-record-box">
              <div className="team-profile-name">
                이봉석 <span>COO</span>
              </div>
              <div className="team-profile-school ">
                서울대 토목공학/ 도쿄대 도시공학 박사
              </div>
              <div className="team-profile-record">
                서울대 토목공학 <br />
                도쿄대 도시공학 박사 <br />
                <br />
                글로벌컨설팅 펌 삼정KPMG <br />
                부동산본부 이사 <br />
                <br />
                노무라종합연구소 팀장 <br />
                삼성물산 건설부문 부장 <br />
                일본 토지종합연구소 조사부 연구원
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-profile-text-box">
        <div className="team-profile-plus">+</div>
        <div className="flex-column gap-12 flex-center">
          <img
            style={{ width: '50px' }}
            src="/asset/images/promotion/consulting_img5.svg"
            alt=""
          />
          <div className="teatm-profile-plus-text">
            AI·빅데이터 전문 분석팀과 <br />
            재무 컨설팅 전문 리더들이 <br />
            최적의 솔루션 제시를 위해 함께 뜁니다.
          </div>
        </div>
      </div>
    </div>
  );
};
