export const RedirectHandler = (key: string) => {
  let url;
  const redirectUrl = `${process.env.REACT_APP_HOST}auth/login/`;

  if (key === 'Kakao') {
    const clientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
    const queryParams = `?client_id=${clientId}&redirect_uri=${redirectUrl}kakao&response_type=code&state=${window.location.origin}`;
    url = `https://kauth.kakao.com/oauth/authorize${queryParams}`;
  } else if (key === 'Naver') {
    const clientId = process.env.REACT_APP_NAVER_CLIENT_ID;
    const queryParams = `?response_type=code&client_id=${clientId}&state=${window.location.origin}&redirect_uri=${redirectUrl}naver`;
    url = `https://nid.naver.com/oauth2.0/authorize${queryParams}`;
  } else if (key === 'Facebook') {
    const client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const queryParams = `?client_id=${client_id}&redirect_uri=${redirectUrl}facebook&scope=email&state=${window.location.origin}`;
    url = `https://www.facebook.com/v11.0/dialog/oauth${queryParams}`;
  } else if (key === 'Google') {
    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const queryParams = `?client_id=${client_id}&redirect_uri=${redirectUrl}google&response_type=code&scope=email profile&state=${window.location.origin}`;
    url = `https://accounts.google.com/o/oauth2/v2/auth${queryParams}`;
  }

  if (url) {
    window.location.href = url;
  } else {
    console.error('Invalid key');
  }
};
