import styled from 'styled-components';
import colors from '../../color';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px 42px 16px;
  flex-grow: 1;
`;

const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  font-family: Pretendard-Bold;
  font-size: 24px;
  color: ${colors.labelNormal};
`;
const Description = styled.span`
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;
const Line = styled.div`
  height: 1px;
  display: flex;
  flex: 1;
  background-color: rgba(112, 115, 124, 0.22);
`;
const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const LongButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const EmailButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
`;
const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${colors.lineNeutral};
`;
const Img = styled.img`
  cursor: pointer;
`;

const SignupText = styled.span`
  cursor: pointer;
`;

export const S = {
  Container,
  ContentBox,
  LineBox,
  Line,
  LogoBox,
  PageTitle,
  Description,
  ButtonBox,
  LongButton,
  EmailButton,
  Divider,
  Img,
  SignupText,
};
