import React, { useRef } from 'react';
import './Info.css';

export const Carousel = ({ scroll }: any) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // 왼쪽으로 스크롤할 픽셀 수
        behavior: 'smooth', // 부드러운 스크롤 애니메이션
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // 오른쪽으로 스크롤할 픽셀 수
        behavior: 'smooth',
      });
    }
  };

  const data = [
    {
      idx: 0,
      title: 'TRANSFARMER',
      content:
        '디지털 트랜스포머(Digital Transformer)와 파머(Farmer)를 결합한 브랜드입니다.',
      img: `/asset/images/profile/carouselImg1.png`,
    },
    {
      idx: 1,
      title: 'EASY FARMING',
      content:
        '농업 입문자부터 전문가까지, 맞춤형 영농 정보를 얻을 수 있는 체계적인 시스템을 제공합니다.',
      img: `/asset/images/profile/carouselImg2.png`,
    },
    {
      idx: 2,
      title: 'AI FARMING',
      content:
        '빠르게 늙어가는 농촌을 위해서는 ‘열심히, 그리고 잘하는’ 현대화된 영농 시스템이 필요합니다. 농업에도 인공지능(AI)이 접목돼야 하는 이유입니다.',
      img: `/asset/images/profile/carouselImg3.png`,
    },
  ];

  return (
    <div
      className={`${scroll > 312 && `fade-in-up`}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        ref={scrollContainerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          overflowX: 'scroll',
          scrollBehavior: 'smooth',
          // marginRight: '16px',
        }}
        className="scroll-container"
      >
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '296px',
              height: '364px',
              borderRadius: '8px',
              overflow: 'hidden',
              flexShrink: 0,
            }}
          >
            <img src={item.img} alt="" />
            <div
              style={{
                width: '100%',
                padding: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                borderRadius: '0 0 8px 8px',
                border: '1px solid rgb(23 30 38 / 6%)',
                height: '100%',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171E26',
                  textAlign: 'center',
                }}
              >
                {item.title}
              </div>
              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#171e268f',
                  textAlign: 'center',
                }}
              >
                {item.content}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px',
          paddingTop: '16px',
        }}
      >
        <img
          style={{ cursor: 'pointer' }}
          src="/asset/images/arrow/ArrowWithCircleLeft.svg"
          onClick={scrollLeft}
          alt="Scroll Left"
        />
        <img
          style={{ cursor: 'pointer' }}
          src="/asset/images/arrow/ArrowWithCircleRight.svg"
          onClick={scrollRight}
          alt="Scroll Right"
        />
      </div>
    </div>
  );
};
