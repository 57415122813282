import { useNavigate } from 'react-router-dom';
import { RedirectHandler } from '../../../controller/Redirect';
import { S } from './SignUpModal.styles';

interface SignUpModalProps {
  setShowSignUpModal: (value: boolean) => void;
  setLoginModalOn: (value: boolean) => void;
  handleTextClick: () => void;
  beforeType: string;
}

const SignUpModal = ({
  setShowSignUpModal,
  setLoginModalOn,
  handleTextClick,
  beforeType,
}: SignUpModalProps) => {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const query = window.location.search;

  // 조합된 결과
  const currentPath = `${path}${query}`;

  return (
    <S.Container>
      <S.Inner>
        <S.PageTop>
          <img
            src="/asset/images/close/close-gray.svg"
            alt=""
            onClick={() => {
              setShowSignUpModal(false);
              beforeType === 'property' ? navigate(-1) : handleTextClick(); //변경된 lat,lng로 홈으로 이동하는 함수
            }}
          />
        </S.PageTop>
        <S.ContentBox>
          <S.PageTitle>
            <div>지금 바로 로그인하면</div>
            <S.Description>
              전국 모든 토지·주택 <br />
              평생 무료 분석
            </S.Description>
          </S.PageTitle>
          <S.ButtonBox>
            <S.LongButton>
              <S.Img
                src="/asset/images/button/btn_kakao_login.svg"
                alt=""
                onClick={() => {
                  localStorage.setItem('currentPath', currentPath);
                  localStorage.setItem('loginModal', 'Y');
                  RedirectHandler('Kakao');
                }}
                style={{ width: '100%', height: '48px' }}
              />
              <S.Img
                src="/asset/images/button/btn_naver_login.svg"
                alt=""
                onClick={() => {
                  localStorage.setItem('currentPath', currentPath);
                  localStorage.setItem('loginModal', 'Y');
                  RedirectHandler('Naver');
                }}
                style={{ width: '100%', height: '48px' }}
              />
            </S.LongButton>
            <S.LineBox>
              <S.Line></S.Line>
              <span>또는</span>
              <S.Line></S.Line>
            </S.LineBox>
            <S.LogoBox>
              <S.Img
                src="/asset/images/logo/facebook.svg"
                alt=""
                onClick={() => {
                  localStorage.setItem('currentPath', currentPath);
                  localStorage.setItem('loginModal', 'Y');
                  RedirectHandler('Facebook');
                }}
              />
              <S.Img
                src="/asset/images/logo/google.svg"
                alt=""
                onClick={() => {
                  localStorage.setItem('currentPath', currentPath);
                  localStorage.setItem('loginModal', 'Y');
                  RedirectHandler('Google');
                }}
              />
            </S.LogoBox>
            <S.EmailButton>
              <S.SignupText
                onClick={() => {
                  navigate('/sign/email?route=signupModal');
                }}
              >
                이메일 회원가입
              </S.SignupText>
              <S.Divider></S.Divider>
              <S.SignupText
                onClick={() => {
                  setShowSignUpModal(false);
                  setLoginModalOn(true);
                }}
              >
                이메일 로그인
              </S.SignupText>
            </S.EmailButton>
          </S.ButtonBox>
        </S.ContentBox>
      </S.Inner>
    </S.Container>
  );
};
export default SignUpModal;
