import { useState } from 'react';
import { Tooltip2 } from '../../components/Tooltip';

const LoanState = ({
  reportData,
  updateReportData,
}: {
  reportData: any;
  updateReportData: any;
}) => {
  const [tooltip2, setTooltip2] = useState(false);
  const [loanType, setLoanType] = useState('');
  const dummy = [
    { name: '원리금균등', type: 'A' },
    { name: '원금균등', type: 'B' },
    { name: '만기일시', type: 'C' },
  ];
  return (
    <div
      className="flex-column padding-20-20 border-radius-8 bg-white gap-20"
      style={{ width: '100%' }}
    >
      <div className="flex-column padding-b-20 border-b-171E26">
        <div className="font-bold f-size-18 line-h-24 color-text-primary">
          4. 농업자금 대출 현황
        </div>
      </div>
      {reportData.loanInfo.map((el: any, i: number) => (
        <div className="flex-column gap-16 border-b-171E26  padding-b-20">
          <div className="flex-row space-between">
            <div className="font-bold f-size-16 line-h-24 color-text-primary">
              대출 {i + 1}
            </div>
            <img
              className="hover"
              src={`/asset/images/close/close-darkgray.svg`}
              alt=""
              onClick={() => {
                reportData.loanInfo.splice(i, 1);
                updateReportData({
                  loanInfo: [...reportData.loanInfo],
                });
              }}
            />
          </div>
          <div className="flex-row space-between center-y w-100per gap-24">
            <div
              className="font-bold f-size-14 line-h-20 color-text-secondary"
              style={{ width: '20%' }}
            >
              대출명
            </div>
            <input
              placeholder="대출 이름을 입력해주세요"
              className="padding-6-14 border-radius-8 border-171E26 font-medium f-size-14 line-h-24 text-end"
              style={{ width: '80%' }}
              value={el.loanName}
              // onChange={(e: any) => {
              //   reportData.loanInfo[i]['loanName'] = e.target.value;
              //   updateReportData({
              //     loanInfo: [...reportData.loanInfo],
              //   });
              // }}
            />
          </div>
          <div className="flex-row space-between center-y">
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              대출실행일
            </div>
            <input
              className="font-medium f-size-14 line-h-24 color-text-secondary padding-6-14 border-radius-8 border-171E26 "
              type="month"
              value={el.loanDate}
              onChange={(e: any) => {
                // reportData.loanInfo[i]['loanDate'] = e.target.value;
                reportData.loanInfo[i]['startYear'] = e.target.value.split('-')[0];
                reportData.loanInfo[i]['startMonth'] = e.target.value.split('-')[1];
                updateReportData({
                  loanInfo: [...reportData.loanInfo],
                });
              }}
            />
          </div>
          <div className="flex-column gap-20">
            <div className="flex-row center-y w-100per gap-20">
              <div className="w-50per font-bold f-size-14 line-h-20 font-text-secondary">
                대출금액
              </div>
              <div className="font-bold f-size-14 line-h-20 font-text-secondary">
                연이자율
              </div>
            </div>
            <div className="flex-row gap-20 ">
              <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                <input
                  className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                  placeholder="입력해주세요"
                  value={Number(el.loanCost).toLocaleString()}
                  onChange={(e: any) => {
                    reportData.loanInfo[i]['loanCost'] = Number(
                      e.target.value.split(',').join(''),
                    );
                    updateReportData({
                      loanInfo: [...reportData.loanInfo],
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-20 font-text-primary">
                  원
                </div>
              </div>
              <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                <input
                  className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                  placeholder="입력해주세요"
                  type="number"
                  value={el.interestRate * 100}
                  onChange={(e: any) => {
                    reportData.loanInfo[i]['interestRate'] = e.target.value / 100;
                    updateReportData({
                      loanInfo: [...reportData.loanInfo],
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-20 font-text-primary">
                  %
                </div>
              </div>
            </div>
            <div className="flex-row center-y w-100per gap-20">
              <div className="w-50per font-bold f-size-14 line-h-20 font-text-secondary">
                대출기간
              </div>
              <div className="font-bold f-size-14 line-h-20 font-text-secondary">
                거치기간
              </div>
            </div>
            <div className="flex-row gap-20 ">
              <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                <input
                  className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                  placeholder="입력해주세요"
                  type="number"
                  value={el.redemptionPeriod}
                  onChange={(e: any) => {
                    reportData.loanInfo[i]['loanYear'] = e.target.value;
                    updateReportData({
                      loanInfo: [...reportData.loanInfo],
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-20 font-text-primary">
                  년
                </div>
              </div>
              <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                <input
                  className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                  placeholder="입력해주세요"
                  type="number"
                  value={el.retentionPeriod}
                  onChange={(e: any) => {
                    reportData.loanInfo[i]['defermentYear'] = e.target.value;
                    updateReportData({
                      loanInfo: [...reportData.loanInfo],
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-20 font-text-primary">
                  년
                </div>
              </div>
            </div>
            <div className="flex-column gap-8">
              <div className="flex-row center-y relative">
                <div className="font-bold f-size-14 line-h-20 color-text-secondary relative">
                  상환방식
                </div>
                <img
                  className="hover"
                  src="/asset/images/warn/Warn20.svg"
                  alt=""
                  onClick={() => {
                    setTooltip2(!tooltip2);
                  }}
                />
                <div style={{ position: 'absolute', zIndex: '11' }}>
                  <Tooltip2
                    on={tooltip2}
                    left="-2"
                    top="-113"
                    width
                    off={() => {
                      setTooltip2(false);
                    }}
                    text={
                      <div className="text-left">
                        <ul
                          className="line-h-14 mr-l-10"
                          style={{
                            listStyle: 'disc',
                            paddingLeft: '16px',
                          }}
                        >
                          <li>
                            만기일시상환 : 약정기간동안 대출원금에 대한 상환없이
                            이자만 부담
                          </li>
                          <br />
                          <li>
                            원리금균등상환 : 대출 원금과 이자를 합친 금액 즉
                            원리금을 만기일까지 균등하게 상환
                          </li>
                          <br />
                          <li>
                            원금균등상환 : 대출한 돈을 일정 기간마다 대출 원금에
                            대해서는 매월 동일한 금액으로 상환하고 남은 대출
                            원금에 대한 이자를 상환. 즉 매달 갚아나가는 원금이
                            일정하고 이자가 변하는 상환방식
                          </li>
                        </ul>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="flex-row w-100per gap-12">
                {dummy.map((item) => {
                  return (
                    <div
                      className={`padding-6-8 border-radius-8 w-33per flex-center hover ${
                        item.type === loanType
                          ? 'bg-primary-600'
                          : 'bg-light-gray-200'
                      }`}
                      onClick={() => {
                        setLoanType(item.type);
                        reportData.loanInfo[i]['loanType'] = item.type;
                        updateReportData({
                          loanInfo: [...reportData.loanInfo],
                        });
                      }}
                    >
                      <div
                        className={`font-bold f-size-12 line-h-24 ${
                          item.type === loanType
                            ? 'color-white'
                            : 'color-disabled'
                        }`}
                      >
                        {item.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div
        className="flex-center padding-10-16 bg-blue-gray-100 border-radius-8  hover"
        onClick={() =>
          updateReportData({
            loanInfo: [
              ...reportData.loanInfo,
              {
                loanType: '',
                loanCost: '',
                interestRate: '0.02',
                startYear: '',
                startMonth: '',
                defermentYear: '5',
                loanYear: '15',
              },
            ],
          })
        }
      >
        <div
          className="font-bold f-size-14 line-h-20 color-text-secondary"
          style={{ textAlign: 'center' }}
        >
          + 대출 내역 추가
        </div>
      </div>
    </div>
  );
};

export default LoanState;
