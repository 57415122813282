export const Contents = ({ myScroll }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '64px',
      }}
    >
      <div
        className={`${
          myScroll >
            (window.innerWidth >= 1920
              ? 1251
              : window.innerWidth >= 1024
                ? 966
                : 1079) && `fade-in-up`
        }`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        {myScroll >
        (window.innerWidth >= 1920
          ? 1251
          : window.innerWidth >= 1024
            ? 966
            : 1191) ? (
          <img
            src="/asset/images/profile/contents1.svg"
            style={{
              display: 'flex',
              borderRadius: '8px',
              width:
                window.innerWidth >= 1920
                  ? '424px'
                  : window.innerWidth >= 1024
                    ? '376px'
                    : '268px',
              height:
                window.innerWidth >= 1920
                  ? '460px'
                  : window.innerWidth >= 1024
                    ? '392px'
                    : `279px`,
              boxShadow:
                window.innerWidth >= 1024
                  ? '-10px 10px 10px 0px #1418131A'
                  : '-10px 10px 10px 0px #1418131A',
            }}
            alt=""
          />
        ) : (
          <div style={{ height: '300px' }} />
        )}
        {myScroll >
        (window.innerWidth >= 1920
          ? 1251
          : window.innerWidth >= 1024
            ? 966
            : 1191) ? (
          <div
            className={` ${
              myScroll > (window.innerWidth >= 1024 ? 966 : 1191) &&
              `fade-in-up`
            }`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              gap: '16px',
              padding: '24px 32px',
            }}
          >
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: '18px',
                color: '#171e26c7',
              }}
            >
              농지 가치는 얼마일까요?
              <br />
            </div>
            <div
              style={{
                fontFamily: 'Pretendard-Medium',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#171e268f',
              }}
            >
              주소만 입력하면 농지 가치를 분석해줍니다. <br />
              트랜스파머의 AI 추정가는 실거래 가격과 최대 95%의 정확도를
              유지하고 있습니다."
            </div>
          </div>
        ) : (
          <div style={{ height: '100px' }} />
        )}
      </div>

      {myScroll > (window.innerWidth >= 1024 ? 1581 : 1591) ? (
        <div
          className={`flex-column gap-16 w-100per flex-center center-y browser-flex-row browser-space-between browser-1920-w-1200 browser-h-460 ${
            myScroll > (window.innerWidth >= 1024 ? 1581 : 1591) && `fade-in-up`
          }`}
        >
          <img
            src="/asset/images/profile/contents2.svg"
            className="flex  border-radius-8 w-300"
            style={{
              width: window.innerWidth >= 1024 ? '376px' : '300px',
              height: window.innerWidth >= 1024 ? '226px' : '180px',
              boxShadow:
                window.innerWidth >= 1024
                  ? '-10px 10px 10px 0px #1418131A'
                  : '-10px 10px 10px 0px #1418131A',
            }}
            alt=""
          />
          {myScroll > (window.innerWidth >= 1024 ? 1581 : 1591) ? (
            <div
              className={` ${
                myScroll > (window.innerWidth >= 1024 ? 1581 : 1591) &&
                `fade-in-up`
              }`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                gap: '16px',
                padding: '24px 32px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '18px',
                  color: '#171e26c7',
                }}
              >
                적합 작물 추천에서
                <br /> 영농 진단까지
              </div>
              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#171e268f',
                }}
              >
                선택한 농지에 적합한 작물을 추천하고, 예상 수익률까지
                분석합니다. 토질·기후 데이터로 학습된 AI를 접목했기에 가능한
                서비스입니다.
              </div>
            </div>
          ) : (
            <div style={{ height: '100px' }} />
          )}
        </div>
      ) : (
        <div style={{ height: '500px' }}>&nbsp;</div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        {myScroll >
        (window.innerWidth >= 1920
          ? 2200
          : window.innerWidth >= 1024
            ? 2049
            : 2017) ? (
          <img
            src="/asset/images/profile/contents3.svg"
            className={` ${
              myScroll > (window.innerWidth >= 1024 ? 2049 : 2017) &&
              `fade-in-up`
            } `}
            style={{
              width: window.innerWidth >= 1024 ? '376px' : '300px',
              height: window.innerWidth >= 1024 ? '226px' : '180px',
              boxShadow:
                window.innerWidth >= 1024
                  ? '-10px 10px 10px 0px #1418131A'
                  : '-10px 10px 10px 0px #1418131A',
            }}
            alt=""
          />
        ) : (
          <div style={{ height: '300px' }} />
        )}
        {myScroll >
        (window.innerWidth >= 1920
          ? 2200
          : window.innerWidth >= 1024
            ? 2049
            : 2017) ? (
          <div style={{ width: '100%' }}>
            <div
              className={` ${
                myScroll >
                  (window.innerWidth >= 1920
                    ? 2200
                    : window.innerWidth >= 1024
                      ? 2049
                      : 2017) && `fade-in-up`
              } `}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                gap: '16px',
                padding: '24px 32px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '18px',
                  color: '#171e26c7',
                }}
              >
                주거환경까지 고민합니다
                <br />
              </div>
              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#171e268f',
                }}
              >
                원하는 전원주택 신축 및 리모델링 설계 유형을 선택하면, 예상
                건축비를 확인할 수 있습니다. <br /> 참고하여 예산을 가늠해
                보세요.
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: '100px' }} />
        )}
      </div>
      <div
        className={`flex-column gap-16 w-100per center-y browser-flex-row browser-space-between browser-h-460 browser-1920-w-1200`}
      >
        {myScroll >
        (window.innerWidth >= 1920
          ? 2700
          : window.innerWidth >= 1024
            ? 2474
            : 2507) ? (
          <img
            src="/asset/images/profile/contents4.svg"
            className={` ${
              myScroll >
                (window.innerWidth >= 1920
                  ? 2700
                  : window.innerWidth >= 1024
                    ? 2474
                    : 2507) && `fade-in-up`
            } `}
            style={{
              width: window.innerWidth >= 1024 ? '360px' : '300px',
              height: window.innerWidth >= 1024 ? '317px' : '263px',
              boxShadow:
                window.innerWidth >= 1024
                  ? '-10px 10px 10px 0px #1418131A'
                  : '-10px 10px 10px 0px #1418131A',
            }}
            alt=""
          />
        ) : (
          <div style={{ height: '300px' }} />
        )}
        {myScroll >
        (window.innerWidth >= 1920
          ? 2700
          : window.innerWidth >= 1024
            ? 2474
            : 2507) ? (
          <div
            className={` ${
              myScroll >
                (window.innerWidth >= 1920
                  ? 2700
                  : window.innerWidth >= 1024
                    ? 2474
                    : 2507) && `fade-in-up`
            } `}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              gap: '16px',
              padding: '24px 32px',
            }}
          >
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: '18px',
                color: '#171e26c7',
              }}
            >
              농장 비용 파악하고
              <br /> 맞춤형 지원책도 한눈에
            </div>
            <div
              style={{
                fontFamily: 'Pretendard-Medium',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#171e268f',
              }}
            >
              농가 대출 가능성부터 숨은 지원 정책까지 맞춤형으로 알 수 있습니다.
              정착 소요시간부터 생활 비용까지 최소화하세요.
            </div>
          </div>
        ) : (
          <div style={{ height: '100px' }} />
        )}
      </div>
      <div
        className={`flex-column gap-16 w-100per center-y browser-row-reverse browser-space-between browser-h-460 browser-1920-w-1200`}
      >
        {myScroll >
        (window.innerWidth >= 1920
          ? 3100
          : window.innerWidth >= 1024
            ? 2935
            : 3026) ? (
          <img
            src="/asset/images/profile/contents5.svg"
            className={` ${
              myScroll >
                (window.innerWidth >= 1920
                  ? 3100
                  : window.innerWidth >= 1024
                    ? 2935
                    : 3026) && `fade-in-up`
            } `}
            style={{
              width: '300px',
              boxShadow: '-10px 10px 23px 0px #1418131A',
            }}
            alt=""
          />
        ) : (
          <div style={{ height: '300px' }} />
        )}
        {myScroll >
        (window.innerWidth >= 1920
          ? 3100
          : window.innerWidth >= 1024
            ? 2935
            : 3026) ? (
          <div
            className={`${
              myScroll >
                (window.innerWidth >= 1920
                  ? 3100
                  : window.innerWidth >= 1024
                    ? 2935
                    : 3026) && `fade-in-up`
            } `}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              gap: '16px',
              padding: '24px 32px',
            }}
          >
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: '18px',
                color: '#171e26c7',
              }}
            >
              노후 준비는
              <br /> 농지연금 분석 서비스로
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary browser-f-size-20 browser-line-h-33 browser-w-380">
              농지연금은 합산 영농경력 5년 이상인 60세 이상 농업인을 대상으로
              농지 담보 연금을 지급해 주는 제도입니다. 트랜스파머를 통해
              농지연금 수령 가능성과 투자수익률을 진단받을 수 있습니다.
            </div>
          </div>
        ) : (
          <div style={{ height: '100px' }} />
        )}
      </div>
    </div>
  );
};
