import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const LoginModal = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <LoginBox>
        최근 실거래가 변동율이 궁금하다면?
        <LoginButton
          onClick={() => {
            navigate('/login');
          }}
        >
          로그인
        </LoginButton>
      </LoginBox>
    </Container>
  );
};
export default LoginModal;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1); /* 살짝 투명하게 */
  backdrop-filter: blur(10px); /* 블러 효과 */
  z-index: 100;
`;

const LoginBox = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  width: calc(100% - 10%);
  transform: translate(-50%, -50%);
  padding: 24px 16px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #616161;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  gap: 16px;
`;

const LoginButton = styled.div`
  width: 100%;
  padding: 8px 16px;
  background: rgba(30, 155, 107, 1);
  color: white;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
