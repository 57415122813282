export const History = ({ scroll }: any) => (
  <div
    className={`${
      scroll >
        (window.innerWidth >= 1920
          ? 4420
          : window.innerWidth >= 1024
            ? 4047
            : 4172) && `fade-in-up`
    }`}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '56px 40px',
      gap: '40px',
      backgroundColor: '#f7f8f9',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '48px',
        }}
      >
        <div
          style={{
            fontFamily: 'Pretendard-Bold',
            fontSize: '18px',
            lineHeight: '26px',
            color: '#171E26',
          }}
        >
          History
        </div>
        <div
          style={{
            width: '100%',
            fontFamily: 'Pretendard-Bold',
            fontSize: '32px',
            lineHeight: '46px',
          }}
        >
          <img
            style={{ display: 'flex' }}
            src="/asset/images/profile/bottom_img.svg"
            alt=""
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171E26',
                }}
              >
                2024
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: 'rgba(23, 30, 38, 0.78)',
                  }}
                >
                  04
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    소셜벤처기업 인증
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    창업진흥원 '초기창업패키지' 사업 선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    한국농업기술진흥원 '농식품 벤처육성지원사업' 선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    정보통신산업진흥원 '클라우드 기반 SW 개발환경' 수요기업 선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    한국사회투자 민간 우수기술 사업화 지원 사업 'Agri-Future'
                    선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    한국농업기술진흥원 농식품 엑셀러레이팅 'Fast Track' 참여기업
                    선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    신용보증기금 '스타트업 네스트' 제 15기 선정
                  </div>
                </div>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                  }}
                >
                  03
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    벤처기업 인증
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                  }}
                >
                  02
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    소풍벤처스 Seed 투자 유치
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    고용노동부 일경험 프로그램 참여기업 선정
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                  }}
                >
                  01
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    AI 바우처 공급기업 선정
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    NH농협 디지털혁신캠퍼스 입주
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    비대면 서비스 바우처 수요기업 선정
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171e26c7',
                }}
              >
                2023
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              ></div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171e26c7',
                }}
              >
                12
              </div>
              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: 'rgba(23, 30, 38, 0.78)',
                }}
              >
                NH농협은행 오픈이노베이션 후속연계기업 선정
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  11
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    서울창조경제혁신센터 보육기업 협약
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    대한상공회의소 유연근무제 인프라 구축비 지원사업 선정 (AWS
                    클라우드)
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    데이터사업자 신고
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    세종대학교 스마트팜학과 MOU (수업 교보재 계약)
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Pretendard-Medium',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#171e26c7',
                      }}
                    >
                      이데일리 “AI로 농촌 소득 올리면 귀농인도 늘어날 겁니다"
                    </div>
                    <img
                      style={{ cursor: 'pointer' }}
                      src="/asset/images/arrow/ArrowRightBlack.svg"
                      onClick={() => {
                        window.open(
                          'https://www.edaily.co.kr/news/read?newsId=03463686635778496&mediaCodeNo=257',
                        );
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    TransFarmer 플랫폼 런칭(v2.0)
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    국제 Smart 농업 엑스포 발제
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  09
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    서울시 캠퍼스 타운 인재양성 사업 인턴십 참여 기업 선정
                    (중앙대학교)
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    한국지능정보사회진흥원 초거대AI 활용 지원사업 선정 (네이버
                    농업 챗봇 PoC)
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    특허/상표권 5건 출원
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    한국부동산원 빈집추정시스템 특허 무상사용 실시권 MOU
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#171e26c7',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  08
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    KOITA 기업부설연구소 설립
                  </div>
                  <div
                    style={{
                      fontFamily: 'Pretendard-Medium',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#171e26c7',
                    }}
                  >
                    정책평가연구원 정책플랫폼 용역 수주
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171e26c7',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                05
              </div>

              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#171e26c7',
                }}
              >
                전남 나주시 TransFarmer 시범사업 진행
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171e26c7',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                04
              </div>

              <div
                style={{
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#171e26c7',
                }}
              >
                한국지능정보사회진흥원 2023 빅데이터 플랫폼 기반 분석서비스
                지원사업 선정
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard-Bold',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#171e26c7',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                03
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Pretendard-Medium',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#171e26c7',
                  }}
                >
                  HCOMBINED Seed 투자유치 (민간기업)
                </div>
                <div
                  style={{
                    fontFamily: 'Pretendard-Medium',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#171e26c7',
                  }}
                >
                  법인 설립
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
