import React, { createContext, useContext, useState } from 'react';

interface FilterContextType {
  menu: string;
  setMenu: React.Dispatch<React.SetStateAction<string>>;
  filterOption: {
    target: string;
    sale_type: string[];
    category: string[];
    purpose: string[];
    price: [string, string];
    area: [string, string];
    year: [string, string];
    theme: string[];
  };
  setFilterOption: React.Dispatch<
    React.SetStateAction<typeof initialFilterOption>
  >;
  saleType: string[];
  setSaleType: React.Dispatch<React.SetStateAction<string[]>>;
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  safeProperty: boolean;
  setSafeProperty: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialFilterOption = {
  target: '전체',
  sale_type: [] as string[],
  category: [] as string[],
  purpose: [] as string[],
  price: ['', ''] as [string, string],
  area: ['', ''] as [string, string],
  year: ['', ''] as [string, string],
  theme: [] as string[],
};

// Context 생성
const FilterContext = createContext<FilterContextType | null>(null);

// Context Provider 컴포넌트
export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [menu, setMenu] = useState('지도');
  const [filterOption, setFilterOption] = useState(initialFilterOption);
  const [saleType, setSaleType] = useState<string[]>([]);
  const [sort, setSort] = useState('PA');
  const [safeProperty, setSafeProperty] = useState(false);
  return (
    <FilterContext.Provider
      value={{
        menu,
        setMenu,
        filterOption,
        setFilterOption,
        saleType,
        setSaleType,
        sort,
        setSort,
        safeProperty,
        setSafeProperty,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Context를 사용하기 위한 커스텀 훅
export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};
