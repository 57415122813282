import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class HouseService {
  static view = async (designIdx: string, address: string) => {
    const result = await request.get(
      'house/design?designIdx=' + designIdx + '&address=' + address,
    );
    return result.data;
  };

  static list = async (limit: number, offset: number, order: string) => {
    const result = await request.get(
      'house/design/list?limit=' +
        limit +
        '&offset=' +
        offset +
        '&order=' +
        order,
    );
    return result.data;
  };

  static download = async (link: string) => {
    const result = await request.get('download/' + link);
    return result;
  };

  static report = async (pyeong: any, pnuCode: string, c_idx: string) => {
    const data = {
      pyeong,
      pnuCode,
      c_idx,
    };
    const result = await request.post('house/report', data);
    return result.data;
  };

  static costCal = async (data: {
    pyeong: any;
    houseInfo: any;
    designIdx: any;
    loanOfConstructionCost: any;
    loanOfHouseCost: any;
    ownHouseYN: any;
  }) => {
    const result = await request.post('house/report/cost', data);
    return result.data;
  };

  static origin = async () => {
    const result = await request.get('/house/origin');
    return result.data;
  };
}
