import styled from 'styled-components';
import colors from '../../color';

interface TabButtonProps {
  active: boolean;
}

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
`;

const TopSearchBox = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const LandMarketTabBox = styled.div`
  width: 218px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
`;
const TabButton = styled.span<TabButtonProps>`
  flex: 1;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${({ active }) => (active ? '#fff' : colors.labelAlternative)};
  background-color: ${({ active }) =>
    active ? colors.primary500 : 'transparent'};
  border-radius: 8px;
  border: ${({ active }) => (active ? '1px solid #E1E2E4' : 'none')};
  cursor: pointer;
`;

const TopButton = styled.div`
  position: sticky;
  bottom: 70px;
  right: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  margin-right: 16px;
  pointer-events: none; /* 아래 div 클릭 가능 */

  & > * {
    pointer-events: auto; /* 자식 요소는 여전히 클릭 가능 */
  }
`;

const CotentBox = styled.div`
  max-width: 720px;
  background-color: #fff;
`;
const MainSection = styled.div`
  width: 100%;
  height: 704px;
  background-image: url('/asset/images/landMarket/product-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 72px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;
const ImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BuildingImg = styled.img`
  position: absolute;
  top: 323px;
  right: 0;
`;
const ContentBox = styled.div`
  width: 100%;
  padding: 42px 16px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
`;
const Card = styled.div`
  width: 328px;
  height: 486px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const CardProfile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222222;
`;
const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;
const Tag = styled.span`
  padding: 8px 12px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #04b452;
  color: white;
  font-family: Pretendard-Bold;
  font-size: 16px;
`;
const ExampleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #04b452;
  margin-bottom: 8px;
`;
const ItemTagBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const ItemTag = styled.span`
  padding: 4px 8px;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 4px;
  color: #616161;
  font-family: Pretendard-Medium;
  font-size: 13px;
`;
const InstructionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const Instruction = styled.div`
  width: 100%;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #04b452;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Pretendard-Medium;
  font-size: 14px;
`;
const RecommendButton = styled.a`
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #04b452;
  border-radius: 999px;
  color: white;
  box-shadow: 0px 2px 8px 0px rgba(25, 136, 93, 0.16);
  cursor: pointer;
  text-decoration: none;
`;
const CheckButton = styled.a`
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(234, 248, 242, 1);
  border-radius: 999px;
  color: rgba(34, 34, 34, 1);
  box-shadow:
    0px 2px 8px 0px rgba(172, 198, 187, 0.16),
    0px 1px 4px 0px rgba(172, 198, 187, 0.12),
    0px 0px 1px 0px rgba(172, 198, 187, 0.12);
  cursor: pointer;
  text-decoration: none;
`;
const Extra = styled.div`
  width: 100%;
  height: auto;
  background-image: url('/asset/images/landMarket/extra-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const ExtraText = styled.div`
  width: 100%;
  font-family: Paperlogy-Medium;
  text-align: center;
  font-size: 16px;
  text-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.12),
    0px 1px 4px rgba(0, 0, 0, 0.08),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  padding: 60px 16px 116px;
`;

export const S = {
  Container,
  TopSearchBox,
  LandMarketTabBox,
  TabButton,
  TopButton,
  CotentBox,
  MainSection,
  ImgBox,
  BuildingImg,
  ContentBox,
  Card,
  CardProfile,
  ProfileText,
  Avatar,
  Tag,
  ExampleBox,
  TextBox,
  ItemTagBox,
  ItemTag,
  ButtonBox,
  InstructionBox,
  Instruction,
  RecommendButton,
  CheckButton,
  Extra,
  ExtraText,
};
