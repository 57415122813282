import styled from 'styled-components';
import { LandGradeProps, LegalDongNameProps } from '../../data';

interface HightLightProps {
  isHighlighted?: boolean;
}

const LandGrade = ({
  data,
  legalDongName,
}: {
  data: LandGradeProps;
  legalDongName: LegalDongNameProps;
}) => {
  // 데이터 변환 함수
  const transformData = (legalDongName: any, data: any) => {
    const result = [];

    for (const key in legalDongName) {
      const address = legalDongName[key];
      const landGrade = data[key].map((item: any) => ({
        [item.year]: item.grade,
      }));

      result.push({
        address,
        landGrade,
      });
    }

    return result;
  };

  // 변환된 데이터
  const transformedData = transformData(legalDongName, data);
  const landGrades = [
    '최소',
    '상위20%',
    '상위40%',
    '중위',
    '하위40%',
    '하위20%',
  ];
  return (
    <LandGradeContainer>
      <HeaderBox>트랜스파머의 토지 등급 분석</HeaderBox>
      {transformedData.map((item, index) => (
        <GradeBox key={index}>
          <div>{item.address}</div>
          <GradeContentBox>
            {/* 등급 헤더 */}
            <GradeRow>
              {landGrades.map((grade, i) => (
                <GradeContent key={i}>{grade}</GradeContent>
              ))}
            </GradeRow>
            {/* 연도별 등급 표시 */}
            {item.landGrade.map((gradeObj: any, i: any) => (
              <GradeRow key={i}>
                {/* 연도 표시 */}
                <GradeContent>{Object.keys(gradeObj)[0]}년</GradeContent>
                {/* 1~5등급만 표시하고, 해당 등급은 노란색으로 강조 */}
                {Array.from({ length: 5 }, (_, j) => (
                  <GradeContent
                    key={j}
                    isHighlighted={
                      j + 1 === Object.values(gradeObj)[0] ? true : false
                    } // 등급이 일치하면 노란색
                  >
                    {j + 1}등급
                  </GradeContent>
                ))}
              </GradeRow>
            ))}
          </GradeContentBox>
        </GradeBox>
      ))}
    </LandGradeContainer>
  );
};

export default LandGrade;

const GradeRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  justify-content: space-between;
`;

const GradeContentBox = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: column;
`;

const GradeContent = styled.div<HightLightProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 0px;
  border: 1px #e1e2e4 solid;

  // border-bottom: 1px solid #e1e2e4; /* 각 요소 사이에만 border */

  // &:not(:last-child) {
  //   border-right: 1px solid #e1e2e4; /* 마지막 요소 제외하고 border-right 추가 */
  // }

  background-color: ${({ isHighlighted }) =>
    isHighlighted ? '#222222' : null};
  color: ${({ isHighlighted }) => (isHighlighted ? 'white' : null)};
`;

const LandGradeContainer = styled.div`
  width: 100%;
  height: 603px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #171e26;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const GradeBox = styled.div`
  width: 100%;
  height: 153px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #616161;
  font-size: 15px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 22.5px;
  word-wrap: break-word;
`;

/*
 <GradeBox>
        <div>공덕면</div>
        <GradeContentBox>
          <GradeRow>
            <GradeContent>최소</GradeContent>
            <GradeContent>상위20%</GradeContent>
            <GradeContent>상위40%</GradeContent>
            <GradeContent>중위</GradeContent>
            <GradeContent>하위40%</GradeContent>
            <GradeContent>하위20%</GradeContent>
          </GradeRow>
          <GradeRow>
            <GradeContent>2023년</GradeContent>
            <GradeContent>1등급</GradeContent>
            <GradeContent>2등급</GradeContent>
            <GradeContent>3등급</GradeContent>
            <GradeContent>4등급</GradeContent>
            <GradeContent>5등급</GradeContent>
          </GradeRow>
          <GradeRow>
            <GradeContent>2023년</GradeContent>
            <GradeContent>1등급</GradeContent>
            <GradeContent>2등급</GradeContent>
            <GradeContent>3등급</GradeContent>
            <GradeContent>4등급</GradeContent>
            <GradeContent>5등급</GradeContent>
          </GradeRow>
        </GradeContentBox>
      </GradeBox>
      <div>김제시</div>
      <div>전북</div>
*/
