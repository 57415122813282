import styled from 'styled-components';
import { renderIcon } from '../../render';
import colors from '../../../../color';
import { useNavigate } from 'react-router-dom';
import { useMapContext } from '../../../../contexts/MapContext';

const PensionInfoModal = ({ data, setPensionModalOn }: any) => {
  const navigate = useNavigate();

  const { latLng } = useMapContext();

  return (
    <Container>
      <Inner>
        <InfoBox>
          <Address>
            <img
              src={`/asset/images/icon/${renderIcon(data.category)}.svg`}
              alt=""
              style={{ width: '14px', height: '14px' }}
            />
            <span>{data.legaldong}</span>
          </Address>
          <InfoTop>
            <InfoTopTextBox>
              <TypeText>{data.type}</TypeText>
              <CategoryText></CategoryText>
              <AreaText> {data.zoning}</AreaText>
              <Divider />
              <AreaText>{Number(data.pyeong).toLocaleString()}평 </AreaText>
            </InfoTopTextBox>
          </InfoTop>
        </InfoBox>
        <PriceBox>
          <PriceText>
            월 최대 <br />
            <TextBold>{(data.pension / 10000).toFixed(0)}</TextBold>만원
          </PriceText>
          <img
            src="/asset/images/arrow/arrow-right-green.svg"
            alt=""
            onClick={() => {
              setPensionModalOn(false);
              localStorage.setItem('lat', String(data.lat));
              localStorage.setItem('lng', String(data.lng));
              navigate(
                `/detail?c_idx=${data.c_idx}&lat=${data?.lat}&lng=${data?.lng}`,
              );
            }}
          />
        </PriceBox>
      </Inner>
    </Container>
  );
};

export default PensionInfoModal;

const Container = styled.div`
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 12px;
  cursor: pointer;
`;

const Inner = styled.div`
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InfoTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InfoTopTextBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const TypeText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
  padding: 2px 6px;
  border-radius: 4px;
  background-color: ${colors.backgroundNeutral};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
`;
const Divider = styled.div`
  width: 1px;
  height: 8px;
  background-color: ${colors.lineNormal};
`;
const AreaText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;
const Address = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelStrong};
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.div`
  text-align: left;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNormal};
  white-space: nowrap;
`;
const TextBold = styled.span`
  font-family: Pretendard-Bold;
  font-size: 18px;
`;
