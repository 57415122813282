import styled from 'styled-components';
import colors from '../../../color';
import { useNavigate } from 'react-router-dom';

interface ButtonBoxProps {
  shownTooltip: boolean;
}

const NoLoginMoreInfo = ({ shownTooltip, setShownTooltip }: any) => {
  const nav = useNavigate();
  // const [shownTooltip, setShownTooltip] = useState(true);

  const OnclickTooltipClose = () => {
    setShownTooltip(false);
  };

  return (
    <MoreInfoContainer shownTooltip={shownTooltip}>
      <MoreInfoTextBox>
        <MoreInfoTextTitle>
          토지찾기, 토지 활용 분석까지 한번에
        </MoreInfoTextTitle>
        <MoreInfoText>나만의 농촌생활을 설계하세요 </MoreInfoText>
      </MoreInfoTextBox>
      <ButtonBox shownTooltip={shownTooltip}>
        {shownTooltip ? (
          <TooltipContainer
            style={{ width: window.innerWidth > 550 ? '70%' : '328px' }}
          >
            <TooltipTextBox>
              {/* <img src="/asset/images/icon/frame.svg" alt="" /> */}
              <p>
                <span>지금 가입하고 관심 주소 평생 무료 토지 분석하세요</span>
              </p>
            </TooltipTextBox>
            <ToolTipImg
              onClick={OnclickTooltipClose}
              src="/asset/images/close/close-white.svg"
              alt=""
            />
          </TooltipContainer>
        ) : null}
        <div
          style={{
            width: '100%',
            height: '100%',
            transform: 'rotate(180deg)',
            transformOrigin: '0 0',
            background: '#4674EE',
          }}
        ></div>
        <SignButton
          onClick={() => {
            nav('/login');
          }}
        >
          로그인 / 회원가입 하기
        </SignButton>
      </ButtonBox>
    </MoreInfoContainer>
  );
};

export default NoLoginMoreInfo;

const MoreInfoContainer = styled.div<ButtonBoxProps>`
  width: 100%;
  height: ${({ shownTooltip }) => (shownTooltip ? '158px' : '114px')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  background-color: #ffffff;
`;

const MoreInfoTextBox = styled.div`
  align-self: stretch;
  height: 48px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

const MoreInfoTextTitle = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const MoreInfoText = styled.div`
  color: ${colors.labelAlternative};
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const ButtonBox = styled.div<ButtonBoxProps>`
  width: 100%;
  height: ${({ shownTooltip }) => (shownTooltip ? '100.7px' : '50px')};
  flex-direction: column;
  display: flex;
  position: relative;
`;

const SignButton = styled.button`
  width: 100%;
  height: 50px;
  padding: 12px 16px;
  background-color: #222222;
  border-radius: 8px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  justify-content: space-between;
  gap: 8px;
  background-color: #0073f0; /* Tooltip 배경색 */
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* 약한 그림자 효과 */

  &::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Tooltip의 아래쪽에 화살표 배치 */
    left: 50%; /* 중앙 배치 */
    transform: translateX(-50%); /* 정확히 중앙에 위치하게 함 */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0073f0; /* Tooltip의 배경색과 동일하게 설정 */
    height: 0; /* 높이를 0으로 설정하여 삼각형 모양 만들기 */
    width: 0; /* 너비를 0으로 설정하여 삼각형 모양 만들기 */
  }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
`;

const ToolTipImg = styled.img`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
`;
