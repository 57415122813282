/** pnu의 길이에 따라 줌레벨을 변하게 하는 함수 */
export const getZoomLevelByPNU = (pnu: string) => {
  if (pnu.length === 5) {
    //읍면동까지 선택하지 않았을 경우
    return 12;
  } else if (pnu.length === 8) {
    //읍면동까지 선택했을 경우
    return 13;
  } else {
    //상세주소의 경우
    return 18;
  }
};
