import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const MyItems = () => {
  const nav = useNavigate();
  return (
    <Container>
      <ContetBox>
        <img src="/asset/images/construction.svg" alt="" />
        <p>
          내 소유 토지/주택 등록 <br />
          준비 중입니다
        </p>
        <Button
          onClick={() => {
            nav('/brokerage');
          }}
        >
          사전 매물등록 신청하기
        </Button>
      </ContetBox>
    </Container>
  );
};

export default MyItems;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px 72px;
  background-color: #f5f5f5;
  flex-grow: 1;
`;

const ContetBox = styled.div`
  margin-top: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #222;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-family: Pretendard-Medium;
  font-size: 16px;
`;
