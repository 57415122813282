import { useNavigate } from 'react-router-dom';
import './PopularList.css';
import { useMapContext } from '../../../contexts/MapContext';
import SearchService from '../../../service/Search';
import { getZoomLevelByPNU } from './ZoomLevel';

interface PopularSearchList {
  address: string;
  code: string;
  rank: string;
  status: string;
}

interface PopularListProps {
  popularSearchList: PopularSearchList[];
}

const renderStatus = (status: string) => {
  if (status === 'U') {
    return <div className="PopularItem_State PopularItem_State_Up">▲</div>;
  } else if (status === 'D') {
    return <div className="PopularItem_State PopularItem_State_Down">▼</div>;
  } else if (status === 'X') {
    return <div className="PopularItem_State PopularItem_State_New">NEW</div>;
  } else {
    return <div className="PopularItem_State PopularItem_State_Stay">-</div>;
  }
};

const formatDate = () => {
  const date = new Date();

  // 년, 월, 일
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 1월이 0이기 때문에 +1
  const day = String(date.getDate()).padStart(2, '0');

  // 시간, 분
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // 최종 포맷
  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

const PopularList = ({ popularSearchList }: PopularListProps) => {
  const currentDate = formatDate();
  const {
    setLatLng,
    setZoomLevel,
    setGeomData,
    setIs_exist,
    setPolygonCenter,
  } = useMapContext();
  const nav = useNavigate();

  const getCoord = async (pnu: string) => {
    try {
      const res = await SearchService.coord(pnu);
      if (res.length > 0) {
        setLatLng({ lat: res[0].lat, lng: res[0].lng });
        setGeomData(res[0].geom);
        setPolygonCenter([res[0].lat, res[0].lng]);
        setZoomLevel(getZoomLevelByPNU(pnu));
        setIs_exist(res[0].is_exist);
        nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  return (
    <div className="PopularBox">
      <div className="PopularBox_top_bar">
        <div className="PopularBox_top_bar_title">인기 검색</div>
        <div className="PopularBox_top_bar_date">{`${currentDate} 업데이트`}</div>
      </div>
      <div className="PopularSearchListBox">
        <div className="PopularSearchList">
          {popularSearchList.slice(0, 5).map((item, index) => {
            return (
              <div
                className="PopularItem"
                key={index}
                onClick={() => {
                  getCoord(item.code);
                }}
              >
                <div className="PopularItem_Data">
                  <div className="PopularItem_Data_No">{item.rank}</div>{' '}
                  <div className="PopularItem_Data_Region">{item.address}</div>
                </div>
                {renderStatus(item.status)}
              </div>
            );
          })}
        </div>
        <div className="PopularSearchList">
          {popularSearchList.slice(5, 10).map((item, index) => {
            return (
              <div
                className="PopularItem"
                key={index}
                onClick={() => {
                  getCoord(item.code);
                }}
              >
                <div className="PopularItem_Data">
                  <div className="PopularItem_Data_No">{item.rank}</div>{' '}
                  <div className="PopularItem_Data_Region">{item.address}</div>
                </div>
                {renderStatus(item.status)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularList;
