import styled from 'styled-components';

const ScrollToTopButton = () => {
  // 페이지 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    const wrapElement = document.getElementsByClassName('wrap')[0]; // React root 요소 확인
    wrapElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ToTopButtonBox>
      <ToTopButton onClick={scrollToTop}>
        <img
          src="/asset/images/arrow/toTop.png"
          alt=""
          style={{ width: '48px' }}
        />
      </ToTopButton>
    </ToTopButtonBox>
  );
};

export default ScrollToTopButton;

const ToTopButtonBox = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 80px;
  curtain: pointer;
  z-index: 1000;
  max-width: inherit;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
`;
const ToTopButton = styled.div`
  cursor: pointer;
`;
