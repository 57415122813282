import styled from 'styled-components';

const EmptyMessage = ({ message }: any) => {
  return (
    <EmptyMessageBox>
      <img src="/asset/images/check/Exclamation-filled.svg" alt="" />
      <div>{message}</div>
    </EmptyMessageBox>
  );
};

export default EmptyMessage;

const EmptyMessageBox = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 112px 0;
  img {
    width: 48px;
    height: 48px;
  }
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
  text-align: center;
`;
