import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../color';
import { logout } from '../../utilities/auth';

const AccountSetting = () => {
  const navigate = useNavigate();

  const loginType = localStorage.getItem('loginType');
  return (
    <Container>
      <PageTopContainer>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: 'pointer' }}
        />
        <PageTopTitle>계정관리</PageTopTitle>
      </PageTopContainer>
      <ContentBox>
        <ContentItem
          onClick={() => {
            logout();
            navigate('/');
          }}
        >
          <span>로그아웃</span>
          <img
            src="/asset/images/arrow/arrow-right-gray-labelNeutral.svg"
            alt=""
          />
        </ContentItem>
        {loginType === 'C' && (
          <ContentItem
            onClick={() => {
              navigate('/find?tab=pw&type=C');
            }}
          >
            <span>비밀번호 재설정</span>
            <img
              src="/asset/images/arrow/arrow-right-gray-labelNeutral.svg"
              alt=""
            />
          </ContentItem>
        )}
        <ContentItem
          onClick={() => {
            navigate('/delete');
          }}
        >
          <span>회원탈퇴</span>
          <img
            src="/asset/images/arrow/arrow-right-gray-labelNeutral.svg"
            alt=""
          />
        </ContentItem>
      </ContentBox>
    </Container>
  );
};

export default AccountSetting;

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f5f5f5;
`;

const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  background-color: #ffffff;
`;

const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

const ContentItem = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNormal};
  cursor: pointer;
`;
