import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../color';

const NoMember = ({ tab }: { tab: string }) => {
  const renderText = () => {
    if (tab === 'pw') {
      return (
        <TextBox>
          <span>
            입력하신 정보와 일치하는 회원정보가 없습니다 <br />
            확인 후 다시 시도해 주세요
          </span>
          <Text>이메일, 이름, 휴대폰번호를 확인해 보세요</Text>
        </TextBox>
      );
    } else {
      return (
        <TextBox>
          <span>
            입력하신 정보와 일치하는 아이디가 없습니다 <br />
            확인 후 다시 시도해 주세요
          </span>
          <Text>이름, 휴대폰번호를 확인해 보세요</Text>
        </TextBox>
      );
    }
  };
  return (
    <Container>
      <img
        src="/asset/images/icon/bang-mark.svg"
        alt=""
        style={{ width: '48px', height: '48px' }}
      />
      {renderText()}
      <SignUpText>
        <span>아직 트랜스파머 회원이 아니신가요?</span>
        <Link
          to="/SignUp"
          style={{ fontFamily: 'Pretendard-SemiBold', color: '#616161' }}
        >
          회원가입 하기
        </Link>
      </SignUpText>
    </Container>
  );
};
export default NoMember;

const Container = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 150%;
  color: #222;
  text-align: center;
`;

const Text = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
  line-height: 20px;
`;
const SignUpText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
  position: absolute;
  bottom: 94px;
  left: 0;
`;
