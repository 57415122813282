import styled from 'styled-components';
import colors from '../../../../color';
import { useNavigate } from 'react-router-dom';
import { useMapContext } from '../../../../contexts/MapContext';

const MainSearchBox = () => {
  const navigate = useNavigate();
  const { setGeomData } = useMapContext();

  return (
    <TopSearchWrapper>
      <TopSearchBox>
        <img
          src="/asset/images/logo/logo_normal.svg"
          alt="logo"
          onClick={() => {
            navigate(0);
          }}
          style={{ cursor: 'pointer' }}
        />
        <InputBox onClick={() => {}}>
          <SearchInput
            onClick={() => {
              navigate('/search');
              setGeomData(null);
            }}
          >
            주소 검색하고 매매가 무료 분석
          </SearchInput>
          <img
            src="/asset/images/icon/search-gray.svg"
            alt="search icon"
            style={{ cursor: 'pointer' }}
          />
        </InputBox>
      </TopSearchBox>
    </TopSearchWrapper>
  );
};

export default MainSearchBox;

const TopSearchWrapper = styled.div`
  width: 100%;
`;

const TopSearchBox = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const InputBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${colors.lightGray100};
  border-radius: 9999px;
`;

const SearchInput = styled.div`
  flex: 1;
  font-family: 'Pretendard-Medium';
  font-size: 13.5px;
  color: ${colors.labelAssistive};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 120px;
  cursor: pointer;
`;
