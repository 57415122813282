import styled from 'styled-components';
import {
  XAxis,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { AnalysisProps } from '../../data';
import {
  formatManWons,
  formatToKoreanCurrency,
} from '../../../../function/function';

interface DummyData {
  name: string;
  price: number;
  gapOfPrice?: number; // 중위값과의 차이
}

interface AnalysisData {
  data: AnalysisProps;
  pyeong: number;
  selectedType: string;
}

const NonPropertyChart = ({ data, pyeong, selectedType }: AnalysisData) => {
  const analysisData = selectedType === 'building' ? data?.house : data?.land;

  const chartDataSet: DummyData[] = [
    { name: '하위 평당 추정가', price: analysisData.low_avg, gapOfPrice: 0 },
    { name: '기준 평당 추정가', price: analysisData.avg, gapOfPrice: 0 },
    { name: '상위 평당 추정가', price: analysisData.high_avg, gapOfPrice: 0 },
  ];

  const medianValue = analysisData.avg;

  return (
    <>
      <TAnalyzeChartBox>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={328}
            height={176}
            data={chartDataSet}
            margin={{ top: 25, right: 20 }}
          >
            <XAxis
              axisLine={false} // X축 선을 제거
              tickLine={false} // X축 tick을 제거
              dataKey="name"
              interval={0}
              tick={({ x, y, payload }) => {
                const isHighlighted = payload.value === '해당 매물';
                const padding = isHighlighted ? 0 : -4;

                // 긴 텍스트에 대해 줄바꿈 처리를 위한 분리 로직
                const lines: string[] = payload.value.includes('추정가')
                  ? [payload.value.slice(0, -3), '추정가']
                  : [payload.value];

                return (
                  <g transform={`translate(${x + padding},${y})`}>
                    <text
                      dy={16}
                      fill={
                        isHighlighted ? '#222222' : 'rgba(55, 56, 60, 0.61)'
                      }
                      fontSize="11px"
                      fontFamily="Pretendard-Medium"
                      fontWeight="500"
                      textAnchor="middle"
                      // style={{
                      //   whiteSpace: 'nowrap',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      // }}
                    >
                      {/* {payload.value} */}
                      {lines.map((line, index) => (
                        <tspan
                          key={index}
                          x={lines.length > 1 ? 4 : 0}
                          dy={index === 0 ? 8 : 11}
                        >
                          {line}
                        </tspan>
                      ))}
                    </text>
                  </g>
                );
              }}
            />
            <Bar dataKey="price" barSize={40} stackId="a" radius={4}>
              {chartDataSet.map((entry, index) => {
                // 색상 결정
                if (entry.name === '기준 평당 추정가') {
                  return <Cell key={`cell-${index}`} fill="#484F55" />;
                } else {
                  return <Cell key={`cell-${index}`} fill="#D2D8DF" />;
                }
              })}
            </Bar>
            <Bar
              dataKey="gapOfPrice"
              barSize={40}
              stackId="a"
              radius={[4, 4, 0, 0]}
              fill="#25805E"
            >
              <LabelList
                dataKey="price"
                position="top"
                content={({ x, y, value, index }) => {
                  if (index !== undefined) {
                    const gapOfPrice =
                      chartDataSet.find((item) => item.name === '해당 매물')
                        ?.gapOfPrice || 0;
                    const total = Number(value) + gapOfPrice;
                    return (
                      <text
                        x={Number(x) + 20}
                        y={Number(y) - 8}
                        fill="#000"
                        textAnchor="middle"
                        fontSize="14"
                        fontFamily="Pretendard-Bold"
                      >
                        {formatManWons(Number(total))}
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>

            {/* 중간값을 표시하는 ReferenceLine */}
            <ReferenceLine
              y={medianValue} // 중간값 설정
              stroke="rgba(55, 56, 60, 0.28)" // 선 색상
              strokeDasharray="3 3" // 점선 스타일
              label={{
                position: 'insideRight',
                value: '기준',
                fontSize: 12,
                fontFamily: 'Pretendard-Medium',
                dx: 20,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </TAnalyzeChartBox>
      <AveragePriceListBox>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_low_avg.svg"></img>
            <div>하위 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            {formatToKoreanCurrency(analysisData.low_avg * pyeong)}~
          </AveragePriceContent>
        </AveragePriceList>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_avg.svg"></img>
            <div>기준 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            {formatToKoreanCurrency(analysisData.avg * pyeong)}
          </AveragePriceContent>
        </AveragePriceList>
        <AveragePriceList>
          <TextGray>
            <img src="/asset/images/icon/dot_high_avg.svg"></img>
            <div>상위 추정가</div>
          </TextGray>
          <AveragePriceContent style={{ fontSize: '14px' }}>
            ~{formatToKoreanCurrency(analysisData.high_avg * pyeong)}
          </AveragePriceContent>
        </AveragePriceList>
      </AveragePriceListBox>
    </>
  );
};

export default NonPropertyChart;

const TAnalyzeChartBox = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 180px;
`;

const AveragePriceListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #fafafa;
`;

const AveragePriceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #222;
  font-size: 13px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
  flex: 1;
`;

const AveragePriceContent = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  line-height: 24px;
  word-wrap: break-word;
  align-self: center;
`;

const TextGray = styled.div`
  display: flex;

  color: #616161;
  gap: 4px;
`;
