import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReportInfo from './components/Info';
import ReportNav from './components/Nav';
import ReportDetail1 from './components/detail/ReportDetail1';
import ReportDetail2 from './components/detail/ReportDetail2';
import ReportDetail3 from './components/detail/ReportDetail3';
import ReportDetail4 from './components/detail/ReportDetail4';
import ReportDetail5 from './components/detail/ReportDetail5';
import ReportDetail7 from './components/detail/ReportDetail7';
import ReportDetail8 from './components/detail/ReportDetail8';
import Summary1 from './components/summary/Summary1';
import Summary2 from './components/summary/Summary2';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReportDetail6 from './components/detail/ReportDetail6';
import { styled } from 'styled-components';
import HouseService from '../../../../../service/House';
import { useDidMountEffect } from '../../../../../utilities/hook';
import ReportDetail10 from './components/detail/ReportDetail10';
import MoreInfoModal from '../../../common/MoreInfoModal';
import Loading from '../../../../components/loading/Loading';
import { useMapContext } from '../../../../../contexts/MapContext';
// import HouseService from '../../service/house/landApi';
// import ReportDetail10 from '../reportResultPrice/components/detail/ReportDetail10';
// import PriceService from '../../service/price/priceApi';
// import { SaveBtn } from '../../component/common/saveBtn';
// import { SaveReportPopUp } from '../../component/common/saveReportPopUp';

const HouseReportMain = ({
  reportIdx = 0,
  setClose = () => {},
  mypage,
}: any) => {
  let param: any;
  const param2 = useParams();
  if (window.innerWidth) {
    param = { reportIdx: param2.reportIdx };
  } else {
    param = { reportIdx: reportIdx };
  }
  const locationHook = useLocation();
  const [query, setQuery] = useState('');
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
  }, [locationHook]);
  const [constructionOwnMoney, setConstructionOwnMoney] = useState(0);
  const [constructionLoanCost, setConstructionLoanCost] = useState(0);
  const [houseLoanInfo, setHouseLoanInfo] = useState({
    loanCost: 75000000,
    loanRate: 0.02,
    loanType: 'T',
    redemptionPeriod: 10,
    retentionPeriod: 5,
  });
  const [constructionLoanInfo, setConstructionLoanInfo] = useState({
    loanCost: 75000000,
    loanRate: 0.02,
    loanType: 'T',
    redemptionPeriod: 10,
    retentionPeriod: 5,
  });
  const [report_desc, setReport_desc] = useState({
    PerMeterEstimatedHousingPrice: null,
    address: '서울특별시 종로구 사간동 6',
    buildingToLandRatio: 0,
    createdAt: '2023-10-17T09:59:22.000Z',
    estimatedHousingPrice: null,
    floor: 2,
    floorAreaRatio: 0,
    houseTradeDate: null,
    houseTradePrice: null,
    perPyeongEstimatedHousingPrice: null,
    totalFloorArea: 80.56,
    totalFloorAreaPyeong: 24.4,
    year_int: 1930,
    type: 'N',
  });

  const [savePopUp, setSavePopUp] = useState(false);

  const [costAnalysis, setCostAnalysis] = useState({
    stdBuildingToLandRatio: 40,
    stdFloorAreaRatio: 100,
    ownHouseYN: 'Y',
    myBuildingToLandRatio: 0.27,
    myFloorAreaRatio: 0.27,

    myTotalFloorArea: 66.4,
    myTotalFloorAreaPyeong: 20.1,

    constructionLoanInfo: {
      totalConstructionCost: 129181157,
      ownMoney: 121044677,
      loanCost: 8136480,
      details: {
        boundaryCost: 750197,
        designCost: 2010000,
        housePermitFee: 2010000,
        constructionCost: 120600000,
        cHouseAcquisitionTax: 2412000,
        cFarmSpecialTax: 241200,
        registrationTax: 964800,
        cLocalEducationTax: 192960,
      },
      loanInfo: {
        loanCost: 8136480,
        loanRate: 0.02,
        loanType: 'T',
        retentionPeriod: 5,
        redemptionPeriod: 10,
      },
      loanPlan: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
      loanPlan1Year: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
    },
    houseCostLoanInfo: {
      totalHouseCost: 10170600,
      ownMoney: 2034120,
      details: {
        houseCost: 10050000,
        pHouseAcquisitionTax: 100500,
        pLocalEducationTax: 10050,
        pFarmSpecialTax: 10050,
      },
      loanInfo: {
        loanCost: 8136480,
        loanRate: 0.02,
        loanType: 'T',
        retentionPeriod: 5,
        redemptionPeriod: 10,
      },
      loanPlan: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
      loanPlan1Year: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
    },
    eligibility: {
      constructionArea: 66.44658000000001,
      liveInFarmYN: 'Y',
      returnToFarmYN: 'Y',
      floor: 1,
    },
  });

  const [housePriceAnalysis, setHousePriceAnalysis] = useState({
    estimatedHousingPrice: 26546478.400000002,
    perPyeongEstimatedHousingPrice: 353953,
    PerMeterEstimatedHousingPrice: 107042,
    surroundings: [
      {
        address: '충청북도 괴산군 칠성면 외사리 364',
        perPyeongHouseTradePrice: 5328498,
        houseTradeDate: '20200901',
        distance: 254.6340774011368,
      },
      {
        address: '충청북도 괴산군 칠성면 외사리 160',
        perPyeongHouseTradePrice: 5136648,
        houseTradeDate: '20221208',
        distance: 390.93157605764566,
      },
      {
        address: '충청북도 괴산군 칠성면 외사리 283-1',
        perPyeongHouseTradePrice: 5478817,
        houseTradeDate: '20180707',
        distance: 506.8175158035264,
      },
    ],
    surroundingAvgValue: 5751244.652662432,
  });

  const [indexInfo, setIndexInfo] = useState({
    houseIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 100,
      },
    ],
    newConstructionIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 74,
      },
    ],
    remodelingIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 133,
      },
    ],
    avgHouseRate: 3.3,
    avgNewConstructionRate: -0.1,
    avgRemodelingRate: 9.4,
  });
  const [houseTotalPeriod, setHouseTotalPeriod] = useState();
  const [isStart, setIsStart] = useState(false);
  const [origin, setOrigin] = useState();
  const getOriginData = async () => {
    try {
      const { result } = await HouseService.origin();
      setOrigin(result);
    } catch (error) {
      console.log(error);
    }
  };

  const [ownHouseYN, setOwnHouseYN] = useState('Y');

  const [loading, setLoading] = useState(false);

  const report = async () => {
    setLoading(true);
    try {
      if (!mypage) {
      } else {
      }

      const data: any = await HouseService.report(
        sessionStorage.pyeongValue,
        decodeURI(locationHook.search.split('=')[1]),
        c_idx,
      );
      // const data2 = await ReportService.reportFarm(param.reportIdx);

      setReport_desc(data.result.report_desc);
      setCostAnalysis(data.result.costAnalysis);
      setHousePriceAnalysis(data.result.housePriceAnalysis);
      setIndexInfo(data.result.indexInfo);
      // setIsStart(true);

      setHouseLoanInfo(data.result.costAnalysis.constructionLoanInfo.loanInfo);
      setConstructionLoanInfo(
        data.result.costAnalysis.constructionLoanInfo.loanInfo,
      );
      setConstructionOwnMoney(
        Math.round(
          (data.result.costAnalysis.constructionLoanInfo.ownMoney /
            data.result.costAnalysis.constructionLoanInfo
              .totalConstructionCost) *
            100,
        ),
      );
      setConstructionLoanCost(
        100 -
          Math.round(
            (data.result.costAnalysis.constructionLoanInfo.ownMoney /
              data.result.costAnalysis.constructionLoanInfo
                .totalConstructionCost) *
              100,
          ),
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const updateCostInfo = (data: { costAnalysis: any }) => {
    setCostAnalysis(data.costAnalysis);

    setHouseLoanInfo(data.costAnalysis.houseCostLoanInfo.loanInfo);
    setConstructionLoanInfo(data.costAnalysis.constructionLoanInfo.loanInfo);
    setConstructionOwnMoney(
      Math.round(
        (data.costAnalysis.constructionLoanInfo.ownMoney /
          data.costAnalysis.constructionLoanInfo.totalConstructionCost) *
          100,
      ),
    );
    setConstructionLoanCost(
      100 -
        Math.round(
          (data.costAnalysis.constructionLoanInfo.ownMoney /
            data.costAnalysis.constructionLoanInfo.totalConstructionCost) *
            100,
        ),
    );
  };

  // 변경 api (대출), 저장 api(저장하기 버튼)
  const report2 = async () => {
    const data = {
      loanOfHouseCost: {
        loanCost: houseLoanInfo.loanCost,
        loanRate: houseLoanInfo.loanRate,
        loanType: houseLoanInfo.loanType,
        retentionPeriod: houseLoanInfo.retentionPeriod,
        redemptionPeriod: houseLoanInfo.redemptionPeriod,
      },
      loanOfConstructionCost: {
        loanCost: constructionLoanInfo.loanCost,
        loanRate: constructionLoanInfo.loanRate,
        loanType: constructionLoanInfo.loanType,
        retentionPeriod: constructionLoanInfo.retentionPeriod,
        redemptionPeriod: constructionLoanInfo.redemptionPeriod,
      },
      ownHouseYN,
      designIdx: 13,
      pyeong: sessionStorage.pyeongValue,
      houseInfo: report_desc,
    };
    try {
      const { result } = await HouseService.costCal(data);

      setCostAnalysis(result);
      // setEligibilityData(result.eligibility);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    report();
    getOriginData();
  }, []);

  useDidMountEffect(() => {
    report2();
  }, [houseLoanInfo, constructionLoanInfo]);

  const conRef = useRef<HTMLDivElement>(null);
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const menuRef8 = useRef<HTMLDivElement>(null);
  const menuRef9 = useRef<HTMLDivElement>(null);
  const menuRef10 = useRef<HTMLDivElement>(null);
  const temp = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);
  const divRef8 = useRef<HTMLDivElement>(null);
  const divRef9 = useRef<HTMLDivElement>(null);
  const convertElementToImage = async (element: any) => {
    // if (element) {
    //   let canvas = await html2canvas(element);
    //   return canvas.toDataURL('image/svg');
    // }
    return null;
  };

  const convertHTMLToImages = async () => {
    const elements = [
      divRef.current,
      divRef2.current,
      divRef3.current,
      divRef4.current,
      divRef5.current,
      divRef6.current,
      divRef7.current,
      divRef8.current,
      divRef9.current,
    ];
    const imageDataArray = await Promise.all(
      elements.map(async (element) => {
        return await convertElementToImage(element);
      }),
    );
    return imageDataArray.filter((imageData) => imageData !== null);
  };

  const handleButtonClick = async () => {
    let imgDataArray = await convertHTMLToImages();

    if (imgDataArray.length > 0) {
      // let doc = new jsPDF();
      // imgDataArray.forEach((imgData: any, index) => {
      //   doc.addImage(imgData, 'SVG', 0, 0, 210, 297);
      //   if (index < imgDataArray.length - 1) {
      //     doc.addPage();
      //   }
      // });
      // doc.save('a4.pdf');
    }
  };

  const { c_idx, address, itemType, pnu, housekye, pyeongValue } =
    sessionStorage;
  const nav = useNavigate();
  const { latLng } = useMapContext();

  return (
    <div
      style={{
        maxWidth: '720px',
        height: '100vh',
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      {/* {savePopUp && <SaveReportPopUp setSavePopUp={setSavePopUp} />} */}
      {/* <button
        onClick={() => {
          handleButtonClick();
        }}
      >
        다운
      </button> */}
      {/* {!search ? (
        <TopNav2
          browser
          text='내 집 건축보고서'
          closeBack
          close
          setClose={setClose}
        />
      ) : (
        ''
      )} */}

      <div
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
        ref={temp}
      >
        <TopWrapper ref={divRef}>
          <div
            style={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div></div>
            <TitleText>내 집 맞춤 건축보고서</TitleText>
            <div
              style={{
                width: '24px',
                height: '24px',
                fontSize: '24px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (c_idx === '') {
                  if (housekye !== '') {
                    nav(
                      `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
                    );
                  } else {
                    nav(
                      `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                    );
                  }
                } else {
                  nav(
                    `/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                  );
                }
              }}
            >
              <img src="/asset/images/close/close-gray.svg" alt="" />
            </div>
          </div>
          <ReportNav
            refList={{
              conRef,
              menuRef1,
              menuRef2,
              menuRef3,
              menuRef4,
              menuRef5,
              menuRef6,
              menuRef7,
              menuRef8,
              menuRef9,
              menuRef10,
              temp,
            }}
            isHave={ownHouseYN}
          />
        </TopWrapper>
        <div style={{ height: '16px', background: '#F7F8F9' }} />
        <div ref={menuRef1} style={{ scrollMarginTop: '200px' }}></div>
        <Summary2 data={indexInfo} />
        <div
          style={{
            width: '100%',
            height: '16px',
            background: '#F7F8F9',
            scrollMarginTop: '120px',
          }}
          ref={menuRef2}
        />
        <div ref={divRef2}>
          <ReportDetail1
            mypage={mypage && true}
            data2={report_desc}
            data3={housePriceAnalysis}
          />
        </div>
        <div
          style={{
            height: '16px',
            background: '#F7F8F9',
            scrollMarginTop: '120px',
          }}
          ref={menuRef3}
        ></div>
        <div ref={divRef3}>
          <ReportDetail2 data={indexInfo} address={address} />
        </div>
        <div
          style={{
            height: '16px',
            background: '#F7F8F9',
            scrollMarginTop: '120px',
          }}
          ref={menuRef4}
        />
        <div ref={divRef4}>
          <ReportDetail3 data={report_desc} />
        </div>
        <div
          style={{
            height: '16px',
            background: '#F7F8F9',
            scrollMarginTop: '120px',
          }}
          ref={menuRef5}
        />
        <div ref={divRef7}>
          <ReportDetail6
            data={costAnalysis}
            data2={report_desc}
            // selectedDesignFloor={
            //   (window.innerWidth)
            //     ? location.state.selectedDesignFloor
            //     : selectedDesignFloor
            // }
            mypage={mypage}
          />
        </div>
        {ownHouseYN === 'N' ? (
          <>
            <div
              style={{
                height: '16px',
                background: '#F7F8F9',
                scrollMarginTop: '120px',
              }}
              ref={menuRef6}
            />
            <div ref={divRef8}>
              <ReportDetail8
                report2={report2}
                mypage={mypage && true}
                data={costAnalysis}
                type={report_desc.type}
                report={report}
                isStart={isStart}
                totalPeriod={houseTotalPeriod}
                setTotalPeriod={setHouseTotalPeriod}
                loanInfo={houseLoanInfo}
                setLoanInfo={setHouseLoanInfo}
                ownHouseYN={ownHouseYN}
                updateCostInfo={updateCostInfo}
              />
            </div>
          </>
        ) : (
          ''
        )}{' '}
        <div
          style={{
            height: '16px',
            background: '#F7F8F9',
            scrollMarginTop: '120px',
          }}
          ref={menuRef7}
        />
        <div ref={divRef6}>
          <ReportDetail7
            report2={report2}
            mypage={mypage && true}
            data={costAnalysis}
            report={report}
            type={report_desc.type}
            isStart={isStart}
            ownMoney={constructionOwnMoney}
            setOwnMoney={setConstructionOwnMoney}
            totalLoanCost={constructionLoanCost}
            setTotalLoanCost={setConstructionLoanCost}
            loanInfo={constructionLoanInfo}
            setLoanInfo={setConstructionLoanInfo}
            ownHouseYN={ownHouseYN}
            setOwnHouseYN={setOwnHouseYN}
            updateCostInfo={updateCostInfo}
            setShowMoreInfoModal={setShowMoreInfoModal}
          />
        </div>
        <ReportDetail10 originData={origin} mypage={mypage} />
      </div>
      <div
        style={{
          padding: '16px',
          position: 'fixed',
          bottom: 0,
          zIndex: 100,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 'inherit',
          backgroundColor: '#fff',
        }}
      >
        <Button
          onClick={() =>
            nav('/house/input?address=' + pnu, {
              state: {
                c_idx: c_idx,
                address: address,
                itemType: itemType,
                pnu: pnu,
                housekye: housekye,
                landArea: sessionStorage.getItem('landArea') ?? '',
                land_BCR: sessionStorage.getItem('land_BCR') ?? '',
              },
            })
          }
        >
          <ButtonText>맞춤 조건 재설정하기</ButtonText>
        </Button>
      </div>
      {showMoreInfoModal && (
        <MoreInfoModal setShowMoreInfoModal={setShowMoreInfoModal} />
      )}
      {loading && <Loading />}
    </div>
  );
};

const TopWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  margin: auto;
  width: 100%;
  max-width: 720px;
  background-color: #ffffff;
  // overflow-y: scroll;
  // overflow-x: scroll;
  margin-bottom: 0px;
  // height: 100%;
`;

const Referal_Text = styled.div`
  font-family: Pretendard-bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: start;
  color: #171e26;
`;

const Button = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const TitleText = styled.div`
  //styleName: Mo/Body/L-16-Medium;
  font-family: Pretendard-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #171e26;
`;

export default HouseReportMain;

export interface IReport_desc {
  address: string;
  totalFloorArea: number;
  totalFloorAreaPyeong: number;
  floor: number;
  year_int: number;
  createdAt: string;
  estimatedHousingPrice: any;
  houseTradePrice: any;
  houseTradeDate: any;
  perPyeongEstimatedHousingPrice: any;
  PerMeterEstimatedHousingPrice: any;
  floorAreaRatio: number;
  buildingToLandRatio: number;
  type: string;
}

export interface ILandInfo {
  landArea: number;
  landPyeong: number;
  zoning: string;
  category: string;
  useStatus: string;
  height: string;
  roadSurface: string;
  CAGR: number;
  shape: string;
  roadSurface_desc: string;
  pnuCode: string;
  officialPriceList:
    | {
        2014: number;
        2015: number;
        2016: number;
        2017: number;
        2018: number;
        2019: number;
        2020: number;
        2021: number;
        2022: number;
        2023: number;
      }
    | any;
}

export interface ICostAnalysis {
  stdBuildingToLandRatio: number;
  stdFloorAreaRatio: number;
  myBuildingToLandRatio: number;
  ownHouseYN: string;
  myTotalFloorArea: number;
  myTotalFloorAreaPyeong: number;
  myFloorAreaRatio: number;
  constructionLoanInfo: {
    totalConstructionCost: number;
    ownMoney: number;
    loanCost: number;
    details: {
      boundaryCost: number;
      designCost: number;
      housePermitFee: number;
      constructionCost: number;
      cHouseAcquisitionTax: number;
      cFarmSpecialTax: number;
      registrationTax: number;
      cLocalEducationTax: number;
    };
    loanInfo: {
      loanCost: number;
      loanRate: number;
      loanType: string;
      retentionPeriod: number;
      redemptionPeriod: number;
    };
    loanPlan: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
    loanPlan1Year: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
  };
  houseCostLoanInfo: {
    totalHouseCost: number;
    ownMoney: number;
    details: {
      houseCost: number;
      pHouseAcquisitionTax: number;
      pLocalEducationTax: number;
      pFarmSpecialTax: number;
    };
    loanInfo: {
      loanCost: number;
      loanRate: number;
      loanType: string;
      retentionPeriod: number;
      redemptionPeriod: number;
    };
    loanPlan: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
    loanPlan1Year: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
  };
  eligibility: {
    constructionArea: number;
    liveInFarmYN: string;
    returnToFarmYN: string;
    floor: number;
  };
}

export interface IHousePriceAnalysis {
  estimatedHousingPrice: number;
  perPyeongEstimatedHousingPrice: number;
  PerMeterEstimatedHousingPrice: number;
  surroundings: {
    address: string;
    perPyeongHouseTradePrice: number;
    houseTradeDate: string;
    distance: number;
  }[];
  surroundingAvgValue: number;
}

export interface IIndexInfo {
  houseIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  newConstructionIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  remodelingIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  avgHouseRate: number;
  avgNewConstructionRate: number;
  avgRemodelingRate: number;
}
