import { useEffect, useState } from 'react';
import {
  RowTableGreenBold,
  RowSectionTitle,
  RowTableValueBold,
} from '../report/Row';
import { TextSectionTitle } from '../report/Text';
import { Tooltip2 } from '../common/tooltip';
import { IHousePriceAnalysis, ILandInfo, IReport_desc } from '../../Main';
import { ButtonSelect1M } from '../report/Button';
import { BoxGreen1 } from '../report/Box';
import { utilTransNum } from '../../../../../../../utilities/utils';

const ReportDetail1 = ({
  data2,
  data3,
  mypage,
}: {
  data2: IReport_desc;
  data3: IHousePriceAnalysis;
  mypage?: boolean;
}) => {
  const [onToolTip, setOnToolTip] = useState(false);
  const [tab2, setTab2] = useState(1);

  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <RowSectionTitle>
        <TextSectionTitle>2. 주택가격</TextSectionTitle>
      </RowSectionTitle>
      <div className="flex-column">
        <div className="padding-16">
          <div
            style={{ background: '#171e2610', width: '100%', height: '1px' }}
          ></div>
        </div>

        <div className="padding-8-16 flex-row gap-8">
          <ButtonSelect1M
            onClick={() => {
              setTab2(1);
            }}
            selected={tab2 === 1}
          >
            거래이력
          </ButtonSelect1M>
          <ButtonSelect1M
            onClick={() => {
              setTab2(2);
            }}
            selected={tab2 === 2}
          >
            주변 거래 사례
          </ButtonSelect1M>
        </div>

        {tab2 === 1 ? (
          <div className="padding-16" style={{ textAlign: 'center' }}>
            {data2.houseTradePrice === null ? (
              <div className="recent_description">
                최근 5년 내 거래 이력이 없습니다
              </div>
            ) : (
              <RowTableValueBold
                title=""
                value={
                  <div className="flex-column end-y">
                    <div className="font-medium f-size-16 line-h-24 color-secondary">
                      {utilTransNum(data2.houseTradePrice)}원
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {utilTransNum(
                        data2.houseTradePrice / (data2.totalFloorArea * 0.3025),
                      )}
                      원/평
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {data2.houseTradeDate &&
                        `${data2.houseTradeDate.slice(
                          0,
                          4,
                        )}-${data2.houseTradeDate.slice(
                          5,
                          7,
                        )}-${data2.houseTradeDate.slice(8, 10)}`}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        ) : (
          <div className="flex-column">
            {/* <div className="padding-16-16-0 flex-row ">
              <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                주변 거래사례
              </div>
              <img
                src="/asset/images/warn/Warn24.svg"
                className="hover"
                onClick={() => setOnToolTip(true)}
                alt=""
              />
            </div> */}
            <Tooltip2
              bottom="0"
              on={onToolTip}
              off={() => setOnToolTip(false)}
              text="더 많은 주변 거래사례는 고객센터에 문의 바랍니다."
            />
            <div className="padding-16 flex-column gap-16">
              {data3.surroundings && data3.surroundings.length > 0 ? (
                data3.surroundings.map((el) => {
                  let arr = el.address.split(' ');
                  let num = arr.pop() || '';
                  let num2 = '';
                  for (let i = 1; i < num.length; i++) {
                    num2 = num2 + '*';
                  }
                  const address = arr.join(' ') + ' ' + num.slice(0, 1) + num2;
                  return (
                    <div className="flex-row space-between">
                      <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
                        반경 {Math.round(el.distance)}m
                      </div>
                      <div className="flex-column end-y">
                        <div className="font-medium f-size-16 line-h-24 color-secondary">
                          {address}
                        </div>
                        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                          {Math.round(
                            el.perPyeongHouseTradePrice,
                          ).toLocaleString()}
                          원/평
                        </div>
                        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                          {/* {el.houseTradeDate.slice(0, 4)}-
                          {el.houseTradeDate.slice(4, 6)}-
                          {el.houseTradeDate.slice(6, 8)} */}
                          {el.houseTradeDate &&
                            el.houseTradeDate.slice(0, 4) +
                              '-' +
                              el.houseTradeDate.slice(4, 6) +
                              '-' +
                              el.houseTradeDate.slice(6, 8)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="font-medium f-size-14 line-h-20 color-text-tertiary"
                  style={{
                    fontFamily: 'Pretendard-Medium',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#171e26c7',
                  }}
                >
                  주변 거래 사례가 없습니다
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportDetail1;
