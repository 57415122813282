import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface PopupProps {
  setShowPopup: (value: boolean) => void;
}

const PopUpModal: React.FC<PopupProps> = ({ setShowPopup }) => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner === 0 ? 1 : 0));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleDontShowToday = () => {
    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('popupDismissedDate', today);
    setShowPopup(false);
  };

  return (
    <Container>
      <Inner>
        {currentBanner === 0 ? (
          <img
            src="/asset/images/popup/main-banner-01.png"
            alt="Land Market Apply Banner"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => {
              navigate('/landMarket/apply');
            }}
          />
        ) : (
          <img
            src="/asset/images/popup/main-banner-02.png"
            alt="Land Market Product Banner"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => {
              navigate('/landMarket/product');
            }}
          />
        )}
        <ButtonBox>
          <Button onClick={handleDontShowToday}>
            <span>오늘 하루 보지 않기</span>
          </Button>
          <Button
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <span>닫기</span>
          </Button>
        </ButtonBox>
      </Inner>
    </Container>
  );
};

export default PopUpModal;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.46);
`;

const Inner = styled.div`
  width: 312px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: #222;
  background-color: #fff;
`;
const Button = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 16px 0;
`;
