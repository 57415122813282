import { useEffect, useRef, useState } from 'react';
import MainSearchBox from './components/search/MainSearchBox';
import styled from 'styled-components';
import colors from '../../color';
import Map from './components/map/Map';
import FilterModal from './components/filter/FilterModal';
import TabMenuContainer from './components/tabMenu/TabMenu';
import BottomNavigation from '../components/navigation/Navigation';
import TopTriangleTooltip from '../components/tootip/Tooltip';
import SaleList from './components/list/List';
import RegionModal from './components/regionModal/RegionModal';
import Sales from '../../service/Sales';
import {
  areaOption,
  priceOption,
  yearOption,
} from './components/filter/FilterOption';
import { SaleData } from './MainData';
import SnackBar from '../components/snackBar/SnackBar';
import { useFilter } from '../../contexts/FilterContext';
import { useMapContext } from '../../contexts/MapContext';
import { Helmet } from 'react-helmet';
import PopUpModal from './components/popup/PopUpModal';
import BrokerList from './components/brokerList/BrokerList';
//전담중개사 더미데이터
import {
  Dummy_Broker_Company,
  Dummy_BrokerData,
} from './components/brokerList/dummy_broker';

const Main = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tabMenuRef = useRef<HTMLDivElement>(null);
  const [tabMenuHeight, setTabMenuHeight] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);
  const [smallDevice, setSmallDevice] = useState(false);
  const [deviceHeight, setDeviceHeight] = useState(0);
  const [filterModalOn, setFilterModalOn] = useState(false);
  const [regionModalOn, setRegionModalOn] = useState(false);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [showListAll, setShowListAll] = useState(false);
  const [listOn, setListOn] = useState(true);
  const [list, setList] = useState<SaleData[]>([]);
  const [isListData, setIsListData] = useState(false);
  const [markerList, setMarkerList] = useState<SaleData[]>([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { menu, saleType, filterOption, sort } = useFilter();
  const { zoomLevel, pnu, mode, bounds } = useMapContext();

  const [brokerOn, setBrokerOn] = useState(false);
  const [brokerList, setBrokerList] = useState<any>();
  const [brokerCompany, setBrokerCompany] = useState<any>();
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    const dismissedDate = localStorage.getItem('popupDismissedDate');

    if (dismissedDate !== today) {
      setShowPopup(true);
    }
  }, []);

  useEffect(() => {
    if (showSnackBar) {
      const timer = setTimeout(() => {
        setShowSnackBar(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSnackBar]);

  const changeValue = (item: string, options: any) => {
    const foundOption = options.find((option: any) => option.label === item);
    return foundOption ? foundOption.value : '';
  };

  const changeAllText = (item: any) => {
    return item.join(',') === '전체' ? '' : item.join(',');
  };
  const getNplBasicInfoData = async () => {
    try {
      const res = await Sales.list(
        bounds.swLat,
        bounds.neLat,
        bounds.swLng,
        bounds.neLng,
        mode === '가격정보'
          ? filterOption.target === '전체'
            ? ''
            : filterOption.target === '토지'
              ? 'L'
              : 'H'
          : 'L',
        mode === '가격정보'
          ? changeAllText(filterOption.category)
          : '전,답,과수',
        changeAllText(filterOption.purpose),
        changeValue(filterOption.price[0], priceOption),
        changeValue(filterOption.price[1], priceOption),
        changeValue(filterOption.area[0], areaOption),
        changeValue(filterOption.area[1], areaOption),
        changeValue(filterOption.year[0], yearOption),
        changeValue(filterOption.year[1], yearOption),
        saleType.join(','),
        pnu,
        sort,
        zoomLevel.toString(),
      );

      if (zoomLevel >= 14) {
        setList(res.result.list);
        setMarkerList(res.result.markerList);
      } else {
        setList([]);
        setMarkerList(res.result);
      }

      //중개사의 회사 정보
      //개별 전담 중개사 마커
      // setBrokerCompany(Dummy_Broker_Company);
      if (res.result.list.length > 0) {
        setIsListData(true);
      } else {
        setIsListData(false);
      }
    } catch (error) {
      console.error('error');
    }
  };

  useEffect(() => {
    if (
      bounds.swLat !== 0 &&
      bounds.swLng !== 0 &&
      bounds.neLat !== 0 &&
      bounds.neLng !== 0
    ) {
      getNplBasicInfoData();
      // 전담중개사 정보
      // 지도 하단의 전담 중개사 리스트
      // setBrokerList(Dummy_BrokerData);
    }
  }, [bounds, filterOption, saleType, sort, pnu, zoomLevel, mode]);

  useEffect(() => {
    if (tabMenuRef.current) {
      setTabMenuHeight(tabMenuRef.current.offsetHeight);
    }
  }, [menu]);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      setDeviceHeight(containerHeight);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      setMapHeight(containerHeight - 56 - tabMenuHeight);
      if (containerHeight < 670) {
        setSmallDevice(true);
      } else {
        setSmallDevice(false);
      }
    }
  }, [menu, tabMenuHeight]);

  useEffect(() => {
    const defaultFilterOption = {
      category: [],
      purpose: [],
      price: ['', ''],
      area: ['', ''],
      year: ['', ''],
      theme: [],
    };

    const { target, sale_type, ...restFilterOption } = filterOption;

    const isChanged =
      JSON.stringify(restFilterOption) !== JSON.stringify(defaultFilterOption);
    setHasFilterChanged(isChanged);
  }, [filterOption]);

  return (
    <>
      <Helmet>
        <title>누구나 토지 투자를 똑똑하게 | 트랜스파머</title>
        <meta
          name="description"
          content="전국 일반 매물, 지자체 매물, 경매, 공매물 검색 - 안심할 수 있는 가격대 매물만 보기 기능을 제공합니다 ."
        />
        <meta
          name="keywords"
          content="토지 매매, 땅매매, 땅값, 땅 시세, 토지 시세, 농지,  주택매매,  토지실거래가, 토지이음, 토지투자,  땅투자, 경매, 유휴지, 캠핑장 사업, 태양광 발전 임대,  창고임대 사업, 펜션 사업"
        />
        <meta
          property="og:title"
          content="트랜스파머 | 누구나 토지 투자를 똑똑하게"
        />
        <meta
          property="og:description"
          content="전국 토지 매물 시세 분석과 토지에 적합한 투자 상품을 제공합니다"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta property="og:url" content="https://www.transfarmer.co.kr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container ref={containerRef}>
        <TopTriangleTooltip />
        <MainSearchBox />
        <TabMenuContainer
          ref={tabMenuRef}
          setFilterModalOn={setFilterModalOn}
          hasFilterChanged={hasFilterChanged}
        />
        <Map
          height={mapHeight}
          setRegionModalOn={setRegionModalOn}
          listOn={listOn}
          setListOn={setListOn}
          isListData={isListData}
          markerList={markerList}
          brokerOn={brokerOn}
          brokerList={brokerList}
          setBrokerOn={setBrokerOn}
          brokerCompany={brokerCompany}
        />
        {listOn && list && list.length > 0 && (
          <SaleList
            data={list}
            showListAll={showListAll}
            setShowListAll={setShowListAll}
            smallDevice={smallDevice}
            deviceHeight={deviceHeight}
            onChange={getNplBasicInfoData}
          />
        )}
        {list.length < 1 && zoomLevel >= 14 && <TriangleTooltip />}
        {filterModalOn && (
          <FilterModal
            menu={menu}
            setFilterModalOn={setFilterModalOn}
            deviceHeight={deviceHeight}
            smallDevice={smallDevice}
            setShowSnackBar={setShowSnackBar}
          />
        )}
        {regionModalOn && (
          <RegionModal
            setRegionModalOn={setRegionModalOn}
            deviceHeight={deviceHeight}
            smallDevice={smallDevice}
          />
        )}
        {showSnackBar && (
          <SnackBar
            leftText={
              hasFilterChanged
                ? '필터가 적용되었습니다.'
                : '필터가 초기화 되었습니다.'
            }
            onClick={() => {}}
            textCenter
          />
        )}
        <BottomNavigation />
        {showPopup && <PopUpModal setShowPopup={setShowPopup} />}
        {brokerOn && (
          <BrokerList
            brokerList={brokerList}
            setBrokerOn={setBrokerOn}
          ></BrokerList>
        )}
      </Container>
    </>
  );
};
export default Main;

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  background-color: ${colors.white};
  position: relative;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const TriangleTooltip = () => {
  return <TooltipContainer>지도에 매물이 안 보인다면?</TooltipContainer>;
};

const TooltipContainer = styled.div`
  position: absolute;
  padding: 8px 16px;
  background-color: #0073f0;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  bottom: 65px;
  left: 20%;
  z-index: 100;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Adjust as needed to position the triangle */
    left: 17%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0073f0;
  }
  @media (min-width: 400px) {
    left: 22%;
  }
  @media (min-width: 500px) {
    left: 24%;
  }
  @media (min-width: 670px) {
    left: 25%;
  }
`;
