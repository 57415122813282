import styled from 'styled-components';
import colors from '../../color';

interface TabProps {
  active: boolean;
}

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  background-color: #ffffff;
`;

const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;
const TabBox = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
`;
const TabInner = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: rgba(112, 115, 124, 0.05);
  display: flex;
  flex-direction: row;
`;
const Tab = styled.div<TabProps>`
  flex: 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active }) =>
    active ? colors.labelNormal : colors.labelAlternative};
  border: 1px solid ${({ active }) => (active ? '#E1E2E4' : 'transparent')};
  background-color: ${({ active }) => (active ? '#fff' : 'transparent')};
  cursor: pointer;
`;
const VerifyInfoBox = styled.div`
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
const Description = styled.span`
  font-size: 14px;
  color: ${colors.labelNeutral};
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputItem = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 16px;
  border-radius: 13px;
  background-color: #f7f8fc;
`;
const Label = styled.span`
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.textSecondary};
`;

const Input = styled.input`
  width: 90%;
  height: 26px;
  border: none;
  background-color: transparent;
  font-family: Pretendard-Medium;
  font-size: 17px;
  color: ${colors.labelNormal};

  &::placeholder {
    font-family: Pretendard-Medium;
    font-size: 17px;
    color: ${colors.labelAssistive};
    padding-left: 0;
  }

  &:focus {
    border-color: none;
    outline: none;
    font-family: Pretendard-Medium;
    font-size: 17px;
    color: ${colors.labelNormal};
  }
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PhoneNumberBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SendNumberButton = styled.button`
  width: 100px;
  height: 32px;
  background-color: rgba(112, 115, 124, 0.16);
  color: #222;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Medium;
  font-size: 14px;
  cursor: pointer;
  border: none;
`;
const FindBtnBox = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  padding: 16px;
  max-width: inherit;
`;
const FindBtn = styled.button`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;
const SignUpText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
  position: absolute;
  bottom: 94px;
  left: 0;
`;
const Wrapper = styled.div`
  max-width: 720px;
`;
const WarningFont = styled.p`
  font-family: Pretendard-Medium;
  color: red;
  font-size: 14px;
  text-align: start;
`;

const AccountList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;
const AccountItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const AccountItemInner = styled.div<TabProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 16px;
  border: ${({ active }) => (active ? 'px solid #1E9B6B' : 'none')};
`;
const AccountTextBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
const SignUpInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAssistive};
`;

const TextGreen = styled.span`
  color: ${colors.primary500};
`;

const EmailBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const FindPasswordBtn = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary500};
  color: white;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;
export const S = {
  Container,
  PageTopContainer,
  PageTopTitle,
  ContentBox,
  TabBox,
  TabInner,
  Tab,
  VerifyInfoBox,
  TitleBox,
  InputBox,
  InputItem,
  Label,
  Input,
  PhoneNumberBox,
  SendNumberButton,
  Description,
  FindBtnBox,
  FindBtn,
  SignUpText,
  Wrapper,
  WarningFont,
  InputRow,
  AccountList,
  AccountItem,
  AccountTextBox,
  SignUpInfo,
  TextGreen,
  AccountItemInner,
  FindPasswordBtn,
  EmailBox,
};
