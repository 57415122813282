import { useEffect } from 'react';
import { S } from './SignUpEmail.styles';
import { useNavigate } from 'react-router-dom';
import { SignUpInfo } from '../../contexts/SignUpContext';
import UserService from '../../service/Users';
import { PageTopClose } from '../components/pageTop/PageTop';
import { useMapContext } from '../../contexts/MapContext';
import { hackleSignUptrack } from '../../hackler';

const jobOptions = [
  { value: 'W', label: '농업인' },
  { value: 'F', label: '귀농 희망자' },
  { value: 'N', label: '귀촌 희망자' },
  { value: 'E', label: '농산업기업체' },
  { value: 'O', label: '지자체 담당자' },
  { value: 'P', label: '정부부처(공공기관)' },
  { value: 'C', label: '농산물 유통' },
  { value: 'B', label: '부동산 중개' },
  { value: 'G', label: '기타(해당사항 없음)' },
];

const SignUpSns = () => {
  const {
    email,
    setEmail,
    loginType,
    setLoginType,
    isEmailValid,
    setIsEmailValid,
    phone,
    setPhone,
    name,
    setName,
    category,
    setCategory,
    farmOperatorId,
    setFarmOperatorId,
    etc,
    setEtc,
    allAgree,
    setAllAgree,
    privacyAgree,
    setPrivacyAgree,
    serviceAgree,
    setServiceAgree,
    locationAgree,
    setLocationAgree,
    notificationAgree,
    setNotificationAgree,
    socialId,
    setSocialId,
  } = SignUpInfo();

  const navigate = useNavigate();
  const { latLng } = useMapContext();

  const getUserInfo = async () => {
    try {
      const res = await UserService.info();
      localStorage.setItem('name', res.result.name);
      localStorage.setItem('point', res.result.point);
      localStorage.setItem('profileImg', res.result.profileImage);
      localStorage.setItem('email', res.result.email);
      localStorage.setItem('phone', res.result.phone);
      localStorage.setItem('category', res.result.category);
      localStorage.setItem('interest_area', res.result.interest_area);
      localStorage.setItem('birth', res.result.birth);
      localStorage.setItem('farm_id', res.result.farm_id);
      localStorage.setItem('loginType', res.result.loginType);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');
    const email = urlParams.get('email');
    const loginType = urlParams.get('loginType');
    const name = urlParams.get('name');
    const phone = urlParams.get('phone');
    const socialId = urlParams.get('socialId');

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      getUserInfo();
      const currentPath = localStorage.getItem('currentPath') ?? '/';
      navigate(currentPath);

      if (localStorage.getItem('loginModal') === 'Y') {
        navigate(currentPath);
        void localStorage.removeItem('loginModal');
        localStorage.removeItem('currentPath');
      } else {
        if (!localStorage.getItem('lat') || !localStorage.getItem('lng')) {
          const latitude = latLng?.lat ?? 0; // 기본값을 0으로 설정
          const longitude = latLng?.lng ?? 0;
          navigate(`/?lat=${latitude}&lng=${longitude}`);
        } else {
          navigate(
            `/?lat=${localStorage.getItem('lat')}&lng=${localStorage.getItem('lng')}`,
          );
        }
      }
    } else {
      setEmail(email === 'null' ? '' : String(email));
      setLoginType(loginType ?? '');
      setName(name ?? '');
      setPhone(phone === 'null' ? '' : String(phone));
      setSocialId(socialId ?? '');
    }
  }, [navigate]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleAllAgree = () => {
    if (!allAgree) {
      setAllAgree(true);
      setPrivacyAgree(true);
      setServiceAgree(true);
      setLocationAgree(true);
      setNotificationAgree(true);
    } else {
      setAllAgree(false);
      setPrivacyAgree(false);
      setServiceAgree(false);
      setLocationAgree(false);
      setNotificationAgree(false);
    }
  };

  const isFormValid = () => {
    return (
      email !== '' &&
      phone !== '' &&
      name !== '' &&
      privacyAgree &&
      serviceAgree &&
      locationAgree
    );
  };

  const SignUp = async () => {
    if (email === '') {
      alert('이메일을 입력해주세요');
      return;
    }
    if (phone === '') {
      alert('휴대폰 번호를 입력해주세요');
      return;
    }
    if (name === '') {
      alert('이름을 입력해주세요');
      return;
    }
    if (!privacyAgree) {
      alert('개인정보 처리방침에 동의해주세요');
      return;
    }
    if (!serviceAgree) {
      alert('트랜스파머 서비스 이용약관에 동의해주세요');
      return;
    }
    if (!locationAgree) {
      alert('위치기반서비스 이용약관에 동의해주세요');
      return;
    }
    try {
      const data = {
        socialId: socialId,
        email: email,
        loginType: loginType,
        name: name,
        phone: phone,
        category: category === '' ? 'U' : category,
        farmOperatorId: farmOperatorId,
        etc: etc,
        isNotification: notificationAgree === true ? 'Y' : 'N',
        landing: sessionStorage.getItem('landingType') ?? '',
        referer: sessionStorage.getItem('beforeUrl') ?? '',
      };
      const res = await UserService.signUp(data);
      if (res.result === '유저 생성 완료') {
        alert('가입이 완료되었습니다');
        navigate('/login');
        SignUpInfoReset();
        getUserInfo();
        hackleSignUptrack(loginType, category, email);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const SignUpInfoReset = () => {
    setSocialId('');
    setEmail('');
    setLoginType('');
    setPhone('');
    setName('');
    setCategory('');
    setFarmOperatorId('');
    setEtc('');
    setAllAgree(false);
    setPrivacyAgree(false);
    setServiceAgree(false);
    setLocationAgree(false);
    setNotificationAgree(false);
  };
  const CheckloginType = () => {
    if (loginType === 'K' || loginType === 'N') {
      return 'true';
    }
    return 'false';
  };

  return (
    <S.Container>
      <PageTopClose />
      <S.ContentBox>
        <S.Title>정보확인 및 약관동의</S.Title>
        <S.InputBox>
          <S.InputItem>
            <S.Label>이름</S.Label>
            <S.Input
              type="text"
              placeholder="홍길동"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              disabled
            />
          </S.InputItem>
          {email === '' && (
            <>
              <S.InputItem>
                <S.Label>이메일</S.Label>
                <S.Input
                  type="text"
                  placeholder="이메일 주소를 입력해 주세요"
                  value={email}
                  onChange={handleEmailChange}
                />
              </S.InputItem>
              {!isEmailValid && (
                <S.WarningFont>이메일 형식이 아닙니다.</S.WarningFont>
              )}
            </>
          )}

          <S.InputItem>
            <S.Label>휴대폰 번호</S.Label>
            <S.PhoneNumberBox>
              <S.Input
                type="tel"
                placeholder="010 0000 0000"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                style={{ flex: 1 }}
                maxLength={11}
                disabled={CheckloginType() === 'true'}
              />
            </S.PhoneNumberBox>
          </S.InputItem>

          <S.InputItem>
            <S.Label>소속 혹은 농촌에 대한 관심사 (선택)</S.Label>
            <S.Select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <S.Option value={''}>어떤 일을 하시나요?</S.Option>
              {jobOptions.map((option, index) => (
                <S.Option key={index} value={option.value}>
                  {option.label}
                </S.Option>
              ))}
            </S.Select>
          </S.InputItem>
          {category === 'W' && (
            <S.InputItem>
              <S.Label>농업 경영체 등록 번호</S.Label>
              <S.Input
                type="text"
                placeholder="농업 경영체 등록 번호를 입력해주세요"
                value={farmOperatorId}
                onChange={(e) => {
                  setFarmOperatorId(e.target.value);
                }}
              />
            </S.InputItem>
          )}
          {category === 'G' && (
            <S.InputItem style={{ height: 'auto' }}>
              <S.Input
                type="text"
                placeholder="하는 일 혹은 관심사를 기재해주세요"
                value={etc}
                onChange={(e) => {
                  setEtc(e.target.value);
                }}
              />
            </S.InputItem>
          )}
        </S.InputBox>
        <S.Line></S.Line>
        <S.TermsBox>
          <S.AllAgreeBox>
            <img
              src={
                allAgree
                  ? '/asset/images/check/terms-check-on.svg'
                  : '/asset/images/check/terms-check-off.svg'
              }
              alt=""
              onClick={() => {
                handleAllAgree();
              }}
            />
            <p>약관 전체 동의</p>
          </S.AllAgreeBox>
          <S.AgreeBox>
            <S.AgreeItem>
              <img
                src={
                  privacyAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setPrivacyAgree(!privacyAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 개인정보 처리방침</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/privacy');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  serviceAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setServiceAgree(!serviceAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 트랜스파머 서비스 이용약관</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/service');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  locationAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setLocationAgree(!locationAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 위치기반서비스 이용약관</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/location');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  notificationAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setNotificationAgree(!notificationAgree);
                }}
              />
              <S.AgreeText>
                <span>[선택] 혜택 정보 푸시 알림 수신</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/notification');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
          </S.AgreeBox>
        </S.TermsBox>
      </S.ContentBox>
      <S.SignUpBtnBox>
        <S.SignUpBtn
          style={{
            color: isFormValid() ? '#ffffff' : 'rgba(55, 56, 60, 0.16)',
            backgroundColor: isFormValid() ? '#1E9B6B' : '#F0F0F0',
          }}
          onClick={() => {
            SignUp();
          }}
        >
          가입하기
        </S.SignUpBtn>
      </S.SignUpBtnBox>
    </S.Container>
  );
};

export default SignUpSns;
