import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class Pension {
  static resultPension = async (
    age: number,
    farmValue: number,
    c_idx: number,
    pnu: string,
  ) => {
    const data = { age, farmValue, c_idx, pnu };
    const result = await request.post('sales/result/pension', data);
    return result.data;
  };
}
