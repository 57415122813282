import { useRef, useState } from 'react';
import styled from 'styled-components';
import PriceTrend from './PriceTrend';
import OfficialPriceTransition from './OfficialPriceTransition';
import ZModel from './ZModel';
import LandPrice from './LandPrice';
import LandGrade from './LandGrade';
import {
  LandGradeProps,
  OfficialPriceDataProps,
  PyeongRegionDataProps,
  TrendDataProps,
  ZModelDataProps,
  LegalDongNameProps,
} from '../../data';
import LoginModal from '../../common/LoginModal';

interface TabContentProps {
  IsActive: boolean;
}

interface PriceAnalysisProps {
  trend: TrendDataProps;
  officalPrice: OfficialPriceDataProps;
  zModel: ZModelDataProps;
  type: string;
  pyeongRegion: PyeongRegionDataProps;
  landGrade: LandGradeProps;
  legalDongName: LegalDongNameProps;
  isLogin2: boolean;
}

const PriceAnalysis = ({
  trend,
  officalPrice,
  zModel,
  type,
  pyeongRegion,
  landGrade,
  legalDongName,
  isLogin2,
}: PriceAnalysisProps) => {
  const [selectedState, setSelectedState] = useState('PriceTrend');
  const tabData = [
    { state: 'PriceTrend', label: '가격 트렌드 분석' },
    { state: 'OfficialPrice', label: '공시지가 추이' },
    { state: 'ZModel', label: 'Z모델' },
    { state: 'LandPrice', label: '지역별 토지단가(평)' },
    { state: 'LandGrade', label: '토지등급분석' },
  ];
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleStateChange = (state: string, index: number) => {
    setSelectedState(state);
    // 클릭한 탭으로 스크롤 이동
    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <AnalysisInfoContainer>
      <AnalysisInfoHeader>
        <AnalysisInfoTitle>가격분석</AnalysisInfoTitle>
        <TabBox>
          {tabData.map((tab, index) => (
            <TabContent
              ref={(el) => (tabRefs.current[index] = el)}
              key={tab.state}
              IsActive={selectedState === tab.state}
              onClick={() => handleStateChange(tab.state, index)}
            >
              {tab.label}
            </TabContent>
          ))}
        </TabBox>
      </AnalysisInfoHeader>
      <div style={{ position: 'relative' }}>
        {!isLogin2 && <LoginModal></LoginModal>}
        {selectedState === 'PriceTrend' && (
          <PriceTrend data={trend}></PriceTrend>
        )}
        {selectedState === 'OfficialPrice' && (
          <OfficialPriceTransition
            data={officalPrice}
          ></OfficialPriceTransition>
        )}
        {selectedState === 'ZModel' && (
          <ZModel data={zModel} type={type}></ZModel>
        )}
        {selectedState === 'LandPrice' && (
          <LandPrice
            data={pyeongRegion}
            type={type}
            legalDongName={legalDongName}
          ></LandPrice>
        )}
        {selectedState === 'LandGrade' && (
          <LandGrade data={landGrade} legalDongName={legalDongName}></LandGrade>
        )}
      </div>
    </AnalysisInfoContainer>
  );
};

export default PriceAnalysis;

const AnalysisInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
`;

const AnalysisInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  gap: 16px;
  width: 100%;
  height: 120px;
`;

const AnalysisInfoTitle = styled.div`
  color: black;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const TabBox = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabContent = styled.div<TabContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.IsActive ? 'white' : '#989ba2')};
  background-color: ${(props) => (props.IsActive ? '#222222' : null)};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  border: ${(props) =>
    props.IsActive ? null : '1px rgba(112, 115, 124, 0.16) solid'};
  border-radius: 9999px;
  white-space: nowrap;
  cursor: pointer;
`;

const PriceFlow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  height: 32px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px #e1e2e4 solid;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
