import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PromotionTopNav = () => {
  return (
    <Box>
      <Inner>
        <Logo>
          <Link to="/">
            <img src="/asset/images/logo/logo_korean_green.svg" alt="" />
          </Link>
          <div className="ai font-medium f-size-12">
            AI가이드로 농촌생활 똑똑하게!
          </div>
        </Logo>
      </Inner>
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  display: flex;
`;
const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
