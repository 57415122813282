import styled from 'styled-components';
import colors from '../../../../color';

interface FilterModalProps {
  selected?: boolean;
  smallDevice?: boolean;
}

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.52);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const ModalInner = styled.div<FilterModalProps>`
  width: 100%;
  height: ${({ smallDevice }) => (smallDevice ? '100%' : 'auto')};
  background-color: white;
  border-radius: 20px 20px 0 0;
  position: relative;
`;
export const ModalTitle = styled.div`
  width: 100%;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 24px;
  color: ${colors.labelNeutral};
  border-bottom: 1px solid ${colors.lineNormal};
  height: 62px;
`;

export const SectionBox = styled.div<FilterModalProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f5f5f5;
  height: 556px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 82px;
`;
export const Section = styled.div`
  flex: 1;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.white};
`;

export const SectionLabel = styled.div`
  width: 100%;
  font-family: 'Pretendard-Bold';
  font-size: 16px;
  color: black;
  display: flex;
  justify-content: space-between;
`;
export const OptionBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
export const OptionItem = styled.span<FilterModalProps>`
  height: 44px;
  padding: 10px 0;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ selected }) => (selected ? colors.primary500 : colors.lineNeutral)};
  color: ${({ selected }) =>
    selected ? colors.primary500 : colors.labelAlternative};
  box-sizing: border-box;
  cursor: pointer;
`;
export const OptionItemBg = styled.span<FilterModalProps>`
  height: 44px;
  padding: 10px 0;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.lineNeutral};
  color: ${({ selected }) =>
    selected ? colors.primary500 : colors.labelAlternative};
  background-color: ${({ selected }) =>
    selected ? colors.primary100 : colors.white};
  box-sizing: border-box;
  cursor: pointer;
`;

export const OptionItemIcon = styled.div<FilterModalProps>`
  width: fit-content;
  padding: 10px 12px;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${({ selected }) => (selected ? colors.primary500 : colors.lineNeutral)};
  color: ${({ selected }) =>
    selected ? colors.primary500 : colors.labelAlternative};
  box-sizing: border-box;
  cursor: pointer;
`;

export const SelectedItemBox = styled.div`
  flex: 1;
  border: 1px solid ${colors.lineNeutral};
  border-radius: 8px;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  height: 53px;
`;

export const SelectedLabel = styled.p`
  font-family: 'Pretendard-Medium';
  font-size: 13px;
  line-height: 18px;
  color: ${colors.labelAlternative};
`;

export const SelectedValue = styled.p`
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 24px;
  color: ${colors.labelNormal};
`;

export const OptionRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const OptionRowTitle = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 15px;
  color: ${colors.labelNeutral};
`;
export const ScrollBox = styled.div`
  width: 100%;
  min-height: 355px;
  background-color: ${colors.white};
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ApplyBtnBox = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.lineNormal};
`;

export const ResetBtn = styled.div`
  padding: 7px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNormal};
  cursor: pointer;
`;

export const ResetBtnText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 10px;
  color: ${colors.labelAlternative};
`;
export const ApplyBtn = styled.span`
  flex: 1;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Pretendard-Bold';
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.primary500};
  cursor: pointer;
`;
