import { useNavigate } from 'react-router-dom';
import {
  Container,
  PageTopContainer,
  PageTopTitle,
  ContentBox,
  Address,
  SectionBox,
  SelectItems,
  SelectLabel,
  TypeSelect,
  SectionTitle,
  Limit2000Text,
  TextArea,
  AutoFillEmail,
  Input,
  CustomCenter,
  CSNumber,
  AplyBtn,
  AplyBtnBox,
  SuccessMessage,
  SuccessMessageBox,
} from './Inquiry.styles';
import { useEffect, useState } from 'react';
import InquiryService from '../../service/inquiry';

const InquiryType = [
  { value: 'D', label: '매매 관련' },
  { value: 'G', label: '서비스 이용' },
  { value: 'I', label: '시스템 불편사항' },
  { value: 'J', label: '회원정보 및 탈퇴' },
  { value: 'K', label: '사업 문의' },
];

const Inquiry = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState<String>();
  const [purpose, setPurpose] = useState<String>();
  const [type, setType] = useState('');
  const [content, setContent] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [autoFillEmail, setAutoFillEmail] = useState(false);
  const [btnOn, setBtnOn] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    setAddress(localStorage.getItem('address') || '');
  }, []);

  const CheckEmail = async () => {
    setAutoFillEmail(false);
    const userEmail = localStorage.getItem('email');
    const userPhone = localStorage.getItem('phone');
    if (userEmail && userPhone) {
      setAutoFillEmail(true);
      setEmail(userEmail);
      setPhone(userPhone);
    } else {
      setAutoFillEmail(false);
      alert('해당 기능은 로그인 후 사용 가능합니다.');
    }
  };

  const handleAutoFillEmail = () => {
    setAutoFillEmail((prevState) => !prevState);
    if (!autoFillEmail) {
      CheckEmail();
    } else {
      setEmail('');
      setPhone('');
    }
  };

  const Submit = async () => {
    try {
      const data = {
        address: address,
        purpose: purpose,
        type: type,
        content: content,
        email: email,
        phone: String(phone),
      };
      const res = await InquiryService.inquiry(data);
      if (res.result === 'success') {
        setBtnOn(false);
        setSuccessMessage(true);
        localStorage.removeItem('address');
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const checkBtn = () => {
    if (address !== '' && purpose === '') {
      alert('주소 분석 목적을 선택해주세요');
    } else if (type === '') {
      alert('유형을 선택해주세요');
    } else if (content === '') {
      alert('문의사항을 입력해주세요');
    } else if (email === '') {
      alert('이메일을 입력해주세요');
    } else if (!isValidEmail(email)) {
      alert('유효한 이메일 주소를 입력해주세요');
    } else if (phone === '') {
      alert('연락처를 입력해주세요');
    } else {
      Submit();
    }
  };
  const checkBtnOn = () => {
    if (type !== '' && content !== '' && email !== '' && phone !== '') {
      setBtnOn(true);
    } else {
      setBtnOn(false);
    }
  };

  useEffect(() => {
    checkBtnOn();
  }, [purpose, type, content, email, phone]);

  return (
    <Container>
      <PageTopContainer>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: 'pointer' }}
        />
        <PageTopTitle>1:1 문의</PageTopTitle>
      </PageTopContainer>
      <ContentBox>
        {address !== '' && (
          <>
            <Address>{address}</Address>
            <SectionBox>
              <div>주소 분석 목적</div>
              <SelectItems>
                <SelectLabel
                  style={{
                    color:
                      purpose === 'A' ? '#171E26' : 'rgba(23, 30, 38, 0.4)',
                  }}
                >
                  <input
                    type="radio"
                    name="purpose"
                    value="B"
                    checked={purpose === 'A'}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                  <img
                    src={
                      purpose === 'A'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=""
                  />
                  <span>사고 싶어요</span>
                </SelectLabel>
                <SelectLabel
                  style={{
                    color:
                      purpose === 'B' ? '#171E26' : 'rgba(23, 30, 38, 0.4)',
                  }}
                >
                  <input
                    type="radio"
                    name="purpose"
                    value="S"
                    checked={purpose === 'B'}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                  <img
                    src={
                      purpose === 'B'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=""
                  />
                  <span>팔고 싶어요</span>
                </SelectLabel>
                <SelectLabel
                  style={{
                    color:
                      purpose === 'C' ? '#171E26' : 'rgba(23, 30, 38, 0.4)',
                  }}
                >
                  <input
                    type="radio"
                    name="purpose"
                    value="N"
                    checked={purpose === 'C'}
                    onChange={(e) => setPurpose(e.target.value)}
                  />
                  <img
                    src={
                      purpose === 'C'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=""
                  />
                  <span>해당 없음</span>
                </SelectLabel>
              </SelectItems>
            </SectionBox>
          </>
        )}
        <SectionBox>
          <div>문의 유형</div>
          <TypeSelect
            name=""
            id=""
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value="">유형을 선택해 주세요</option>
            {InquiryType.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </TypeSelect>
        </SectionBox>
        <SectionBox>
          <SectionTitle>
            <span>문의 내용</span>
            <span>
              {content.length}
              <Limit2000Text> / 2000 </Limit2000Text>
            </span>
          </SectionTitle>
          <TextArea
            maxLength={2000}
            placeholder="문의 내용을 입력해주세요."
            onChange={(e: any) => {
              setContent(e.target.value);
            }}
          />
        </SectionBox>
        <SectionBox>
          <SectionTitle>
            <span>이메일</span>
            <AutoFillEmail onClick={handleAutoFillEmail}>
              <img
                src={
                  autoFillEmail
                    ? '/asset/images/check/Check24GreenCircle2.svg'
                    : '/asset/images/check/checkWithGrayCircle.svg'
                }
                alt=""
              />
              <span>가입 정보로 받기</span>
            </AutoFillEmail>
          </SectionTitle>
          <Input
            value={email}
            placeholder="회신 받을 이메일을 입력해주세요."
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
          />
        </SectionBox>
        <SectionBox>
          <SectionTitle>
            <span>연락처</span>
          </SectionTitle>
          <Input
            type="tel"
            value={phone}
            maxLength={11}
            placeholder="‘-’를 제외한 휴대폰 번호를 작성해주세요."
            onChange={(e: any) => {
              setPhone(e.target.value);
            }}
          />
        </SectionBox>
        <CustomCenter>
          <span>고객센터</span>
          <CSNumber>
            <img src="/asset/images/icon/phone.svg" alt="" />
            <div>02)555-2822 (평일 09:00~18:00)</div>
          </CSNumber>
        </CustomCenter>
      </ContentBox>
      <AplyBtnBox>
        <AplyBtn
          style={{
            color: btnOn ? '#ffffff' : 'rgba(23, 30, 38, 0.40)',
            backgroundColor: btnOn ? '#1E9B6B' : '#F0F0F0',
          }}
          onClick={() => {
            checkBtn();
          }}
        >
          접수
        </AplyBtn>
      </AplyBtnBox>
      {successMessage && (
        <SuccessMessageBox>
          <SuccessMessage>✓ 문의가 접수되었습니다!</SuccessMessage>
        </SuccessMessageBox>
      )}
    </Container>
  );
};
export default Inquiry;
