import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { S } from './FindInfo.styles';
import FindId from './FindId';
import FindPassword from './FindPassword';
import { isLogin } from '../../utilities/auth';

const FindInfo = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('id');
  const [type, setType] = useState('');

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const queryTab = query.get('tab');
    const queryType = query.get('type');
    setTab(queryTab || 'id');
    setType(queryType || '');
  }, []);

  const renderContent = () => {
    if (tab === 'id') {
      return <FindId type={type} tab={tab} setTab={setTab} setType={setType} />;
    }
    return <FindPassword type={type} tab={tab} />;
  };

  return (
    <S.Container>
      <S.PageTopContainer>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: 'pointer' }}
        />
        <S.PageTopTitle>아이디/비밀번호 찾기</S.PageTopTitle>
      </S.PageTopContainer>
      <S.ContentBox>
        <S.TabBox>
          <S.TabInner>
            <S.Tab
              active={tab === 'id'}
              onClick={() => {
                setTab('id');
                setType('D');
              }}
            >
              아이디 찾기
            </S.Tab>
            <S.Tab
              active={tab === 'pw'}
              onClick={() => {
                setTab('pw');
                if (isLogin()) {
                  setType('C');
                } else {
                  setType('B');
                }
              }}
            >
              비밀번호 찾기
            </S.Tab>
          </S.TabInner>
        </S.TabBox>
        {renderContent()}
      </S.ContentBox>
    </S.Container>
  );
};
export default FindInfo;
