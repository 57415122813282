// import DatePicker from "./components/DatePicker";

import { useState, useEffect } from 'react';
import DatePicker from './DatePicker';
// import closeImg from '../../asset/images/close/close_black.svg';
import './report.css';
import React from 'react';

export const BoxDateSelector1 = ({ close, date, setDate }: any) => {
  const [cDate, setCDate] = useState(date);

  const title = '생년월일 선택';

  const datePicker = () => {
    return (
      <DatePicker
        className=""
        fromDate={new Date('1930-01-01')}
        infinite
        toDate={new Date('2010-12-31')}
        initDate={new Date(cDate || '1964-01-01')}
        onChange={(y, m, d) => {
          setCDate([y, ('0' + m).slice(-2), ('0' + d).slice(-2)].join('-'));
        }}
      />
    );
  };

  const onClick = () => {
    setDate(cDate);
    close();
  };

  return BoxDateSelector(title, close, datePicker, onClick, setDate, cDate);
};

function BoxDateSelector(
  title: string,
  close: any,
  datePicker: any,
  onClick: () => void,
  setDate: any,
  cDate: any,
) {
  return (
    <div
      className="box_date_selector"
      style={{
        zIndex: '900',
        height: '100vh',
        maxWidth: '720px',
        margin: '0 auto',
      }}
    >
      <div
        className="top"
        style={{
          // left: 10,
          width: '100%',
          maxWidth: '720px',
          overflowY: 'hidden',
          zIndex: '1000',
          
        }}
      >
        <div className="content" style={{ width: '100', padding: '0' }}>
          <div
            className="title"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {title}
            <img
              src={'/asset/images/close/close-lightgray.svg'}
              onClick={close}
              className="hover"
            />
          </div>
          <div className="picker" style={{ marginTop: 10, marginBottom: 10 }}>
            {datePicker()}
          </div>
        </div>
        <div className="bottom mobile">
          <div
            className="flex-1 center-x color-text-tertiary"
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={close}
          >
            취소
          </div>
          <div className="div" />
          <div
            className="flex-1 center-x color-primary-500"
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={onClick}
          >
            선택
          </div>
        </div>
      </div>
    </div>
  );
}
