import styled from 'styled-components';
import colors from '../../color';

export const Container = styled.div`
  max-width: 720px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
`;

export const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 24px 16px 96px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Address = styled.div`
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.textPrimary};
`;

export const SectionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.textPrimary};
`;
export const SelectItems = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SelectLabel = styled.label`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard-Medium;
  font-size: 14px;

  input {
    display: none; /* 기본 radio 버튼 숨기기 */
  }
`;

export const TypeSelect = styled.select`
  width: 100%;
  height: 48px;
  border: 1px solid ${colors.lineNormal};
  border-radius: 5px;
  padding: 0 16px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.textPrimary};

  background: url('/asset/images/arrow/arrow-down-black.svg') no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  appearance: none;

  &:focus {
    outline: none;
  }
`;
export const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Limit2000Text = styled.span`
  color: rgba(23, 30, 38, 0.4);
`;

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${colors.lineNormal};
  border-radius: 8px;
  padding: 16px;
  height: 274px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.textPrimary};

  &:focus {
    outline: none;
  }
`;

export const AutoFillEmail = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 8px;
  border: 1px solid ${colors.lineNormal};
  border-radius: 5px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.textPrimary};
  height: 48px;
  cursor: pointer;
`;

export const CustomCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${colors.lightGray100};
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.textPrimary};
`;

export const CSNumber = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: ${colors.textTetiary};
`;

export const AplyBtnBox = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  padding: 16px;
  max-width: inherit;
`;
export const AplyBtn = styled.button`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;

export const SuccessMessageBox = styled.div`
  width: 100%;
  position: absolute;
  z-index: 3000;
  bottom: 32%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SuccessMessage = styled.div`
  opacity: 0.9;
  background-color: #2a3746;
  border-radius: 8px;
  padding: 8px 20px;
  width: fit-content;
  color: white;
  font-family: Pretendard-Bold;
  font-size: 14px;
`;
