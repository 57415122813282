import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class LandService {
  static register = async (data: any) => {
    const result = await request.post('land/save/idle-land', data);
    return result.data;
  };
}
