import { useState } from 'react';
import styled from 'styled-components';
import GetLandShpaeImage from '../../util/GetLandShapeImage';
interface LandButtonProps {
  isLandInfo: boolean;
}

const DetailInfo = ({ data }: { data: any }) => {
  const land = data.land;
  const house = data.house;
  const [isLandInfo, setIsLandInfo] = useState(true);
  const OnClickInfoButton = () => {
    setIsLandInfo(!isLandInfo);
  };
  let temp;
  if (land && land.zoning && land && land.zoning.length === 9) {
    const firstThreeChars = land && land.zoning.slice(0, 3); // 첫 3글자 추출
    const remainingChars = land && land.zoning.slice(3); // 나머지 글자 추출
    temp = (
      <RowContentValue className="text-end">
        {firstThreeChars}
        <br />
        {remainingChars}
      </RowContentValue>
    );
  } else if (land && land.zoning && land && land.zoning.length === 8) {
    const firstThreeChars = land && land.zoning.slice(0, 2); // 첫 3글자 추출
    const remainingChars = land && land.zoning.slice(2); // 나머지 글자 추출
    temp = (
      <div style={{ textAlign: 'right' }}>
        {firstThreeChars}
        <br />
        {remainingChars}
      </div>
    );
  } else {
    temp = land && land.zoning;
  }

  const shapeWithoutSuffix =
    land && land.shape
      ? land.shape.slice(-1) === '형'
        ? land.shape.slice(0, -1)
        : land.shape
      : '';

  return (
    <DetailInfoContainer>
      <DetailInfoHeader>
        <div>토지・건물 정보</div>
        <LandUseInfo
          onClick={() => {
            window.open(
              `https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=470&selUmd=0102&selRi=00&landGbn=1&bobn=711&bubn=1&sggcd=&isNoScr=script&mode=search&selGbn=umd&s_type=1&add=land&pnu=${
                data && data.pnu
              }`,
            );
          }}
        >
          <div>토지이용계획열람</div>
          <img
            src="/asset/images/arrow/arrow-right-lightgray.svg"
            style={{
              width: '16px',
              height: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
            alt=""
          />
        </LandUseInfo>
      </DetailInfoHeader>
      <DetailInfoButtonBox>
        <DetailInfoButton isLandInfo={isLandInfo} onClick={OnClickInfoButton}>
          토지 정보
        </DetailInfoButton>
        <DetailInfoButton isLandInfo={!isLandInfo} onClick={OnClickInfoButton}>
          건물 정보
        </DetailInfoButton>
      </DetailInfoButtonBox>
      {isLandInfo ? (
        <DetailInfoWrapper isLandInfo={isLandInfo}>
          <DetailInfoBox>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>토지면적</RowContentKey>
                <RowContentValue>{`${land ? Number(land.area).toLocaleString() : 0}m²`}</RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>용도지역</RowContentKey>
                {temp}
              </RowContent>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>지목</RowContentKey>
                <RowContentValue>{land && land.category}</RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>이용상황</RowContentKey>
                <RowContentValue>{land && land.useStatus}</RowContentValue>
              </RowContent>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>고저</RowContentKey>
                <RowContentValue>{land && land.height}</RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>도로</RowContentKey>
                <RowContentValue>{land && land.roadSurface}</RowContentValue>
              </RowContent>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>용적률</RowContentKey>
                <RowContentValue>{`${
                  land.FAR ? land.FAR : '-'
                }%`}</RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>건폐율</RowContentKey>
                <RowContentValue>{`${
                  land.BCR ? land.BCR : '-'
                }%`}</RowContentValue>
              </RowContent>
            </DetailInfoRow>
          </DetailInfoBox>
          <DetailInfoImgBox>
            <img src={GetLandShpaeImage(shapeWithoutSuffix)} alt="" />
            <InfoImgContentBox>
              <ShapeName>{shapeWithoutSuffix}형</ShapeName>
              <div>{(land && land.shapeDesc) || land.roadSurface_desc}</div>
            </InfoImgContentBox>
          </DetailInfoImgBox>
        </DetailInfoWrapper>
      ) : house ? (
        <DetailInfoWrapper isLandInfo={isLandInfo}>
          <DetailInfoBox>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>연면적</RowContentKey>
                <RowContentValue>
                  {house && house.totalFloorArea
                    ? `${house.totalFloorArea} m²`
                    : '-m²'}
                </RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>건물층수</RowContentKey>
                <RowContentValue>
                  {house && house.floor ? `${house.floor}층` : '-층'}
                </RowContentValue>
              </RowContent>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent>
                <RowContentKey>건폐율</RowContentKey>
                <RowContentValue>{`${
                  Math.round(house && house.BCR * 10) / 10
                }%`}</RowContentValue>
              </RowContent>
              <RowContent>
                <RowContentKey>용적률</RowContentKey>
                <RowContentValue>{`${Math.round(house && house.FAR * 10) / 10}%`}</RowContentValue>
              </RowContent>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent2>
                <RowContentKey>주용도</RowContentKey>
                <RowContentValue>{house && house.mainPurpose}</RowContentValue>
              </RowContent2>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent2>
                <RowContentKey>건물구조</RowContentKey>
                <RowContentValue>{house && house.structure}</RowContentValue>
              </RowContent2>
            </DetailInfoRow>
            <DetailInfoRow>
              <RowContent2>
                <RowContentKey>노후년도</RowContentKey>
                <RowContentValue>
                  {house && house.approvalYear
                    ? `${house.approvalYear}년 (노후 ${
                        Number(new Date().getFullYear()) -
                        Number(house.approvalYear)
                      }년)`
                    : '건물 정보가 없습니다.'}
                </RowContentValue>
              </RowContent2>
            </DetailInfoRow>
          </DetailInfoBox>
        </DetailInfoWrapper>
      ) : (
        <NoResultHouse>등록된 건물 정보가 없습니다.</NoResultHouse>
      )}
    </DetailInfoContainer>
  );
};

export default DetailInfo;

const DetailInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
  justify-content: space-between;
  background-color: #ffffff;
`;

const DetailInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  height: 26px;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const LandUseInfo = styled.div`
  width: 130px;
  height: 28px;
  border-radius: 4px;
  padding: 3px 6px;
  border: 1px rgba(112, 115, 124, 0.22) solid;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    display: flex;
  }
`;

const DetailInfoButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 36px;
`;

const DetailInfoButton = styled.div<LandButtonProps>`
  width: 84px;
  height: 36px;
  padding: 16px 8px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isLandInfo ? 'rgba(112, 115, 124, 0.08)' : null};
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => (props.isLandInfo ? '#222222' : '#616161')};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  cursor: pointer;
`;

const DetailInfoWrapper = styled.div<LandButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`;

const DetailInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
`;

const DetailInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  //   align-items: baseline;
  align-items: stretch;
`;

const RowContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-bottom: 8px;
  border-bottom: 1px rgba(23, 30, 38, 0.1) solid;
  justify-content: space-between;
  align-items: center;

  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
`;

const RowContent2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-bottom: 8px;
  border-bottom: 1px rgba(23, 30, 38, 0.1) solid;
  justify-content: space-between;
  align-items: center;

  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
`;

const RowContentKey = styled.div`
  white-space: nowrap;
  flex-grow: 1;
`;

const RowContentValue = styled.div`
  color: #222222;
  display: flex;
  justify-content: flex-end;
  word-wrap: break-word;
  flex-grow: 1;
`;

const DetailInfoImgBox = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 8px;
  padding: 24px;
  background-color: rgba(112, 115, 124, 0.05);
`;

const InfoImgContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;

  color: rgba(23, 30, 38, 0.56);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const ShapeName = styled.div`
  color: rgba(23, 30, 38, 0.78);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const NoResultHouse = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
`;
