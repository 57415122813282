import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CallAlert from './CallAlert';
import RegionSelectBox from './RegionSelectBox';

interface BrokerListProps {
  brokerList: any;
  setBrokerOn: (value: boolean) => void;
}

const BrokerList = ({ brokerList, setBrokerOn }: BrokerListProps) => {
  const [callOn, setCallOn] = useState(false);
  const [sort, setSort] = useState('42');
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setSort(brokerList.region);
  }, [brokerList]);

  const [visibleIndex, setVisibleIndex] = useState(1);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5, // 50%가 보일 때 감지
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = cardRefs.current.indexOf(
            entry.target as HTMLDivElement,
          );
          if (index !== -1) setVisibleIndex(index + 1);
        }
      });
    }, observerOptions);

    cardRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => {
      cardRefs.current.forEach((ref) => ref && observer.unobserve(ref));
    };
  }, []);

  return (
    <BrokerListContainer>
      <BrokerListHeader>
        <BrokerListHeaderImgBox>
          <img
            onClick={() => setBrokerOn(false)}
            src="/asset/images/arrow/arrow-left-gray.svg"
          ></img>
        </BrokerListHeaderImgBox>
        <BrokerListHeaderContentBox>
          트랜스파머와 함께하는
          <br />
          <span>지역 전담 중개사</span>를 만나보세요
        </BrokerListHeaderContentBox>
      </BrokerListHeader>
      <BrokerInnerContainer>
        <RegionBox>
          <RegionHeader>지역선택</RegionHeader>
          <RegionSelectBox value={sort} onChange={setSort}></RegionSelectBox>
        </RegionBox>
        <BrokerListWrapper>
          <BrokerCardWrapper>
            {brokerList &&
              brokerList.list.map((broker: any, index: number) => (
                <BrokerCardBox
                  key={index}
                  ref={(el) => (cardRefs.current[index] = el)}
                >
                  <BrokerInfoBox>
                    <BrokerCardHeaderBox>
                      <img
                        src={
                          broker.profileImg !== ''
                            ? broker.profileImg
                            : '/asset/images/icon/broker_default_img.png'
                        }
                      ></img>
                      <BrokerInfo>
                        <BrokerCompany>{broker.companyName}</BrokerCompany>
                        <div>대표 {broker.name}</div>
                      </BrokerInfo>
                    </BrokerCardHeaderBox>
                    <BrokerDescriptionBox>
                      <BrokerDescriptionInnerBox>
                        <BrokerDescriptionHeader>소개</BrokerDescriptionHeader>
                        <div>{broker.introduce}</div>
                      </BrokerDescriptionInnerBox>
                      <BrokerDescriptionInnerBox>
                        <BrokerDescriptionHeader>
                          담당지역
                        </BrokerDescriptionHeader>
                        <div> {broker.region}</div>
                      </BrokerDescriptionInnerBox>
                    </BrokerDescriptionBox>
                  </BrokerInfoBox>
                  <ButtonBox
                    onClick={() => {
                      setIndex(index);
                      setCallOn(true);
                    }}
                  >
                    전화 상담하기
                  </ButtonBox>
                </BrokerCardBox>
              ))}
          </BrokerCardWrapper>
          <ListNumberBox>
            <span>{visibleIndex}</span>/{brokerList.list.length}
          </ListNumberBox>
        </BrokerListWrapper>
      </BrokerInnerContainer>
      {callOn && (
        <CallAlert
          setCallOn={setCallOn}
          brokerInfo={brokerList.list[index]}
          region={brokerList.region}
        />
      )}
    </BrokerListContainer>
  );
};

export default BrokerList;

const BrokerCardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 370px;
  flex-direction: row;
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  scrollbar-width: thin; /* auto | thin | none */
  .fOAaXS::-webkit-scrollbar {
    height: 8px; /* 스크롤바 높이 설정 */
  }
`;

const RegionText = styled.div`
  color: #616161;
  font-size: 17px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
`;

const ListNumberBox = styled.div`
  color: rgba(23, 30, 38, 0.4);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  span {
    color: black;
  }
  display: flex;
  justify-content: center;
`;

const ButtonBox = styled.div`
  color: #1e9b6b;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  padding: 12px 16px;
  border: 1px #1e9b6b solid;
  margin: 0 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

const BrokerDescriptionHeader = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const BrokerDescriptionInnerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BrokerDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  gap: 24px;
  padding: 0 16px;
`;

const BrokerCompany = styled.div`
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const BrokerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
`;

const BrokerCardHeaderBox = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: 500;
  font-family: Pretendard-medium;
  background-color: rgba(30, 155, 107, 1);
  line-height: 16px;
  word-wrap: break-word;
  gap: 16px;
  img {
    width: 47px;
    height: 46px;
  }
  border-top-left-radius: 16px; /* 왼쪽 상단 */
  border-top-right-radius: 16px; /* 오른쪽 상단 */
  padding: 16px 28px;
`;

const BrokerInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BrokerCardBox = styled.div`
  display: flex;
  height: 356px;
  width: 318px;
  min-width: 318px;
  max-width: 318px;
  //   height: 356px;
  flex-direction: column;
  gap: 48px;
  background-color: white;
  border-radius: 16px;
  padding-bottom: 24px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
`;

const BrokerListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  background-color: white;
  overflow-x: auto;
`;

const RegionHeader = styled.div`
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const RegionBox = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-left: 16px;
  background-color: #f7f8fc;
  border-radius: 13px;
`;

const BrokerInnerContainer = styled.div`
  width: 100%;
  height: calc(100% - 146px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px;
  background-color: white;
`;

const BrokerListHeaderContentBox = styled.div`
  color: black;
  font-size: 20px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 29px;
  word-wrap: break-word;
  span {
    color: #1e9b6b;
  }
`;

const BrokerListHeaderImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BrokerListHeader = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  height: 92px;
  gap: 8px;
`;

const BrokerListContainer = styled.div`
  width: 100%;
  height: calc(100vh - 57px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 100;
  background-color: white;
  position: absolute;
  top: 57px;
  margin-bottom: 55px;
  overflow-y: hidden;
`;

{
  /* <BrokerCardBox>
              <BrokerInfoBox>
                <BrokerCardHeaderBox>
                  <img src={'/asset/images/icon/broker_default_img.png'}></img>
                  <BrokerInfo>
                    <BrokerCompany>트팜공인중개사</BrokerCompany>
                    <div>대표 함소영</div>
                  </BrokerInfo>
                </BrokerCardHeaderBox>
                <BrokerDescriptionBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>소개</BrokerDescriptionHeader>
                    <div>
                      우주최강 복덕방! 부동산 소개입니다.
                      <br />
                      간단하게 소개자리가 들어갑니다.
                    </div>
                  </BrokerDescriptionInnerBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>담당지역</BrokerDescriptionHeader>
                    <div> 강서구, 강동구, 강남구</div>
                  </BrokerDescriptionInnerBox>
                </BrokerDescriptionBox>
              </BrokerInfoBox>
              <ButtonBox
                onClick={() => {
                  window.alert('전화 상담하기');
                  setCallOn(true);
                }}
              >
                전화 상담하기
              </ButtonBox>
            </BrokerCardBox>
            <BrokerCardBox>
              <BrokerInfoBox>
                <BrokerCardHeaderBox>
                  <img src={'/asset/images/icon/broker_default_img.png'}></img>
                  <BrokerInfo>
                    <BrokerCompany>트팜공인중개사</BrokerCompany>
                    <div>대표 함소영</div>
                  </BrokerInfo>
                </BrokerCardHeaderBox>
                <BrokerDescriptionBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>소개</BrokerDescriptionHeader>
                    <div>
                      우주최강 복덕방! 부동산 소개입니다.
                      <br />
                      간단하게 소개자리가 들어갑니다.
                    </div>
                  </BrokerDescriptionInnerBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>담당지역</BrokerDescriptionHeader>
                    <div> 강서구, 강동구, 강남구</div>
                  </BrokerDescriptionInnerBox>
                </BrokerDescriptionBox>
              </BrokerInfoBox>
              <ButtonBox>전화 상담하기</ButtonBox>
            </BrokerCardBox>
            <BrokerCardBox>
              <BrokerInfoBox>
                <BrokerCardHeaderBox>
                  <img src={'/asset/images/icon/broker_default_img.png'}></img>
                  <BrokerInfo>
                    <BrokerCompany>트팜공인중개사</BrokerCompany>
                    <div>대표 함소영</div>
                  </BrokerInfo>
                </BrokerCardHeaderBox>
                <BrokerDescriptionBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>소개</BrokerDescriptionHeader>
                    <div>
                      우주최강 복덕방! 부동산 소개입니다.
                      <br />
                      간단하게 소개자리가 들어갑니다.
                    </div>
                  </BrokerDescriptionInnerBox>
                  <BrokerDescriptionInnerBox>
                    <BrokerDescriptionHeader>담당지역</BrokerDescriptionHeader>
                    <div> 강서구, 강동구, 강남구</div>
                  </BrokerDescriptionInnerBox>
                </BrokerDescriptionBox>
              </BrokerInfoBox>
              <ButtonBox
                onClick={() => {
                  window.alert('전화 상담하기');
                  setCallOn(true);
                }}
              >
                전화 상담하기
              </ButtonBox>
            </BrokerCardBox> */
}
