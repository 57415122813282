import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ConsultingBanner = () => {
  const navigate = useNavigate();
  return (
    <LineBannerContainer
      onClick={() => {
        navigate(
          '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner',
        );
      }}
    >
      <LineBanner>
        <LineBannerInner>
          <LineBannerText>
            <div>맞춤 컨설팅</div>
          </LineBannerText>
          <LineBannerText2>
            토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
            하세요
          </LineBannerText2>
        </LineBannerInner>
        <img src="/asset/images/arrow/arrow-right-white.svg" alt="" />
      </LineBanner>
    </LineBannerContainer>
  );
};
export default ConsultingBanner;

const LineBannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: #171e26;
`;

const LineBanner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LineBannerInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const LineBannerText = styled.div`
  padding: 2px 6px;
  background-color: #09ce7d;
  border-radius: 2px;
  font-family: Pretendard-Bold;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
`;
const LineBannerText2 = styled.div`
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;
