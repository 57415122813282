import { DatePickerValueFormater } from "./types";
import koFormatter from "./formatters/ko";

type DatePickerSourceItem = {
  value: number;
  text: string;
};

export type DatePickerSourceOptions = {
  fromDate?: Date;
  toDate?: Date;
  currentYear?: number;
  currentMonth?: number;

  formatters?: {
    year?: DatePickerValueFormater;
    month?: DatePickerValueFormater;
    day?: DatePickerValueFormater;
  };
};

class DatePickerSource {
  _fromDate: Date;
  _toDate: Date;
  _currentYear: number;
  _currentMonth: number;
  _yearFormatter: DatePickerValueFormater;
  _monthFormatter: DatePickerValueFormater;
  _dayFormatter: DatePickerValueFormater;
  _years: DatePickerSourceItem[];
  _months: DatePickerSourceItem[];
  _days: DatePickerSourceItem[];
  constructor({
    fromDate,
    toDate,
    currentYear,
    currentMonth,
    formatters,
  }: DatePickerSourceOptions = {}) {
    this._fromDate = fromDate ?? new Date();
    this._toDate = toDate ?? this._getDefaultToDate();
    this._currentYear = currentYear ?? this._fromDate.getFullYear();
    this._currentMonth = currentMonth ?? this._fromDate.getMonth() + 1;
    this._yearFormatter = formatters?.year ?? koFormatter.year;
    this._monthFormatter = formatters?.month ?? koFormatter.month;
    this._dayFormatter = formatters?.day ?? koFormatter?.day;
    this._years = this._getYears();
    this._months = this._getMonths();
    this._days = this._getDays();
  }
  _getDefaultToDate() {
    const newDate = new Date(this._fromDate);
    newDate.setFullYear(newDate.getFullYear() + 40);
    newDate.setMonth(11);
    newDate.setDate(31);
    return newDate;
  }
  init({ fromDate, toDate, currentYear, currentMonth }: DatePickerSourceOptions) {
    if (fromDate) {
      this._fromDate = fromDate;
    }
    if (toDate) {
      this._toDate = toDate;
    }
    if (currentYear) {
      this._currentYear = currentYear;
    }
    if (currentMonth) {
      this._currentMonth = currentMonth;
    }
    this._years = this._getYears();
    this._months = this._getMonths();
    this._days = this._getDays();
  }
  _getItems(from: number, to: number, formatter: (val: number) => string) {
    return [...new Array(to - from + 1)].map((_, i) => ({
      value: i + from,
      text: formatter(i + from),
    }));
  }
  _getYears() {
    return this._getItems(
      this._fromDate.getFullYear(),
      this._toDate.getFullYear(),
      this._yearFormatter
    );
  }
  _getMonths() {
    const fromMonth =
      this._currentYear === this._fromDate.getFullYear() ? this._fromDate.getMonth() + 1 : 1;
    const toMonth =
      this._currentYear === this._toDate.getFullYear() ? this._toDate.getMonth() + 1 : 12;
    return this._getItems(fromMonth, toMonth, this._monthFormatter);
  }
  _getDays() {
    const fromDay =
      this._currentYear === this._fromDate.getFullYear() &&
        this._currentMonth === this._fromDate.getMonth() + 1
        ? this._fromDate.getDate()
        : 1;
    const toDay =
      this._currentYear === this._toDate.getFullYear() &&
        this._currentMonth === this._toDate.getMonth() + 1
        ? this._toDate.getDate()
        : new Date(this._currentYear, this._currentMonth, 0).getDate();
    return this._getItems(fromDay, toDay, this._dayFormatter);
  }
  setCurrent(year: number, month?: number) {
    this._currentYear = year;
    this._months = this._getMonths();
    if (month) {
      this._currentMonth = month;
      this._days = this._getDays();
    }
  }
  get years() {
    return this._years;
  }
  get months() {
    return this._months;
  }
  get days() {
    return this._days;
  }
}

export default DatePickerSource;
