import { useEffect, useState } from 'react';
import { RowSectionTitle, RowBold1 } from '../report/Row';
import { TextSectionTitle } from '../report/Text';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Tooltip2 } from '../common/tooltip';
import { MordeInfoButton, Section, SectionInner } from './RepoertDetail.styles';
// import DoughnutChart2 from '../mobile/DoughnutChart2';
// import UserService from '../../../../service/checkAccount/CheckAccount';
import React from 'react';
import { Slider } from '@mui/material';
// import arrowTop from './../../../../asset/images/arrow/arrowTopGray.svg';
// import arrowBot from './../../../../asset/images/arrow/arrowBotGray.svg';
// import ReportService from '../../../../service/report/reportApi';
// import useDidMountEffect from '../../../../hooks/UseDidMountEffect';
import { ICostAnalysis, ILandInfo, IReport_desc } from '../../Main';
// import HouseService from '../../../../service/house/landApi';
import { ButtonSelectorBox } from '../report/Button';
import { BoxGreen1, BoxTable2 } from '../report/Box';
import { styled } from 'styled-components';
import { utilTransNum } from '../../../../../../../utilities/utils';
import { transNum } from '../../../../../../../function/function';
// import SignController from '../../../../controller/sign/SignController';
// import { useDispatch } from 'react-redux';

const ReportDetail7 = ({
  data,
  report,
  isStart,
  type,
  mypage,
  report2,
  ownMoney,
  setOwnMoney,
  totalLoanCost,
  setTotalLoanCost,
  loanInfo,
  setLoanInfo,
  ownHouseYN,
  setOwnHouseYN,
  updateCostInfo,
  setShowMoreInfoModal,
}: {
  data: ICostAnalysis;
  report: any;
  isStart: boolean;
  type: string;
  mypage?: boolean;
  report2?: any;
  ownMoney: number;
  setOwnMoney: any;
  totalLoanCost: number;
  setTotalLoanCost: any;
  loanInfo: any;
  setLoanInfo: any;
  ownHouseYN: string;
  setOwnHouseYN: any;
  updateCostInfo: any;
  setShowMoreInfoModal: (value: boolean) => void;
}) => {
  let maxLoanCost =
    data.constructionLoanInfo.totalConstructionCost >
    (type === 'R' ? 100000000 : 200000000)
      ? type === 'R'
        ? 100000000
        : 200000000
      : data.constructionLoanInfo.totalConstructionCost;

  const dummy = [
    {
      color: '#09CE7D',
      text: '자기자금',
      rate: ownMoney / 100,
      value: (ownMoney * data.constructionLoanInfo.totalConstructionCost) / 100,
    },
    {
      color: '#1E9B6B',
      text: '융자자금',
      rate: 1 - ownMoney / 100,
      value: (totalLoanCost * maxLoanCost) / 100,
    },
  ];

  const [totalPeriod, setTotalPeriod] = useState(
    data.constructionLoanInfo.loanInfo.redemptionPeriod +
      data.constructionLoanInfo.loanInfo.retentionPeriod,
  );

  const dummy2 = [
    { title: '원리금균등', type: 'T' },
    { title: '원금균등', type: 'P' },
    { title: '만기일시', type: 'B' },
  ];

  const [tab, setTab] = useState(1);

  const [onToolTip, setOnToolTip] = useState(false);
  const [onToolTip2, setOnToolTip2] = useState(false);
  const [onToolTip3, setOnToolTip3] = useState(false);
  const [onToolTip4, setOnToolTip4] = useState(false);
  const [onToolTip5, setOnToolTip5] = useState(false);
  const [open, setOpen] = useState(true);
  const [areaType, setAreaType] = useState('P');
  const obj = {
    P: '원금균등',
    T: '원리금균등',
    B: '만기일시',
  };
  let tbList =
    data.constructionLoanInfo &&
    data.constructionLoanInfo.loanPlan1Year.map((el: any, i: any) => {
      return [
        i + 1,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  let tbList2 =
    data.constructionLoanInfo &&
    data.constructionLoanInfo.loanPlan.map((el: any, i: any) => {
      return [
        i + 1,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  const table = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList,
  };
  const table2 = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList2,
  };

  const [name, setName] = useState(localStorage.getItem('name'));
  const [tempRetentionPeriod, setTempRetentionPeriod] = useState(
    loanInfo.retentionPeriod,
  );
  const [tempRate, setTempRate] = useState(loanInfo.loanRate);
  useEffect(() => {
    setTempRate(loanInfo.loanRate);
    setTotalPeriod(
      data.constructionLoanInfo.loanInfo.redemptionPeriod +
        data.constructionLoanInfo.loanInfo.retentionPeriod,
    );
    setTempRetentionPeriod(loanInfo.retentionPeriod);
  }, [loanInfo]);

  useEffect(() => {
    setTotalPeriod(
      Number(tempRetentionPeriod) + Number(loanInfo.redemptionPeriod),
    );
  }, [tempRetentionPeriod, loanInfo.redemptionPeriod]);

  const temp = `1. 원리금균등 분할상환 : 매달 원금 + 이자금액을 균등하게 납부하는 방법\n2. 원금균등 분할상환 : 매달 원금을 균등하게 납부하고 이에 따른 이자는 점차 줄어드는 방법\n3. 만기일시 상환 : 대출기간동안 이자만 납부하고 원금은 만기에 일시상환하는 방법`;

  useEffect(() => {
    report2();
  }, [ownHouseYN]);
  return (
    <div className="padding-b-24 browser-bg-FFFFFF relative ">
      <RowSectionTitle>
        <TextSectionTitle
          warn
          onClick={() => {
            setOnToolTip(!onToolTip);
          }}
        >
          {ownHouseYN === 'Y' ? 6 : 7}. 귀농 대출 진단
        </TextSectionTitle>
      </RowSectionTitle>
      <Section>
        <SectionInner>
          <div>
            귀농 대출 조건을 충족하기 위해 이주 기한, 거주 기간 등의 조건을
            가정하여 분석됩니다.
          </div>
          <MordeInfoButton
            onClick={() => {
              setShowMoreInfoModal(true);
            }}
          >
            <span>자세히 보기</span>
            <img
              src="/asset/images/arrow/arrow-right-gray-alternative.svg"
              alt=""
            />
          </MordeInfoButton>
        </SectionInner>
      </Section>
      <Tooltip2
        left="7"
        bottom="3"
        on={onToolTip}
        off={() => setOnToolTip(false)}
        text="본 대출 진단은 참고용으로만 제공됩니다. 트랜스파머㈜는 실제 대출 승인 여부를 보증하지 않으며, 이로 인해 발생하는 어떠한 책임도 지지 않습니다."
      />
      <div>
        <div style={{ padding: '16px 16px 8px' }}>
          <div
            style={{
              fontFamily: 'Pretendard-Bold',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '24px',
              color: '#171E26',
            }}
          >
            1. 해당 주택을 보유 중이신가요?
          </div>
        </div>
        <div style={{ padding: '8px 16px 16px', display: 'flex', gap: '12px' }}>
          <div
            onClick={() => setOwnHouseYN('Y')}
            className="hover"
            style={{
              background: ownHouseYN === 'N' ? '#F0F0F0' : '#1E9B6B',
              flex: 1,
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                fontFamily: 'Pretendard-bold',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px',
                color: ownHouseYN === 'N' ? '#171E2666' : '#FFFFFF',
                textAlign: 'center',
              }}
            >
              네
            </div>
          </div>
          <div
            onClick={() => setOwnHouseYN('N')}
            className="hover"
            style={{
              background: ownHouseYN === 'Y' ? '#F0F0F0' : '#1E9B6B',
              flex: 1,
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                fontFamily: 'Pretendard-bold',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px',
                color: ownHouseYN === 'Y' ? '#171E2666' : '#FFFFFF',
                textAlign: 'center',
              }}
            >
              아니오
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-column"
        style={{
          overflow: 'hidden',
          transition: 'all 0.5s',
          maxHeight: !open ? 0 : '2000px',
        }}
      >
        <div className="padding-16-16-0" style={{ padding: '16px 16px 0' }}>
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            대출 신청 금액 설정
          </div>
        </div>
        <div className="padding-8-16">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            ※ 본 {ownHouseYN === 'N' ? '시공비' : '사업비'} 대출 진단은 귀농
            창업 및 주택구입자금 대출(연이자율 2%)에 한합니다.
          </div>
        </div>
        <div className="padding-16-16-8" style={{ padding: '16px 16px 0' }}>
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            1) 자부담금 설정
          </div>
        </div>
        <div className="padding-8-16">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            총 {ownHouseYN === 'N' ? '시공비' : '사업비'}를 참고하여 <br />
            예상 자부담금과 대출금 비율을 설정하세요.
          </div>
        </div>
        <div
          className="flex-row space-between center-y padding-8-16-16"
          style={{ padding: '16px 16px 0' }}
        >
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            총 {ownHouseYN === 'N' ? '시공비' : '사업비'}
          </div>
          <div className="font-medium f-size-16 line-h-24 color-text-secondary">
            {utilTransNum(data.constructionLoanInfo.totalConstructionCost)}원
          </div>
        </div>
        <div
          className="flex-row w-100per gap-40 center-y h-100per padding-x-16 "
          style={{ height: '180px' }}
        >
          <DoughnutChart2 list={dummy} />
          <div className="flex-column w-100per gap-8 ">
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-400 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  자부담금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[0].rate * 1000) / 10}%
              </div>
            </div>
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-500 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  대출금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[1].rate * 1000) / 10}%
              </div>
            </div>
          </div>
        </div>
        {[
          {
            title: '자부담금',
            price: ownMoney,
          },
          {
            title: '대출금',
            price: totalLoanCost,
          },
        ].map((el, index) => (
          <div
            key={index}
            className="flex-center flex-column padding-8-16-24 gap-30"
            style={{ padding: '16px 16px 0' }}
          >
            <div className="flex-column flex-center">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                {el.title}
              </div>
              <div className="font-bold f-size-16 line-h-24 color-text-primary ">
                {utilTransNum(
                  el.title === '대출금'
                    ? ((100 - ownMoney) / 100) *
                        data.constructionLoanInfo.totalConstructionCost
                    : (ownMoney *
                        data.constructionLoanInfo.totalConstructionCost) /
                        100,
                )}
                원
              </div>
            </div>
            <div
              className="flex-column w-100per"
              style={{ paddingLeft: '10px' }}
            >
              <Slider
                value={el.price}
                onChange={(e: any, value: any) => {
                  if (el.title === '자부담금') {
                    if (
                      ((100 - value) *
                        data.constructionLoanInfo.totalConstructionCost) /
                        100 <=
                      maxLoanCost
                    ) {
                      setOwnMoney(value);
                      setTotalLoanCost(
                        ((100 - value) *
                          data.constructionLoanInfo.totalConstructionCost) /
                          maxLoanCost,
                      );
                    }
                  } else {
                    setTotalLoanCost(value);
                    setOwnMoney(
                      100 -
                        (value * maxLoanCost) /
                          data.constructionLoanInfo.totalConstructionCost,
                    );
                  }
                }}
                onChangeCommitted={(e: any, value: any) => {
                  if (el.title === '자부담금') {
                    if (
                      ((100 - value) *
                        data.constructionLoanInfo.totalConstructionCost) /
                        100 <=
                      maxLoanCost
                    ) {
                    } else {
                    }
                  } else {
                  }
                  // report2(
                  //   "C",
                  // ((100 - ownMoney) *
                  //   data.constructionLoanInfo.totalConstructionCost) /
                  //   100,
                  //   loanInfo.loanRate,
                  //   loanInfo.loanType,
                  //   totalPeriod,
                  //   loanInfo.retentionPeriod,
                  //   "N"
                  // );
                  setLoanInfo((prev: any) => {
                    return {
                      ...prev,
                      loanCost:
                        ((100 - ownMoney) *
                          data.constructionLoanInfo.totalConstructionCost) /
                        100,
                    };
                  });
                }}
                marks={(() => {
                  let arr = [];
                  for (let i = 10; i < 100; i += 10) {
                    arr.push({ value: i });
                  }
                  return arr;
                })()}
                valueLabelDisplay={'on'}
                slotProps={{ valueLabel: { style: { display: 'none' } } }}
                valueLabelFormat={(value: number, index: number) => (
                  <div
                    className="speech-bubble font-bold f-size-11 line-h-12 border-radius-2"
                    style={{
                      backgroundColor: '#2A3746',
                      color: 'white',
                      padding: '4px 5px',
                      marginLeft: 0,
                      marginBottom: 0,
                    }}
                  >
                    {Math.round(el.title === '대출금' ? 100 - ownMoney : value)}
                    %
                  </div>
                )}
                step={1}
                tabIndex={10}
                // getAriaValueText={valuetext}
                sx={{
                  height: '8px',
                  '& .MuiSlider-thumb': {
                    color: '#166F4D',
                  },
                  '& .MuiSlider-track': { color: '#1E9B6B' },
                  '& .MuiSlider-rail': { color: '#F0F0F0' },
                  '& .MuiSlider-active': {
                    color: 'green',
                  },
                  '& .Mui-active': {
                    boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                  },
                  '& .MuiSlider-valueLabel': { background: 'none' },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#FFFFFF',
                    height: 8,
                  },
                }}
                min={0}
                max={100}
              />
              <div className="flex-row w-100per">
                {[
                  { 1: '0%', 2: '최소', 3: 'start-y' },
                  { 1: '50%', 2: '기준', 3: 'center-y' },
                  { 1: '100%', 2: '최대', 3: 'end-y' },
                ].map((el, index) => (
                  <div key={index} className={'flex-column flex-1 ' + el['3']}>
                    <div className="font-medium f-size-12 line-h-14 color-text-secondary">
                      {el['1']}
                    </div>
                    <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                      {el['2']}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="padding-16-16-8" style={{ padding: '16px 16px 0' }}>
          <div className="font-bold f-size-16 line-h-24 color-secondary">
            2) 대출 조건 설정
          </div>
        </div>
        <div className={`flex-column gap-16 padding-8-16 ${onToolTip2 && ``}`}>
          <div className="flex-row gap-24">
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출 신청 금액
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  readOnly
                  value={utilTransNum(
                    ((100 - ownMoney) *
                      data.constructionLoanInfo.totalConstructionCost) /
                      100,
                  )}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  원
                </div>
              </div>
            </div>
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                연이자율
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  style={{ background: 'none' }}
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  value={Math.round(tempRate * 100)}
                  type="number"
                  onChange={(e: any) => {
                    setTempRate(e.target.value / 100);
                  }}
                  onBlur={() => {
                    // report2(
                    //   "C",
                    //   (totalLoanCost / 100) *
                    //     data.constructionLoanInfo.totalConstructionCost,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // );
                    setLoanInfo((prev: any) => {
                      return {
                        ...prev,
                        loanRate: tempRate,
                      };
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row gap-24">
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  value={totalPeriod}
                  type="number"
                  onChange={(e: any) => {
                    setTotalPeriod(e.target.value);
                  }}
                  onBlur={() => {
                    // report2(
                    //   "C",
                    //   ((100 - ownMoney) *
                    //     data.constructionLoanInfo.totalConstructionCost) /
                    //     100,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // );

                    if (totalPeriod >= loanInfo.retentionPeriod) {
                      setLoanInfo((prev: any) => {
                        return {
                          ...prev,
                          redemptionPeriod:
                            totalPeriod - loanInfo.retentionPeriod,
                        };
                      });
                    }
                  }}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                거치기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-50per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  type="number"
                  value={tempRetentionPeriod}
                  onChange={(e: any) => setTempRetentionPeriod(e.target.value)}
                  onBlur={() => {
                    // report2(
                    //   "C",
                    //   ((100 - ownMoney) *
                    //     data.constructionLoanInfo.totalConstructionCost) /
                    //     100,
                    //   loanInfo.loanRate,
                    //   loanInfo.loanType,
                    //   totalPeriod,
                    //   loanInfo.retentionPeriod,
                    //   "N"
                    // );
                    setLoanInfo((prev: any) => {
                      return {
                        ...prev,
                        retentionPeriod: Number(tempRetentionPeriod),
                      };
                    });
                  }}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column gap-8">
            <div className="flex-row center-y">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                상환방식
              </div>
              <img
                className="hover"
                src="/asset/images/warn/Warn24.svg"
                alt=""
                onClick={() => {
                  setOnToolTip2(!onToolTip2);
                }}
              />
            </div>

            <div className="flex-row gap-12">
              {dummy2.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`padding-6-8  border-radius-8 w-100per flex-center pointer ${
                      loanInfo.loanType === item.type
                        ? `bg-primary-600`
                        : `bg-light-gray-200`
                    }`}
                    onClick={() => {
                      setLoanInfo((prev: any) => {
                        return { ...prev, loanType: item.type };
                      });
                      // patchLoan(item.type, totalLoanCost);
                    }}
                  >
                    <div
                      className={`font-bold f-size-12 line-h-24 ${
                        loanInfo.loanType === item.type
                          ? `color-white`
                          : `color-disabled`
                      }`}
                      style={{ textAlign: 'center' }}
                    >
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Tooltip2
        left="10"
        on={onToolTip2}
        off={() => setOnToolTip2(false)}
        text={temp}
      />
      <div className="w-100epr center-x padding-t-16 ">
        <div
          className="flex-center w-32 h-32 bg-light-gray-200 border-radius-999 pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {/* <img className="w-14 h-8" src={!open ? arrowBot : arrowTop} /> */}
        </div>
      </div>
      {/* <Tooltip2
        bottom="0"
        on={onToolTip}
        off={() => setOnToolTip(false)}
        text="대출 이자율은 고정금리에 한해 분석합니다."
      /> */}
      <div className="padding-16 ">
        <div
          style={{ background: '#171e2610', width: '100%', height: '1px' }}
        ></div>
      </div>
      <div style={{ padding: '24px 16px 8px' }}>
        <ButtonBox>
          <TabButton onClick={() => setTab(1)} active={tab === 1}>
            <div>대출 적격 여부</div>
          </TabButton>
          <TabButton onClick={() => setTab(2)} active={tab === 2}>
            <div>1년 상환계획</div>
          </TabButton>
          <TabButton onClick={() => setTab(3)} active={tab === 3}>
            <div>전체 상환계획</div>
          </TabButton>
        </ButtonBox>
      </div>
      {/* <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} /> */}
      {tab === 1 ? (
        <div className="flex-column">
          {[data.constructionLoanInfo.loanInfo].map((el: any, i: any) => (
            <div className="flex-column">
              {/* <div className="padding-16-16-8">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  대출 적격 여부 진단
                </div>
              </div> */}

              {[
                {
                  title: '1) 신청금액',
                  case:
                    ownHouseYN === 'N'
                      ? type === 'R'
                        ? data.constructionLoanInfo.loanInfo.loanCost <=
                          100000000
                        : data.constructionLoanInfo.loanInfo.loanCost <=
                          200000000
                      : type === 'R'
                        ? data.constructionLoanInfo.loanInfo.loanCost <=
                          100000000
                        : data.constructionLoanInfo.loanInfo.loanCost <=
                          200000000,
                  onClick: () => {
                    setOnToolTip3(!onToolTip3);
                  },
                  tooltipText:
                    '신축(개축, 재축 포함) 2억원, 증축·대수선 1억원 내',
                  on: onToolTip3,
                },
                {
                  title: '2) 시공면적',
                  case: data.eligibility.constructionArea <= 150,
                  onClick: () => {
                    setOnToolTip4(!onToolTip4);
                  },
                  tooltipText: '연면적 150m² 이하',
                  on: onToolTip4,
                },
                {
                  title: '3) 농촌지역 거주',
                  case: data.eligibility.liveInFarmYN === 'Y',
                  onClick: () => {
                    setOnToolTip5(!onToolTip5);
                  },
                  tooltipText:
                    '농촌이란 다음의 어느 하나에 해당하는 지역을 말한다.\n1. 읍･면의 지역\n\n2. 지방자치법 :  주거･상업･공업지역 외의 용도지역\n\n3. 지방자치법 : 가. 도시지역의 녹지지역 중 생산･보전녹지지역  나. 관리지역 중 생산･보전관리지역 다. 농림･자연환경보전지역',
                  on: onToolTip5,
                },
                {
                  title: (
                    <div
                      className="font-bold f-size-14 line-h-20 color-text-secondary flex-row gap-4 center-y"
                      style={{ wordBreak: 'keep-all' }}
                    >
                      <span className="font-bold f-size-16 line-h-24">4)</span>
                      귀농 농업창업 및 주택구입 지원사업원 대상
                    </div>
                  ),
                  case: data.eligibility.returnToFarmYN === 'Y',
                },
              ].map((el, i) => {
                if (ownHouseYN !== 'N' && i === 3) {
                  return (
                    <div
                      key={i}
                      className="padding-16-16-8"
                      style={{ padding: '16px 16px 0' }}
                    >
                      {' '}
                      <RowBold1
                        title={el.title}
                        warn={i === 3 ? false : true}
                        green={el.case}
                        value={el.case ? '적격' : '부적격'}
                        onClick={el.onClick}
                      />
                      <Tooltip2
                        text={el.tooltipText}
                        on={el.on}
                        off={el.onClick}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className="padding-16-16-8"
                    style={{ padding: '16px 16px 0' }}
                  >
                    <RowBold1
                      title={el.title}
                      warn={i === 3 ? false : true}
                      green={el.case}
                      value={el.case ? '적격' : '부적격'}
                      onClick={el.onClick}
                    />
                    <Tooltip2
                      text={el.tooltipText}
                      on={el.on}
                      off={el.onClick}
                    />
                  </div>
                );
              })}

              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    {([
                      ownHouseYN === 'N'
                        ? type === 'R'
                          ? data.constructionLoanInfo.loanInfo.loanCost <=
                            100000000
                          : data.constructionLoanInfo.loanInfo.loanCost <=
                            200000000
                        : type === 'R'
                          ? data.constructionLoanInfo.loanInfo.loanCost <=
                            100000000
                          : data.constructionLoanInfo.loanInfo.loanCost <=
                            200000000,
                      data.eligibility.constructionArea <= 150,
                      data.eligibility.liveInFarmYN === 'Y',
                      data.eligibility.returnToFarmYN === 'Y' &&
                        ownHouseYN === 'N',
                    ].filter((el) => el).length === 4 &&
                      ownHouseYN === 'N') ||
                    ([
                      ownHouseYN === 'N'
                        ? type === 'R'
                          ? data.constructionLoanInfo.loanInfo.loanCost <=
                            100000000
                          : data.constructionLoanInfo.loanInfo.loanCost <=
                            200000000
                        : type === 'R'
                          ? data.constructionLoanInfo.loanInfo.loanCost <=
                            100000000
                          : data.constructionLoanInfo.loanInfo.loanCost <=
                            200000000,
                      data.eligibility.constructionArea <= 150,
                      data.eligibility.liveInFarmYN === 'Y',
                      data.eligibility.returnToFarmYN === 'Y' &&
                        ownHouseYN === 'N',
                    ].filter((el) => el).length === 3 &&
                      ownHouseYN !== 'N') ? (
                      <>
                        {name}님의 대출 적격 요건은 <br />
                        <span className="color-primary-500">
                          모든 항목에서 부합합니다.
                        </span>
                      </>
                    ) : (
                      <>
                        {name}님은{' '}
                        <span className="color-primary-500">
                          {(ownHouseYN === 'N' ? 4 : 3) -
                            [
                              ownHouseYN === 'N'
                                ? type === 'R'
                                  ? data.constructionLoanInfo.loanInfo
                                      .loanCost <= 100000000
                                  : data.constructionLoanInfo.loanInfo
                                      .loanCost <= 200000000
                                : type === 'R'
                                  ? data.constructionLoanInfo.loanInfo
                                      .loanCost <= 100000000
                                  : data.constructionLoanInfo.loanInfo
                                      .loanCost <= 200000000,
                              data.eligibility.constructionArea <= 150,
                              data.eligibility.liveInFarmYN === 'Y',
                              data.eligibility.returnToFarmYN === 'Y' &&
                                ownHouseYN === 'N',
                            ].filter((el) => el).length}
                          개 항목
                        </span>
                        에서
                        <br />
                        대출 적격 요건을 충족하지 못하여
                        <br />
                        <span className="color-primary-500">
                          부적격 항목의 기준을 달성해야 합니다.
                        </span>
                      </>
                    )}
                  </div>
                </BoxGreen1>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {tab === 2 ? (
        <div className="flex-column">
          {/* <div className="padding-16-16-8">
        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
          향후 1년 상환 계획
        </div>
      </div> */}
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.constructionLoanInfo.loanInfo.loanType as 'P']}
            </div>
          </div>
          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '224px' }}
          >
            <BoxTable2 table={table} />
          </div>
        </div>
      ) : (
        ''
      )}
      {tab === 3 ? (
        <div className="flex-column">
          {/* <div className="padding-16-16-8">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              전체 상환 계획
            </div>
          </div> */}
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.constructionLoanInfo.loanInfo.loanType as 'P']}
            </div>
          </div>

          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '224px' }}
          >
            <BoxTable2 table={table2} />
          </div>
          <div className="flex- row padding-12-16 gap-8 end-x">
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#09CE7D' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-primary-400">
                이자
              </div>
            </div>
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#869CB6' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
                납입원금
              </div>
            </div>
          </div>
          <div
          // className="of-x-scroll of-y-hidden "
          // style={{
          //   width: `${
          //     window.innerWidth > 720 ? 720 - 16 : window.innerWidth - 16
          //   }px`,
          // }}
          >
            <ResponsiveContainer width="96%" height={181}>
              <AreaChart
                className="flex-medium f-size-11 center-x"
                data={data.constructionLoanInfo.loanPlan}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="round" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="interest"
                  name="이자"
                  fill="#09CE7D"
                />
                <Area
                  type="monotone"
                  dataKey="principal"
                  name="납입원금"
                  fill="#869CB6"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="font-medium f-size-11 line-h-12 color-primary text-center">
            회차
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

interface TabButtonProps {
  active: boolean;
}

const ButtonBox = styled.div`
  padding: 2px;
  border-radius: 8px;
  background: var(--Alias-Color-Text-White-Gray-LightGray-200, #f0f0f0);
  border: 1px solid var(--Alias-Color-Text-White-Gray-LightGray-200, #f0f0f0);
  display: flex;
  flex-direction: row;
`;

const TabButton = styled.div<TabButtonProps>`
  border-radius: 8px;
  flex: 1;
  cursor: pointer;
  background: ${({ active }) => (active ? '#fff' : 'transparent')};
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  font-size: 16px;
  color: ${({ active }) => (active ? '#222222' : '#171e2666')};
  padding: 8.5px 4px;
  text-align: center;
`;

const DoughnutChart2 = ({ list }: any) => {
  const options = {
    responsive: false,

    maintainAspectRatio: false, // Disable default aspect ratio

    devicePixelRatio: 3,
  };

  const colorArray = [
    '#0CA96D',
    '#F8B566',
    '#5377A1',
    '#9b1010',
    '#ca29c2',
    '#c8dd08',
    '#1a1a1d',
  ];

  const expData = {
    datasets: [
      {
        labels: list.map((element: any) => element.text),
        data: list && list.map((element: any) => element.rate),
        borderWidth: 0,
        hoverBorderWidth: 3,
        backgroundColor: list && list.map((element: any) => element.color),
        fill: true,
      },
    ],
  };

  return (
    <div
      style={{
        display: 'relative',
        flexDirection: 'column',
        gap: '10px',
        width: 'fit-content',
        alignItems: 'center',
      }}
    >
      <Doughnut
        data={expData}
        options={options}
        height="100vh"
        width="100vh"
        style={{
          width: '150px',
          height: '150px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </div>
  );
};

export default ReportDetail7;
