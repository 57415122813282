export class CropInfo {
  Rank: number;
  SGA: number;
  empLaborCosts: number;
  facility: string;
  avgFacilityCost: number;
  facilityCost: number;
  landPurchaseCost?: number;
  farmEquipmentRentalCost: number;
  farmMachineryCost?: number;
  income: number;
  materialCost: number;
  name: string;
  orchardAdjustmentCost: number;
  ownLaborCosts: number;
  productionCost: number;
  quantity: number;
  rate?: number;
  realIncome: number;
  sales: number;
  sido?: string;
  totalLaborCosts: number;
  unitPrice: number;
  waterHeatingCost: number;

  constructor() {
    this.Rank = 0;
    this.SGA = 0;
    this.empLaborCosts = 0;
    this.facility = '';
    this.avgFacilityCost = 0;
    this.facilityCost = 0;
    this.landPurchaseCost = 0;
    this.farmEquipmentRentalCost = 0;
    this.farmMachineryCost = 0;
    this.income = 0;
    this.materialCost = 0;
    this.name = '';
    this.orchardAdjustmentCost = 0;
    this.ownLaborCosts = 0;
    this.productionCost = 0;
    this.quantity = 0;
    this.rate = 0;
    this.realIncome = 0;
    this.sales = 0;
    this.sido = '';
    this.totalLaborCosts = 0;
    this.unitPrice = 0;
    this.waterHeatingCost = 0;
  }
}

export interface ICallDataType {
  SGARate: number;
  avgInfraCost: number;
  avgOperationCost: number;
  daySales: number;
  dsr: number;
  facilityCostRate: number;
  farmEquipmentCostRate: number;
  farmMachineryCostRate: number;
  inRate: number;
  infraCost: number;
  infraCostRate: number;
  irr: number;
  laborCostRate: number;
  landPurchaseCostRate: number;
  landRentalCostRate: number;
  loanFund: number;
  loanFundRate: number;
  loanInfo: any;
  materialCostRate: number;
  monthSales: number;
  operationCost: number;
  operationCostRate: number;
  orchardCostRate: number;
  ownMoney: number;
  ownMoneyRate: number;
  realIncome: number;
  realIncomeRate: number;
  totalInterate: number;
  waterHeatingCostRate: number;
  yearSales: number;
}
