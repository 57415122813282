import styled from 'styled-components';
import SearchService from '../../../service/Search';
import { useNavigate } from 'react-router-dom';
import { useMapContext } from '../../../contexts/MapContext';
import { useEffect, useState } from 'react';
import { getZoomLevelByPNU } from './ZoomLevel';

interface NewsearchResultProps {
  data: { address: string; code: string; type: string }[];
  inputValue: string;
  isDetail?: boolean;
}
const NewsearchResult = ({
  data,
  inputValue,
  isDetail,
}: NewsearchResultProps) => {
  const nav = useNavigate();
  const [searchList, setSearchList] = useState(data || []);
  const [offset, setOffset] = useState(5);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const {
    setLatLng,
    setZoomLevel,
    setGeomData,
    setIs_exist,
    setPolygonCenter,
  } = useMapContext();

  useEffect(() => {
    setSearchList(data);
  }, [data]);

  const fetchSearchResults = async () => {
    try {
      const newResults = await SearchService.searchTerm(
        inputValue,
        '5',
        offset.toString(),
      );
      if (newResults.length > 0) {
        setSearchList((prevList) => [...prevList, ...newResults]);
      } else {
        setShowMoreButton(false);
      }
    } catch (error) {
      console.error('Error loading more results:', error);
    }
  };

  const getCoord = async (pnu: string) => {
    try {
      const res = await SearchService.coord(pnu);
      if (res.length > 0) {
        sessionStorage.setItem('direct', 'Y');
        setLatLng({ lat: res[0].lat, lng: res[0].lng });
        setGeomData(res[0].geom);
        setPolygonCenter([res[0].lat, res[0].lng]);
        setIs_exist(res[0].is_exist);
        setZoomLevel(getZoomLevelByPNU(pnu));
        nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const LogSearch = async (address: string, code: string) => {
    try {
      const data = {
        address: address,
        code: code,
      };
      const res = await SearchService.saveSearchKeyword(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + 5);
    fetchSearchResults();
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <Container isDetail={isDetail}>
      <List isDetail={isDetail} onMouseDown={handleMouseDown}>
        {searchList.map((item, index) => (
          <ListItem
            key={index}
            onClick={() => {
              getCoord(item.code);
              LogSearch(item.address, item.code);
            }}
          >
            <img src="/asset/images/icon/location-gray.svg" alt="" />
            <span>{item.address}</span>
          </ListItem>
        ))}
        {showMoreButton && (
          <MoreButton onClick={handleLoadMore}>더보기</MoreButton>
        )}
      </List>
    </Container>
  );
};

export default NewsearchResult;

const Container = styled.div<{ isDetail?: boolean }>`
  width: 100%;
  max-width: 720px;
  background-color: ${({ isDetail }) => (isDetail ? 'none' : '#f5f5f5')};
  padding: ${({ isDetail }) => (isDetail ? '0 16px 8px' : '24px 16px')};
  margin-top: ${({ isDetail }) => (isDetail ? '80px' : '56px')};
  height: ${({ isDetail }) => (isDetail ? 'auto' : '100dvh')};
  max-height: ${({ isDetail }) => (isDetail ? '70vh' : 'auto')};
  overflow-y: auto;
  position: ${({ isDetail }) => (isDetail ? 'fixed' : 'relative')};
  z-index: ${({ isDetail }) => (isDetail ? 201 : 0)};
`;

const List = styled.div<{ isDetail?: boolean }>`
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  box-shadow: ${({ isDetail }) =>
    isDetail ? '0px 4px 12px rgba(0, 0, 0, 0.08)' : 'none'};
`;
const ListItem = styled.div`
  width: 100%;
  padding: 8px 0 16px 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222222;
  cursor: pointer;
`;

const MoreButton = styled.div`
  width: 100%;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #616161;
  line-height: 20px;
  cursor: pointer;
`;
