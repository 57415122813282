import { useEffect, useState } from 'react';
import './ReportDetail.css';
import { ZModelDataProps } from '../../data';

interface TableProps {
  th: Array<string | number | undefined>;
  tb: Array<Array<string | number | undefined>>;
}
interface BoxTableProps {
  table: TableProps;
  right?: boolean;
  right2?: boolean;
  right3?: boolean;
}

const ZModel = ({ data, type }: { data: ZModelDataProps; type: string }) => {
  const [kmTab, setKmTab] = useState(0);
  const [pyeong, setPyeong] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const [estimatedPrice, setEstimatedPrice] = useState({
    list_1000: [{ avgPrice: 0 }],

    list_2000: [{ avgPrice: 0 }],

    list_3000: [{ avgPrice: 0 }],
  });

  useEffect(() => {
    console.log(kmTab);
    if (kmTab === 1) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_1000[0].avgPrice).toLocaleString(),
      );
    } else if (kmTab === 2) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_2000[0].avgPrice).toLocaleString(),
      );
    } else if (kmTab == 3) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_3000[0].avgPrice).toLocaleString(),
      );
    }
  }, [kmTab]);

  const tableData = {
    th: ['거리', '1년 이내', '2년 이내'],
    tb: [
      [
        '1km 이내',
        data.distance1Year1 &&
        Math.round(data.distance1Year1.pricePerPyeong) > 0
          ? pyeong
            ? Math.round(data.distance1Year1.pricePerPyeong).toLocaleString() +
              '원/평'
            : Math.round(data.distance1Year1.pricePerMeter).toLocaleString() +
              '원/㎡'
          : '실거래 없음',
        data.distance1Year2 &&
        Math.round(data.distance1Year2.pricePerPyeong) > 0
          ? pyeong
            ? Math.round(data.distance1Year2.pricePerPyeong).toLocaleString() +
              '원/평'
            : Math.round(data.distance1Year2.pricePerMeter).toLocaleString() +
              '원/㎡'
          : '실거래 없음',
      ],
      [
        '2km 이내',
        data.distance2Year1 &&
        Math.round(data.distance2Year1.pricePerPyeong) > 0
          ? pyeong
            ? Math.round(data.distance2Year1.pricePerPyeong).toLocaleString() +
              '원/평'
            : Math.round(data.distance2Year1.pricePerMeter).toLocaleString() +
              '원/㎡'
          : '실거래 없음',
        data.distance2Year2 &&
        Math.round(data.distance2Year2.pricePerPyeong) > 0
          ? pyeong
            ? Math.round(data.distance2Year2.pricePerPyeong).toLocaleString() +
              '원/평'
            : Math.round(data.distance2Year2.pricePerMeter).toLocaleString() +
              '원/㎡'
          : '실거래 없음',
      ],
    ],
  };

  //url을 변경해서 언마운트 했을 때
  useEffect(() => {
    sessionStorage.setItem('kmTab', '1');
    sessionStorage.setItem(
      'kmPrice',
      Math.round(estimatedPrice.list_1000[0].avgPrice).toLocaleString(),
    );
    sessionStorage.setItem('priceReport', 'open');

    const handleBeforeUnload = () => {
      sessionStorage.removeItem('kmTab');
      sessionStorage.removeItem('kmPrice');
      sessionStorage.removeItem('priceReport');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // 컴포넌트 언마운트 되었을때 remove
  useEffect(() => {
    // 컴포넌트가 마운트될 때 실행되는 부분

    // 클린업 함수
    return () => {
      // 컴포넌트가 언마운트될 때 실행되는 부분
      sessionStorage.removeItem('kmTab');
      sessionStorage.removeItem('kmPrice');
      sessionStorage.removeItem('priceReport');
    };
  }, []);
  // 나머지 컴포넌트 로직

  const BoxTableSelected: React.FC<BoxTableProps> = ({
    table,
    right,
    right2,
    right3,
  }) => {
    return (
      <table
        className="box-table box-table-center"
        style={{ borderCollapse: 'collapse' }}
      >
        <thead>
          <tr>
            {table.th.map((el, i) => (
              <th key={i}>{el}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.tb.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    textWrap: 'nowrap',
                    color: cellIndex === 0 ? '#171e268f' : '#171E26',
                    textAlign: cellIndex !== 0 ? 'right' : 'center',
                    padding: cellIndex !== 0 ? '0 16px 0 0' : '0 16px',
                    fontFamily: 'SpoqaHanSansNeoMedium',
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <>
        <div className="tota">
          <div className="title">트랜스파머 Z 모델</div>
          <div className="flex-row gap-8">
            {type === 'L' && (
              <div className="flex-row gap-2 flex-center">
                <div
                  className="w-8 h-8 border-radius-999"
                  style={{ backgroundColor: '#E2963E' }}
                />
                <div className="m-c2-11-m color-dark-secondary">농지 은행</div>
              </div>
            )}
            <div className="flex-row gap-2 flex-center">
              <div
                className="w-8 h-8 border-radius-999"
                style={{ backgroundColor: '#1BADC2' }}
              />
              <div className="m-c2-11-m color-dark-secondary">경매</div>
            </div>
            <div className="flex-row gap-2 flex-center">
              <div
                className="w-8 h-8 border-radius-999"
                style={{ backgroundColor: '#EA5B54' }}
              />
              <div className="m-c2-11-m color-dark-secondary">공매</div>
            </div>
            <div className="flex-row gap-2 flex-center">
              <div
                className="w-8 h-8 border-radius-999"
                style={{ backgroundColor: '#47C633' }}
              />
              <div className="m-c2-11-m color-dark-secondary">실거래가</div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            position: 'relative',
          }}
        >
          <div className="frame-2609241">
            <div className="mask-group">
              {/* <ScatterChart
                    width={350}
                    height={350}
                    margin={{
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray='0' />
                    <XAxis dataKey='x' type='number' name='stature' />
                    <YAxis dataKey='y' type='number' name='weight' />
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                    <Legend
                      verticalAlign='top'
                      height={30}
                      iconSize={7}
                      align='right'
                    />
                    <Scatter name='농지은행' data={data} fill='#E2963E' />
                    <Scatter name='경매' data={data} fill='#1BADC2' />
                    <Scatter name='공매' data={data} fill='#EA5B54' />
                    <Scatter name='실거래가' data={data} fill='##47C633' />
                  </ScatterChart> */}

              <div className="frame-2609226 relative">
                <div className="frame-2609224">
                  <div className="frame-2609205">
                    <div className="text">근거리</div>
                    <div className="text2">1km</div>
                    <div className="text">원거리</div>
                  </div>
                </div>
                <div className="frame-2609204 ">
                  <div className="frame-2609203">
                    <div className="rectangle-2948"></div>
                    <div className="rectangle-2949"></div>
                  </div>
                  <div className="frame-2609237">
                    <div className="rectangle-2948"></div>
                    <div className="rectangle-2949"></div>
                  </div>
                </div>
                <>
                  {data.list &&
                    data.list.map((item: any, index: number) => {
                      const temp = 130 * (item.distance / 1000);
                      const year = parseInt(item.date?.split('-')[0]);
                      const month = parseInt(item.date?.split('-')[1]);
                      const day = parseInt(item.date?.split('-')[2]);
                      const specificDate = new Date(year, month - 1, day);
                      const currentDate = new Date();
                      const timeDiff =
                        currentDate.getTime() - specificDate.getTime();
                      const daysDiff = timeDiff / (1000 * 3600 * 24);
                      const temp2 = 45 + 137 * (daysDiff / 365); //45는 초기 left값 137은 중간값으로 양쪽 =,- 비율로 계산
                      return (
                        <>
                          <div
                            className="absolute z-999"
                            onMouseEnter={() => {
                              setHoverIndex(index);
                            }}
                            onMouseLeave={() => {
                              setHoverIndex(-1);
                            }}
                            style={{
                              left: temp2 + `px`,
                              // left: "182px", //중앙값
                              top: temp + `px`,
                              width: '8px',
                              height: '8px',
                              borderRadius: '999px',
                              backgroundColor:
                                item.type === 'realDealPrice'
                                  ? '#47C633'
                                  : item.type === 'auction'
                                    ? '#1BADC2'
                                    : item.type === 'pAuction'
                                      ? '#EA5B54'
                                      : item.type === 'farmLandBank'
                                        ? '#E2963E'
                                        : '',
                            }}
                          />
                          {index === hoverIndex && (
                            <div
                              className="absolute bg-primary-100 border-radius-8 padding-8 z-9999 border-171E26"
                              style={{
                                // left: `${temp2 + 20}px`,
                                left:
                                  temp2 > 182
                                    ? `${temp2 - 160}px`
                                    : `${temp2 + 20}px`,
                                top: `${temp}px`,
                              }}
                            >
                              <div className="m-b2-14-m no-wrap">
                                {pyeong
                                  ? `평당가 : ${Math.round(item.perPyeongPrice).toLocaleString()}원/평`
                                  : `㎡당가 : ${Math.round(item.price).toLocaleString()}원/㎡`}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </>
              </div>
              <div className="frame-2609225">
                <div className="frame-2609206">
                  <div className="text">최근</div>
                  <div className="text2">1년</div>
                  <div className="text">과거</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="tota w-100per">
          <div className="flex-row gap-10 space-between w-100per">
            <div className="title" style={{ fontSize: '14px' }}>
              거리당 {type === 'L' ? '토지' : '주택'}단가
            </div>
            <div
              onClick={() => {
                setPyeong(!pyeong);
              }}
              className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row  border-1-e2e2e2 font-medium f-size-14 line-h-20 color-text-primary"
              style={{ borderRadius: '4px', gap: '4px' }}
            >
              <img src="/asset/images/icon/rotation.svg" alt="" />
              {pyeong ? '㎡' : '평'}
            </div>
          </div>
        </div>
        <div className="frame-26083552" style={{ maxHeight: '168px' }}>
          <BoxTableSelected table={tableData} />
        </div>
        <div className="frame-2609057"></div>
      </>
    </div>
  );
};

export default ZModel;
