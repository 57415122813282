export const targetOption = ['전체', '토지', '주택'];

export const saleTypeOption = [
  '전체',
  '매물',
  '지자체매물',
  '경매',
  '공매',
  '인터넷매물',
];

export const categoryOption = ['전체', '전', '답', '과수', '임야', '대'];

export const purposeOption = [
  '전체',
  '주거',
  '관리',
  '농림',
  '자연환경보전',
  '상업',
  '공업',
  '보전관리',
  '생산관리',
  '계획관리',
  '개발제한',
  '자연녹지',
  '보전녹지',
  '생산녹지',
];

export const priceOption = [
  { label: '최소', value: '' },
  { label: '5백만', value: '5000000' },
  { label: '1천만', value: '10000000' },
  { label: '2천만', value: '20000000' },
  { label: '5천만', value: '50000000' },
  { label: '1억', value: '100000000' },
  { label: '1억5천', value: '150000000' },
  { label: '2억', value: '200000000' },
  { label: '5억', value: '500000000' },
  { label: '10억', value: '1000000000' },
  { label: '20억', value: '2000000000' },
  { label: '20억~', value: '' },
];

export const areaOption = [
  { label: '최소', value: '' },
  { label: '30', value: '30' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '200', value: '200' },
  { label: '500', value: '500' },
  { label: '1000', value: '1000' },
  { label: '2000', value: '2000' },
  { label: '3000', value: '3000' },
  { label: '4000', value: '4000' },
  { label: '5000', value: '5000' },
  { label: '5000~', value: '' },
];

export const yearOption = [
  { label: '신축', value: '' },
  { label: '1년', value: '1' },
  { label: '2년', value: '2' },
  { label: '3년', value: '3' },
  { label: '5년', value: '5' },
  { label: '10년', value: '10' },
  { label: '15년', value: '15' },
  { label: '20년', value: '20' },
  { label: '30년', value: '30' },
  { label: '50년', value: '50' },
  { label: '100년', value: '100' },
  { label: '100년~', value: '' },
];

export const themeOption = {
  공통: [
    { icon: 'thunder', text: '빨리 팔릴 것 같아요' },
    { icon: 'fire', text: '지역 실거래가 활발해요' },
    { icon: 'rising', text: '공시지가 3년째 상승 중' },
  ],

  농지: [
    { icon: 'carrot', text: '주말농장 추천해요' },
    { icon: 'truck', text: '농산물 유통에 유리해요' },
    { icon: 'farmer', text: '전통시장에 가까워요' },
    { icon: 'highway', text: '고속도로와 가까워요' },
    { icon: 'apple', text: '로컬푸드 매장과 가까워요' },
  ],

  주택: [
    { icon: 'house', text: '귀촌을 생각하신다면?' },
    { icon: 'school', text: '학교와 가까워요' },
    { icon: 'transport', text: '대중교통이 가까워요' },
    { icon: 'hospital', text: '의료시설과 가까워요' },
    { icon: 'ocean', text: '바다가 보이는 전원주택' },
  ],
};

export const landOption = [
  { icon: 'thunder', text: '빨리 팔릴 것 같아요' },
  { icon: 'fire', text: '지역 실거래가 활발해요' },
  { icon: 'rising', text: '공시지가 3년째 상승 중' },
  { icon: 'carrot', text: '주말농장 추천해요' },
  { icon: 'truck', text: '농산물 유통에 유리해요' },
  { icon: 'farmer', text: '전통시장에 가까워요' },
  { icon: 'highway', text: '고속도로와 가까워요' },
  { icon: 'apple', text: '로컬푸드 매장과 가까워요' },
];
export const houseOption = [
  { icon: 'thunder', text: '빨리 팔릴 것 같아요' },
  { icon: 'fire', text: '지역 실거래가 활발해요' },
  { icon: 'rising', text: '공시지가 3년째 상승 중' },
  { icon: 'house', text: '귀촌을 생각하신다면?' },
  { icon: 'school', text: '학교와 가까워요' },
  { icon: 'transport', text: '대중교통이 가까워요' },
  { icon: 'hospital', text: '의료시설과 가까워요' },
  { icon: 'ocean', text: '바다가 보이는 전원주택' },
];

export const tagOtion = [
  { icon: 'thunder', text: '빨리 팔릴 것 같아요' },
  { icon: 'fire', text: '지역 실거래가 활발해요' },
  { icon: 'rising', text: '공시지가 3년째 상승 중' },
  { icon: 'carrot', text: '주말농장 추천해요' },
  { icon: 'truck', text: '농산물 유통에 유리해요' },
  { icon: 'farmer', text: '전통시장에 가까워요' },
  { icon: 'highway', text: '고속도로와 가까워요' },
  { icon: 'apple', text: '로컬푸드 매장과 가까워요' },
  { icon: 'house', text: '귀촌을 생각하신다면?' },
  { icon: 'school', text: '학교와 가까워요' },
  { icon: 'transport', text: '대중교통이 가까워요' },
  { icon: 'hospital', text: '의료시설과 가까워요' },
  { icon: 'ocean', text: '바다가 보이는 전원주택' },
];
