import { useState } from 'react';
import styled from 'styled-components';
import { MobileButton } from '../../components/Button';
import './Mobile.css';
type CostPropType = {
  title: string;
  item: any;
  minusClick: any;
  plusClick: any;
  disabled: boolean;
  value: any;
  onChange: any;
};

export const CostControl = (prop: CostPropType) => {
  return (
    <Container>
      <Title>{prop.title}</Title>
      <Row>
        <DecreaseButton onClick={prop.minusClick}>
          <DecreaseText>-10%</DecreaseText>
        </DecreaseButton>
        <Input
          disabled={prop.disabled}
          type="string"
          placeholder="0"
          value={prop.value}
          onChange={prop.onChange}
        />
        <IncreaseButton>
          <IncreaseText onClick={prop.plusClick}>+10%</IncreaseText>
        </IncreaseButton>
      </Row>
    </Container>
  );
};

export const CropSelecter = ({ value, onChange, cropList, off }: any) => {
  const [crop, setCrop]: any = useState(value);
  const [index, setIndex]: any = useState();
  return window.innerWidth < 1024 ? (
    <div
      className="fixed z-100 flex-column end-x"
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000070',
        display: 'flex',
        flex: 1,
      }}
      onClick={off}
    >
      <div
        className="bg-FFFFFF"
        style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          // maxWidth: '720px',
          // margin: 'auto',
          // marginBottom: '0',
        }}
        onClick={(e) => e.stopPropagation()} // 내부 클릭 시 이벤트 전파 막기
      >
        <div className="flex-column border-b-1-171E2610 gap-16 padding-b-24">
          <div className="padding-24-16-4">
            <div className="flex-row space-between">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                작물선택
              </div>
              <img
                src="/asset/images/close/CloseBlack24.svg"
                className="hover"
                onClick={off}
                alt=""
              />
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              원하는 작물 1종류를 선택해주세요
            </div>
          </div>
          <div className="flex-row padding-x-16">
            <div
              className=""
              style={{
                borderRadius: '15px',
                padding: '5px 12px',
                background: true ? '#2A3746' : '',
                border: true ? '' : '1px solid #171e2640',
              }}
            >
              <div
                className="font-bold f-size-14 line-h-20"
                style={{ color: true ? '#FFFFFF' : '#171e2656' }}
              >
                추천 외 작물 전체
              </div>
            </div>
          </div>
        </div>
        <div className="of-y-auto">
          <div
            className="padding-x-22"
            style={{ maxHeight: `${window.innerHeight - 100 - 230}px` }}
          >
            <div className="flex-column gap-16 center-y border-b-1-171E2610 padding-y-24">
              {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                식량작물
              </div> */}
              <div className="flex-row flex-wrap start-x w-100per gap-8">
                {cropList.map((el: any, i: any) => {
                  return (
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      onClick={() => {
                        setCrop(el);
                        setIndex(i);
                      }}
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border:
                              crop && crop.name === el.name
                                ? '2px solid #1E9B6B'
                                : '1px solid #171e2610',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${el.img}`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>
                        {crop && crop.name === el.name ? (
                          <img
                            src="/asset/images/check/Check24GreenCircle.svg"
                            alt=""
                            style={{
                              position: 'absolute',
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color:
                            crop && crop.name === el.name
                              ? '#1e9b6b'
                              : '#000000',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '16px 16px 30px',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          <ResetButtonBox
            onClick={() => {
              setCrop('');
              setIndex('');
            }}
          >
            <img src="/asset/images/icon/resetButtonBox.svg" />
          </ResetButtonBox>
          <MobileButton
            on={crop}
            description="선택완료"
            onClick={() => {
              onChange(crop, index);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="fixed z-100 flex-column end-x "
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000070',
        display: 'flex',
        flex: 1,
        width: '100%',
        maxWidth: '720px',
        margin: '0 auto',
      }}
      onClick={off}
    >
      <div
        className="bg-FFFFFF"
        style={{
          maxWidth: '720px',
          margin: 'auto',
          marginBottom: '0',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
        onClick={(e) => e.stopPropagation()} // 내부 클릭 시 이벤트 전파 막기
      >
        <div className="flex-column border-b-1-171E2610 gap-16 padding-b-24">
          <div className="padding-24-16-4">
            <div className="flex-row space-between">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                작물선택
              </div>
              <img
                src="/asset/images/close/CloseBlack24.svg"
                className="hover"
                onClick={off}
                alt=""
              />
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              원하는 작물 1종류를 선택해주세요
            </div>
          </div>
          <div className="flex-row padding-x-16">
            <div
              className=""
              style={{
                borderRadius: '15px',
                padding: '5px 12px',
                background: true ? '#2A3746' : '',
                border: true ? '' : '1px solid #171e2640',
              }}
            >
              <div
                className="font-bold f-size-14 line-h-20"
                style={{ color: true ? '#FFFFFF' : '#171e2656' }}
              >
                전체
              </div>
            </div>
          </div>
        </div>
        <div className="of-y-auto">
          <div
            className="padding-x-22"
            // style={{ height: `${window.innerHeight - 100 - 142.8 - 400}px` }}
          >
            <div className="flex-column gap-16 center-y border-b-1-171E2610 padding-y-24">
              {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                식량작물
              </div> */}
              <div className="flex-row flex-wrap start-x w-100per gap-8">
                {cropList.map((el: any, i: any) => {
                  return (
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      onClick={() => {
                        setCrop(el);
                        setIndex(i);
                      }}
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border:
                              crop && crop.name === el.name
                                ? '2px solid #1E9B6B'
                                : '1px solid #171e2610',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${el.img}`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>
                        {crop && crop.name === el.name ? (
                          <img
                            src="/asset/images/check/Check24GreenCircle.svg"
                            alt=""
                            style={{
                              position: 'absolute',
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color:
                            crop && crop.name === el.name
                              ? '#1e9b6b'
                              : '#000000',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '16px 16px 30px',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          <ResetButtonBox
            onClick={() => {
              setCrop('');
              setIndex('');
            }}
          >
            <img src="/asset/images/icon/resetButtonBox.svg" />
          </ResetButtonBox>
          <MobileButton
            on={crop}
            description="선택완료"
            onClick={() => {
              onChange(crop, index);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const dummyCost = [
  { title: '토지 매입비', state: 'landPurchaseCost' },
  { title: '시설 구축비', state: 'facilityCost' },
  { title: '농기계 구입비', state: 'farmMachineryCost' },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px0;
  word-wrap: break-word;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px; /* f-size-14 */
  line-height: 20px; /* line-h-20 */
  color: #666666; /* color-text-secondary */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px; /* border-radius-8 */
  justify-content: space-between;
  border: 1px solid #171e26; /* border-1-171E26 */
`;

const Button = styled.div`
  width: 20%;
  padding: 10px 8px; /* padding-6-8 */

  display: flex; /* flex for centering the text */
  align-items: center;
  justify-content: center;
`;

const DecreaseButton = styled(Button)`
  background-color: #f6f6ff; /* bg-F6F6FF */
  border-top-left-radius: 8px; /* 왼쪽 상단 모서리 */
  border-bottom-left-radius: 8px; /* 왼쪽 하단 모서리 */
`;

const IncreaseButton = styled(Button)`
  background-color: #fff6f6; /* bg-FFF6F6 */
  border-top-right-radius: 8px; /* 오른쪽 상단 모서리 */
  border-bottom-right-radius: 8px; /* 오른쪽 하단 모서리 */
`;

const Input = styled.input`
  padding: 10px 16px; /* padding-6-8 */
  border: none; /* border-none */
  text-align: end; /* text-end */
  font-size: 14px; /* f-size-14 */
  line-height: 24px; /* line-h-24 */
  width: 60%; /* w-50per */
  font-weight: 500; /* font-medium */
`;

const DecreaseText = styled.div`
  color: #4b47fc; /* color-text-decrease */
`;

const IncreaseText = styled(DecreaseText)`
  color: #ec4f4f; /* bg-FFF6F6 */
`;

const ResetButtonBox = styled.div`
  width: 58px;
  height: 50px;
  cursor: pointer;
`;
