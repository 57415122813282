import Select, { SingleValue, components } from 'react-select';
const options = [
  {
    value: '42',
    label: '강원',
  },
  {
    value: '41',
    label: '경기',
  },
  {
    value: '48',
    label: '경남',
  },
  {
    value: '47',
    label: '경북',
  },
  {
    value: '29',
    label: '광주',
  },
  {
    value: '27',
    label: '대구',
  },
  {
    value: '30',
    label: '대전',
  },
  {
    value: '26',
    label: '부산',
  },
  {
    value: '11',
    label: '서울',
  },
  {
    value: '36',
    label: '세종',
  },
  {
    value: '31',
    label: '울산',
  },
  {
    value: '28',
    label: '인천',
  },
  {
    value: '46',
    label: '전남',
  },
  {
    value: '45',
    label: '전북',
  },
  {
    value: '50',
    label: '제주',
  },
  {
    value: '44',
    label: '충남',
  },
  {
    value: '43',
    label: '충북',
  },
];

interface CustomSelectProps {
  onChange: (value: string) => void;
  value: string;
  
}

const RegionSelectBox = ({ onChange, value }: CustomSelectProps) => {
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {props.label}
          {props.isSelected && (
            <img
              src="/asset/images/checkbox/check-green.svg"
              alt="Checked"
              style={{ marginLeft: '8px' }}
            />
          )}
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      options={options}
      value={options.find((option) => option.value === value)} // 선택된 값 설정
      onChange={(
        selectedOption: SingleValue<{ value: string; label: string }>,
      ) => {
        if (selectedOption) onChange(selectedOption.value); // selectedOption이 null이 아닐 때만 onChange 호출
      }}
      isSearchable={false}
      menuPlacement="bottom"
      styles={{
        control: (base, state) => ({
          ...base,

          minWidth: '72px', // 전체 셀렉트 박스 너비
          minHeight: '36px', // 전체 셀렉트 박스 너비
          backgroundColor: '#f7f8fc',
          border: 'none',
          borderRadius: '20px',
          borderColor: state.isFocused ? 'transparent' : 'transparent', // 포커스 상태에서 테두리 색상
          paddingLeft: '0px',
          paddingRight: '0px',
          boxShadow: state.isFocused ? 'none' : 'none', // 포커스 상태에서 그림자 없음
          color: 'red',
          fontSize: '17px',
          fontFamily: 'Pretendard-medium',
          fontWeight: '500',
          lineHeight: '26px',
          wordWrap: 'break-word',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: '0px',
          padding: 0, // 기존 padding 제거
        }),

        valueContainer: (base) => ({
          ...base,
          padding: 0, // 기존 패딩 제거
          margin: 0, // 여백도 제거하고 싶다면 추가
        }),

        singleValue: (provided) => ({
          ...provided,
          height: '24px',
          display: 'flex',
          justifyContent: 'flex-start',
          minWidth: '74px', // 전체 셀렉트 박스 너비
          color: '#616161', // 텍스트 색상
          fontSize: '16px', // 텍스트 크기
          fontFamily: 'Pretendard-medium', // 폰트 패밀리
          fontWeight: '500', // 폰트 두께
          lineHeight: '24px', // 줄 높이
          marginLeft: '0px',
          marginRight: '0px',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: 'none',
          color: state.isSelected ? '#1E9B6B' : '#616161',
          padding: '10px 16px',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'baseline',
          fontSize: '16px',
          fontFamily: 'Pretendard-medium',
          fontWeight: state.isSelected ? '700' : '500',
          lineHeight: '24px',
          wordWrap: 'break-word',
        }),
        menu: (base) => ({
          ...base,
          width: `calc(100% + 24px)`, // 전체 옵션 박스의 최대 너비
          overflowY: 'auto', // 내용이 넘칠 경우 스크롤 가능
          borderRadius: '8px',
          marginLeft: '-15px',
          marginTop: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '8px',
        }),
        indicatorSeparator: () => ({ display: 'none' }), // 화살표 옆의 선 제거
        dropdownIndicator: (base) => ({
          ...base,
          color: '#222222', // 화살표 색상 조정
          display: 'flex', // flex를 추가해 정렬 가능하게 설정
          justifyContent: 'center',
          alignSelf: 'baseline', // 세로 중앙 정렬
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: '192px', // 옵션 리스트 최대 높이
          overflowY: 'auto', // 스크롤 가능
          paddingTop: '0px',
          paddingBottom: '0px',
        }),
      }}
      components={{ Option: CustomOption }}
      // menuIsOpen={true} //드롭다운을 항상 열어두고 싶을 때
    />
  );
};

export default RegionSelectBox;
