import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CropInfo } from './common';
import { CropSelecter } from './component';

interface PropsType {
  facility: string;
  crop: string;
  recommendCrop: CropInfo[];
  setCrop: any;
  anotherCrop: CropInfo[];
  setIndex: any;
  index: number;
}

interface SelectCropContainerProps {
  facility: string;
  anotherCropLength?: number; // anotherCrop가 없을 수 있으니 선택적 타입으로 설정
}

const SelectCrop = ({
  crop,
  setCrop,
  recommendCrop,
  facility,
  anotherCrop,
  setIndex,
  index,
}: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);
  useEffect(() => {
    setIndex(-1);
    setCrop(null);
  }, [anotherCrop, recommendCrop]);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음

    e.preventDefault();

    if (containerRef.current) {
      // clickPoint는 onMouseDown에서 처음 클릭한 위치가 할당된다.
      // walk = 마우스를 끌고간 최종 위치 - 처음 마우스를 클릭한 위치
      // 오른쪽에서 왼쪽으로 끌면 음수이다.
      const walk = e.pageX - clickPoint;

      // walk가 음수, 즉 오른쪽에서 왼쪽으로 끌었을때 scrollLeft - walk는 양수가 되면서
      // containerRef의 scrollLeft가 증가하면서 스크롤이 오른쪽으로 움직인다.
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const [onCropSelecter, setOnCropSelecter] = useState(false);

  return (
    <SelectCropContainer
      facility={facility}
      anotherCropLength={anotherCrop ? anotherCrop.length : undefined}
    >
      {onCropSelecter ? (
        <CropSelecter
          value={crop}
          onChange={(e: any, i: any) => {
            setIndex(i + recommendCrop.length);
            setCrop(e.name);
            setOnCropSelecter(false);
          }}
          off={() => setOnCropSelecter(false)}
          cropList={anotherCrop || []}
        />
      ) : (
        ''
      )}
      <Title>2.작물</Title>
      {/** 시설을 선택하지 않은 경우*/}
      {facility === '' ? (
        <div className="flex-column w-100per gap-16">
          <div className="font-bold f-size-14 line-h-20 color-text-primary">
            AI 추천 작물
          </div>
          <div
            className="flex-column flex-center w-100per h-120 bg-blue-gray-100 border-none border-radius-8"
            style={{
              paddingTop: '24px',
              paddingBottom: '24px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <div className="font-bold f-size-14 line-h-20 color-text-secondary text-center">
              재배 환경을 입력하면 <br />
              AI가 토양 및 기후 데이터를 분석하여
              <br />
              적합 작물을 추천해드려요
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-column gap-20" style={{ width: '100%' }}>
          <div className="flex-column">
            <div className="flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                AI 추천 작물
              </div>
              <div
                className="of-x-auto flex-row gap-16 "
                ref={containerRef}
                onMouseDown={handleMouseDownEvent}
                onMouseLeave={() => setDragging(false)}
                onMouseUp={() => setDragging(false)}
                onMouseMove={handleMouseMoveEvent}
              >
                {recommendCrop.length > 0 ? (
                  recommendCrop.map((item: any, i: number) => {
                    return (
                      <div
                        className="flex-column gap-8 center-y hover center-y"
                        onClick={() => {
                          setIndex(i);
                          setCrop(item.name);
                        }}
                        style={{ width: '100px' }}
                      >
                        <div
                          className="relative"
                          style={{
                            width: '80px',
                            height: '80px',
                          }}
                        >
                          <div
                            className=" border-radius-100per of-hidden center-x center-y"
                            style={{
                              width: '80px',
                              height: '80px',
                              border:
                                crop === item.name
                                  ? '2px solid #1E9B6B'
                                  : '1px solid #171e2610',
                            }}
                          >
                            <img
                              src={`https://back.transfarmer.kr/img/${item.img}`}
                              alt=""
                              style={{ width: '70px', height: '70px' }}
                            />
                          </div>
                          {crop === item.name ? (
                            <img
                              src="/asset/images/check/Check24GreenCircle.svg"
                              alt=""
                              style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className="font-medium f-size-14 line-h-20 text-center"
                          style={{
                            color: crop === item.name ? '#1e9b6b' : '#000000',
                            wordBreak: 'keep-all',
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="flex-center padding-24-16 bg-blue-gray-100 border-radius-8"
                    style={{ width: '100%' }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary text-center">
                      해당 재배 환경에 적합한 추천 작물이 없습니다.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {facility !== '스마트팜' && (
            <div className="flex-column padding-y-16 gap-16">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                추천 외 작물 선택
              </div>
              {(!crop || index < recommendCrop.length) &&
                (anotherCrop && anotherCrop.length === 0 ? (
                  <div
                    className="flex-center padding-24-16 bg-blue-gray-100 border-radius-8"
                    style={{ width: '100%' }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary text-center">
                      해당 재배 환경에 적합한 추천 작물이 없습니다.
                    </div>
                  </div>
                ) : (
                  <div
                    className="gap-8 center-y flex-column w-100per bg-primary-100 border-radius-8 padding-y-24 hover"
                    onClick={() => setOnCropSelecter(true)}
                  >
                    <img src="/asset/images/plus/plus38.svg" alt="" />
                    <div className="font-bold f-size-14 line-h-20 color-primary-600">
                      원하는 작물을 선택해보세요
                    </div>
                  </div>
                ))}
              {crop &&
                index - recommendCrop.length >= 0 &&
                anotherCrop[index - recommendCrop.length] &&
                (anotherCrop[index - recommendCrop.length] as any).img && (
                  <div className="flex-row start-y">
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border: '2px solid #1E9B6B',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${
                              (anotherCrop[index - recommendCrop.length] as any)
                                .img
                            }`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>

                        <img
                          src="/asset/images/check/Check24GreenCircle.svg"
                          alt=""
                          style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color: '#1e9b6b',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {crop}
                      </div>
                    </div>
                    <img
                      src="/asset/images/close/Close28Ring.svg"
                      alt=""
                      className="hover"
                      onClick={() => {
                        setIndex(0);
                        setCrop(null);
                      }}
                    />
                  </div>
                )}
              {/* <div
                ref={temp}
                className={`flex-row gap-8 flex-wrap max-h-90 overflow-hidden ${
                  more ? `max-h-524` : ``
                }`}
              >
                {(anotherCrop || []).length > 0 &&
                  anotherCrop.map((item: any, i: number) => {
                    let tempIndex = i + recommendCrop.length;
                    return (
                      <div
                        className={`flex-column border-166F4D-2px center-y padding-5-10-10-10 border-radius-6 gap-10 no-wrap flex-center pointer ${
                          crop === item.name ? `bg-primary-600` : `bg-white`
                        }`}
                        onClick={() => {
                          setIndex(i + recommendCrop.length);
                          setCrop(item.name);
                        }}
                      >
                        <div
                          className={`font-medium f-size-14 line-h-20 ${
                            crop === item.name
                              ? `color-white`
                              : `color-primary-600`
                          }`}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
              </div> */}

              {/* <div className="w-100per flex-center">
                <div
                  className="bg-light-gray-200 border-radius-100 flex-center w-24 h-24 pointer"
                  onClick={() => {
                    setMore(!more);
                  }}
                >
                  <img src={more ? arrowUp : arrowDown} />
                </div>
              </div> */}
            </div>
          )}
        </div>
      )}
    </SelectCropContainer>
  );
};

const SelectCropContainer = styled.div<SelectCropContainerProps>`
  width: 100%;
  min-height: 230px;
  height: ${(props) =>
    props.facility === '' || props.facility === '스마트팜'
      ? '230px'
      : props.anotherCropLength && props.anotherCropLength !== 0
        ? '408px'
        : 'auto'};
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background-color: white;

  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const Title = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const AIRecommend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AIRecommendContent = styled.div`
  width: 100%;
  height: 108px;
  padding: 24px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  border-radius: 8px;
  background-color: #eff2f5;
`;

const EtcRecommend = styled.div`
  width: 100%;
  height: 144px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`;

const SelectCropBox = styled.div`
  width: 100%;
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1e9b6b;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  gap: 8px;
  background-color: #eaf8f2;
  border-radius: 8px;
  cursor: pointer;
`;

export default SelectCrop;
