import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLogin } from '../../../utilities/auth';

import { useMapContext } from '../../../contexts/MapContext';

const BottomNavigation = () => {
  const [page, setPage] = useState('');
  const [imageToggle, setImageToggle] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { latLng } = useMapContext();

  useEffect(() => {
    setPage(location.pathname);
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageToggle((prev) => !prev);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <BottomNavBarContainer>
      <BottomNavBtn
        active={page === '/' ? true : false}
        onClick={() => {
          navigate(`/?lat=${latLng?.lat}&lng=${latLng?.lng}`);
        }}
      >
        <img
          src={`/asset/images/icon/${page === '/' ? 'findOn' : 'findOff'}.svg`}
          alt=""
        />
        <span>찾아줘</span>
      </BottomNavBtn>
      <BottomNavBtn
        active={page === '/property' ? true : false}
        onClick={() => {
          navigate('/property');
        }}
      >
        <img
          src={`/asset/images/icon/${page === '/property' ? 'saleOn' : 'saleOff'}.svg`}
          alt=""
        />
        <span>전국매물</span>
      </BottomNavBtn>
      <BottomNavBtn
        active={page === '/mychon' ? true : false}
        onClick={() => {
          isLogin() ? navigate('/mychon') : navigate('/login');
        }}
      >
        <img
          src={`/asset/images/icon/${page === '/mychon' ? 'myteraOn' : 'myteraOff'}.svg`}
          alt=""
        />
        <span>마이촌</span>
      </BottomNavBtn>
      <BottomNavBtn
        active={page === '/brokerage' ? true : false}
        onClick={() => {
          navigate('/brokerage');
        }}
      >
        <TagImg
          src={
            imageToggle
              ? '/asset/images/sellTag.svg'
              : '/asset/images/findTag.svg'
          }
          alt="Tag"
        />
        <img
          src={`/asset/images/icon/${page === '/brokerage' ? 'brokerageOn' : 'brokerageOff'}.svg`}
          alt=""
        />
        <span>맞춤중개</span>
      </BottomNavBtn>
      <BottomNavBtn
        active={page === '/more' ? true : false}
        onClick={() => {
          navigate('/more');
        }}
      >
        <img
          src={`/asset/images/icon/${page === '/more' ? 'moreOn' : 'moreOff'}.svg`}
          alt=""
        />
        <span>더보기</span>
      </BottomNavBtn>
    </BottomNavBarContainer>
  );
};
export default BottomNavigation;

interface BottomNavBtnProps {
  active: boolean;
}

const BottomNavBarContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 101;
  background-color: white;
  display: flex;
  border-top: 1px solid ${colors.lineNormal};
  background-color: white;
  max-width: inherit;
`;

const BottomNavBtn = styled.div<BottomNavBtnProps>`
  flex: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard-Medium';
  font-size: 11px;
  line-height: 12px;
  color: ${({ active }) =>
    active ? colors.primary500 : colors.labelAlternative};
  cursor: pointer;
  position: relative;
`;

const TagImg = styled.img`
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
`;
