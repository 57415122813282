import './Info.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Footer from '../../component/AIIntroduction/Footer';
// import FooterBrowser from '../../component/browser/Footer';
import { Carousel2 } from './Carousel2';
import { Carousel } from './Carousel';
import { Contents } from './Contents';
import { History } from './History';
import styled from 'styled-components';
import colors from '../../color';
import { useNavigate } from 'react-router-dom';

const CompanyInfo = () => {
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);
  const [myScroll, setMyScroll] = useState(0);
  const [isAnimated, setIsAnimated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setMyScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setInitialHeight(window.innerHeight);
    setInitialWidth(window.innerWidth);
    // console.log(
    //   'innerwidth',
    //   window.innerWidth,
    //   'innerHeight',
    //   window.innerHeight,
    // );
  }, []);

  const [hasScrolled, setHasScrolled] = useState(false);

  const imageStyle = useMemo(() => {
    return {
      filter: hasScrolled ? 'brightness(60%)' : 'brightness(100%)',
      borderRadius: hasScrolled ? '0' : '16px',
      width: hasScrolled ? '100%' : '100%',
      padding: hasScrolled ? '0px' : '0px 16px',
      height: hasScrolled ? '100vh' : '',
      transform: hasScrolled ? 'scale(1.2)' : 'scale(1)',
      transition: hasScrolled
        ? 'filter 1s, border-radius 1s, transform 1s'
        : '',
    };
  }, [hasScrolled]);
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        // 첫 스크롤 이벤트가 발생했을 때 실행할 코드
        console.log('첫 스크롤 이벤트 발생!');
        const scrollPosition = window.scrollY;
        if (!isAnimated && scrollPosition > 1079) {
          setIsAnimated(true);
        }
        // 필요한 처리를 추가하고, hasScrolled를 업데이트하여 더 이상 이벤트가 실행되지 않도록 합니다.
        setHasScrolled(true);

        // 1초 동안 다른 스크롤 이벤트를 무시하도록 setTimeout 사용
      }
    };

    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  // useEffect(() => {
  //   console.log('myscroll', myScroll);
  // });

  return (
    <div
      style={{
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '720px',
        overflowY: 'auto',
      }}
    >
      <PageTopContainer>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <PageTopTitle>회사소개</PageTopTitle>
      </PageTopContainer>
      <div
        ref={ref2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '48px',
          position: 'relative',
          overflow: 'hidden',
          padding: hasScrolled ? '' : '130px 16px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
            position: hasScrolled ? 'absolute' : 'relative',
            zIndex: hasScrolled ? 10 : 0,
            top: hasScrolled ? `${window.innerHeight / 2 - 100}px` : '',
            // transition: "top 1s",
            transform: myScroll ? `translateY(50px)` : 'translateY(0)',
            // opacity: myScroll ? 1 : 0,
            transition: 'opacity 1s ease-out, transform 1s ease-out',
          }}
        >
          <div
            style={{
              fontFamily: 'Pretendard-Bold',
              fontSize: '20px',
              lineHeight: '29px',
              color: hasScrolled ? `#FFFFFF` : `#171E26`,
            }}
          >
            트랜스파머는
          </div>
          <div
            style={{
              fontFamily: 'Pretendard-Medium',
              fontSize: '18px',
              lineHeight: '26px',
              textAlign: 'center',
              color: hasScrolled ? `#FFFFFF` : `#171E26`,
            }}
          >
            AI 가이드로 농업의 문턱을 낮추고 <br /> 누구나 참여할 수 있는 농촌을
            만들어갑니다.
          </div>
        </div>
        <img
          style={imageStyle}
          src="/asset/images/profile/profile_img_1.png"
          alt=""
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            padding: '56px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '48px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {myScroll > 167 ? (
            <div
              className={`${myScroll > 167 && `fade-in-up`}`}
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: '18px',
                lineHeight: '29px',
                color: '#171E26',
              }}
              ref={ref3}
            >
              농촌경제 DX 플랫폼 트랜스파머
            </div>
          ) : (
            <div
              style={{
                height: '300px',
              }}
            >
              &nbsp;
            </div>
          )}
          {myScroll > 312 ? (
            <Carousel scroll={myScroll} />
          ) : (
            <div
              style={{
                height: '400px',
              }}
            >
              &nbsp;
            </div>
          )}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '56px 0',
            gap: '48px',
            backgroundColor: '#f7f8f9',
          }}
        >
          {myScroll > 811 && (
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: '18px',
                lineHeight: '26px',
                color: '#171E26',
              }}
            >
              똑똑한 농촌생활을 위한 AI 활용 가이드
            </div>
          )}
          <Contents myScroll={myScroll} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '56px 16px',
        }}
      >
        <div
          style={{
            fontFamily: 'Pretendard-Bold',
            fontSize: '18px',
            lineHeight: '26px',
            color: '#171E26',
          }}
        >
          Team Leader
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {myScroll > 3439 && <Carousel2 scroll={myScroll} />}
        </div>
      </div>
      <History scroll={myScroll} />
    </div>
  );
};

export default CompanyInfo;

export const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
`;

export const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
