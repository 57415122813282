import { useState } from 'react';
import { CropInfo } from './common';
import { CostControl } from './component';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface LandInfoType {
  area: number;
  avg: number;
  category: string;
  estimatedPrice: number;
  estimatedRentalPrice: number;
  officialPrice: any;
  perMeterPrice: number;
  perPyeongPrice: number;
  pnuCode: string;
  pyeong: number;
  tranApplyAt: null | string;
  transaction: null | string;
  warnning: string;
  zoning: string;
}

const InitialInvestment = ({
  selectedCrop,
  setSelectedCrop,
  landPrice,
}: {
  selectedCrop: CropInfo;
  setSelectedCrop: any;
  landPrice: number;
}) => {
  const locationHook = useLocation();
  const [purchaseType, setPurchaseType] = useState('P');
  const dummyCost = [
    { title: '토지 매입비', state: 'landPurchaseCost' },
    { title: '시설 구축비', state: 'facilityCost' },
    { title: '농기계 구입비', state: 'farmMachineryCost' },
  ];

  const calculateTotalCost = () => {
    let total = 0;
    dummyCost.forEach((item) => {
      if (item.title !== '토지 매입비' || purchaseType !== 'R') {
        const stateKey: keyof CropInfo = item.state as keyof CropInfo;
        let costToAdd = Number(selectedCrop[stateKey]) || 0;
        if (stateKey === 'landPurchaseCost') {
          costToAdd = costToAdd === 0 ? Number(landPrice) : costToAdd;
        }
        total += costToAdd;
      }
    });
    return total.toLocaleString();
  };

  const SetCostControl = (item: any) => {
    if (!selectedCrop) {
      return (
        <CostControl
          title={item.title}
          item={item}
          value={0}
          minusClick={() => {}}
          disabled={true}
          onChange={() => {}}
          plusClick={() => {}}
        />
      );
    } else {
    }
    const updatedSelectedCrop = {
      ...selectedCrop,
      landPurchaseCost: selectedCrop.landPurchaseCost || Number(landPrice),
      farmMachineryCost: selectedCrop.farmMachineryCost || 0,
    };
    return (
      <CostControl
        title={item.title}
        item={item}
        value={Number(
          updatedSelectedCrop[item.state as 'facility'],
        ).toLocaleString()}
        minusClick={() =>
          setSelectedCrop((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(updatedSelectedCrop[item.state as 'facility']) * 90) /
                  100,
              ),
            };
          })
        }
        disabled={
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/search/report/cost'
        }
        onChange={(e: any) => {
          setSelectedCrop(
            (prev: CropInfo) =>
              ({
                ...prev,
                [item.state]: Number(e.target.value.split(',').join('')),
              }) as CropInfo,
          );
        }}
        plusClick={() =>
          setSelectedCrop((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(updatedSelectedCrop[item.state as 'facility']) * 110) /
                  100,
              ),
            };
          })
        }
      ></CostControl>
    );
  };

  return (
    <InitialInvestmentContainer>
      <InitialInvestmentHeader>4. 초기투자비</InitialInvestmentHeader>
      {dummyCost.map((item) => {
        return SetCostControl(item);
      })}

      <FlexRow>
        <TotalText>총액</TotalText>
        <TotalAmount>{selectedCrop ? calculateTotalCost() : 0}원</TotalAmount>
      </FlexRow>
    </InitialInvestmentContainer>
  );
};

export default InitialInvestment;

const InitialInvestmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  gap: 24px;
  padding: 24px 16px;
  background-color: white;
`;

const InitialInvestmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 24px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 8px; /* padding-top-8 */
`;

const TotalText = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const TotalAmount = styled.div`
  color: #171e26;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;
