export const renderIcon = (category: string) => {
  switch (category) {
    case '전':
      return 'green-sprout';
    case '답':
      return 'green-wheat';
    case '과수원':
      return 'green-apple';
    case '임야':
      return 'green-mountain';
    case '목장지':
      return 'green-cow';
    case '잡종지':
      return 'green-forest';
    case '주택':
      return 'green-building';
    case '대':
      return 'green-land';
    default:
      return 'green-land';
  }
};
