const colors = {
  white: '#FFFFFF',
  lightGray100: '#f7f8f9',
  primary100: '#EAF8F2',
  primary500: '#1E9B6B',
  lineNormal: 'rgba(112, 115, 124, 0.22)',
  lineNeutral: 'rgba(112, 115, 124, 0.16)',
  lineBorder: 'rgba(23, 30, 38, 0.10)',
  labelAssistive: 'rgba(55, 56, 60, 0.28)',
  labelNormal: '#222',
  labelNeutral: '#616161',
  labelAlternative: 'rgba(55, 56, 60, 0.61)',
  labelStrong: '#000',
  labelDisable: 'rgba(55, 56, 60, 0.16)',
  orange: '#FB6400',
  backgroundNeutral: '#F5F5F5',
  interactionNeutral: '#989BA2',
  interactionDisable: '#F7F7F8',
  textPrimary: '#172626',
  textSecondary: 'rgba(23, 30, 38, 0.78)',
  textTetiary: 'rgba(23, 30, 38, 0.56)',
};

export default colors;
