export const utilTransNum = (num: number) => {
    num = Number(num)
    if(num === 0) return '0'
    else if(typeof num !== 'number'){
        return '0'
    }
    else if(num < 10000){
        return (Math.round(num)).toLocaleString()
    }
    else if(num < 100000000){
        return (Math.round(num / 10000)).toLocaleString() + '만'
    }
    else if(num < 1000000000000){
        return ((num / 100000000).toFixed(2)).toLocaleString() + '억'
    }
    else{
        return ((num / 1000000000000).toFixed(2)).toLocaleString() + '조'
    }
}