import './LatestSearchList.css';
import { useNavigate } from 'react-router-dom';
import SearchService from '../../../service/Search';
import { useMapContext } from '../../../contexts/MapContext';
import { getZoomLevelByPNU } from './ZoomLevel';

interface latestSearchItem {
  id: number;
  code: string;
  search_term: string;
}
interface LatestSearchListProps {
  latestSearchItemList: latestSearchItem[];
  func: () => void;
}

const LatestSearchList = ({
  latestSearchItemList,
  func,
}: LatestSearchListProps) => {
  const nav = useNavigate();
  const itemList = latestSearchItemList;
  const { setLatLng, setZoomLevel, setGeomData,setIs_exist,setPolygonCenter } = useMapContext();

  const getCoord = async (pnu: string) => {
    try {
      const res = await SearchService.coord(pnu);
      if (res.length > 0) {
        setLatLng({ lat: res[0].lat, lng: res[0].lng });
        setGeomData(res[0].geom);
        setPolygonCenter([res[0].lat, res[0].lng]);
        setZoomLevel(getZoomLevelByPNU(pnu));

        setIs_exist(res[0].is_exist);
        nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const deleteLog = async (id: number) => {
    try {
      await SearchService.deleteLogItem(id);
      func();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAll = async () => {
    try {
      await SearchService.deleteAllItems();
      func();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="LatestSearchList">
      <div className="LatestSearchList_top_bar">
        <div className="LatestSearchList_top_bar_title">최근 검색</div>
        <div
          onClick={() => {
            deleteAll();
          }}
          className="LatestSearchList_top_bar_delete_text"
        >
          전체삭제
        </div>
      </div>
      <div className="LatestSearchList_content_box_wrapper">
        {itemList.map((item, index) => {
          return (
            <div className="LatestSearchList_content_box">
              <div
                key={index}
                className="LatestSearchList_content_box_text"
                onClick={() => {
                  getCoord(item.code);
                }}
              >
                {item.search_term}
              </div>
              <img
                className="LatestSearchList_content_box_img"
                src="/asset/images/close/close-lightgray.svg"
                alt="close"
                onClick={() => {
                  deleteLog(item.id);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestSearchList;
