import { useNavigate } from 'react-router-dom';
import {
  Container,
  PageTopContainer,
  PageTopTitle,
  NoticeInner,
  NoticeContentBox,
  TopLine,
  NoticeListBox,
  ListItem,
  List,
  NoticeDate,
} from './Notice.styles';
import { useEffect, useState } from 'react';
import Pagination from '../components/pagination/Pagination';
import styled from 'styled-components';
import BoardService from '../../service/board';

interface NoticeItem {
  articleIdx: string;
  title: string;
  createTime: string;
}

const NoticeList = () => {
  const [noticeList, setNoticeList] = useState<NoticeItem[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages = Math.ceil(totalCount / 10);
  const navigate = useNavigate();

  const board = 'A';

  const GetNoticeList = async () => {
    try {
      const res = await BoardService.list(board, limit, offset);
      setNoticeList(res.result.list);
      setTotalCount(res.result.totalCount);
    } catch (error) {
      alert(error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  };

  useEffect(() => {
    GetNoticeList();
  }, [offset]);

  return (
    <Container>
      <PageTopContainer>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
          style={{ cursor: 'pointer' }}
        />
        <PageTopTitle>공지사항</PageTopTitle>
      </PageTopContainer>
      <NoticeInner>
        <NoticeContentBox>
          {noticeList && noticeList.length > 0 && <TopLine></TopLine>}
          {noticeList && noticeList.length > 0 ? (
            <NoticeListBox>
              <List>
                {noticeList.map((item: any) => (
                  <ListItem
                    key={item.articleIdx}
                    onClick={() =>
                      navigate(`/notice/detail/${item.articleIdx}`)
                    }
                  >
                    <span>{item.title}</span>
                    <NoticeDate>{item.createTime}</NoticeDate>
                  </ListItem>
                ))}
              </List>
              {noticeList.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </NoticeListBox>
          ) : (
            <EmptyMessage />
          )}
        </NoticeContentBox>
      </NoticeInner>
    </Container>
  );
};

export default NoticeList;

const EmptyMessage = ({ message }: any) => {
  return (
    <EmptyMessageBox>
      <img src="/asset/images/check/Exclamation-filled.svg" alt="" />
      <div>{message}</div>
    </EmptyMessageBox>
  );
};

const EmptyMessageBox = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 112px 0;
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: #171e26;
  text-align: center;
`;
