import styled from 'styled-components';
import colors from '../../../../color';

interface ListContainerProps {
  showListAll?: boolean;
  smallDevice?: boolean;
  deviceHeight?: number;
  isModalType?: boolean;
}

export const ListContainer = styled.div<ListContainerProps>`
  width: 100%;
  height: ${({ showListAll, smallDevice }) =>
    showListAll ? (smallDevice ? '100%' : 'auto') : '54px'};
  position: ${({ isModalType }) => (isModalType ? 'absolute' : '')};
  left: 0;
  bottom: ${({ showListAll }) => (showListAll ? 0 : '54px')};
  z-index: 50;
  border-radius: ${({ isModalType }) =>
    isModalType ? '20px 20px 0 0' : 'none'};
  overflow: hidden;
  background-color: ${colors.white};
`;
export const ListInner = styled.div`
  position: relative;
`;

export const ListTop = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 10px 0 12px;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNormal};
  border-bottom: 1px solid ${colors.lineNormal};
  cursor: pointer;
`;
export const TextGreen = styled.span`
  color: ${colors.primary500};
`;

export const ListTopBtn = styled.div`
  width: 38px;
  height: 4px;
  border-radius: 100px;
  background-color: ${colors.labelAssistive};
`;
export const ListBox = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const ListFilterBox = styled.div`
  width: 100%;
  height: 44px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const List = styled.div`
  width: 100%;
  padding: 12px 12px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ListCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.lineNormal};
  cursor: pointer;
`;

export const FilterLeft = styled.div`
  display: flex;
  gap: 2px;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNeutral};
  align-items: center;
`;

export const FilterSelect = styled.select`
  border: none;
  color: ${colors.labelNeutral};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

export const CardInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
`;
export const ImageBox = styled.div`
  position: relative;
  width: 114px;
  height: 114px;
  border-radius: 4px;
`;

export const OverRay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.35;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.98) 5.000000074505806%,
    rgba(0, 0, 0, 0.96) 9.000000357627869%,
    rgba(0, 0, 0, 0.93) 12.999999523162842%,
    rgba(0, 0, 0, 0.9) 16.66666716337204%,
    rgba(0, 0, 0, 0.86) 20.000000298023224%,
    rgba(0, 0, 0, 0.82) 23.999999463558197%,
    rgba(0, 0, 0, 0.77) 28.999999165534973%,
    rgba(0, 0, 0, 0.71) 34.00000035762787%,
    rgba(0, 0, 0, 0.65) 40.00000059604645%,
    rgba(0, 0, 0, 0.57) 46.00000083446503%,
    rgba(0, 0, 0, 0.48) 54.00000214576721%,
    rgba(0, 0, 0, 0.38) 62.99999952316284%,
    rgba(0, 0, 0, 0.27) 74.00000095367432%,
    rgba(0, 0, 0, 0.14) 86.00000143051147%,
    rgba(0, 0, 0, 0) 100%
  );
`;
export const SafeImg = styled.img`
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 1;
`;

export const HeartImg = styled.img`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
`;

export const TextBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TypeTextRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
`;
export const TypeTag = styled.span`
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: ${colors.labelAlternative};
`;

export const PriInfoRow = styled.div`
  width: 100%;
`;

export const Price = styled.div`
  font-family: Pretendard-Bold;
  font-size: 18px;
  line-height: 26px;
  color: ${colors.labelStrong};
`;
export const Address = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.labelNormal};
`;

export const Area = styled.div`
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.labelAlternative};
`;

export const TextBlack = styled.span`
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.labelNormal};
  margin-left: 2px;
`;

export const CardTagBox = styled.div`
  width: fit-content;
  display: flex;
  gap: 4px;
  box-sizing: border-box;
`;

export const CardTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: rgba(112, 115, 124, 0.08);
  color: ${colors.labelAlternative};
  font-family: Pretendard-Medium;
  font-size: 14px;
  white-space: nowrap;
`;

export const ExtraCardTag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: rgba(112, 115, 124, 0.08);
  color: ${colors.labelAlternative};
  font-family: Pretendard-Medium;
  font-size: 14px;
  white-space: nowrap;
  width: fit-content;
`;

export const ToMapBtnBox = styled.div`
  position: absolute;
  bottom: 70px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  padding: 8px 12px;
  background-color: rgba(34, 34, 34, 0.86);
  border-radius: 100px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;

const RecommendListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const NearText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #f5f5f5;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;
const RegionText = styled.span`
  color: ${colors.primary500};
  margin-right: 2px;
`;

const TooltipBox = styled.div`
  width: 280px;
  position: absolute;
  top: 32px;
  left: 40px;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

const TooltipText = styled.div`
  flex: 1;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: '#222222';
`;

const NoSafeItem = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
const NoSafeItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222222;
`;

const NoSafeItemDescText = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
`;

export const S = {
  ListContainer,
  ListInner,
  ListTop,
  TextGreen,
  ListTopBtn,
  ListBox,
  ListFilterBox,
  List,
  ListCard,
  FilterLeft,
  FilterSelect,
  CardInfo,
  ImageBox,
  OverRay,
  SafeImg,
  HeartImg,
  TextBox,
  TypeTextRow,
  TypeTag,
  PriInfoRow,
  Price,
  Address,
  Area,
  TextBlack,
  CardTagBox,
  CardTag,
  ExtraCardTag,
  ToMapBtnBox,
  RecommendListBox,
  NearText,
  TooltipBox,
  TooltipText,
  NoSafeItem,
  NoSafeItemText,
  NoSafeItemDescText,
  RegionText,
};
