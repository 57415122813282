import { useNavigate } from 'react-router-dom';
import { PageTopClose } from '../components/pageTop/PageTop';
import { S } from './Signup.styles';
import { RedirectHandler } from '../../controller/Redirect';
const SignUp = () => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <PageTopClose />
      <S.ContentBox>
        <S.PageTitle>
          <span>땅 위의 모든 검색</span>
          <S.Description>
            트랜스파머에서 <br />
            나만의 농촌 생활을 설계하세요!
          </S.Description>
        </S.PageTitle>
        <S.ButtonBox>
          <S.LongButton>
            <S.Img
              src="/asset/images/button/btn_kakao_login.svg"
              alt=""
              onClick={() => {
                RedirectHandler('Kakao');
              }}
            />
            <S.Img
              src="/asset/images/button/btn_naver_login.svg"
              alt=""
              onClick={() => {
                RedirectHandler('Naver');
              }}
            />
          </S.LongButton>
          <S.LineBox>
            <S.Line></S.Line>
            <span>또는</span>
            <S.Line></S.Line>
          </S.LineBox>
          <S.LogoBox>
            <S.Img
              src="/asset/images/logo/facebook.svg"
              alt=""
              onClick={() => {
                RedirectHandler('Facebook');
              }}
            />
            <S.Img
              src="/asset/images/logo/google.svg"
              alt=""
              onClick={() => {
                RedirectHandler('Google');
              }}
            />
          </S.LogoBox>
          <S.EmailButton>
            <S.SignupText
              onClick={() => {
                navigate('/sign/email');
              }}
            >
              이메일 회원가입
            </S.SignupText>
            <S.Divider></S.Divider>
            <S.SignupText
              onClick={() => {
                navigate('/login');
              }}
            >
              이메일 로그인
            </S.SignupText>
          </S.EmailButton>
        </S.ButtonBox>
      </S.ContentBox>
    </S.Container>
  );
};

export default SignUp;
