import { useEffect, useState } from 'react';
import { S } from '../../login/Login.styles';
import cookie from 'react-cookies';
import UserService from '../../../service/Users';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../../service/auth';
import { RedirectHandler } from '../../../controller/Redirect';
import styled from 'styled-components';
import colors from '../../../color';
import SignUpModal from './SignUpModal';

interface LoginModalProps {
  beforeType: string;
  setLoginModalOn: (value: boolean) => void;
  handleTextClick: () => void;
}

const LoginModal = ({
  beforeType,
  setLoginModalOn,
  handleTextClick,
}: LoginModalProps) => {
  const [id, setId] = useState(cookie.load('rememberId') || '');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [rememberChecked, setRememberChecked] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const route = query.get('route');

  const validateEmail = (email: string) => {
    if (email === 'ian') return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getUserInfo = async () => {
    try {
      const res = await UserService.info();
      localStorage.setItem('name', res.result.name);
      localStorage.setItem('point', res.result.point);
      localStorage.setItem('profileImg', res.result.profileImage);
      localStorage.setItem('email', res.result.email);
      localStorage.setItem('phone', res.result.phone);
      localStorage.setItem('category', res.result.category);
      localStorage.setItem('interest_area', res.result.interest_area);
      localStorage.setItem('birth', res.result.birth);
      localStorage.setItem('farm_id', res.result.farm_id);
      localStorage.setItem('loginType', res.result.loginType);
    } catch (error) {
      console.error(error);
    }
  };

  const LoginAPI = async () => {
    const data = {
      userId: id,
      password: password,
    };

    try {
      const res = await AuthService.login(data);

      if (rememberChecked) {
        cookie.save('rememberId', id, { path: '/' });
      } else {
        cookie.remove('rememberId');
      }
      localStorage.setItem('accessToken', res.result.accessToken);
      getUserInfo();
      setLoginModalOn(false);
    } catch (e) {
      console.log(e);
      setLoginError(true);
    }
  };

  useEffect(() => {
    // 초기 렌더링 시 체크
    if (cookie.load('rememberId')) {
      setId(cookie.load('rememberId'));
      setRememberChecked(true);
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 720);
    };
    handleResize();
    // 창 크기 변화에 따른 이벤트 리스너
    window.addEventListener('resize', handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Blur>
      <Container
        isIdValid={isEmailValid}
        isPasswordValid={!loginError}
        isMobile={isMobile}
      >
        <PageTop>
          <img
            src="/asset/images/close/close-darkgray.svg"
            alt=""
            onClick={() => {
              setLoginModalOn(false);
              if (beforeType === 'property') {
                navigate(-1);
              } else {
                handleTextClick();
              }
            }}
          />
        </PageTop>
        <ContentBox>
          <Content>
            <Title>이메일로 로그인</Title>
            <InputContainer>
              <InputBox>
                <span>이메일</span>
                <Input
                  type="text"
                  placeholder="이메일 주소를 입력해주세요"
                  value={id}
                  onChange={(e) => {
                    setId(e.target.value);
                    setIsEmailValid(validateEmail(e.target.value));
                  }}
                  isValid={isEmailValid}
                />
                {!isEmailValid && (
                  <ErrorText>올바른 이메일 주소를 입력해 주세요.</ErrorText>
                )}
              </InputBox>
              <InputBox>
                <span>비밀번호</span>
                <Input
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  isValid={!loginError}
                />
                {loginError && (
                  <ErrorText style={{ padding: '0 14px' }}>
                    트랜스파머에 등록되지 않은 아이디거나, 아이디 또는
                    비밀번호가 회원정보와 일치하지 않습니다.
                  </ErrorText>
                )}
              </InputBox>
              <Button
                onClick={() => {
                  LoginAPI();
                }}
              >
                로그인
              </Button>
              <ControlId>
                <SaveId
                  style={{ flex: 1 }}
                  onClick={() => {
                    setRememberChecked(!rememberChecked);
                  }}
                >
                  <img
                    src={`/asset/images/icon/${rememberChecked ? 'checked-black' : 'unChecked'}.svg`}
                    alt=""
                  />
                  <span>아이디 저장</span>
                </SaveId>
                <SaveId
                  style={{ gap: 0 }}
                  onClick={() => {
                    navigate('/find?tab=id&type=D');
                  }}
                >
                  <span>아이디/비밀번호 찾기</span>
                  <img
                    src="/asset/images/arrow/arrow-right-gray-findId.svg"
                    alt=""
                  />
                </SaveId>
              </ControlId>
            </InputContainer>
            <SocialLogin>
              <LineBox>
                <Line></Line>
                <span>SNS로 간편하게 시작하기</span>
                <Line></Line>
              </LineBox>
              <LogoBox>
                <Img
                  src="/asset/images/logo/kakao.svg"
                  alt=""
                  onClick={() => {
                    RedirectHandler('Kakao');
                    localStorage.setItem('loginModal', 'Y');
                  }}
                />
                <Img
                  src="/asset/images/logo/naver.svg"
                  alt=""
                  onClick={() => {
                    RedirectHandler('Naver');
                    localStorage.setItem('loginModal', 'Y');
                  }}
                />
                <Img
                  src="/asset/images/logo/facebook.svg"
                  alt=""
                  onClick={() => {
                    RedirectHandler('Facebook');
                    localStorage.setItem('loginModal', 'Y');
                  }}
                />
                <Img
                  src="/asset/images/logo/google.svg"
                  alt=""
                  onClick={() => {
                    RedirectHandler('Google');
                    localStorage.setItem('loginModal', 'Y');
                  }}
                />
              </LogoBox>
            </SocialLogin>
          </Content>
          <SignUpText>
            <span>아직 트랜스파머 회원이 아니신가요?</span>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/sign/email');
              }}
            >
              회원가입 하기
            </div>
          </SignUpText>
        </ContentBox>
      </Container>
    </Blur>
  );
};

export default LoginModal;

interface InputProps {
  isValid?: boolean;
}

interface ValidProps {
  isIdValid: boolean;
  isPasswordValid: boolean;
  isMobile: boolean;
}

const Blur = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  max-width: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const Container = styled.div<ValidProps>`
  width: 312px;
  background-color: white;
  margin: 0 auto;
  position: sticky;
  border-radius: 16px;
  padding: 24px 16px;
  transform: translateY(10%);
  height: ${({ isIdValid, isPasswordValid, isMobile }) => {
    if (!isIdValid && !isPasswordValid) {
      return '653px';
    }
    if (!isIdValid) {
      return '623px';
    }
    if (!isPasswordValid) {
      return '633px';
    }
    return '603px'; // 두 값이 모두 true일 경우 기본값
  }};
`;
const PageTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const ContentBox = styled.div`
  width: 100%;
  // padding: 32px 16px 72px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Title = styled.div`
  width: 100%;
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: ${colors.labelNormal};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Regular;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const Input = styled.input<InputProps>`
  width: 100%;
  height: 50px;
  padding-left: 14px;
  border: 1px solid
    ${({ isValid }) => (isValid ? `${colors.lineNeutral}` : '#EC4F4F')};
  border-radius: 10px;

  &:focus {
    border-color: ${({ isValid }) => (isValid ? '#0dde60' : '#EC4F4F')};
    outline: none;
    font-family: Pretendard-Medium;
    font-size: 16px;
    color: ${colors.labelNormal};
  }
`;

const Button = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${colors.labelNormal};
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;

const ControlId = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const SaveId = styled.span`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
const SocialLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;
const Line = styled.div`
  height: 1px;
  display: flex;
  flex: 1;
  background-color: rgba(112, 115, 124, 0.22);
`;

const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const SignUpText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.labelAlternative};
`;

const ErrorText = styled.p`
  color: #ec4f4f;
  font-size: 12px;
  margin-top: 5px;
  font-family: Pretendard-Medium;
`;
const Img = styled.img`
  cursor: pointer;
`;
