import { TextSectionTitle } from '../report/Text';
import { RowSectionTitle, RowTableValueBold } from '../report/Row';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { IIndexInfo } from '../../Main';
import { BoxGreen1 } from '../report/Box';
import styled from 'styled-components';

const ReportDetail2 = ({
  data,
  address,
}: {
  data: IIndexInfo;
  address: string;
}) => {
  const parts = address ? address.split(' ') : ['']; // address가 존재할 경우 분리하고, 없으면 빈 배열

  const addressResult = `${parts[0]} ${parts[1]}`.trim();

  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <RowSectionTitle>
        <TextSectionTitle>3. 건축거래 활성화 지수</TextSectionTitle>
      </RowSectionTitle>
      {[
        {
          title: '신축거래 활성화 지수',
          data: data.newConstructionIndexList || [],
        },
        {
          title: '리모델링거래 활성화 지수',
          data: data.remodelingIndexList || [],
        },
      ].map((el, index) => {
        return (
          <div className="flex-column " key={index}>
            <div style={{ padding: '16px' }}>
              <RowTableValueBold title={el.title} value={addressResult} />
            </div>
            {el.data.length === 0 || el.data[0].gugun === null ? (
              <NoDataWrapper>
                <div>거래 활성화 지수 데이터가 없습니다.</div>
              </NoDataWrapper>
            ) : (
              <>
                <div
                  className="flex-column of-x-auto"
                  style={{ overflowY: 'hidden' }}
                >
                  <ResponsiveContainer width="96%" height={181}>
                    <LineChart
                      className="flex-medium f-size-11 font-medium center-x"
                      data={el.data.map((el, i: number) => {
                        return {
                          day: `${el.stdYear}-${el.stdMonth}`,
                          value: el.index,
                        };
                      })}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <Line
                        type="monotone"
                        dataKey="value"
                        name="활성화 지수"
                        stroke="#09CE7D"
                      />
                      <ReferenceLine
                        y={100}
                        label="20~22년 평균 기준(100)"
                        stroke="red"
                      />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div className="padding-8-16 gap-10">
                  <BoxGreen1>
                    <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                      {el.data[el.data.length - 1].stdMonth}월(최근 집계 기준){' '}
                      {el.data[el.data.length - 1].gugun}
                    </div>
                    <div
                      className="font-bold f-size-16 line-h-24 "
                      style={{
                        color:
                          el.data[el.data.length - 1].index < 100
                            ? '#d62554'
                            : '#1E9B6B',
                      }}
                    >
                      {el.title}는 <br /> {el.data[el.data.length - 1].index}
                      입니다.
                    </div>
                  </BoxGreen1>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReportDetail2;

const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100px;
  font-size: 14px;
  padding: 24px 16px;
  // padding-bottom: 48px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  div {
    padding: 0px 16px;
  }
`;
