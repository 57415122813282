import styled from 'styled-components';
import colors from '../../color';

interface InputProps {
  isValid?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const ContentBox = styled.div`
  width: 100%;
  padding: 32px 16px 72px 16px;
  display: flex;
  flex-direction: column;
  gap: 72px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Title = styled.div`
  width: 100%;
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: ${colors.labelNormal};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Regular;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const Input = styled.input<InputProps>`
  width: 100%;
  height: 50px;
  padding-left: 14px;
  border: 1px solid
    ${({ isValid }) => (isValid ? `${colors.lineNeutral}` : '#EC4F4F')};
  border-radius: 10px;

  &:focus {
    border-color: ${({ isValid }) => (isValid ? '#0dde60' : '#EC4F4F')};
    outline: none;
    font-family: Pretendard-Medium;
    font-size: 16px;
    color: ${colors.labelNormal};
  }
`;

const Button = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${colors.labelNormal};
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;

const ControlId = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const SaveId = styled.span`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
const SocialLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;
const Line = styled.div`
  height: 1px;
  display: flex;
  flex: 1;
  background-color: rgba(112, 115, 124, 0.22);
`;

const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const SignUpText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
`;

const ErrorText = styled.p`
  color: #ec4f4f;
  font-size: 12px;
  margin-top: 5px;
  font-family: Pretendard-Medium;
`;
const Img = styled.img`
  cursor: pointer;
`;
export const S = {
  Container,
  ContentBox,
  Content,
  Title,
  InputContainer,
  InputBox,
  Input,
  Button,
  ControlId,
  SaveId,
  SocialLogin,
  LineBox,
  Line,
  LogoBox,
  SignUpText,
  ErrorText,
  Img,
};
