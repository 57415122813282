import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

const ReportDetail10 = (originData: any) => {
  const [arrowDirection, setArrowDirection] = useState('down');

  const changeArrowDirection = () => {
    setArrowDirection((prevDirection) =>
      prevDirection === 'down' ? 'up' : 'down',
    );
  };

  return (
    <div className=" browser-bg-FFFFFF"
    style={{marginBottom: '86px'}}
    >
      <div className="flex-column gap-8">
        <div
          style={{
            padding: '24px 16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={changeArrowDirection}
        >
          <Referal_Text>데이터 출처 보기</Referal_Text>
          <img
            className="hover"
            src={`/asset/images/arrow/Arrow${
              arrowDirection === 'down' ? 'Bottom' : 'Top'
            }24-light-gray.svg`}
            alt=""
          />
        </div>
        {arrowDirection === 'up' ? (
          <div className="padding-16 flex-column">
            <div
              className="flex-row bg-light-gray-100 border-b-171E26 border-t-171E26 font-medium f-size-14"
              style={{ padding: '8px 0' }}
            >
              <div className="w-72 flex-center">
                <div
                  className="color-text-tertiary"
                  style={{ textAlign: 'center' }}
                >
                  구분
                </div>
              </div>
              <div className="w-125 flex-center">
                <div
                  className=" color-text-tertiary"
                  style={{ textAlign: 'center' }}
                >
                  기준일
                </div>
              </div>
              <div className="flex-center flex-1">
                <div
                  className="color-text-tertiary"
                  style={{ textAlign: 'center' }}
                >
                  출처
                </div>
              </div>
            </div>
            <div
              style={{
                maxHeight: '450px',
                overflow: 'auto',
              }}
            >
              {originData.originData &&
                originData.originData.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="flex-row border-b-171E26 font-medium f-size-12 "
                      style={{
                        padding: '8px',
                      }}
                    >
                      <div className="w-72 flex-center">
                        <div className="color-text-primary text-center">
                          {item.category}
                        </div>
                      </div>
                      <div className="w-125 flex-center">
                        <div className="color-text-primary text-center">
                          {item.createdtime}
                        </div>
                      </div>
                      <div className="flex-center flex-1">
                        <div className="color-text-primary text-center">
                          {item.origin}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div style={{ height: '16px' }}></div>
        )}
      </div>
    </div>
  );
};

const Referal_Text = styled.div`
  font-family: Pretendard-bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: start;
  color: #171e26;
`;
export default ReportDetail10;
