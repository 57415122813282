import styled from 'styled-components';

const dummy_option = [
  {
    imgSrc: '/asset/images/icon/smartFarm-img.svg',
    title: '스마트팜',
    description: '매립,전기,지하수 완벽하게 갖춘 매물',
  },
  {
    imgSrc: '/asset/images/icon/greenhouse.svg',
    title: '하우스',
    description: '딸기 재배용 비닐하우스 설치된 농지',
  },
  {
    imgSrc: '/asset/images/icon/farmEquipment.svg',
    title: '농기구',
    description: '중고 이앙기 얀마 6조식 포함',
  },
  {
    imgSrc: '/asset/images/icon/sprout.svg',
    title: '묘종',
    description: '실생 2년생 노지 직파 묘목',
  },
  {
    imgSrc: '/asset/images/icon/clipboard.svg',
    title: '기타',
    description: '-',
  },
];

const ItemOption = () => {
  return (
    <ItemOptionContainer>
      <ItemOptionHeader>
        <div>옵션정보</div>
        <OptionDate>트팜중개사인증매물 24.07.31</OptionDate>
      </ItemOptionHeader>
      <ItemOptionContentBox>
        {dummy_option.map((item, index) => (
          <ContentWrapper key={index}>
            <img src={item.imgSrc}></img>
            <ContentBox>
              <ContentTitle>{item.title}</ContentTitle>
              <div>{item.description}</div>
            </ContentBox>
          </ContentWrapper>
        ))}
      </ItemOptionContentBox>
    </ItemOptionContainer>
  );
};

export default ItemOption;

const ItemOptionContainer = styled.div`
  width: 100%;
  height: 498px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 24px;
`;

const ItemOptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const OptionDate = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const ItemOptionContentBox = styled.div`
  width: 100%;
  height: 384px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 16px;
  align-items: center;
  background-color: #fafafa;
  border-radius: 8px;
  img {
    width: 24px;
    height: 24px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  gap: 2px;
`;

const ContentTitle = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;
