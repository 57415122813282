import styled from 'styled-components';
import colors from '../../../color';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  max-width: 720px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.52);
`;
const Inner = styled.div`
  width: 312px;
  height: 461px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding: 24px 16px;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const PageTitle = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 20px;
  color: ${colors.labelNormal};
`;
const Description = styled.div`
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: ${colors.labelNormal};
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;
const Line = styled.div`
  height: 1px;
  display: flex;
  flex: 1;
  background-color: rgba(112, 115, 124, 0.22);
`;
const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const LongButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const EmailButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
`;
const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${colors.lineNeutral};
`;
const Img = styled.img`
  cursor: pointer;
`;

const SignupText = styled.span`
  cursor: pointer;
`;

const PageTop = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export const S = {
  Container,
  ContentBox,
  LineBox,
  Line,
  LogoBox,
  PageTitle,
  Description,
  ButtonBox,
  LongButton,
  EmailButton,
  Divider,
  Img,
  SignupText,
  Inner,
  PageTop,
};
