import './NoResult.css';

interface NoResultProps {
  type: string;
}

const NoResult = ({ type }: NoResultProps) => {
  if (type === 'noResult_noCheck') {
    return (
      <div className="NoSearchResultBox">
        <img
          className="NoResultBox_img"
          src="/asset/images/icon/sad-face.svg"
          alt=""
        />
        <div className="NoResultBox_text">
          <div className="NoResultBox_text_1">검색결과가 없어요</div>
          <div className="NoResultBox_text_2">
            검색어를 확인 후 재검색해 보세요
          </div>
        </div>
      </div>
    );
  }

  if (type === 'search') {
    return (
      <div className="NoResultBox">
        <div className="NoResultBox_top_bar">
          <div className="NoResultBox_top_bar_text">최근 검색</div>
        </div>
        <img className="NoResultBox_img" src="./emoji_nodate_2.png" alt="" />
        <div className="NoResultBox_text">
          <div className="NoResultBox_text_1">최근 검색 기록이 없어요</div>
          <div className="NoResultBox_text_2">지역명으로 검색해 보세요</div>
        </div>
      </div>
    );
  }
  if (type === 'check') {
    return (
      <div className="NoCheckResultBox">
        <div className="NoResultBox_top_bar">
          <div className="NoResultBox_top_bar_text">최근 조회 주소지</div>
          {/* <div className="NoResultBox_top_bar_delete">전체삭제</div> */}
        </div>
        <img
          className="NoResultBox_img"
          src="/asset/images/icon/magnifier.svg"
          alt=""
        />
        <div className="NoResultBox_text">
          <div className="NoResultBox_text_1">최근 조회 주소지가 없어요</div>
          <div className="NoResultBox_text_2">
            주소지 검색 혹은 마커 클릭해서
            <br />
            매물 심층 분석을 해보세요.
          </div>
        </div>
      </div>
    );
  }

  return <div>No Result</div>;
};

export default NoResult;
