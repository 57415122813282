import styled from 'styled-components';
import {
  XAxis,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { AnalysisProps } from '../../data';
import {
  formatManWons,
  formatToKoreanCurrency,
} from '../../../../function/function';

interface DummyData {
  name: string;
  price: number;
  gapOfPrice?: number; // 중위값과의 차이
  labelPrice: number;
}

interface AnalysisData {
  data: AnalysisProps;
  pyeong: number;
  price: number;
  dealDate: string;
  selectedType: string;
}

const RealPriceChart = ({
  data,
  pyeong,
  price,
  dealDate,
  selectedType,
}: AnalysisData) => {
  const analysisData = selectedType === 'building' ? data?.house : data?.land;

  const chartDataSet: DummyData[] = [
    {
      name: '과거 평당가',
      price:
        (analysisData.price ?? 0) - analysisData.avg > 0
          ? analysisData.avg
          : (analysisData.price ?? 0),
      gapOfPrice:
        (analysisData.price ?? 0) - analysisData.avg > 0
          ? (analysisData.price ?? 0) - analysisData.avg
          : 0,
      labelPrice: analysisData.price,
    },
    {
      name: '하위 평당 추정가',
      price: analysisData.low_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.low_avg,
    },
    {
      name: '기준 평당 추정가',
      price: analysisData.avg,
      gapOfPrice: 0,
      labelPrice: analysisData.avg,
    },
    {
      name: '상위 평당 추정가',
      price: analysisData.high_avg,
      gapOfPrice: 0,
      labelPrice: analysisData.high_avg,
    },
  ];

  const medianValue = analysisData.avg;

  const formatDate = (dateStr: string) => {
    const [year, month, day] = dateStr.split('-');
    return `${year.slice(2)}.${month}`;
  };
  return (
    <>
      <TAnalyzeChartBox>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={328}
            height={176}
            data={chartDataSet}
            margin={{ top: 25, right: 20 }}
          >
            <XAxis
              axisLine={false} // X축 선을 제거
              tickLine={false} // X축 tick을 제거
              dataKey="name"
              interval={0}
              tick={({ x, y, payload }) => {
                const isHighlighted = payload.value === '과거 실거래 정보';
                const padding = isHighlighted ? 0 : -4;

                // 긴 텍스트에 대해 줄바꿈 처리를 위한 분리 로직
                const lines: string[] = payload.value.includes('추정가')
                  ? [payload.value.slice(0, -3), '추정가']
                  : [payload.value];

                return (
                  <g transform={`translate(${x + padding},${y})`}>
                    <text
                      dy={16}
                      fill={
                        isHighlighted ? '#222222' : 'rgba(55, 56, 60, 0.61)'
                      }
                      fontSize="11px"
                      fontFamily="Pretendard-Medium"
                      fontWeight="500"
                      textAnchor="middle"
                      // style={{
                      //   whiteSpace: 'nowrap',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      // }}
                    >
                      {lines.map((line, index) => (
                        <tspan
                          key={index}
                          x={lines.length > 1 ? 4 : 0}
                          dy={index === 0 ? 8 : 11}
                        >
                          {line}
                        </tspan>
                      ))}
                    </text>
                  </g>
                );
              }}
            />
            <Bar dataKey="price" barSize={40} stackId="a" radius={[0, 0, 4, 4]}>
              {chartDataSet.map((entry, index) => {
                // 색상 결정
                let fillColor = '#D2D8DF'; // 기본 색상 (검정색)
                if (entry.name === '과거 평당가' && entry.gapOfPrice === 0) {
                  fillColor = '#1E9B6B';
                  return (
                    <Cell key={`cell-${index}`} fill={fillColor} radius={4} />
                  );
                }
                if (entry.name === '과거 평당가') {
                  fillColor = '#1E9B6B';
                  return <Cell key={`cell-${index}`} fill={fillColor} />;
                } else {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.name === '기준 평당 추정가'
                          ? '#484F55'
                          : '#D2D8DF'
                      }
                      radius={4}
                    />
                  );
                }
              })}
            </Bar>
            <Bar
              dataKey="gapOfPrice"
              barSize={40}
              stackId="a"
              radius={[4, 4, 0, 0]}
              fill="#25805E"
            >
              <LabelList
                dataKey="labelPrice"
                position="top"
                content={({ x, y, value, index }) => {
                  if (index !== undefined) {
                    return (
                      <text
                        x={Number(x) + 20}
                        y={Number(y) - 8}
                        fill="#000"
                        textAnchor="middle"
                        fontSize="14"
                        fontFamily="Pretendard-Bold"
                      >
                        {formatManWons(Number(value))}
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>
            <ReferenceLine
              y={medianValue}
              stroke="rgba(55, 56, 60, 0.28)"
              strokeDasharray="3 3"
              label={{
                position: 'insideRight',
                value: '기준',
                fontSize: 12,
                fontFamily: 'Pretendard-Medium',
                dx: 20,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </TAnalyzeChartBox>
      <AveragePriceListBox>
        <PriceBox>
          <AveragePriceList>
            <PriceName>
              <img src="/asset/images/icon/dot_price.svg"></img>
              <div>과거실거래가</div>
            </PriceName>
            <MaxPrice>
              <Tag>거래일 {dealDate && formatDate(dealDate)}</Tag>
              {formatToKoreanCurrency(price)}
            </MaxPrice>
          </AveragePriceList>
          <ListDivider></ListDivider>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_low_avg.svg"></img>
              <div>하위 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {formatToKoreanCurrency(analysisData.low_avg * pyeong)}~
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_avg.svg"></img>
              <div>기준 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {formatToKoreanCurrency(analysisData.avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>
              <img src="/asset/images/icon/dot_low_avg.svg"></img>
              <div>상위 추정가</div>
            </TextGray>
            <AveragePriceContent>
              {'~' + formatToKoreanCurrency(analysisData.high_avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
        </PriceBox>
      </AveragePriceListBox>
    </>
  );
};

export default RealPriceChart;

const TAnalyzeChartBox = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 180px;
`;

const AveragePriceListBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
  gap: 13px;
`;

const PriceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
`;

const AveragePriceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  width: 100%;
  color: #222222;
`;

const AveragePriceContent = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
const MaxPrice = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e2e4;
`;

const TextGray = styled.div`
  display: flex;
  font-size: 13px;
  color: #616161;
  gap: 4px;
`;

const Tag = styled.span`
  padding: 3px 4px;
  border-radius: 4px;
  background-color: rgba(112, 115, 124, 0.08);
  color: rgba(55, 56, 60, 0.61);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
`;

const PriceName = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
`;
