import { useNavigate } from 'react-router-dom';
import './NoLogin.css';

const NoLogin = () => {
  const nav = useNavigate();
  return (
    <div className="NoLoginBox">
      <img className="NoLogin_img" src="./emoji_fail_01.png" alt="" />
      <div className="NoLogin_text">
        <div className="NoLogin_text_1">저번에 봤던 그 땅, 주소가 뭐였지?</div>
        <div className="NoLogin_text_2">로그인하고, 조회 이력 확인해보세요</div>
      </div>
      <button
        onClick={() => {
          nav('/login');
        }}
        className="NoLogin_Button"
      >
        로그인하고 편리하게 이용하기
      </button>
    </div>
  );
};
export default NoLogin;
