import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLogin } from '../../../utilities/auth';
import { useMapContext } from '../../../contexts/MapContext';

interface OuterContainerProps {
  height: number;
}

const MoreContent = () => {
  const [spreadPartner, setSpreadPartner] = useState(true);
  const [spreadNotice, setSpreadNotice] = useState(true);
  const [spreadCompanyInfo, setSpreadCompanyInfo] = useState(true);
  const [spreadPolicy, setSpreadPolicy] = useState(true);
  const [OuterContainerHeight, setOuterContainerHeight] = useState(584);
  const outerContainerRef = useRef<HTMLDivElement | null>(null); // ref 생성
  const { latLng } = useMapContext();
  const navigate = useNavigate();

  //중개파트너 밑의 이미지 클릭 시 setSpreadParter 상태 변경
  const handleSpreadPartner = (type: string) => {
    switch (type) {
      case 'partner':
        setSpreadPartner(!spreadPartner);
        break;
      case 'notice':
        setSpreadNotice(!spreadNotice);
        break;
      case 'company':
        setSpreadCompanyInfo(!spreadCompanyInfo);
        break;
      case 'policy':
        setSpreadPolicy(!spreadPolicy);
        break;
    }
  };

  // spreadPartner, spreadNotice ,spreadCompanyInfo ,spreadPolicy 상태가 변경될 때마다 OuterContainer의 높이를 변경
  useEffect(() => {
    const height =
      530 +
      134 +
      (spreadPartner ? 48 : 0) +
      (spreadNotice ? 192 : 0) +
      (spreadCompanyInfo ? 96 : 0) +
      (spreadPolicy ? 144 : 0);
    setOuterContainerHeight(height);
  }, [spreadPartner, spreadNotice, spreadCompanyInfo, spreadPolicy]);

  return (
    <OuterContainer height={OuterContainerHeight} ref={outerContainerRef}>
      <MoreContentContainer>
        <MoreContentBox>
          <ContentTitle>
            <div>중개파트너</div>
            <img
              onClick={() => handleSpreadPartner('partner')}
              src={
                spreadPartner
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadPartner ? null : (
            <ContentBox>
              <ContentBoxContent
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLScpOsy0bsOTdJPXyH6OYs2BsMFqVqFnTntKl478lXA4xgC2Nw/viewform',
                    '_blank',
                  );
                }}
              >
                지역 파트너 중개사 신청
                <img src="/asset/images/icon/broker_tag.svg"></img>
              </ContentBoxContent>
              {/* <ContentBox_Content>매물관리</ContentBox_Content> */}
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>문의 및 공지</div>
            <img
              onClick={() => handleSpreadPartner('notice')}
              src={
                spreadNotice
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadNotice ? null : (
            <ContentBox>
              {/* <ContentBox_Content>고객센터</ContentBox_Content> */}
              <ContentBoxContent
                onClick={() => {
                  navigate('/inquiry');
                }}
              >
                1:1 문의
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  window.open(
                    'https://transfarmer.notion.site/20eba2a06fd246d1a1fe45b2e9d9d835',
                    '_blank',
                  );
                }}
              >
                자주 묻는 질문
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/notice');
                }}
              >
                공지사항
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>회사 정보</div>
            <img
              onClick={() => handleSpreadPartner('company')}
              src={
                spreadCompanyInfo
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadCompanyInfo ? null : (
            <ContentBox>
              <ContentBoxContent
                onClick={() => {
                  navigate('/companyinfo');
                }}
              >
                회사소개
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/newsRoom');
                }}
              >
                뉴스룸
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>이용정책</div>
            <img
              onClick={() => handleSpreadPartner('policy')}
              src={
                spreadPolicy
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadPolicy ? null : (
            <ContentBox>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/service');
                }}
              >
                이용약관
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/privacy');
                }}
              >
                개인정보처리방침
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/location');
                }}
              >
                위치기반서비스 이용약관
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>채널</ContentTitle>
          <ChannelBox>
            <ChannelBoxContent>
              <img
                src="./facebook_logo_w_1.svg"
                alt=""
                onClick={() => {
                  window.open(
                    'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/',
                    '_blank',
                  );
                }}
              />
              <span
                onClick={() => {
                  window.open(
                    'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/',
                    '_blank',
                  );
                }}
              >
                페이스북
              </span>
            </ChannelBoxContent>
            <ChannelBoxContent>
              <img
                src="./naver_blog 1.svg"
                alt=""
                onClick={() => {
                  window.open(
                    'https://blog.naver.com/transfarmer2023',
                    '_blank',
                  );
                }}
              />
              <span
                onClick={() => {
                  window.open(
                    'https://blog.naver.com/transfarmer2023',
                    '_blank',
                  );
                }}
              >
                블로그
              </span>
            </ChannelBoxContent>
          </ChannelBox>
        </MoreContentBox>
        <MoreContentBox>
          <BoxLine />
        </MoreContentBox>
        <div>
          <img
            style={{
              height: '20px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
            src="/asset/images/logo/Logo_korean_gray.svg"
            alt=""
          />
        </div>

        <CopyContent>
          <div>대표이사 : 김기현 | 사업자등록번호 : 507-87-02631 |</div>
          <div>Tel : 02-555-2822 | E-mail : help@transfarmer.co.kr</div>
          <div>서울특별시 서초구 매헌로 24 NH농협 디지털혁신캠퍼스</div>
          <div>5층 트랜스파머㈜</div>
          <div>COPYRIGHT ⓒ 2023 Transfarmer. ALL RIGHTS RESERVED.</div>
        </CopyContent>
        <LoginBox>
          {isLogin() ? (
            <div>
              <span
                onClick={() => {
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('name');
                  localStorage.removeItem('point');
                  localStorage.removeItem('profileImg');
                  localStorage.removeItem('email');
                  localStorage.removeItem('phone');
                  localStorage.removeItem('category');
                  localStorage.removeItem('interest_area');
                  localStorage.removeItem('birth');
                  localStorage.removeItem('farm_id');
                  localStorage.removeItem('loginType');
                  alert('로그아웃 되었습니다.');

                  if (
                    !localStorage.getItem('lat') ||
                    !localStorage.getItem('lng')
                  ) {
                    const latitude = latLng?.lat ?? 0; // 기본값을 0으로 설정
                    const longitude = latLng?.lng ?? 0;
                    navigate(`/?lat=${latitude}&lng=${longitude}`);
                  } else {
                    navigate(
                      `/?lat=${localStorage.getItem('lat')}&lng=${localStorage.getItem('lng')}`,
                    );
                    localStorage.removeItem('lat');
                    localStorage.removeItem('lng');
                  }
                }}
              >
                로그아웃
              </span>
            </div>
          ) : (
            <div>
              <span
                onClick={() => {
                  navigate('/login');
                }}
              >
                로그인
              </span>
            </div>
          )}
        </LoginBox>
      </MoreContentContainer>
    </OuterContainer>
  );
};
export default MoreContent;

const OuterContainer = styled.div<OuterContainerProps>`
  width: 100%;
  height: ${(props) => props.height}px;
  // min-height: 584px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const MoreContentContainer = styled.div`
  padding: 16px 16px 72px 16px;
  width: 100%;
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  margin-bottom: 54px;
`;

const MoreContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  color: rgba(55, 56, 60, 0.28);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding-top: 8px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBoxContent = styled.div`
  padding: 12px 0;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
  gap: 16px;
  display: flex;
`;

const ChannelBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ChannelBoxContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  padding: 12px 0;
  gap: 6px;
  img {
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
`;

const BoxLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(112, 115, 124, 0.16);
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  color: #616161;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  text-decoration: underline;
  line-height: 24px;
  word-wrap: break-word;
  position: relative;
  span {
    cursor: pointer;
  }
`;

const CopyContent = styled.div`
  color: rgba(23, 30, 38, 0.56);
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 400;
  line-height: 12px;
  word-wrap: break-word;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
