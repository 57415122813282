import styled from 'styled-components';
import colors from '../../color';

export const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-x: hidden; /* 가로 스크롤만 숨기기 */
  overflow-y: auto; /* 필요할 때만 세로 스크롤 허용 */
  background-color: #fff;
`;
export const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
`;

export const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;

export const NoticeInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  padding: 16px 16px 80px 16px;
`;

export const NoticeContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const TopLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #171e26;
  display: none;
`;

export const NoticeListBox = styled.div`
  width: 100%;
  max-height: 730px;
`;
export const List = styled.ul`
  margin-bottom: 40px;
  width: 100%;
`;
export const ListItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #e8e9ea;
  cursor: pointer;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #171e26;
`;

export const NoticeDate = styled.span`
  font-size: 14px;
  color: rgba(23, 30, 38, 0.56);
`;
