import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterWrapper>
      <div>
        <img
          src="/asset/images/logo/Logo_korean_gray.svg"
          alt=""
          style={{ height: '20px' }}
        />
      </div>
      <CopyRightBox>
        <div>대표이사 : 김기현 | 사업자등록번호 : 507-87-02631 |</div>
        <div>Tel : 02-555-2822 | E-mail : help@transfarmer.co.kr</div>
        <div>서울특별시 서초구 매헌로 24 NH농협 디지털혁신캠퍼스</div>
        <div>5층 트랜스파머㈜</div>
        <div>COPYRIGHT ⓒ 2023 Transfarmer. ALL RIGHTS RESERVED.</div>
      </CopyRightBox>
    </FooterWrapper>
  );
};
export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  padding: 32px 16px 80px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;
const CopyRightBox = styled.div`
  width: 100%;
  font-family: Pretendard-Regular;
  font-size: 12px;
  line-height: 12px;
  color: rgba(23, 30, 38, 0.56);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
