import { PageTopClose } from '../components/pageTop/PageTop';
import { S } from './SignUpEmail.styles';
import AuthService from '../../service/auth';
import UserService from '../../service/Users';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignUpInfo } from '../../contexts/SignUpContext';
import { hackleSignUptrack } from '../../hackler';

export const jobOptions = [
  { value: 'W', label: '농업인' },
  { value: 'F', label: '귀농 희망자' },
  { value: 'N', label: '귀촌 희망자' },
  { value: 'E', label: '농산업기업체' },
  { value: 'O', label: '지자체 담당자' },
  { value: 'P', label: '정부부처(공공기관)' },
  { value: 'C', label: '농산물 유통' },
  { value: 'B', label: '부동산 중개' },
  { value: 'G', label: '기타(해당사항 없음)' },
];

const SignUpEmail = () => {
  const {
    email,
    setEmail,
    loginType,
    setLoginType,
    isEmailValid,
    setIsEmailValid,
    phone,
    setPhone,
    password,
    setPassword,
    name,
    setName,
    category,
    setCategory,
    farmOperatorId,
    setFarmOperatorId,
    etc,
    setEtc,
    code,
    setCode,
    confirmPassword,
    setConfirmPassword,
    isPasswordValid,
    setIsPasswordValid,
    isPasswordMatch,
    setIsPasswordMatch,
    allAgree,
    setAllAgree,
    privacyAgree,
    setPrivacyAgree,
    serviceAgree,
    setServiceAgree,
    locationAgree,
    setLocationAgree,
    notificationAgree,
    setNotificationAgree,
    availableEmail,
    setAvailableEmail,
  } = SignUpInfo();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const route = query.get('route');

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleConfirmPasswordChange = (e: any) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword); // Check match on confirm password change
  };

  const sendSms = async () => {
    if (email === '' || phone === '') {
      alert('이메일과 휴대폰 번호를 입력해주세요');
    }
    try {
      const data = {
        email: email,
        phone: phone,
        type: 'A',
        name: name,
      };
      const res = await AuthService.sendSms(data);
      alert('인증번호가 발송되었습니다');
    } catch (error) {
      console.error(error);
    }
  };

  const checkVerifyCode = async (code: string) => {
    try {
      const data = {
        email: email,
        phone: phone,
        type: 'A',
        name: name,
        authCode: code,
      };
      await AuthService.verifySms(data);
      alert('인증되었습니다');
    } catch (error) {
      console.error(error);
      alert('인증번호가 일치하지 않습니다');
    }
  };
  const handleAllAgree = () => {
    if (!allAgree) {
      setAllAgree(true);
      setPrivacyAgree(true);
      setServiceAgree(true);
      setLocationAgree(true);
      setNotificationAgree(true);
    } else {
      setAllAgree(false);
      setPrivacyAgree(false);
      setServiceAgree(false);
      setLocationAgree(false);
      setNotificationAgree(false);
    }
  };

  const isFormValid = () => {
    return (
      email !== '' &&
      phone !== '' &&
      password !== '' &&
      name !== '' &&
      code !== '' &&
      privacyAgree &&
      serviceAgree &&
      locationAgree
    );
  };

  const SignUp = async () => {
    if (email === '') {
      alert('이메일을 입력해주세요');
      return;
    }
    if (phone === '') {
      alert('휴대폰 번호를 입력해주세요');
      return;
    }
    if (password === '') {
      alert('비밀번호를 입력해주세요');
      return;
    }
    if (!isPasswordMatch) {
      alert('비밀번호가 서로 일치하지 않습니다.');
      return;
    }
    if (name === '') {
      alert('이름을 입력해주세요');
      return;
    }
    if (code === '') {
      alert('인증번호를 입력해주세요');
      return;
    }
    if (!privacyAgree) {
      alert('개인정보 처리방침에 동의해주세요');
      return;
    }
    if (!serviceAgree) {
      alert('트랜스파머 서비스 이용약관에 동의해주세요');
      return;
    }
    if (!locationAgree) {
      alert('위치기반서비스 이용약관에 동의해주세요');
      return;
    }
    try {
      const data = {
        email: email,
        loginType: 'C',
        password: password,
        name: name,
        phone: phone,
        category: category === '' ? 'U' : category,
        farmOperatorId: farmOperatorId,
        etc: etc,
        isNotification: notificationAgree === true ? 'Y' : 'N',
        landing: sessionStorage.getItem('landingType') ?? '',
        referer: sessionStorage.getItem('beforeUrl') ?? '',
      };
      const res = await UserService.signUp(data);
      if (res.result === '유저 생성 완료') {
        alert('가입이 완료되었습니다');
        if (route === 'signupModal') {
          window.location.href = '/';
          SignUpInfoReset();
        } else {
          navigate('/login?route=signup');
          SignUpInfoReset();
        }
      }
      hackleSignUptrack('C', category, email);
    } catch (error) {
      console.error(error);
    }
  };
  const SignUpInfoReset = () => {
    setEmail('');
    setLoginType('');
    setIsEmailValid(true);
    setPhone('');
    setPassword('');
    setName('');
    setCategory('');
    setFarmOperatorId('');
    setEtc('');
    setCode('');
    setConfirmPassword('');
    setIsPasswordValid(true);
    setIsPasswordMatch(true);
    setAllAgree(false);
    setPrivacyAgree(false);
    setServiceAgree(false);
    setLocationAgree(false);
    setNotificationAgree(false);
    setAvailableEmail(true);
  };

  const IdEqualCheck = async () => {
    try {
      const data = {
        email: email,
      };
      const res = await UserService.checkEmail(data);
      if (res.result === 'Y') {
        setAvailableEmail(true);
      } else {
        setAvailableEmail(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <S.Container>
      <PageTopClose />
      <S.ContentBox>
        <S.Title>이메일로 가입하기</S.Title>
        <S.InputBox>
          <S.InputItem>
            <S.Label>이메일</S.Label>
            <S.Input
              type="text"
              placeholder="이메일 주소를 입력해 주세요"
              value={email}
              onChange={handleEmailChange}
              onBlur={(e: any) => {
                e.target.value !== '' && IdEqualCheck();
              }}
            />
          </S.InputItem>
          {!isEmailValid && (
            <S.WarningFont>이메일 형식이 아닙니다.</S.WarningFont>
          )}
          {!availableEmail && (
            <S.WarningFont>사용중인 이메일입니다.</S.WarningFont>
          )}
          <S.InputItem>
            <S.Label>비밀번호</S.Label>
            <S.Input
              type="password"
              placeholder="비밀번호를 입력해주세요"
              onChange={handlePasswordChange}
              maxLength={16}
            />
          </S.InputItem>
          {!isPasswordValid && (
            <S.WarningFont>
              영문/숫자/특수문자를 사용하여 8자~16자로 입력
            </S.WarningFont>
          )}
          <S.InputItem>
            <S.Label>비밀번호 확인</S.Label>
            <S.Input
              type="password"
              placeholder="비밀번호 확인"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              maxLength={16}
            />
          </S.InputItem>
          {!isPasswordMatch && (
            <S.WarningFont>비밀번호가 일치하지 않습니다.</S.WarningFont>
          )}
          <S.InputItem>
            <S.Label>이름</S.Label>
            <S.Input
              type="text"
              placeholder="홍길동"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </S.InputItem>
          <S.InputItem>
            <S.Label>휴대폰 번호</S.Label>
            <S.PhoneNumberBox>
              <S.Input
                type="tel"
                placeholder="010 0000 0000"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                style={{ flex: 1 }}
                maxLength={11}
              />
              <S.SendNumberButton
                onClick={() => {
                  sendSms();
                }}
              >
                인증번호 받기
              </S.SendNumberButton>
            </S.PhoneNumberBox>
          </S.InputItem>
          <S.InputItem>
            <S.Label>인증번호</S.Label>
            <S.Input
              type="text"
              placeholder="인증번호 6자리 입력를 입력해주세요"
              onChange={(e) => {
                setCode(e.target.value);
                if (e.target.value.length === 6) {
                  checkVerifyCode(e.target.value);
                } else {
                  return null;
                }
              }}
              maxLength={6}
            />
          </S.InputItem>
          <S.InputItem>
            <S.Label>소속 혹은 농촌에 대한 관심사 (선택)</S.Label>
            <S.Select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <S.Option value={''}>어떤 일을 하시나요?</S.Option>
              {jobOptions.map((option, index) => (
                <S.Option
                  key={index}
                  value={option.value}
                  onChange={(e: any) => {}}
                >
                  {option.label}
                </S.Option>
              ))}
            </S.Select>
          </S.InputItem>
          {category === 'W' && (
            <S.InputItem>
              <S.Label>농업 경영체 등록 번호</S.Label>
              <S.Input
                type="text"
                placeholder="농업 경영체 등록 번호를 입력해주세요"
                value={farmOperatorId}
                onChange={(e) => {
                  setFarmOperatorId(e.target.value);
                }}
              />
            </S.InputItem>
          )}
          {category === 'G' && (
            <S.InputItem style={{ height: 'auto' }}>
              <S.Input
                type="text"
                placeholder="하는 일 혹은 관심사를 기재해주세요"
                value={etc}
                onChange={(e) => {
                  setEtc(e.target.value);
                }}
              />
            </S.InputItem>
          )}
        </S.InputBox>
        <S.Line></S.Line>
        <S.TermsBox>
          <S.AllAgreeBox>
            <img
              src={
                allAgree
                  ? '/asset/images/check/terms-check-on.svg'
                  : '/asset/images/check/terms-check-off.svg'
              }
              alt=""
              onClick={() => {
                handleAllAgree();
              }}
            />
            <p>약관 전체 동의</p>
          </S.AllAgreeBox>
          <S.AgreeBox>
            <S.AgreeItem>
              <img
                src={
                  privacyAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setPrivacyAgree(!privacyAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 개인정보 처리방침</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/privacy');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  serviceAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setServiceAgree(!serviceAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 트랜스파머 서비스 이용약관</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/service');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  locationAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setLocationAgree(!locationAgree);
                }}
              />
              <S.AgreeText>
                <span>[필수] 위치기반서비스 이용약관</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/location');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
            <S.AgreeItem>
              <img
                src={
                  notificationAgree
                    ? '/asset/images/check/terms-check-on.svg'
                    : '/asset/images/check/terms-check-off.svg'
                }
                alt=""
                onClick={() => {
                  setNotificationAgree(!notificationAgree);
                }}
              />
              <S.AgreeText>
                <span>[선택] 혜택 정보 푸시 알림 수신</span>
                <S.ArrwoImg
                  src="/asset/images/arrow/arrow-right-gray-textDisabled.svg"
                  alt=""
                  onClick={() => {
                    navigate('/terms/notification');
                  }}
                />
              </S.AgreeText>
            </S.AgreeItem>
          </S.AgreeBox>
        </S.TermsBox>
      </S.ContentBox>
      <S.SignUpBtnBox>
        <S.SignUpBtn
          style={{
            color: isFormValid() ? '#ffffff' : 'rgba(55, 56, 60, 0.16)',
            backgroundColor: isFormValid() ? '#1E9B6B' : '#F0F0F0',
          }}
          onClick={() => {
            SignUp();
          }}
        >
          가입하기
        </S.SignUpBtn>
      </S.SignUpBtnBox>
    </S.Container>
  );
};
export default SignUpEmail;
