import { MouseEventHandler, useState } from 'react';

export const ButtonSelect1M = ({
  children,
  selected,
  width,
  onClick,
  price,
}: {
  children: string;
  selected: boolean;
  width?: boolean | undefined;
  onClick: MouseEventHandler;
  price?: boolean;
}) => (
  <div
    onClick={onClick}
    className={selected ? 'button_select_selected' : 'button_select'}
    style={{ width: price ? '100px' : width ? '100%' : 'auto' }}
  >
    <div className={`${selected ? 'buttion_text_selected' : 'buttion_text'}`}>
      {children}
    </div>
  </div>
);

export const ButtonSelectorBox = ({
  btnArr,
  tab,
  setTab,
  type,
}: {
  btnArr: Array<{ children: string; selected: number } | string>;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  type?: number;
}) => {
  const getWidth = (type: number) => {
    switch (type) {
      case 2:
        return '0.32px';
      case 3:
        return '0.62px';
      default:
        return '';
    }
  };

  const getBackground = (type: number) => {
    return type === 2 || type === 3 ? '#757575' : '';
  };

  return (
    <div className="padding-8-16 gap-10">
      <div className="border-1-f0f0f0 bg-light-gray-200 padding-2 border-radius-8 flex-row">
        {btnArr.map((el, i) => {
          if (i % 2 === 0 && typeof el !== 'string') {
            return (
              <ButtonSelect1M
                onClick={() => {
                  setTab(el.selected);
                }}
                children={el.children}
                selected={el.selected === tab}
                width={true}
                key={i}
              />
            );
          } else {
            let acc = btnArr[i - 1];
            let nxt = btnArr[i + 1];
            const isType =
              typeof acc !== 'string' &&
              !(acc.selected === tab) &&
              nxt &&
              typeof nxt !== 'string' &&
              !(nxt.selected === tab);

            return (
              <div className="padding-y-8" key={i}>
                <div
                  style={{
                    height: '100%',
                    border: isType
                      ? '0.31px solid #171e2640'
                      : '0.31px solid #00000000',
                    width: getWidth(type || 0),
                    background: getBackground(type || 0),
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};


export const MobileButton = ({ children, description, onClick, on, menu, loading }: any) => {
  return (
    <div
      className={`${
        menu === 'idfind' ? (on ? `hover` : ``) : `hover`
      } w-100per padding-14-16 center-x center-y gap-10 flex-row border-radius-8 ${
        !on ? 'bg-light-gray-200' : 'bg-primary-500'
      }`}
      onClick={onClick}
    >
      {children}
      {loading ? (
        <img
          src='/asset/images/icon/loading-white.svg'
          alt='Loading'
          className='loading-icon'
        />
      ) : (
        description && (
          <div
            className={`font-bold f-size-18 line-h-26 ${
              on ? ` color-FFFFFF` : `color-disabled`
            }`}
          >
            {description}
          </div>
        )
      )}
    </div>
  );
};