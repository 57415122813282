import styled from 'styled-components';

const Loading = () => {
  return (
    <Container>
      <img
        src="/asset/images/spinner.gif"
        alt=""
        style={{ width: '95px', height: '95px' }}
      />
      <span>데이터를 불러오는 중입니다.</span>
    </Container>
  );
};

export default Loading;

export const NoBgLoading = () => {
  return (
    <NoBgContainer>
      <img
        src="/asset/images/spinner.gif"
        alt=""
        style={{ width: '20px', height: '20px' }}
      />
      <span>데이터를 불러오는 중입니다.</span>
    </NoBgContainer>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #222222;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const NoBgContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
`;
