import { useEffect, useRef, useState } from 'react';
import { S } from './Apply.styles';
import LandService from '../../service/land';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';

declare global {
  interface Window {
    daum: {
      Postcode: new (options: { oncomplete: (data: any) => void }) => {
        open: () => void;
      };
    };
  }
}

const LandApply = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [marketTab, setMarketTab] = useState('유휴토지 신청');
  const [showButton, setShowButton] = useState(false);
  const nav = useNavigate();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [postCode, setPostCode] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [showTerm, setShowTerm] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleAddressSearch = () => {
    new window.daum.Postcode({
      oncomplete: (data) => {
        setPostCode(data.zonecode);
        setAddress(data.roadAddress || data.jibunAddress);
      },
    }).open();
  };

  const registerLand = async () => {
    try {
      const data = {
        name: name,
        phone: phone,
        address: address,
        addrDetail: detailAddress,
      };
      const res = await LandService.register(data);
      alert('등록이 완료되었습니다.');
      setName('');
      setPhone('');
      setPostCode('');
      setAddress('');
      setDetailAddress('');
      setAgree(false);
    } catch {
      alert('등록에 실패했습니다.');
    }
  };

  const handleSubmit = () => {
    if (!name || !phone || !postCode || !address || !detailAddress) {
      alert('모든 항목을 입력해주세요.');
      return;
    }
    if (!agree) {
      alert('개인정보 수집 및 제3자 이용동의에 동의해주세요.');
      return;
    }
    registerLand();
  };

  const onClickTopButton = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        setShowButton(scrollTop >= 100);
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <S.Container ref={containerRef}>
      <S.TopSearchBox>
        <img
          src="/asset/images/logo/land-market.svg"
          alt="logo"
          onClick={() => {
            nav('/');
          }}
          style={{ cursor: 'pointer' }}
        />
        <S.LandMarketTabBox>
          <S.TabButton
            active={marketTab === '토지상품'}
            onClick={() => {
              nav('/landMarket/product');
              // alert('준비중입니다.');
            }}
          >
            토지 상품
          </S.TabButton>
          <S.TabButton
            active={marketTab === '유휴토지 신청'}
            onClick={() => {
              nav('/landMarket/apply');
            }}
          >
            유휴토지 신청
          </S.TabButton>
        </S.LandMarketTabBox>
      </S.TopSearchBox>
      <S.ContenBox>
        <S.TitleBox>
          <img src="/asset/images/landMarket/bubble.svg" alt="" />
          <div>
            <div>방치된 유휴지가</div>
            <S.TitleGreen>수익창출 토지로</S.TitleGreen>
          </div>
        </S.TitleBox>
        <S.DescriptionBox>
          재산세만 나가는 토지 <br /> AI 기반 입지분석으로 <br />
          최적의 토지 투자 상품으로 변신합니다.
        </S.DescriptionBox>
        <S.InputBox>
          <S.InputRow>
            <S.InputLabel>
              이름/법인명 <S.Must>*</S.Must>
            </S.InputLabel>
            <S.Input
              type="text"
              placeholder="이름 또는 법인명을 입력해 주세요"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </S.InputRow>
          <S.InputRow>
            <S.InputLabel>
              연락처 <S.Must>*</S.Must>
            </S.InputLabel>
            <S.Input
              type="tel"
              placeholder="연락 가능한 번호를 입력해 주세요"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </S.InputRow>
          <S.InputRow>
            <S.InputLabel>
              토지주소 <S.Must>*</S.Must>
            </S.InputLabel>
            <S.SearchAddress>
              <S.Input
                type="text"
                placeholder="주소 찾기로 입력해 주세요"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
                disabled
                style={{ flex: 1 }}
              />
              <S.SearchButton onClick={handleAddressSearch}>
                주소찾기
              </S.SearchButton>
            </S.SearchAddress>
            <S.Input
              type="text"
              placeholder="주소 찾기로 입력해 주세요"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled
            />
            <S.Input
              type="text"
              placeholder="상세 주소를 입력해 주세요"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
            />
          </S.InputRow>
          <S.TermBox>
            <S.CheckTerm>
              <S.Check
                onClick={() => {
                  setAgree(!agree);
                }}
              >
                {agree ? (
                  <img src="/asset/images/check/terms-check-on.svg" alt="" />
                ) : (
                  <img src="/asset/images/check/check-gray-circle.svg" alt="" />
                )}
                <span>개인정보 수집 및 제3자 이용동의</span>
              </S.Check>
              <img
                src="/asset/images/arrow/arrow-bottom-gray-small.svg"
                alt=""
                style={{
                  transform: showTerm ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowTerm(!showTerm);
                }}
              />
            </S.CheckTerm>
            {showTerm && (
              <S.TermText>
                <div>제공받는 자: 트랜스파머, 제휴 공인중개사</div>
                <div> 제공 목적: 이용자가 문의한 서비스 제공</div>
                <div>
                  제공하는 개인정보 항목: 이름, 이메일 주소, 휴대전화번호
                </div>
                <div>
                  개인정보를 제공받는자의 개인정보 보유 및 이용기간 : 서비스
                  목적 달성시까지
                </div>
              </S.TermText>
            )}
          </S.TermBox>
          <S.GuideText>
            등록 신청하신 토지 정보는 AI 입지분석 검토 후 <br />
            토지개발사업 대상 토지로 등록됩니다.
          </S.GuideText>
          <S.SubmitButton onClick={handleSubmit}>
            <span>내 유휴지 등록하기</span>
            <img src="/asset/images/arrow/arrow-white-20.svg" alt="" />
          </S.SubmitButton>
        </S.InputBox>
      </S.ContenBox>
      <Footer />
      {showButton && (
        <S.TopButton>
          <img
            onClick={onClickTopButton}
            src="/asset/images/arrow/arrow-to-top.png"
            alt=""
          />
        </S.TopButton>
      )}
    </S.Container>
  );
};
export default LandApply;
