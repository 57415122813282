import { forwardRef } from 'react';
import styled from 'styled-components';
import colors from '../../../../color';
import { useFilter } from '../../../../contexts/FilterContext';
import { useMapContext } from '../../../../contexts/MapContext';
import { useNavigate } from 'react-router-dom';

interface TabMenuProps {
  setFilterModalOn: (value: boolean) => void;
  hasFilterChanged: boolean;
}

const TabMenuContainer = forwardRef<HTMLDivElement, TabMenuProps>(
  ({ setFilterModalOn, hasFilterChanged }, ref) => {
    const { menu, setMenu, filterOption, setFilterOption } = useFilter();
    const { mode } = useMapContext();
    const navigate = useNavigate();

    // 테마 데이터 작업 후 주석 해제
    // const landFilter = ['지목', '가격', '면적', '테마'];
    // const houseFilter = ['용도', '가격', '면적', '건축연도', '테마'];
    const totalFilter = ['대상', '지목', '용도', '가격', '면적', '건축연도'];
    const landFilter = ['가격', '면적'];
    const houseFilter = ['용도', '가격', '면적', '건축연도'];

    const isPensionMode = mode === '농지연금';

    const handleReset = (text?: string) => {
      if (text === '전체') {
        setFilterOption({
          ...filterOption,

          target: '전체',
          sale_type: [] as string[],
          theme: [] as string[],
        });
      }

      if (text === '토지') {
        setFilterOption({
          ...filterOption,
          target: '토지',
          sale_type: [] as string[],
          purpose: [] as string[],
          year: ['', ''] as [string, string],
          theme: [] as string[],
        });
      }
      if (text === '주택') {
        setFilterOption({
          ...filterOption,
          target: '주택',
          sale_type: [] as string[],
          category: [] as string[],
          year: ['', ''] as [string, string],
          theme: [] as string[],
        });
      }
    };

    const renderFilter = () => {
      if (menu === '지도') {
        return (
          <>
            {hasFilterChanged ? (
              <SelectedOptionContainer>
                <ResetBtn
                  onClick={() => {
                    setFilterOption({
                      target: '전체',
                      sale_type: [] as string[],
                      category: [] as string[],
                      purpose: [] as string[],
                      price: ['', ''] as [string, string],
                      area: ['', ''] as [string, string],
                      year: ['', ''] as [string, string],
                      theme: [] as string[],
                    });
                  }}
                >
                  <img src="/asset/images/icon/reset.svg" alt="" />
                </ResetBtn>
                {filterOption.category.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.category.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, category: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {filterOption.purpose.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.purpose.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, purpose: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.price[0] !== '' ||
                  filterOption.price[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.price[0]} ~{filterOption.price[1]}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, price: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.area[0] !== '' ||
                  filterOption.area[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.area[0] !== '최소'
                        ? filterOption.area[0] + '평'
                        : '최소'}
                      ~{filterOption.area[1] + '평'}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, area: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {filterOption.theme.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.theme.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, theme: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.year[0] !== '' ||
                  filterOption.year[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.year[0]} ~{filterOption.year[1]}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, year: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
              </SelectedOptionContainer>
            ) : (
              <FilterContainer>
                {totalFilter.map((filter, index) => (
                  <FilterTab
                    key={index}
                    onClick={() => {
                      setFilterModalOn(true);
                    }}
                  >
                    <FilterTabText>{filter}</FilterTabText>
                    <img src="/asset/images/arrow/arrow-down.svg" alt="" />
                  </FilterTab>
                ))}
              </FilterContainer>
            )}
          </>
        );
      } else if (menu === '찾아줘 토지') {
        return (
          <>
            {hasFilterChanged ? (
              <SelectedOptionContainer>
                <ResetBtn
                  onClick={() => {
                    setFilterOption({
                      target: '토지',
                      sale_type: [] as string[],
                      category: [] as string[],
                      purpose: [] as string[],
                      price: ['', ''] as [string, string],
                      area: ['', ''] as [string, string],
                      year: ['', ''] as [string, string],
                      theme: [] as string[],
                    });
                  }}
                >
                  <img src="/asset/images/icon/reset.svg" alt="" />
                </ResetBtn>
                {filterOption.category.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.category.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, category: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.price[0] !== '' ||
                  filterOption.price[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.price[0]} ~{filterOption.price[1]}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, price: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.area[0] !== '' ||
                  filterOption.area[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.area[0] !== '최소'
                        ? filterOption.area[0] + '평'
                        : '최소'}
                      ~{filterOption.area[1] + '평'}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, area: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {filterOption.theme.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.theme.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, theme: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
              </SelectedOptionContainer>
            ) : (
              <FilterContainer>
                {landFilter.map((filter, index) => (
                  <FilterTab
                    key={index}
                    onClick={() => {
                      setFilterModalOn(true);
                    }}
                  >
                    <FilterTabText>{filter}</FilterTabText>
                    <img src="/asset/images/arrow/arrow-down.svg" alt="" />
                  </FilterTab>
                ))}
              </FilterContainer>
            )}
          </>
        );
      } else if (menu === '찾아줘 주택') {
        return (
          <>
            {hasFilterChanged ? (
              <SelectedOptionContainer>
                <ResetBtn
                  onClick={() => {
                    setFilterOption({
                      target: '주택',
                      sale_type: [] as string[],
                      category: [] as string[],
                      purpose: [] as string[],
                      price: ['', ''] as [string, string],
                      area: ['', ''] as [string, string],
                      year: ['', ''] as [string, string],
                      theme: [] as string[],
                    });
                  }}
                >
                  <img src="/asset/images/icon/reset.svg" alt="" />
                </ResetBtn>
                {filterOption.purpose.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.purpose.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, purpose: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.price[0] !== '' ||
                  filterOption.price[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.price[0]} ~{filterOption.price[1]}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, price: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.area[0] !== '' ||
                  filterOption.area[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.area[0] !== '최소'
                        ? filterOption.area[0] + '평'
                        : '최소'}
                      ~{filterOption.area[1] + '평'}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, area: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {(filterOption.year[0] !== '' ||
                  filterOption.year[1] !== '') && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.year[0]} ~{filterOption.year[1]}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, year: ['', ''] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
                {filterOption.theme.length > 0 && (
                  <SelecteOptionBtn>
                    <SelecteOptionBtnText>
                      {filterOption.theme.join(', ')}
                    </SelecteOptionBtnText>
                    <img
                      src="/asset/images/close/close-green.svg"
                      alt=""
                      onClick={() => {
                        setFilterOption({ ...filterOption, theme: [] });
                      }}
                    />
                  </SelecteOptionBtn>
                )}
              </SelectedOptionContainer>
            ) : (
              <FilterContainer>
                {houseFilter.map((filter, index) => (
                  <FilterTab
                    key={index}
                    onClick={() => {
                      setFilterModalOn(true);
                    }}
                  >
                    <FilterTabText>{filter}</FilterTabText>
                    <img src="/asset/images/arrow/arrow-down.svg" alt="" />
                  </FilterTab>
                ))}
              </FilterContainer>
            )}
          </>
        );
      } else return null;
    };

    return (
      <div ref={ref} style={{ width: '100%' }}>
        <TabMenuBox>
          <TabMenu>
            <TabMenuItem
              style={{ paddingLeft: 0 }}
              active={menu === '지도'}
              onClick={() => {
                if (isPensionMode) {
                  alert('농지연금 모드에서는 토지만 설정 가능합니다.');
                  handleReset('토지');
                  setMenu('찾아줘 토지');
                } else {
                  setMenu('지도');
                  setFilterOption((prev: any) => ({
                    ...prev,
                    target: '전체',
                  }));
                  handleReset('전체');
                }
              }}
            >
              <img
                src={`/asset/images/icon/${menu === '지도' ? 'marker' : 'markerOff'}.svg`}
                alt=""
              />
              <span>지도</span>
            </TabMenuItem>
            <TabMenuItem
              active={menu === '찾아줘 토지'}
              onClick={() => {
                setMenu('찾아줘 토지');
                setFilterOption((prev: any) => ({
                  ...prev,
                  target: '토지',
                }));
                handleReset('토지');
              }}
            >
              찾아줘 토지
            </TabMenuItem>
            <TabMenuItem
              active={menu === '찾아줘 주택'}
              onClick={() => {
                if (isPensionMode) {
                  alert('농지연금 모드에서는 토지만 설정 가능합니다.');
                  handleReset('토지');
                  setMenu('찾아줘 토지');
                } else {
                  setMenu('찾아줘 주택');
                  setFilterOption((prev: any) => ({
                    ...prev,
                    target: '주택',
                  }));
                  handleReset('주택');
                }
              }}
            >
              찾아줘 주택
            </TabMenuItem>
            <TabMenuItem
              active={menu === '토지마켓'}
              onClick={() => {
                navigate('/landMarket/product');
                // navigate('/landMarket/apply');
              }}
            >
              토지마켓
            </TabMenuItem>
          </TabMenu>
          {menu !== '토지마켓' && (
            <img
              src={`/asset/images/icon/${hasFilterChanged ? 'filterOn' : 'filter'}.svg`}
              alt=""
              onClick={() => {
                setFilterModalOn(true);
              }}
              style={{ cursor: 'pointer' }}
            />
          )}
        </TabMenuBox>
        {renderFilter()}
      </div>
    );
  },
);

export default TabMenuContainer;

interface TabMenuItemProps {
  active: boolean;
}

const FilterContainer = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border-top: 1px solid ${colors.lineNeutral};
  border-bottom: 1px solid ${colors.lineNeutral};
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const FilterTab = styled.div`
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNeutral};
  cursor: pointer;
`;

const FilterTabText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNeutral};
  line-height: 20px;
  white-space: nowrap;
`;
const TabMenuBox = styled.div`
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabMenu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const TabMenuItem = styled.div<TabMenuItemProps>`
  padding: 10px 12px;
  height: 46px;
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  font-size: 16px;
  white-space: nowrap;
  color: ${({ active }) =>
    active ? colors.labelNormal : colors.interactionNeutral};
  border-bottom: 2px solid
    ${({ active }) => (active ? colors.labelNormal : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const SelectedOptionContainer = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  gap: 6px;
  align-items: center;
  overflow-x: auto;
  border-top: 1px solid ${colors.lineNeutral};

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ResetBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNormal};
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 6px;
`;
const SelecteOptionBtn = styled.div`
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.primary500};
  cursor: pointer;
`;
const SelecteOptionBtnText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.primary500};
  white-space: nowrap;
`;
