import { useEffect, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const LandingBanner = () => {
  const navigate = useNavigate();
  const [cardIdx, setCardIdx] = useState(0);
  const temp = useRef<HTMLDivElement>(null);
  const cardRefs = {
    card1: useRef<HTMLDivElement>(null),
    card2: useRef<HTMLDivElement>(null),
    card3: useRef<HTMLDivElement>(null),
    card4: useRef<HTMLDivElement>(null),
    card5: useRef<HTMLDivElement>(null),
  };
  const [scrollXPosition, setScrollXPosition] = useState(0);
  const [scrollableDistance, setScrollableDistance] = useState(0);
  useEffect(() => {
    const ofScrollContainer = temp.current;

    if (!ofScrollContainer) {
      return;
    }

    const handleScroll = () => {
      const distance =
        ofScrollContainer.scrollWidth - ofScrollContainer.clientWidth;
      setScrollableDistance(distance);
      const currentScrollX = ofScrollContainer.scrollLeft || 0;
      setScrollXPosition(currentScrollX);
      // console.log("scroll", currentScrollX);
    };

    handleScroll();

    ofScrollContainer.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      ofScrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [temp]);
  const dummy = [
    {
      text1: '이 토지/주택,',
      text2: '얼마나 될까?',
      text3: '가격 분석 가이드',
      src: '/asset/images/promotion/guideImg1_mo.svg',
      hash: '#실거래가 #공시지가 #AI 농지추정가 #AI 주택추정가',
      ref: cardRefs.card1,
      url: '/promotion/%EC%A0%95%ED%99%95%ED%95%9C-%ED%86%A0%EC%A7%80-%EB%86%8D%EC%A7%80-%EC%A3%BC%ED%83%9D-%EA%B0%80%EA%B2%A9%EC%9D%84-%EC%95%8C%EC%95%84%EB%B3%B4%EB%8A%94-%EB%B0%A9%EB%B2%95',
    },
    {
      text1: '내 농지연금,',
      text2: '얼마나 받을 수 있을까?',
      text3: '농지연금 분석 가이드',
      src: '/asset/images/promotion/guideImg2_mo.svg',
      hash: '#농지연금 가입가능성 #농지연금 예상수령액 #농사수익',
      ref: cardRefs.card2,
      url: '/promotion/%EB%86%8D%EC%A7%80_%EA%B0%80%EA%B2%A9_%ED%99%95%EC%9D%B8%EB%B6%80%ED%84%B0_%EB%86%8D%EC%A7%80%EC%97%B0%EA%B8%88_%EA%B3%84%ED%9A%8D%EA%B9%8C%EC%A7%80_%ED%95%9C%EB%B2%88%EC%97%90',
    },
    {
      text1: '이 농지, 가격과',
      text2: '농사수익은 얼마일까?',
      text3: '농지 찾기 가이드',
      src: '/asset/images/promotion/guideImg3_mo.svg',
      hash: '#AI추천작물 #예상경작수익 #AI 농지추정가 #영농사업',
      ref: cardRefs.card3,
      url: '/promotion/%EB%86%8D%EC%82%AC-%EC%88%98%EC%9D%B5%EA%B3%BC-%EB%B9%84%EC%9A%A9%EB%B6%84%EC%84%9D-AI%EB%A1%9C-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EA%B3%84%ED%9A%8D-%EC%84%B8%EC%9A%B0%EA%B8%B0',
    },
    {
      text1: '내 농장 매출/운영비',
      text2: '높을까, 낮을까?',
      text3: '내 농장 진단 가이드',
      src: '/asset/images/promotion/guideImg4_mo.svg',
      hash: '#농사수익분석 #적합작물추천 #농업경영계획',
      ref: cardRefs.card4,
      url: '/promotion/AI%EB%A1%9C-%EB%86%8D%EC%9E%A5-%EC%A7%84%EB%8B%A8-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EC%86%94%EB%A3%A8%EC%85%98',
    },
    {
      text1: '나의 첫 전원주택,',
      text2: '예상 건축비 얼마나 될까?',
      text3: '내 집 짓기 가이드',
      src: '/asset/images/promotion/guideImg5_mo.svg',
      hash: '#주택설계 #설계도확인 #시공비확인',
      ref: cardRefs.card5,
      url: '/promotion/%EC%A0%84%EC%9B%90%EC%A3%BC%ED%83%9D-%EC%84%A4%EA%B3%84%EC%99%80-%EA%B1%B4%EC%B6%95%EB%B9%84-%EB%B6%84%EC%84%9D%EC%9D%84-AI%EB%A1%9C-%EA%B0%84%ED%8E%B8%ED%95%98%EA%B2%8C',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (cardIdx === 2) {
        setCardIdx(0);
      } else {
        setCardIdx((prevIdx) => prevIdx + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [cardIdx]);

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      temp.current?.scrollTo({
        left: temp.current?.scrollLeft - 312,
        behavior: 'smooth',
      });
    } else {
      temp.current?.scrollTo({
        left: temp.current?.scrollLeft + 312,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Wrapper>
      <BannerTitle>첫 방문 가이드</BannerTitle>
      <Inner
        onTouchEnd={() => {
          if (scrollXPosition < 312) {
            setCardIdx(0);
          } else if (scrollXPosition < 624) {
            setCardIdx(1);
          } else {
            setCardIdx(2);
          }
        }}
      >
        <Content>
          <ContentInner ref={temp}>
            {dummy.map((item, index) => {
              return (
                <Card
                  key={index}
                  onClick={() => {
                    navigate(item.url);
                  }}
                  ref={item.ref}
                  style={{
                    minWidth:
                      window.innerWidth < 1024
                        ? '312px'
                        : window.innerWidth > 1024 && window.innerWidth < 1920
                          ? '360px'
                          : '389px',
                  }}
                >
                  <Top>
                    <TopText>
                      <CardTitle>
                        {item.text1} <br /> {item.text2}
                      </CardTitle>
                      <ButtonText>{item.text3}</ButtonText>
                    </TopText>
                    <img src={item.src} alt="" />
                  </Top>
                  <HashTag>{item.hash}</HashTag>
                </Card>
              );
            })}
          </ContentInner>
          {/* {(temp.current?.offsetWidth || 0) < 1097 && (
            <Slider
              size="small"
              aria-label="Small"
              valueLabelDisplay="auto"
              value={(scrollXPosition / scrollableDistance) * 100}
              sx={{
                height: '3px',
                // width: window.innerWidth === 1920 ? "1008px" : "712px",
                '& .MuiSlider-thumb': {
                  display: 'none', // Hide the Thumb
                },
                '& .MuiSlider-track': { color: 'black' },
                '& .MuiSlider-rail': { color: '#F0F0F0' },

                '& .Mui-active': {
                  boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                },
                '& .MuiSlider-valueLabel': { background: 'none' },
                '& .MuiSlider-mark': {
                  backgroundColor: '#FFFFFF',
                  height: 8,
                },
              }}
            />
          )}
          {window.innerWidth >= 1200 && (
            <div className="flex-row end-x gap-8 padding-t-16">
              <img
                src="/asset/images/arrow/ArrowWithCircleLeft.svg"
                alt=""
                onClick={() => handleArrowClick('left')}
              />
              <img
                src="/asset/images/arrow/ArrowWithCircleRight.svg"
                alt=""
                onClick={() => handleArrowClick('right')}
              />
            </div>
          )} */}
        </Content>
      </Inner>
    </Wrapper>
  );
};

export default LandingBanner;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  gap: 24px;
  width: 100%;
  border-bottom: 16px solid #f0f0f0;
  background-color: #fff;
`;

const BannerTitle = styled.p`
  font-size: 18px;
  font-family: NanumSquareNeo-Bold;
  width: 100%;
  text-align: left;
  padding-left: 16px;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  background-color: #fff;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 16px;
  overflow-x: auto;
`;

const Card = styled.div`
  width: 312px;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border: 1px solid #171e2610;
  cursor: pointer;
  text-align: left;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #171e2610;
  text-align: left;
`;
const TopText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CardTitle = styled.div`
  width: 100%;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;
`;
const ButtonText = styled.span`
  font-family: Pretendard-Bold;
  font-size: 12px;
  line-height: 14px;
  color: #1e9b6b;
  border: 1px solid #1e9b6b;
  width: fit-content;
  border-radius: 999px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HashTag = styled.div`
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: #171e26c7;
  text-align: left;
`;
