import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

interface ButtonProps {
  buttonType: string;
}

const categories = [
  { imgSrc: '/asset/images/icon/landIncomeImg.svg', text: '토지소득' },
  { imgSrc: '/asset/images/icon/pensionImg.svg', text: '농지연금' },
  { imgSrc: '/asset/images/icon/houseImg.svg', text: '주택견적' },
];

interface PointAlertProps {
  url: string;
  setModal: any;
  state?: any;
}

const PointAlert = ({ url, setModal, state }: PointAlertProps) => {
  const nav = useNavigate();
  const [category, setCategory] = useState<string | undefined>();

  useEffect(() => {
    const categoryFromStorage = sessionStorage.getItem('ReportCategory');
    setCategory(categoryFromStorage ?? undefined);
  }, []);

  const renderImage = (category: any) => {
    if (category === '가격분석') {
      return '/asset/images/icon/landIncomeImg.svg';
    } else if (category === '농지연금분석') {
      return '/asset/images/icon/pensionImg.svg';
    } else {
      return '/asset/images/icon/houseImg.svg';
    }
  };

  return (
    <Overlay>
      <PointAlertContainer>
        <ImgBox
          onClick={() => {
            setModal(false);
          }}
        >
          <img src="/asset/images/close/close24.svg" alt="" />
        </ImgBox>
        <InnerContainer>
          <AlertContentBox>
            {/* <ContetentHeader>
              <div>{`${localStorage.getItem('name')}님`}</div>
              <div>현재 주소지의</div>
            </ContetentHeader> */}
            <CategoryWrapper>
              <img src={renderImage(category)} alt="" />
            </CategoryWrapper>
            <CategoryDescription>
              안전한 토지거래를 위해 트랜스파머가
              <br />
              {category}을<span style={{ color: '#1E9B6B' }}> 무료로 제공</span>
              합니다
            </CategoryDescription>
          </AlertContentBox>
          <ButtonBox>
            <AlertButton
              buttonType={'result'}
              onClick={() => {
                state ? nav(url, { state }) : nav(url);
              }}
            >
              무료로 결과보기
            </AlertButton>
            <AlertButton
              buttonType={'cancel'}
              onClick={() => {
                setModal(false);
              }}
            >
              다음에 할게요
            </AlertButton>
          </ButtonBox>
        </InnerContainer>
      </PointAlertContainer>
    </Overlay>
  );
};

export default PointAlert;

const Overlay = styled.div`
  position: fixed; /* 화면 전체를 덮기 위해 고정 위치 설정 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.52);
  display: block;
  z-index: 1000; /* 다른 요소 위에 표시되도록 설정 */
`;

const PointAlertContainer = styled.div`
  // width: calc(100% - 32px);
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: white;
  position: fixed;
  top: 180px;
  left: 16px;
  right: 16px;
  z-index: 300;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ImgBox = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const AlertContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  align-items: center;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const CategoryDescription = styled.div`
  color: black;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  text-align: center;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

const AlertButton = styled.div<ButtonProps>`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background-color: #1e9b6b;
  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 26.4px;
  word-wrap: break-word;

  ${(props) =>
    props.buttonType === 'cancel' &&
    `
  background-color: white;
 border: 1px rgba(112, 115, 124, 0.22) solid; 
 color: #1E9B6B; `}
`;

const ContetentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
