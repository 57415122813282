// import close from './../../asset/images/close/close.svg';
// import close2 from './../../asset/images/close/Close_white.svg';

interface PropsType {
  text: string | undefined | JSX.Element;
  on: boolean | undefined;
  top?: string;
  left?: string;
  off?: any;
  text2?: string | JSX.Element;
  right?: string;
  bottom?: string;
  width?: any;
  aifarm?: boolean;
  xImg?: boolean;
}

interface ModeType {
  mode: 'M' | '2' | 'X';
}

const TooltipBase = ({
  text,
  on,
  top,
  left,
  off,
  text2,
  right,
  bottom,
  mode,
  width,
  xImg,
}: PropsType & ModeType) => {
  const styleName =
    ' bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-100 absolute';

  return (
    <div
      className={mode === 'M' ? 'flex-center absolute' : 'flex end-x absolute'}
    >
      {on && (
        <div
          className={(mode === 'M' ? 'tablet' : 'flex-row') + styleName}
          style={{
            left: `${left}px`,
            right: `${right}px`,
            top: `${top}px`,
            bottom: `${bottom}px`,
            justifyContent: 'space-between',
            overflow: 'visible',
            width: width ? '250px' : '324px',
            position: 'relative',
          }}
        >
          <div
            className='flex-column h-100per'
            style={{
              alignItems: 'left',
              justifyContent: 'center',
            }}
          >
            <div
              className='font-medium f-size-11 line-h-12 color-EFF0F6 h-100per'
              style={{
                whiteSpace: 'pre-wrap',
                textAlign: mode === 'X' || mode === '2' ? 'left' : 'center',
              }}
            >
              {text}
            </div>
            <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
              {text2}
            </div>
          </div>
          {xImg && (
            <img
              className='hover w-14'
              // src={mode == 'M' ? close : close2}
              onClick={off}
            />
          )}
          {mode === 'X' && (
            <div
              className='tooltip-tail'
              style={{
                position: 'absolute',
                borderWidth: '6px',
                borderTop: '0px solid transparent',
                borderBottom: '8px solid #4A5568',
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                top: '-6px',
                right: '40px',
                zIndex: '0',
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const TooltipMobile = ({
  text,
  on,
  top,
  left,
  off,
  text2,
  right,
  bottom,
  width,
}: PropsType) => {
  return (
    <TooltipBase
      text={text}
      on={on}
      top={top}
      left={left}
      off={off}
      text2={text2}
      right={right}
      bottom={bottom}
      mode={'M'}
      width={width}
    ></TooltipBase>
  );
};

export const Tooltip2 = ({
  aifarm,
  text,
  on,
  top,
  left,
  off,
  text2,
  right,
  bottom,
  width,
}: PropsType) => {
  return (
    <TooltipBase
      text={text}
      text2={text2}
      on={on}
      top={top}
      left={left}
      off={off}
      right={right}
      bottom={bottom}
      mode={'2'}
      width={width}
      xImg
    ></TooltipBase>
  );
};
export const TriangleTooltip = ({
  text,
  on,
  top,
  left,
  off,
  text2,
  right,
  bottom,
  width,
  xImg,
}: PropsType) => {
  return (
    <TooltipBase
      text={text}
      text2={text2}
      on={on}
      top={top}
      left={left}
      off={off}
      right={right}
      bottom={bottom}
      mode={'X'}
      width={width}
      xImg={xImg}
    ></TooltipBase>
  );
};

// export const Tooltip3 = ({}) => {
//   return (
//     <Tooltip2
//       bottom="0"
//       on={onToolTip}
//       off={() => setOnToolTip(false)}
//       text="당월 현재까지 상등품 평균 경락가격의 % (출하시기 아님으로 정보없으면 전월 기준)"
//     />
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//     <Tooltip2
//       bottom="0"
//       on={onToolTip}
//       off={() => setOnToolTip(false)}
//       text="더 많은 주변 거래 사례는 고객센터에 문의 바랍니다."
//     />
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//     <Tooltip2
//       bottom="0"
//       on={onToolTip}
//       off={() => setOnToolTip(false)}
//       text="가장 보수적인 판매가격은 산지도매상 납품가 기준입니다."
//     />
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//     <Tooltip2
//       bottom="0"
//       on={onToolTip}
//       off={() => setOnToolTip(false)}
//       text="작물 유통처 분포 합계는 100%를 넘을 수 없습니다."
//     />
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//     <Tooltip2
//       bottom="0"
//       on={onToolTip}
//       off={() => setOnToolTip(false)}
//       text="소매 매출은 대형 유통사 평균 판매가격의 50%를 적용합니다."
//     />
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//
//   );
// };
// export const Tooltip3 = ({}) => {
//   return (

//   );
// };
// export const Tooltip3 = ({}) => {
//   return (
//
//   );
// };
