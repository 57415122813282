import styled from 'styled-components';
import colors from '../../../color';

interface UnitSwitchProps {
  unit: string;
  setUnit: (unit: string) => void;
}

const UnitSwitch = ({ unit, setUnit }: UnitSwitchProps) => {
  return (
    <UnitSwitchBtn
      onClick={() => {
        unit === '평' ? setUnit('㎡') : setUnit('평');
      }}
    >
      <img src="/asset/images/icon/rotation.svg" alt="" />
      {unit === '평' ? '㎡' : '평'}
    </UnitSwitchBtn>
  );
};

export default UnitSwitch;

const UnitSwitchBtn = styled.div`
  height: 24px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${colors.lineNormal};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNormal};
  cursor: pointer;
`;
