import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

interface LikeData {
  c_idx?: number;
  pnu?: string;
  type?: string;
}

export default class Interaction {
  static like = async (data: LikeData) => {
    const result = await request.post('interaction/like', data);
    return result.data;
  };
  static disLike = async (int_idx: number) => {
    const result = await request.delete(`interaction/like/${int_idx}`);
    return result.data;
  };

  static check = async (c_idx: number, pnu?: string, type?: string) => {
    if (!c_idx) {
      const result = await request.get(
        `interaction/like/check/c_idx/${type}/${pnu}`,
      );
      return result.data;
    } else {
      const result = await request.get(
        `interaction/like/check/${c_idx}/${type ?? 'type'}/${pnu ?? 'pnu'}`,
      );
      return result.data;
    }
  };

  static list = async (sort: string, intType: string) => {
    const result = await request.get(
      `interaction/like?&sort=${sort}&intType=${intType}`,
    );
    return result.data;
  };

  static recent = async (data: any) => {
    const result = await request.post('interaction/recent', data);
    return result.data;
  };
}
