import '../../report.css';
interface BoxCardProps {
  title: string;
  value: string | JSX.Element | number;
  underLine?: boolean;
  mypage?: boolean;
  mypage2?: boolean;
  onClick?: () => void;
}

export const BoxGreen1 = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => (
  <div
    className="padding-16 gap-10 border-radius-8 bg-primary-100"
    // style={{ flex: "1" }}
  >
    {children}
  </div>
);

export const BoxCard1: React.FC<BoxCardProps> = ({
  title,
  value,
  underLine,
}) => (
  <div
    className={`box_card   start-y`}
    style={{ borderBottom: underLine ? '1px solid #171E261A' : 'none' }}
  >
    <div className={`title w-100per`}>{title}</div>
    <div className={`text w-100per`}>{value}</div>
  </div>
);
