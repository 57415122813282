import styled from 'styled-components';

interface SnackBarProps {
  leftText?: string;
  rightText?: string;
  textCenter?: boolean;
  onClick: () => void;
}

const SnackBar = ({
  leftText,
  rightText,
  onClick,
  textCenter,
}: SnackBarProps) => {
  return (
    <SnackBarContainer textCenter={textCenter}>
      <LeftText>{leftText}</LeftText>
      <RightText onClick={onClick}>{rightText}</RightText>
    </SnackBarContainer>
  );
};

export default SnackBar;

interface SnackBarContainerProps {
  textCenter?: boolean;
}

const SnackBarContainer = styled.div<SnackBarContainerProps>`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  display: flex;
  flex-direction: row;
  justify-content: ${({ textCenter }) =>
    textCenter ? 'center' : 'space-between'};
  align-items: center;
  z-index: 9999;
  background-color: rgba(81, 85, 86, 0.92);
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);
`;

export const LeftText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  color: white;
`;
export const RightText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  color: rgba(13, 222, 96, 1);
  cursor: pointer;
`;
