import { useState, useEffect, useRef } from 'react';
import './Main.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Base from './components/Base';
import { Slider } from '@mui/material';

import { BoxDateSelector1 } from '../../../../components/common/Box';
import { PointAlert2 } from '../../../../components/common/pointAlert2';
// import { LoginPopUp2 } from '../../../../components/common/LoginPopUp2';
// import { PointColumn } from '../../../../components/common/pointColumn';
// import WarnPoint from '../../../../components/common/warnPoint';
import { styled } from 'styled-components';
import { transNum } from '../../../../../function/function';
import { useMapContext } from '../../../../../contexts/MapContext';

const PensionInput = ({
  setReportIdx,
  setClose,
  close,
  props,
  onReport3,
}: any) => {
  const location = useLocation();
  const { high_avg, low_avg, avg, address, c_idx, itemType, pnu, housekye } =
    location.state || {};
  const temp = useRef<HTMLDivElement>(null);
  const temp2 = useRef<HTMLDivElement>(null);
  const nav = useNavigate();

  const isHave = [
    { title: '네', type: 'Y' },
    { title: '아니오', type: 'N' },
  ];
  const [point, setPoint] = useState(0);
  const locationHook = useLocation();
  const [query, setQuery] = useState('');
  const [landInfo, setLandInfo] = useState({
    pnuCode: '',
  });
  const [farmValueRange, setFarmValueRange] = useState({
    officialPrice: low_avg,
    estimatedPrice: high_avg,
    estimatedPrice90Per: avg,
  });
  const [farmValue, setFarmValue] = useState(avg);
  const params = useParams();

  const [maxPyeongValue, setMaxPyeongValue] = useState(81);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [onDate, setOnDate] = useState(false);
  const [onToast, setOnToast] = useState(false);
  const [age, setAge] = useState('1964-01');
  const [career, setCareer] = useState<number | undefined | string>(5);
  const [isFarm, setIsFarm] = useState('Y');
  const [isHave2, setIsHave2] = useState('Y');
  const [isNear, setIsNear] = useState('Y');
  const [isNear2, setIsNear2] = useState('Y');
  const [isRight, setIsRight] = useState('N');
  const [isIllegal, setIsIllegal] = useState('N');
  const [isShare, setIsShare] = useState('N');
  const [onPointAlert, setOnPointAlert] = useState(false);

  function calculateAge(birthdate: string) {
    var currentDate = new Date();

    var birthdateDate = new Date(birthdate);

    var age = currentDate.getFullYear() - birthdateDate.getFullYear();

    if (
      currentDate.getMonth() < birthdateDate.getMonth() ||
      (currentDate.getMonth() === birthdateDate.getMonth() &&
        currentDate.getDate() < birthdateDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  const [name, setName] = useState('');

  const [toolTip, setToolTip] = useState(false);

  //생년월일 입력 모달이 나왔을 때 뒤에 화면이 스크롤 되지 않게 하기 위한 useEffect
  useEffect(() => {
    if (onDate) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [onDate]);

  //가입 제한 사유 내용 펼치기
  const [arrowDirection, setArrowDirection] = useState('down');
  const changeArrowDirection = () => {
    console.log('arrowDirection', arrowDirection);
    setArrowDirection((prevDirection) =>
      prevDirection === 'down' ? 'up' : 'down',
    );
  };
  const { latLng } = useMapContext();

  //로딩될 때 페이지 상단 위치로 이동
  useEffect(() => {
    if (temp2.current) {
      temp2.current.scrollIntoView();
    }
  }, []);

  return (
    <div
      ref={temp2}
      style={{
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        maxWidth: '720px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 16px',
        }}
      >
        <div
          style={{ fontSize: '36px', cursor: 'pointer' }}
          onClick={() => {
            if (c_idx === '') {
              if (housekye !== '') {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
                );
              } else {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                );
              }
            } else {
              nav(
                `/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
              );
            }
          }}
        >
          <img src="/asset/images/close/close-gray.svg" alt="" />
        </div>
      </div>
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            setOnPointAlert={setOnPointAlert}
            // save={save}
            point={point}
          />
        ) : (
          ''
          // <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}
      <div
        className="flex-column  border-b-171E2610 h-100per"
        style={{ overflowX: 'hidden', backgroundColor: '#f5f5f5' }}
      >
        {loginPopUp &&
          // <LoginPopUp
          //   setOnLoginPopup={setLoginPopUp}
          //   address={props.address}
          //   pathName={locationHook.pathname}
          // />
          // <LoginPopUp2
          //   address={props.address}
          //   setOnLoginPopup={setLoginPopUp}
          //   // setOnLoginPopup={setOnLoginPopup}
          //   // address={props.address}
          //   // pathName={locationHook.pathname}
          // />
          ''}

        <div
          className="flex-column w-100per h-100per gap-16 "
          style={{ overflowX: 'hidden' }}
        >
          <div className="flex-column" style={{ padding: '32px 16px 0 16px' }}>
            <span className="font-bold f-size-20 line-h-29 color-text-secondary">
              농지연금 알아보기
            </span>
          </div>

          <div style={{ padding: '0 16px' }}>
            <div
              style={{
                padding: '16px',
                borderRadius: '8px',
                background: '#EAF8F2',
              }}
            >
              <div
                style={{
                  color: '#1E9B6B',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontFamily: 'Pretendard-Medium',
                  fontWeight: '500',
                }}
              >
                농지가치, 가입연령을 선택하면 해당 토지에서 받을 수 있는 예상
                수령액을 조회할 수 있습니다.
              </div>
            </div>
          </div>

          <div className="flex-column" style={{ padding: '0' }}>
            <span className="font-bold f-size-20 line-h-29 color-text-secondary padding-x-16">
              맞춤 조건 입력하기
            </span>
            <div
              className="font-medium f-size-12 line-h-14 color-text-secondary padding-x-16"
              style={{ padding: '8px 16px' }}
            >
              {address}
            </div>
          </div>

          <Base
            title="1. 농지가치 설정하기"
            warn
            onClick={() => setToolTip(true)}
          >

            <div className="font-medium f-size-14 line-h-20 color-text-secondary padding-x-16">
              농지연금 수령액은 농지 가치에 따라 달라집니다. 트랜스파머 AI
              추정가를 참고하여 해당 농지의 가치를 설정해보세요.
            </div>
            <div className="flex-column padding-8-16-0 gap-16">
              <div className="flex-column w-100per gap-8">
                <div className="flex-row space-between">
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    하위 추정가
                  </div>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    상위 추정가
                  </div>
                </div>
                <Slider
                  value={farmValue}
                  onChange={(e: any, value: any) => {
                    setFarmValue(Math.round(value / 10000) * 10000);
                  }}
                  onChangeCommitted={(e: any, value: any) => {}}
                  marks={(() => {
                    let arr = [];
                    for (
                      let i = farmValueRange.officialPrice;
                      i < farmValueRange.estimatedPrice;
                      i +=
                        (farmValueRange.estimatedPrice -
                          farmValueRange.officialPrice) /
                        10
                    ) {
                      arr.push({ value: i });
                    }
                    return arr;
                  })()}
                  valueLabelDisplay={'on'}
                  slotProps={{ valueLabel: { style: { display: 'none' } } }}
                  valueLabelFormat={(value: number, index: number) => (
                    <div
                      className="speech-bubble font-bold f-size-11 line-h-12 border-radius-2"
                      style={{
                        position: 'sticky',

                        backgroundColor: '#2A3746',
                        color: 'white',
                        padding: '4px 5px',
                        marginLeft: 0,
                        marginBottom: 0,
                      }}
                    >
                      {transNum(value)}원
                    </div>
                  )}
                  step={
                    farmValueRange.officialPrice >= 100000000
                      ? 10000000
                      : farmValueRange.officialPrice >= 10000
                        ? 10000
                        : 1000
                  }
                  tabIndex={10}
                  // getAriaValueText={valuetext}
                  sx={{
                    height: '8px',
                    '& .MuiSlider-thumb': {
                      color: '#166F4D',
                    },
                    '& .MuiSlider-track': { color: '#1E9B6B' },
                    '& .MuiSlider-rail': { color: '#F0F0F0' },
                    '& .MuiSlider-active': {
                      color: 'green',
                    },
                    '& .Mui-active': {
                      boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                    },
                    '& .MuiSlider-valueLabel': { background: 'none' },
                    '& .MuiSlider-mark': {
                      backgroundColor: '#FFFFFF',
                      height: 8,
                    },
                  }}
                  min={farmValueRange.officialPrice}
                  max={farmValueRange.estimatedPrice}
                />
                <div className="flex-row w-100per">
                  {[
                    { 1: '', 2: '공시지가', 3: 'start-y' },
                    { 1: '', 2: '상위 추정가', 3: 'end-y' },
                  ].map((el: any) => (
                    <div className={'flex-column flex-1 ' + el['3']}>
                      <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                        {el['2']}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                ※ 실제 연금액을 결정하는 농지 가치는 공시지가 100% 또는 감정평가
                액의 90%로 산정합니다.
              </div>
            </div>
          </Base>
          <Base title="2. 가입연령">
            <div className="padding-x-16 gap-24 flex-column">
              <div className="flex-row space-between center-y padding-b-16 border-b-1-171E2610">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  생년월일
                </div>
                <div
                  className="flex-row padding-6-8 bg-light-gray-200 border-radius-8 gap-8 hover"
                  onClick={() => {
                    setOnDate(true);
                  }}
                >
                  <img src="/asset/images/calender/Calender.svg" alt="" />
                  <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                    {(typeof age === 'string' && (age as any).slice(0, 7)) ||
                      '1964-01'}
                  </div>
                </div>
              </div>
              {age ? (
                <div className="flex-row space-between">
                  <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                    만 {calculateAge(age)}세
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-primary-500">
                    가입기준 {60 <= calculateAge(age) ? '' : '미'}
                    충족
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="flex-column gap-4">
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  ※ 농지연금은 만 60세 이상인 자만 가입 가능합니다. <br />
                  (신청년도 말일 기준)
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  ※ 2024년 기준 : 1964년 12월 31일 이전 출생자
                </div>
              </div>
            </div>
          </Base>
          {onDate ? (
            <BoxDateSelector1
              close={() => setOnDate(false)}
              date={age}
              setDate={setAge}
            />
          ) : (
            ''
          )}
          <div style={{ padding: '16px' }}>
            <div className="flex-column gap-4">
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                ※  농지 연금 분석 서비스는 농지연금 가입 기준 충족을 위해 수령
                조건의 일부를 가정하여 분석됩니다.
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                - 영농 경력 : 5년 이상 <br />- 농지 이용 현황 : 실제 영농으로
                이용 중 <br />- 보유 기간 : 2년 이상 <br />- 거주지 : 신청자의
                거주지와 농지 소재지가 동일
                <br />- 거리 : 신청자의 주소지와 농지와의 직선거리가 30km 이내
              </div>
            </div>
          </div>
          <div
            style={{
              padding: '0 16px',
              marginBottom: '126px',
            }}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 0',
                borderBottom: '1px solid #E1E2E4',
              }}
            >
              <div
                className="flex-column gap-8 "
                // style={{ marginBottom: '40px' }}
              >
                <div
                  className="font-medium f-size-14 line-h-20 color-text-secondary"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>※  농지 연금 가입제한 사유</div>
                  <img
                    onClick={() => changeArrowDirection()}
                    src={`/asset/images/arrow/Arrow${
                      arrowDirection === 'down' ? 'Bottom' : 'Top'
                    }24-light-gray.svg`}
                    alt=""
                  />
                </div>
                {arrowDirection === 'up' && (
                  <div
                    className="font-medium f-size-14 line-h-20 color-text-tertiary padding-16"
                    style={{ paddingTop: '0', paddingBottom: '0' }}
                  >
                    - 저당권, 제한물건, 압류 등 소유권 이외의 권리가 설정되어
                    있을 때
                    <br />- 농업용 목적이 아닌 시설 및 불법건축물이 설치되어
                    있을 때
                    <br />- 본인 및 배우자(부부) 이외의 자가 공동 소유 했을 때
                    <br />- 해당 농지가 맹지일 때
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" w-100per  "
        style={{
          width: '100%',
          position: 'fixed',
          bottom: '0',
          zIndex: 100,
          maxWidth: 'inherit',
        }}
        ref={temp}
      >
        <ButtonWrapper>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              nav('/pension/report', {
                state: {
                  age: calculateAge(age),
                  farmValue: farmValue,
                  high_avg: high_avg,
                  low_avg: low_avg,
                  avg: avg,
                  address: address,
                  c_idx: c_idx,
                  itemType: itemType,
                  pnu: pnu,
                  housekye: housekye,
                },
              });
            }}
          >
            <ButtonText> 예상 월 수령액 확인하기 </ButtonText>
          </Button>
        </ButtonWrapper>
      </div>
    </div>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 24px 16px;
  padding-top: 40px;
  background: linear-gradient(
    to bottom,
    rgba(245, 245, 245, 0) 1%,
    rgba(245, 245, 245, 1) 20%
  );
`;

const Button = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;
export default PensionInput;
