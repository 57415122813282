import { useNavigate } from 'react-router-dom';
import './MyInfo.css';
import { useEffect, useState } from 'react';
import UserService from '../../service/Users';

const jobOptions = [
  { value: '', label: '항목을 선택해 주세요' },
  { value: 'W', label: '농업인' },
  { value: 'F', label: '귀농 희망자' },
  { value: 'N', label: '귀촌 희망자' },
  { value: 'E', label: '농산업기업체' },
  { value: 'O', label: '지자체 담당자' },
  { value: 'P', label: '정부부처(공공기관)' },
  { value: 'C', label: '농산물 유통' },
  { value: 'B', label: '부동산 중개' },
  { value: 'G', label: '기타(해당사항 없음)' },
];
const areaOptions = [
  { value: '', label: '항목을 선택해 주세요' },
  { value: '서울', label: '서울' },
  { value: '부산', label: '부산' },
  { value: '대구', label: '대구' },
  { value: '인천', label: '인천' },
  { value: '광주', label: '광주' },
  { value: '대전', label: '대전' },
  { value: '울산', label: '울산' },
  { value: '세종', label: '세종' },
  { value: '경기', label: '경기' },
  { value: '강원', label: '강원' },
  { value: '충북', label: '충북' },
  { value: '충남', label: '충남' },
  { value: '전북', label: '전북' },
  { value: '전남', label: '전남' },
  { value: '경북', label: '경북' },
  { value: '경남', label: '경남' },
  { value: '제주', label: '제주' },
];

const MyInfo = () => {
  const navigate = useNavigate();

  const changeText = (code: string) => {
    return jobOptions.find((item) => item.label === code)?.value;
  };
  const initialName = localStorage.getItem('name') || '';
  const initialEmail = localStorage.getItem('email') || '';
  const initialImage = localStorage.getItem('profileImg') || '';
  const initialPhone = localStorage.getItem('phone') || '';
  const initialCategory =
    localStorage.getItem('category') === 'null'
      ? ''
      : localStorage.getItem('category') || '';

  const initialInterestArea =
    localStorage.getItem('interest_area') === 'null'
      ? ''
      : localStorage.getItem('interest_area') || '';
  const initialBirth =
    localStorage.getItem('birth') === 'null'
      ? ''
      : localStorage.getItem('birth') || '';
  const initialFarmId =
    localStorage.getItem('farm_id') === 'null'
      ? ''
      : localStorage.getItem('farm_id') || '';

  const [name, setName] = useState<string>(initialName);
  const [email, setEmail] = useState<string>(initialEmail);
  const [image, setImage] = useState<string>(initialImage);
  const [phone, setPhone] = useState<string>(initialPhone);
  const [farmId, setFarmId] = useState<string>(initialFarmId);
  const [category, setCategory] = useState(initialCategory);
  const [interest_area, setInterest_area] = useState(initialInterestArea);
  const [birth, setBirth] = useState(initialBirth);

  const checkBtnOn = () => {
    return (
      category !== initialCategory ||
      interest_area !== initialInterestArea ||
      birth !== initialBirth
    );
  };

  const getUserInfo = async () => {
    try {
      const res = await UserService.info();
      localStorage.setItem('name', res.result.name);
      localStorage.setItem('point', res.result.point);
      localStorage.setItem('profileImg', res.result.profileImage);
      localStorage.setItem('email', res.result.email);
      localStorage.setItem('phone', res.result.phone);
      localStorage.setItem('category', res.result.category);
      localStorage.setItem('interest_area', res.result.interest_area);
      localStorage.setItem('birth', res.result.birth);
      localStorage.setItem('farm_id', res.result.farm_id);
      localStorage.setItem('loginType', res.result.loginType);
    } catch (error) {
      console.error(error);
    }
  };

  const InfoUpdate = async () => {
    try {
      const data = {
        category: changeText(category),
        farmOperatorId: farmId,
        interest_area: interest_area,
        birth: birth,
      };
      const res = await UserService.update(data);
      alert('수정되었습니다.');
      getUserInfo();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container">
      <div className="page-top-myinfo">
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>내 정보</p>
        <span
          className="link-button"
          onClick={() => {
            navigate('/account');
          }}
        >
          계정관리
        </span>
      </div>
      <div className="content-box">
        <div className="basic-info-box">
          <div className="image-box">
            <div className="image-inner">
              {image === 'null' ? (
                <div className="profile"></div>
              ) : (
                <img
                  className="profile"
                  src={`${process.env.REACT_APP_HOST}${image}`}
                  alt=""
                />
              )}

              {/* <img
                className="pencil"
                src="/asset/images/icon/edit.svg"
                alt=""
                onClick={() => {}}
              /> */}
            </div>
          </div>
          <div className="info-input-box">
            <div className="section-title">내정보</div>
            <div className="info_item">
              <div className="label">
                이름 <span>*</span>
              </div>
              <input type="text" className="info-input" disabled value={name} />
            </div>
            <div className="info_item">
              <div className="label">
                이메일 <span>*</span>
              </div>
              <input
                type="text"
                className="info-input"
                disabled
                value={email}
              />
            </div>
            <div className="info_item">
              <div className="label">
                휴대폰 번호 <span>*</span>
              </div>
              <input
                type="text"
                className="info-input"
                disabled
                value={phone}
              />
              <div className="ask-change-phone">
                휴대폰 번호 변경은{' '}
                <span
                  onClick={() => {
                    navigate('/inquiry');
                  }}
                >
                  문의하기
                </span>
                로 요청해 주세요.
              </div>
            </div>
          </div>
        </div>
        <div className="extra-info-box">
          <div className="section-title">추가 정보 (선택)</div>
          <div className="info_item">
            <div className="label">소속 혹은 농촌에 대한 관심사</div>
            <div className="info-select-box">
              <select
                className="select-non-border"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {jobOptions.map((item, index) => (
                  <option key={index} value={item.label}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {category === '농업인' && (
            <div className="info_item">
              <input
                type="text"
                className="info-input"
                value={farmId}
                placeholder="농업 경영체 등록 번호를 입력해주세요"
                onChange={(e) => {
                  setFarmId(e.target.value);
                }}
              />
            </div>
          )}
          <div className="info_item">
            <div className="label">관심 지역</div>
            <div className="info-select-box">
              <select
                className="select-non-border"
                value={interest_area}
                onChange={(e) => {
                  setInterest_area(e.target.value);
                }}
              >
                {areaOptions.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="info_item">
            <div className="label">생년월일</div>
            <input
              type="text"
              className="info-input"
              placeholder="예) 19001204"
              value={birth}
              onChange={(e) => {
                setBirth(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="apply-button-box">
        <button
          className="apply-button"
          style={{
            color: checkBtnOn() ? '#ffffff' : 'rgba(23, 30, 38, 0.40)',
            backgroundColor: checkBtnOn() ? '#1E9B6B' : '#F0F0F0',
          }}
          onClick={() => {
            InfoUpdate();
          }}
        >
          수정하기
        </button>
      </div>
    </div>
  );
};

export default MyInfo;
