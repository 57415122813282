import styled from 'styled-components';
import colors from '../../color';

export const Container = styled.div`
  max-width: 720px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  box-shadow:
    0 0 30px hsla(0, 0%, 51%, 0.15),
    inset 0 0 10px hsla(0, 0%, 51%, 0.1);

  z-index: 1;
`;
export const TopTextBox = styled.div`
  width: 100%;
  padding: 24px 16px;
  font-family: Pretendard-Bold;
  font-size: 24px;
  line-height: 145%;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TextGreen = styled.span`
  color: ${colors.primary500};
`;

export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
  margin-bottom: 54px;
`;

export const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  border: 1px rgba(112, 115, 124, 0.22) solid;
`;

export const CardContent = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CardTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

export const CardTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: #171e26;

 
`;
export const Description = styled.div`
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;

export const DescriptionBig = styled.div`
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 18px;
  color: #171e26;
  margin-bottom: 8px;
`;

export const CardTagBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const Tag = styled.span`
  padding: 8px 12px;
  border-radius: 100px;
  border: 1px solid ${colors.primary500};
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.primary500};
`;

export const Button = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: white;
  background-color: ${colors.primary500};
`;

export const MoreInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.textSecondary};
  margin-top: 8px;
`;

export const SafetyBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 16px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

export const SafetyTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  font-size: 14px;
`;
export const SafetyDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #eff9f5;
  span {
    color: #1e9b6b;
  }
`;

export const DescriptionTitle = styled.div`
  color: rgba(55, 56, 60, 0.61);
`;
