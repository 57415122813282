import styled from 'styled-components';
import { renderIcon } from '../../render';
import colors from '../../../../color';
import { useNavigate } from 'react-router-dom';
import { useMapContext } from '../../../../contexts/MapContext';
import { NonPropertyList } from '../../MainData';
import { useEffect } from 'react';

interface NonPropertyModalProps {
  data: NonPropertyList;
  setListOn: (value: boolean) => void;
}

const checkIsLand = (category: string) => {
  return (
    category === '전' ||
    category === '답' ||
    category === '과수원' ||
    category === '임야' ||
    category === '대'
  );
};
const NonPropertyModal = ({ data, setListOn }: NonPropertyModalProps) => {
  const navigate = useNavigate();
  const { latLng } = useMapContext();
  const { setNonPropertyOn } = useMapContext();
  // useEffect(() => {
  //   if (sessionStorage.getItem('direct') === 'Y') {
  //     sessionStorage.setItem('direct', 'N');
  //     if (data.house.length > 0 && !checkIsLand(data.house[0].category)) {
  //       navigate(
  //         `/detail?type=H&pnu=${data.house[0].pnucode}&housekye=${data.house[0].housekey}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
  //       );
  //       setNonPropertyOn(false);
  //     } else if (data.land.length > 0 && checkIsLand(data.land[0].category)) {
  //       navigate(
  //         `/detail?type=L&pnu=${data.land[0].pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
  //       );
  //       setNonPropertyOn(false);
  //     }
  //   }
  // }, []);
  // 렌더링 전에 pnu와 area를 매핑
  const landMap = new Map(data.land.map((land: any) => [land.pnu, land.area]));
  return (
    <Container>
      <List
      // style={{
      //   height:
      //     data.house.length > 0 && checkIsLand(data.house[0].category)
      //       ? '281px'
      //       : 'auto',
      // }}
      >
        {data.house.length === 0 &&
          data.land.map((item: any, index: number) => (
            <ListItem key={item.pnu} first={index === 0}>
              <InfoBox>
                <InfoTop>
                  <InfoTopTextBox>
                    <TypeText>토지</TypeText>
                    <CategoryText>{item.category}</CategoryText>
                    <Divider />
                    <AreaText>{Number(item.area).toLocaleString()}m² </AreaText>
                  </InfoTopTextBox>
                  {index === 0 && (
                    <img
                      src="/asset/images/close/close-lightgray.svg"
                      alt=""
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setNonPropertyOn(false);
                        setListOn(true);
                      }}
                    />
                  )}
                </InfoTop>
                <Address>
                  <img
                    src={`/asset/images/icon/${renderIcon(item.category)}.svg`}
                    alt=""
                    style={{ width: '14px', height: '14px' }}
                  />
                  <span>{item.address}</span>
                </Address>
              </InfoBox>
              {checkIsLand(item.category) ? (
                <GotoDatailBtn
                  onClick={() => {
                    navigate(
                      `/detail?type=L&pnu=${item.pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                    );
                    setNonPropertyOn(false);
                  }}
                >
                  상세보기
                </GotoDatailBtn>
              ) : (
                <NonServiceMessage>
                  <span>서비스 제공 가능 주소지가 아닙니다.</span>
                  <TextRegular>
                    현재 전/답/과수/임야 정보만 제공됩니다.
                  </TextRegular>
                </NonServiceMessage>
              )}
            </ListItem>
          ))}
        {data.house.length > 0 &&
          checkIsLand(data.house[0].category) &&
          data.house.map((item: any, index: number) => (
            <ListItem key={item.pnu} first={index === 0}>
              <InfoBox>
                <InfoTop>
                  <InfoTopTextBox>
                    <TypeText>주택</TypeText>
                    <CategoryText>{item.category}</CategoryText>
                    <Divider />
                    <AreaText>
                      대지{' '}
                      {Number(landMap.get(item.pnucode) || 0).toLocaleString()}
                      m²{' '}
                    </AreaText>
                    <AreaText>
                      건물 {Number(item.area).toLocaleString()}m²{' '}
                    </AreaText>
                  </InfoTopTextBox>
                  {index === 0 && (
                    <img
                      src="/asset/images/close/close-lightgray.svg"
                      alt=""
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setNonPropertyOn(false);
                        setListOn(true);
                      }}
                    />
                  )}
                </InfoTop>
                <Address>
                  <img
                    src={`/asset/images/icon/${renderIcon(item.category)}.svg`}
                    alt=""
                    style={{ width: '14px', height: '14px' }}
                  />
                  <span>{item.address}</span>
                </Address>
              </InfoBox>
              <GotoDatailBtn
                onClick={() => {
                  navigate(
                    `/detail?type=H&pnu=${item.pnucode}&housekye=${item.housekey}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                  );
                  setNonPropertyOn(false);
                }}
              >
                상세보기
              </GotoDatailBtn>
            </ListItem>
          ))}
      </List>
    </Container>
  );
};
export default NonPropertyModal;

interface ContainerProps {
  first?: boolean;
}

const Container = styled.div`
  position: absolute;
  bottom: 66px;
  left: 0;
  width: 100%;
  z-index: 3000;
  padding: 0 12px;
`;

const List = styled.div`
  width: 100%;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

const ListItem = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: ${({ first }) => (first ? 0 : '16px')};
  border-top: ${({ first }) =>
    first ? 'none' : `1px solid ${colors.lineNormal}`};
`;

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InfoTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InfoTopTextBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const TypeText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
  padding: 2px 6px;
  border-radius: 4px;
  background-color: ${colors.backgroundNeutral};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const Divider = styled.div`
  width: 1px;
  height: 8px;
  background-color: ${colors.lineNormal};
`;
const AreaText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const Address = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelStrong};
`;

const GotoDatailBtn = styled.span`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${colors.primary500};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 26.4px;
  color: ${colors.primary500};
  cursor: pointer;
`;
const NonServiceMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
`;
const TextRegular = styled.span`
  font-family: Pretendard-Regular;
  font-size: 12px;
  color: rgba(55, 56, 60, 0.61);
`;
