import React, { createContext, useContext, useState } from 'react';

interface SignUpContextType {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  loginType: string;
  setLoginType: React.Dispatch<React.SetStateAction<string>>;
  isEmailValid: boolean;
  setIsEmailValid: React.Dispatch<React.SetStateAction<boolean>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  farmOperatorId: string;
  setFarmOperatorId: React.Dispatch<React.SetStateAction<string>>;
  etc: string;
  setEtc: React.Dispatch<React.SetStateAction<string>>;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  isPasswordValid: boolean;
  setIsPasswordValid: React.Dispatch<React.SetStateAction<boolean>>;
  isPasswordMatch: boolean;
  setIsPasswordMatch: React.Dispatch<React.SetStateAction<boolean>>;
  allAgree: boolean;
  setAllAgree: React.Dispatch<React.SetStateAction<boolean>>;
  privacyAgree: boolean;
  setPrivacyAgree: React.Dispatch<React.SetStateAction<boolean>>;
  serviceAgree: boolean;
  setServiceAgree: React.Dispatch<React.SetStateAction<boolean>>;
  locationAgree: boolean;
  setLocationAgree: React.Dispatch<React.SetStateAction<boolean>>;
  notificationAgree: boolean;
  setNotificationAgree: React.Dispatch<React.SetStateAction<boolean>>;
  availableEmail: boolean;
  setAvailableEmail: React.Dispatch<React.SetStateAction<boolean>>;
  socialId: string;
  setSocialId: React.Dispatch<React.SetStateAction<string>>;
}

// Context 생성
const SignUPContext = createContext<SignUpContextType | null>(null);

// Context Provider 컴포넌트
export const SignUpInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [email, setEmail] = useState('');
  const [loginType, setLoginType] = useState('C');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [socialId, setSocialId] = useState('');
  const [category, setCategory] = useState('');
  const [farmOperatorId, setFarmOperatorId] = useState('');
  const [etc, setEtc] = useState('');
  const [code, setCode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [allAgree, setAllAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [serviceAgree, setServiceAgree] = useState(false);
  const [locationAgree, setLocationAgree] = useState(false);
  const [notificationAgree, setNotificationAgree] = useState(false);
  const [availableEmail, setAvailableEmail] = useState(true);

  return (
    <SignUPContext.Provider
      value={{
        email,
        setEmail,
        loginType,
        setLoginType,
        isEmailValid,
        setIsEmailValid,
        phone,
        setPhone,
        password,
        setPassword,
        name,
        setName,
        category,
        setCategory,
        farmOperatorId,
        setFarmOperatorId,
        etc,
        setEtc,
        code,
        setCode,
        confirmPassword,
        setConfirmPassword,
        isPasswordValid,
        setIsPasswordValid,
        isPasswordMatch,
        setIsPasswordMatch,
        allAgree,
        setAllAgree,
        privacyAgree,
        setPrivacyAgree,
        serviceAgree,
        setServiceAgree,
        locationAgree,
        setLocationAgree,
        notificationAgree,
        setNotificationAgree,
        availableEmail,
        setAvailableEmail,
        socialId,
        setSocialId,
      }}
    >
      {children}
    </SignUPContext.Provider>
  );
};

// Context를 사용하기 위한 커스텀 훅
export const SignUpInfo = () => {
  const context = useContext(SignUPContext);
  if (!context) {
    throw new Error(
      'useFilterContext must be used within a SignUpInfoProvider',
    );
  }
  return context;
};
