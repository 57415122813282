import { useEffect, useRef, useState } from 'react';
import { S } from './Product.styles';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';

const data = [
  {
    profileText: '안전지향형 임대사업가',
    tag: '창고 임대',
    avatarImg: '/asset/images/landMarket/avatar1.png',
    text1: '월세 버금가는 보관료 수입',
    text2: '땅에서 나오는 고정수익의 비밀',
    exampleImg: '/asset/images/landMarket/card1.svg',
    itemTags: ['매월고정수입', '관리용이성', '장기계약가능'],
    backGroundImg: '/asset/images/landMarket/card-border83.svg',
    height: '486px',
    buttonText: '150평 창고, 얼마면 시작할 수 있을까?',
  },
  {
    profileText: '미래지향형 투자가',
    tag: '태양광 발전 임대',
    avatarImg: '/asset/images/landMarket/avatar2.png',
    text1: '20년 안정적 고정수익',
    text2: '햇빛으로 만드는 황금빛 노후',
    exampleImg: '/asset/images/landMarket/card2.svg',
    itemTags: ['초기 투자 후 장기 안정적 월 수익'],
    backGroundImg: '/asset/images/landMarket/card-border129.svg',
    height: '486px',
    buttonText: '300평 태양광, 얼마면 시작할 수 있을까?',
  },
  {
    profileText: '액티브한 시니어',
    tag: '캠핑장',
    avatarImg: '/asset/images/landMarket/avatar4.png',
    text1: '캠핑 열풍 속 대세',
    text2: '주말마다 통장이 두둑해지는 수익성',
    exampleImg: '/asset/images/landMarket/card4.svg',
    itemTags: ['아웃도어 활동형', '성수기 주말 특수'],
    backGroundImg: '/asset/images/landMarket/card-border66.svg',
    height: '494px',
    buttonText: '900평 캠핑장, 얼마면 시작할 수 있을까?',
  },
  {
    profileText: '활발한 소통가',
    tag: '펜션/숙박업',
    avatarImg: '/asset/images/landMarket/avatar5.png',
    text1: '계절마다 다른 매력',
    text2: '펜션으로 즐기면서 버는 인생 2막',
    exampleImg: '/asset/images/landMarket/card5.svg',
    itemTags: ['공간 창조형', '개성있는 시즌 마케팅'],
    backGroundImg: '/asset/images/landMarket/card-border100.svg',
    height: '494px',
    buttonText: '150평 펜션, 얼마면 시작할 수 있을까?',
  },
];

const LandProduct = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [marketTab, setMarketTab] = useState('토지상품');
  const [showButton, setShowButton] = useState(false);
  const nav = useNavigate();

  const onClickTopButton = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        setShowButton(scrollTop >= 100);
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <S.Container ref={containerRef}>
      <S.TopSearchBox>
        <img
          src="/asset/images/logo/land-market.svg"
          alt="logo"
          onClick={() => {
            nav('/');
          }}
          style={{ cursor: 'pointer' }}
        />
        <S.LandMarketTabBox>
          <S.TabButton
            active={marketTab === '토지상품'}
            onClick={() => {
              nav('/landMarket/product');
            }}
          >
            토지 상품
          </S.TabButton>
          <S.TabButton
            active={marketTab === '유휴토지 신청'}
            onClick={() => {
              nav('/landMarket/apply');
            }}
          >
            유휴토지 신청
          </S.TabButton>
        </S.LandMarketTabBox>
      </S.TopSearchBox>
      <S.CotentBox>
        <S.MainSection>
          <img
            src="/asset/images/landMarket/main-text1.svg"
            alt=""
            style={{ width: '222px' }}
          />
          <S.ImgBox>
            <img
              src="/asset/images/landMarket/main-text2.svg"
              alt=""
              style={{ width: '312px' }}
            />
          </S.ImgBox>
          <S.BuildingImg src="/asset/images/landMarket/building.svg" alt="" />
        </S.MainSection>
        <S.ContentBox>
          {data.map((item, index) => (
            <S.Card
              key={index}
              style={{
                backgroundImage: `url(${item.backGroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: item.height,
              }}
            >
              <S.CardProfile>
                <S.ProfileText>
                  <S.Avatar src={item.avatarImg} alt="" />
                  <span>{item.profileText}</span>
                </S.ProfileText>
                <S.Tag>{item.tag}</S.Tag>
              </S.CardProfile>
              <S.ExampleBox>
                <S.TextBox>
                  <div>{item.text1}</div>
                  <div>{item.text2}</div>
                </S.TextBox>
                <img src={item.exampleImg} alt="" />
                <S.ItemTagBox>
                  {item.itemTags.map((tag, index) => (
                    <S.ItemTag key={index}>{tag}</S.ItemTag>
                  ))}
                </S.ItemTagBox>
              </S.ExampleBox>
              <S.InstructionBox>
                <S.Instruction>
                  <img src="/asset/images/icon/point.svg" alt="" />
                  <span>{item.buttonText}</span>
                </S.Instruction>
                <S.ButtonBox>
                  <S.RecommendButton
                    href="https://naver.me/GHv2v72h"
                    target="blank"
                  >
                    <span>맞춤 토지 추천 받기</span>
                    <img src="/asset/images/arrow/arrow-white-20.svg" alt="" />
                  </S.RecommendButton>
                  <S.CheckButton
                    href="https://naver.me/xzxeMy5E"
                    target="blank"
                  >
                    <span>내 토지 적합성 확인</span>
                    <img
                      src="/asset/images/arrow/arrow-right-gray-20.svg"
                      alt=""
                    />
                  </S.CheckButton>
                </S.ButtonBox>
              </S.InstructionBox>
            </S.Card>
          ))}
        </S.ContentBox>
        <S.Extra>
          <S.ExtraText>
            더 다양한 토지 활용 상품을 <br />
            준비 중입니다
          </S.ExtraText>
        </S.Extra>
      </S.CotentBox>
      <Footer />
      {showButton && (
        <S.TopButton>
          <img
            onClick={onClickTopButton}
            src="/asset/images/arrow/arrow-to-top.png"
            alt=""
          />
        </S.TopButton>
      )}
    </S.Container>
  );
};
export default LandProduct;
