import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class LandIncome {
  static cropList = async (pnu: string, facility: string, area?: number) => {
    let url = `sales/crop/list?pnu=${pnu}&facility=${facility}`;

    // area 값이 존재할 경우 쿼리 스트링에 추가
    if (area !== undefined) {
      url += `&area=${area}`;
    }
    const result = await request.get(url);
    return result.data;
  };

  static cropResult = async (data: any) => {
    const result = await request.post('sales/result/income', data);
    return result.data;
  };
}
