import styled from 'styled-components';
import SelectCrop from './component/SelectCrop';
import UnitSwitch from '../../../../components/unitSwitch/UnitSwitch';
import { useEffect, useState } from 'react';
import InitialInvestment from './component/InitialInvestment';
import { CropInfo } from './component/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LandIncome from '../../../../../service/LandIncome';
import LoanState from './component/LoanState';
import { useMapContext } from '../../../../../contexts/MapContext';

const LandIncomeInput = () => {
  const location = useLocation();
  const nav = useNavigate();
  //area는 제곱미터로 받아옴
  const { c_idx, pnu, area, avg, address, itemType, housekye } =
    location.state || {};

  const [unit, setUnit] = useState('평');
  const [crop, setCrop] = useState('');
  const [index, setIndex] = useState(-1);
  const [selectedCrop, setSelectedCrop] = useState<CropInfo>(new CropInfo());
  const [anotherCrop, setAnotherCrop] = useState<any>();
  const [recommendCrop, setRecommendCrop] = useState<CropInfo[]>([]);
  const [facility, setFacility] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number, value: string) => {
    setActiveIndex(index);
    setFacility(value);
  };

  const landTypes = [
    { label: '노지', value: '노지' },
    { label: '비닐하우스', value: '시설' },
    { label: '스마트팜', value: '스마트팜' },
  ];

  const getCropList = async (pnu: string, facility: string, area?: number) => {
    const res =
      area !== undefined
        ? await LandIncome.cropList(pnu, facility, area)
        : await LandIncome.cropList(pnu, facility);
    setRecommendCrop(res.result.recommendCrop);
    setAnotherCrop(res.result.anotherCrop);
  };

  useEffect(() => {
    if (pnu && facility) {
      getCropList(pnu, facility);
    }
  }, [facility]);

  useEffect(() => {
    if (index > recommendCrop.length - 1) {
      setSelectedCrop(anotherCrop[index - recommendCrop.length]);
    } else {
      setSelectedCrop(recommendCrop[index]);
    }
  }, [index]);

  const { latLng } = useMapContext();

  const [reportData, setReportData]: any = useState({
    loanInfo: [],
  });
  const updateReportData = (data: any) => {
    setReportData({ ...reportData, ...data });
  };

  const onClickResult = () => {
    const data = {
      pnu: pnu,
      facility: facility,
      quantity: selectedCrop.quantity,
      unitPrice: selectedCrop.unitPrice,
      crops: 1,
      landPurchaseCost: selectedCrop.landPurchaseCost || avg,
      facilityCost: selectedCrop.facilityCost,
      farmMachineryCost: selectedCrop.farmMachineryCost || 0,
      empLaborCost: selectedCrop.empLaborCosts,
      ownLaborCost: selectedCrop.ownLaborCosts,
      materialCost: selectedCrop.materialCost,
      waterHeatingCost: selectedCrop.waterHeatingCost,
      equipmentRentalCost: selectedCrop.farmEquipmentRentalCost,
      SGA: selectedCrop.SGA,
      orchardCost: selectedCrop.orchardAdjustmentCost,
      loanFlow: reportData.loanInfo,
      c_idx: Number(c_idx) || 0,
    };
    const cropName = selectedCrop.name;
    nav('/landIncome/result', {
      state: {
        data: data,
        c_idx: c_idx,
        pnu: pnu,
        area: area,
        landPrice: avg,
        cropName: cropName,
        itemType: itemType,
        housekye: housekye,
        address: address,
      },
    });
  };

  return (
    <LandIncomeInputContainer>
      <CancelButton>
        <div
          onClick={() => {
            if (c_idx === '') {
              if (housekye !== '') {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
                );
              } else {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                );
              }
            } else {
              nav(
                `/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
              );
            }
          }}
        >
          <img src="/asset/images/close/close-gray.svg" alt="" />
        </div>
      </CancelButton>
      <LandIncomeInnerContainer>
        <LandIncomeHeader>
          <div>경작 예상 소득</div>
          <HeaderContent>
            재배환경, 작물을 선택 후 예산을 입력하면 해당 토지의 가치를 확인할
            수 있습니다.
          </HeaderContent>
        </LandIncomeHeader>
        <InputHeader>
          <div>맞춤 조건 입력하기</div>
          <InputHeaderContent>{address}</InputHeaderContent>
        </InputHeader>
        <LandTypeWrapper>
          <div>1.토지 소득 분석</div>
          <LandTypeBox>
            {landTypes.map((type, index) => (
              <LandTypeContent
                key={index}
                isActive={activeIndex === index}
                onClick={() => handleClick(index, type.value)}
              >
                <img
                  src={
                    activeIndex === index
                      ? '/asset/images/icon/check_box_on.png'
                      : '/asset/images/icon/check_box_off.png'
                  }
                  alt={type.label}
                />
                {type.label}
              </LandTypeContent>
            ))}
          </LandTypeBox>
        </LandTypeWrapper>
        <SelectCrop
          crop={crop}
          setCrop={setCrop}
          recommendCrop={recommendCrop}
          facility={facility}
          anotherCrop={anotherCrop}
          setIndex={setIndex}
          index={index}
        ></SelectCrop>
        <ExpectedArearBox>
          <AreaTitle>
            <div>3.예상 경작면적</div>
            <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
          </AreaTitle>
          <AreaUnitBox>
            <AreaInput
              type="text"
              placeholder="예상 경작면적을 입력하세요"
              value={
                unit === '평'
                  ? (Number(area) / 3.305).toFixed(2).toLocaleString()
                  : Number(area).toLocaleString()
              }
            />
            <AreaUnit>{unit === '평' ? '평' : 'm²'}</AreaUnit>
          </AreaUnitBox>
        </ExpectedArearBox>
        <InitialInvestment
          selectedCrop={selectedCrop}
          setSelectedCrop={setSelectedCrop}
          landPrice={avg}
        />
        <LoanState
          reportData={reportData}
          updateReportData={updateReportData}
        ></LoanState>
        <DescriptionBox>
          ※ 경작 소득 분석 서비스는 경작 환경의 일부를 가정하여 분석됩니다.
          <br />
          <br />- 1년 재배 횟수 : 1기작
          <br />- 농지 소유 형태 : 매매(임차 제외)
          <br />- 영농 경력 유무 : 있음
          <br />- 경작 능력 : 지역 평균
        </DescriptionBox>
        <DescriptionBox>
          ※ 경작 소득 분석 서비스는 경작 환경의 일부를 가정하여 분석됩니다.
          <br />
          <br />- 초기 투자 비용 : 80% 대출 가능
          <br />- 대출금 한도 : 스마트팜이 아닌 경우 3억 원 제한
          <br />- 이자율 : 2%
          <br />- 거치 기간: 5년
          <br />- 대출 기간: 15년 (원리금 균등 상환)
        </DescriptionBox>
      </LandIncomeInnerContainer>
      <ResultButtonBox
        isSelect={selectedCrop?.name !== undefined}
        onClick={() => {
          if (selectedCrop?.name === undefined) {
            return;
          } else {
            onClickResult();
          }
        }}
      >
        <div>경작 예상 소득 확인하기</div>
      </ResultButtonBox>
    </LandIncomeInputContainer>
  );
};

interface SelectCropProps {
  isSelect: boolean;
}

export default LandIncomeInput;

const LandIncomeInputContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f5;
`;

const CancelButton = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
  font-size: 36px;
  cursor: pointer;
`;

const LandIncomeInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 118px 16px;
  padding-top: 0px;
`;

const LandIncomeHeader = styled.div`
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: black;
  font-size: 20px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 29px;
  word-wrap: break-word;
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 72px;
  color: #1e9b6b;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding: 16px;
  background-color: #eaf8f2;
  border-radius: 8px;
`;

const InputHeader = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const InputHeaderContent = styled.div`
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const LandTypeWrapper = styled.div`
  width: 100%;
  height: 272px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: white;
  border-radius: 8px;
  padding: 24px 16px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const LandTypeBox = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LandTypeContent = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  padding: 12px 16px;
  border: 1px rgba(112, 115, 124, 0.22) solid;
  border-radius: 8px;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;

  ${(props) =>
    props.isActive &&
    `
    background-color: #EAF8F2; 
    border: 1px #1E9B6B solid ;
    color: #1E9B6B;
  `}
`;

const ExpectedArearBox = styled.div`
  width: 100%;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 24px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  background-color: white;
  border-radius: 8px;
`;

const AreaTitle = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const AreaInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  text-align: end;
`;

const AreaUnitBox = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  border: 1px rgba(112, 115, 124, 0.22) solid;
  border-radius: 8px;
  padding: 6px 8px;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  align-items: center;
`;

const AreaUnit = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const DescriptionBox = styled.div`
  width: 100%;
  height: 140px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ResultButtonBox = styled.div<SelectCropProps>`
  position: fixed;
  bottom: 0px;
  max-width: 720px;
  margin: 0 auto;
  padding: 24px 16px;
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(245, 245, 245, 0) 1%,
    rgba(245, 245, 245, 1) 20%
  );
  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  cursor: ${(props) => (props.isSelect ? 'pointer' : 'default')};
  div {
    display: flex;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 8px;
    width: 100%;
    background-color: ${(props) =>
      props.isSelect ? '#1e9b6b' : 'rgba(55, 56, 60, 0.16)'};
    color: ${(props) => (props.isSelect ? 'white' : 'rgba(55, 56, 60, 0.28)')};
  }
`;
