import styled from 'styled-components';
import colors from '../../../color';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2),
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PageNumber
          key={i}
          onClick={() => onPageChange(i)}
          isActive={i === currentPage}
        >
          {i}
        </PageNumber>,
      );
    }
    return pageNumbers;
  };

  return (
    <Container>
      <ArrowButton
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
      </ArrowButton>
      <PageNumbersContainer>{renderPageNumbers()}</PageNumbersContainer>
      <ArrowButton
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        style={{ transform: 'rotate(180deg)' }}
      >
        <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
      </ArrowButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 40px 0;
`;

const PageNumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const PageNumber = styled.button<{ isActive: boolean }>`
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
  line-height: 26.4px;
  color: ${({ isActive }) =>
    isActive ? colors.textPrimary : colors.textTetiary};
  background: none;
  border: none;
  cursor: pointer;
`;

const ArrowButton = styled.button`
  background: #f0f0f0;
  border-radius: 999px;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    cursor: not-allowed;
  }
`;

export default Pagination;
