import React, { createContext, useContext, useState } from 'react';

interface Address {
  sido: string;
  sigungu: string;
  eupmd: string;
  ri: string;
}

interface PropertyFilterContextType {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  isSafeItem: boolean;
  setIsSafeItem: React.Dispatch<React.SetStateAction<boolean>>;
  propertyFilterOption: {
    target: string;
    sale_type: string[];
    category: string[];
    purpose: string[];
    price: [string, string];
    area: [string, string];
    year: [string, string];
    theme: string[];
  };
  setPropertyFilterOption: React.Dispatch<
    React.SetStateAction<typeof initialFilterOption>
  >;
  propertySort: string;
  setPropertySort: React.Dispatch<React.SetStateAction<string>>;
  propertyPnu: string;
  setPropertyPnu: React.Dispatch<React.SetStateAction<string>>;
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
}

const initialFilterOption = {
  target: '전체',
  sale_type: [] as string[],
  category: [] as string[],
  purpose: [] as string[],
  price: ['', ''] as [string, string],
  area: ['', ''] as [string, string],
  year: ['', ''] as [string, string],
  theme: [] as string[],
};

// Context 생성
const PropertyFilterContext = createContext<PropertyFilterContextType | null>(
  null,
);

// Context Provider 컴포넌트
export const PropertyFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tab, setTab] = useState('매물');
  const [isSafeItem, setIsSafeItem] = useState(false);
  const [propertyFilterOption, setPropertyFilterOption] =
    useState(initialFilterOption);
  const [propertySort, setPropertySort] = useState('PA');
  const [propertyPnu, setPropertyPnu] = useState('');
  const [address, setAddress] = useState({
    sido: '',
    sigungu: '',
    eupmd: '',
    ri: '',
  });

  return (
    <PropertyFilterContext.Provider
      value={{
        tab,
        setTab,
        isSafeItem,
        setIsSafeItem,
        propertyFilterOption,
        setPropertyFilterOption,
        propertySort,
        setPropertySort,
        propertyPnu,
        setPropertyPnu,
        address,
        setAddress,
      }}
    >
      {children}
    </PropertyFilterContext.Provider>
  );
};

// Context를 사용하기 위한 커스텀 훅
export const usePropertyFilter = () => {
  const context = useContext(PropertyFilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};
