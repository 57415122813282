import styled from 'styled-components';
import ListMap from './ListMap';
import { MapData } from '../../detail/data';
import { useEffect, useState } from 'react';
import Interaction from '../../../service/interaction';

interface listImgProps {
  img: string;
  lat: number;
  lng: number;
  geom: [number, number][];
  price: number;
  category: string;
  area: number;
  pyeong: number;
  bidcount: number | 1;
  saleType: string;
  dealdate: string | null;
}

const ListImg = ({
  img,
  lat,
  lng,
  geom,
  price,
  category,
  area,
  pyeong,
  bidcount,
  saleType,
  dealdate,
  
}: listImgProps) => {
  const data = {
    lat: lat,
    lng: lng,
    geom: geom,
    price: price,
    category: category,
    area: area,
    pyeong: pyeong,
    bidcount: bidcount,
    dealdate: dealdate,
  };

  const renderImg = () => {
    if (img) {
      return <ListImgPhoto src={img} alt="img" />;
    } else {
      return <ListMap data={data} saleType={saleType}></ListMap>;
    }
  };

  return (
    <ListImgBox>
      {renderImg()}
    </ListImgBox>
  );
};

export default ListImg;

const ListImgBox = styled.div`
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */
  border: none;
  outline: none;
`;

const ListImgPhoto = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */
  border: none;
  outline: none;
`;

