import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Pension from '../../../../../service/pension';
import Sales from '../../../../../service/Sales';
import { OriginProps } from '../../../data';
import {
  formatToKoreanCurrency,
  transNum,
} from '../../../../../function/function';
import Toast from '../../../../components/common/Toast';
import DataSource from '../../landIncome/result/components/DataSource';
import { useMapContext } from '../../../../../contexts/MapContext';

const ageObj = {
  20: 63,
  15: 68,
  10: 73,
  5: 78,
};
const PensionReportMain = ({}) => {
  const location = useLocation();
  const nav = useNavigate();
  const {
    age,
    farmValue,
    high_avg,
    low_avg,
    avg,
    address,
    c_idx,
    itemType,
    pnu,
    housekye,
  } = location.state || {};

  const [origin, setOrigin] = useState<OriginProps[]>([]);
  const [buttonActive, setButtonActive] = useState(true);
  const [monthlyBtn, setMonthlyBtn] = useState(true);
  const [toastAge, setToastAge] = useState(0);
  const [fixedTab, setFixedTab] = useState(0);

  const [totalData, setTotalData]: any = useState({
    fixedTerm63: {
      year: 0,
      month: 0,
    },
    fixedTerm68: {
      year: 0,
      month: 0,
    },
    fixedTerm73: {
      year: 0,
      month: 0,
    },
    fixedTerm78: {
      year: 0,
      month: 0,
    },
    management63: {
      year: 0,
      month: 0,
    },
    management68: {
      year: 0,
      month: 0,
    },
    management73: {
      year: 0,
      month: 0,
    },
    management78: {
      year: 0,
      month: 0,
    },
    fixedAmountForLife: {
      year: 4318920,
      month: 359910,
    },
    backAndForthThinning: {
      backThinning: {
        year: 5213280,
        month: 434440,
      },
      forthThinning: {
        year: 3649320,
        month: 304110,
      },
    },
    occasionalWithdrawal: {
      pension: {
        year: 3049200,
        month: 254100,
      },
      withdrawal: {
        year: 300000000,
        month: 25000000,
      },
    },
  });
  const getOrigin = async () => {
    try {
      const res = await Sales.origin();
      setOrigin(res.result);
    } catch (e) {
      console.log(e);
    }
  };

  const getResult = async () => {
    const data = await Pension.resultPension(
      Number(age),
      Number(farmValue),
      Number(c_idx ?? 0),
      pnu,
    );
    setTotalData(data.result);
  };

  useEffect(() => {
    getResult();
    getOrigin();
    setToastWidth(window.innerWidth > 720 ? 720 : window.innerWidth);
  }, [farmValue, age]);

  const [onToast, setOnToast] = useState(false);
  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 1000);
    }
  }, [onToast]);
  const [toastWidth, setToastWidth] = useState(0);

  //데이터 출처
  const [arrowDirection, setArrowDirection] = useState('down');
  const changeArrowDirection = () => {
    setArrowDirection((prevDirection) =>
      prevDirection === 'down' ? 'up' : 'down',
    );
  };

  const periodPrice = (fixedTab: number, type: string, monthlyBtn: boolean) => {
    if (type === 'fixedTerm') {
      switch (fixedTab) {
        case 1:
          return monthlyBtn
            ? totalData.fixedTerm78.month
            : totalData.fixedTerm78.year;
        case 2:
          return monthlyBtn
            ? totalData.fixedTerm73.month
            : totalData.fixedTerm73.year;
        case 3:
          return monthlyBtn
            ? totalData.fixedTerm68.month
            : totalData.fixedTerm68.year;
        case 4:
          return monthlyBtn
            ? totalData.fixedTerm63.month
            : totalData.fixedTerm63.year;
        default:
          return 0;
      }
    } else {
      switch (fixedTab) {
        case 1:
          return monthlyBtn
            ? totalData.management78.month
            : totalData.management78.year;
        case 2:
          return monthlyBtn
            ? totalData.management73.month
            : totalData.management73.year;
        case 3:
          return monthlyBtn
            ? totalData.management68.month
            : totalData.management68.year;
        case 4:
          return monthlyBtn
            ? totalData.management63.month
            : totalData.management63.year;
        default:
          return 0;
      }
    }
  };

  const { latLng } = useMapContext();

  return (
    <Container>
      {onToast && (
        <div className="flex-center z-100 ">
          <Toast
            text={`만 나이 ${
              ageObj[toastAge as 10]
            }세 이상이 아니므로 해당사항 없습니다.`}
            bottom={550}
            left={25}
            width={toastWidth}
          />
        </div>
      )}
      <Header>
        <CloseButton
          onClick={() => {
            if (c_idx === '') {
              if (housekye !== '') {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
                );
              } else {
                nav(
                  `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                );
              }
            } else {
              nav(
                `/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
              );
            }
          }}
        >
          <img src="/asset/images/close/close-gray.svg" alt="" />
        </CloseButton>
        <HeaderContent>
          <Title>농지 연금 분석</Title>
          <PensionTypes>
            <PillButton
              onClick={() => setButtonActive((prev) => !prev)}
              active={buttonActive}
            >
              종신형 연금
            </PillButton>
            <PillButton
              onClick={() => setButtonActive((prev) => !prev)}
              active={!buttonActive}
            >
              기간선택형 연금
            </PillButton>
          </PensionTypes>
          <PaymentFrequencyContainer>
            <PaymentFrequencyOption
              onClick={() => setMonthlyBtn((prev) => !prev)}
              active={monthlyBtn}
            >
              월 단위
            </PaymentFrequencyOption>
            <PaymentFrequencyOption
              onClick={() => setMonthlyBtn((prev) => !prev)}
              active={!monthlyBtn}
            >
              1년 단위
            </PaymentFrequencyOption>
          </PaymentFrequencyContainer>
        </HeaderContent>
      </Header>
      <Content>
        <Section>
          {!buttonActive ? (
            <Wrapper>
              <InfoContainer
                active={fixedTab === 1}
                onClick={() => {
                  if (Number(age) > 78) {
                    setFixedTab(1);
                  } else {
                    setOnToast(true);
                    setToastAge(5);
                  }
                }}
              >
                <InfoTitle>5년 수령</InfoTitle>
                <InfoSubtitle>만 78세 이상</InfoSubtitle>
              </InfoContainer>
              <InfoContainer
                active={fixedTab === 2}
                onClick={() => {
                  if (age > 73) {
                    setFixedTab(2);
                  } else {
                    setOnToast(true);
                    setToastAge(10);
                  }
                }}
              >
                <InfoTitle>10년 수령</InfoTitle>
                <InfoSubtitle>만 73세 이상</InfoSubtitle>
              </InfoContainer>
              <InfoContainer
                active={fixedTab === 3}
                onClick={() => {
                  if (age > 68) {
                    setFixedTab(3);
                  } else {
                    setOnToast(true);
                    setToastAge(15);
                  }
                }}
              >
                <InfoTitle>15년 수령</InfoTitle>
                <InfoSubtitle>만 68세 이상</InfoSubtitle>
              </InfoContainer>
              <InfoContainer
                active={fixedTab === 4}
                onClick={() => {
                  if (age > 63) {
                    setFixedTab(4);
                  } else {
                    setOnToast(true);
                    setToastAge(20);
                  }
                }}
              >
                <InfoTitle>20년 수령</InfoTitle>
                <InfoSubtitle>만 63세 이상</InfoSubtitle>
              </InfoContainer>
            </Wrapper>
          ) : null}
        </Section>
        <PensionPlanContainer>
          {buttonActive ? (
            <>
              <div style={{ padding: '0 16px' }}>
                <PensionPlanDetail>
                  <PensionPlanRow>
                    <PlanType>종신정액형</PlanType>
                    <PlanAmount>
                      {monthlyBtn
                        ? formatToKoreanCurrency(
                            totalData.fixedAmountForLife.month,
                          )
                        : formatToKoreanCurrency(
                            totalData.fixedAmountForLife.year,
                          )}
                    </PlanAmount>
                  </PensionPlanRow>
                  <PlanDescription>
                    사망 시까지 매월 일정한 연금을 안정적으로 지급받는 방식
                  </PlanDescription>
                </PensionPlanDetail>
              </div>
              <div style={{ padding: '0 16px' }}>
                <PensionPlanDetail>
                  <PensionPlanRow>
                    <PlanType>전후후박형</PlanType>
                    <div>
                      <PlanAmount>
                        {monthlyBtn
                          ? formatToKoreanCurrency(
                              totalData.backAndForthThinning.backThinning.month,
                            )
                          : formatToKoreanCurrency(
                              totalData.backAndForthThinning.backThinning.year,
                            )}
                        (10년간)
                      </PlanAmount>
                      <PlanAmount>
                        {monthlyBtn
                          ? formatToKoreanCurrency(
                              totalData.backAndForthThinning.forthThinning
                                .month,
                            )
                          : formatToKoreanCurrency(
                              totalData.backAndForthThinning.forthThinning.year,
                            )}
                        (10년후)
                      </PlanAmount>
                    </div>
                  </PensionPlanRow>
                  <PlanDescription>
                    가입 초기 10년은 더 많은 연금을 지급받고, 이후 사망시 까지
                    매월 일정한 연금을 안정적으로 지급받는 방식
                  </PlanDescription>
                </PensionPlanDetail>
              </div>
              <div
                style={{
                  padding: '0 16px',
                }}
              >
                <PensionPlanDetail>
                  <PensionPlanRow
                    style={{ width: '100%', alignItems: 'flex-end' }}
                  >
                    <div>
                      <PlanType>수시인출형</PlanType>
                      <PlanType2>수시인출금</PlanType2>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <PlanAmount>
                        {monthlyBtn
                          ? formatToKoreanCurrency(
                              totalData.occasionalWithdrawal.pension.month,
                            )
                          : formatToKoreanCurrency(
                              totalData.occasionalWithdrawal.pension.year,
                            )}
                      </PlanAmount>
                      <PlanAmount2>
                        {monthlyBtn
                          ? transNum(
                              totalData.occasionalWithdrawal.withdrawal.month,
                            ) + '원'
                          : transNum(
                              totalData.occasionalWithdrawal.withdrawal.year,
                            ) + '원'}
                      </PlanAmount2>
                    </div>
                  </PensionPlanRow>
                  <PlanDescription>
                    목돈 필요시 연금의 30%까지 일시에 인출할 수 있고, 이후 사망
                    시까지 매월 일정한 연금을 안정적으로 지급받는 방식
                  </PlanDescription>
                </PensionPlanDetail>
              </div>
            </>
          ) : (
            <>
              <div style={{ padding: '0 16px' }}>
                <PensionPlanDetail>
                  <PensionPlanRow>
                    <PlanType>기간정액형</PlanType>
                    <PlanAmount>
                      {transNum(
                        periodPrice(fixedTab, 'fixedTerm', monthlyBtn),
                      ) + '원'}
                    </PlanAmount>
                  </PensionPlanRow>
                  <PlanDescription>
                    일정 기간 동안 매월 일정한 연금을 지급받는 방식
                  </PlanDescription>
                </PensionPlanDetail>
              </div>
              <div style={{ padding: '0 16px' }}>
                <PensionPlanDetail>
                  <PensionPlanRow>
                    <PlanType>경영이양형</PlanType>
                    <PlanAmount>
                      {transNum(
                        periodPrice(fixedTab, 'management', monthlyBtn),
                      ) + '원'}
                    </PlanAmount>
                  </PensionPlanRow>
                  <PlanDescription>
                    일정 기간 동안 매월 연금을 지급 받고, 농어촌공사에 담보 농지
                    매도를 약정하는 방식
                  </PlanDescription>
                </PensionPlanDetail>
              </div>
            </>
          )}
        </PensionPlanContainer>
        <Disclaimer>
          ※ 예상 농지연금 지급액은 트랜스파머 인공지능이 자동 산출한 것으로
          실재값과 1%이내 일부 차이가 있을 수 있습니다.
        </Disclaimer>
      </Content>
      <Divider />
      <div style={{ marginBottom: '96px' }}>
        <DataSource
          originData={origin}
          arrowDirection={arrowDirection}
          changeArrowDirection={changeArrowDirection}
        />
      </div>
      <Footer>
        <ButtonContainer>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              // 페이지 네비게이션
              nav(`/pension/input`, {
                state: {
                  high_avg,
                  low_avg,
                  avg,
                  address,
                  c_idx,
                  itemType,
                  pnu,
                  housekye,
                },
              });
            }}
          >
            <ButtonText>맞춤 조건 재설정하기</ButtonText>
          </Button>
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  // padding-bottom: 24px;
`;

const Button = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
  cursor: pointer;
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const Divider = styled.div`
  display: flex;
  width: 100%;
  min-height: 8px;
  height: 8px;
  background-color: #f5f5f5;
`;

const Container = styled.div`
  position: relative;
  max-width: 720px;
  height: 100dvh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  width: 100%;
  background: #ffffff;
`;

const CloseButton = styled.div`
  font-size: 24px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 16px;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.div`
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const PensionTypes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const PillButton = styled.div<{ active: boolean }>`
  border-radius: 999px;
  padding: 6px 16px;
  background: ${({ active }) => (active ? '#222222' : '#FFFFFF')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#989BA2')};
  width: fit-content;
  border: ${({ active }) => (active ? 'none' : '1px solid #70737C29')};
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const PaymentFrequencyContainer = styled.div`
  background: #70737c0d;
  display: flex;
  border-radius: 8px;
`;

const PaymentFrequencyOption = styled.div<{ active: boolean }>`
  border-radius: 8px;
  border: ${({ active }) => (active ? '1px solid #E1E2E4' : 'none')};
  padding: 10px;
  background: ${({ active }) => (active ? '#FFFFFF' : 'transparent')};
  flex: 1;
  cursor: pointer;
  text-align: center;
  font-family: 'Pretendard-bold';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ active }) => (active ? '#222222' : '#37383C9C')};
`;

const PensionPlanContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PensionPlanDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 0 24px;
  border-bottom: 1px solid #e1e2e4;
`;

const PensionPlanRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlanType = styled.div`
  font-family: Pretendard-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #171e26c7;
`;

const PlanType2 = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #171e26c7;
`;

const PlanAmount = styled.div`
  font-family: Pretendard-bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #171e26c7;
`;

const PlanAmount2 = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #171e26c7;
`;

const PlanDescription = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #171e268f;
`;

const Disclaimer = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #171e268f;
  padding: 0 32px 32px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border: none;
  max-width: inherit;
`;

const FooterContent = styled.div`
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
`;

const FooterButton = styled.div`
  font-family: Pretendard-Bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  padding: 20px 16px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  // margintop: 1px;
  padding-top: 24px;
`;

const InfoContainer = styled.div<{ active?: boolean }>`
  flex: 1;
  padding: 6px 0;
  gap: 8px;
  border-radius: 8px;
  border: ${({ active }) =>
    active
      ? '1px solid var(--Alias-Color-Text-White-Primary-500, #1E9B6B)'
      : '1px solid var(--Alias-Color-Line-Border, #171e261a)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: ${({ active }) => (active ? '#1E9B6B' : '#37383c9c')};
  background: ${({ active }) => (active ? '#EAF8F2' : 'none')};
`;

const InfoTitle = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

const InfoSubtitle = styled.div`
  font-family: Pretendard-Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
`;

export default PensionReportMain;
