import { useEffect, useRef, useState } from 'react';
import colors from '../../../../color';
import { S } from './Map.styles';
import {
  makeAuctionforPensionMarkers,
  makeAuctionforPriceMarkers,
  makePropertyforPensionMarkers,
  makePropertyforPriceMarkers,
  makeReaPriceMarkers,
  makeUnderlevel16,
  calculateScale,
  makeBrokerCompanyMarkers,
} from './markerData';
import { NonPropertyList, SaleData } from '../../MainData';
import NonPropertyModal from '../nonPropertyModal/nonPropertyModal';
import Sales from '../../../../service/Sales';
import PensionInfoModal from '../pensionInfoModal/PensionInfoModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMapContext } from '../../../../contexts/MapContext';
import { useFilter } from '../../../../contexts/FilterContext';
import { usePropertyFilter } from '../../../../contexts/PropertyContext';

declare global {
  interface Window {
    naver: any;
  }
}

interface MapProps {
  height: number;
  setRegionModalOn: (value: boolean) => void;
  listOn: boolean;
  setListOn: (value: boolean) => void;
  markerList: SaleData[];
  isListData: boolean;
  brokerOn: boolean;
  setBrokerOn: (value: boolean) => void;
  brokerList: any;
  brokerCompany: any;
}

const Map = ({
  height,
  setRegionModalOn,
  setListOn,
  markerList,
  isListData,
  brokerList,
  setBrokerOn,
  brokerCompany,
}: MapProps) => {
  const mapElement = useRef<HTMLDivElement>(null);
  const cadastralLayerRef = useRef<any>(null);
  const mapRef = useRef<any>(null);
  const markersRef = useRef<any[]>([]);
  const polygonRef = useRef<any>(null);
  const clickedMarkerRef = useRef<any>(null);
  const searchedMarkerRef = useRef<any>(null);
  const [propertyData, setPropertyData] = useState<SaleData[]>([]);
  const [realDealData, setRealDealData] = useState<SaleData[]>([]);
  const [auctionData, setAuctionData] = useState<SaleData[]>([]);
  const [nonPropertyList, setNonPropertyList] = useState<NonPropertyList>();
  const [pensionModalOn, setPensionModalOn] = useState(false);
  const [noDataLand, setNoDataLand] = useState(false);
  const [selectedMarkerInfo, setSelectedMarkerInfo] = useState<SaleData | null>(
    null,
  );
  const [width, setWidth] = useState(
    window.innerWidth > 720 ? 720 : window.innerWidth,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { latLng, setLatLng, pnu, setPnu, setBounds } = useMapContext();
  const { setPropertyPnu, address, setAddress } = usePropertyFilter();
  const { setMenu, setFilterOption, filterOption } = useFilter();
  const {
    mode,
    setMode,
    mapType,
    cadastralMap,
    propertyOn,
    realPriceOn,
    auctionOn,
    nonPropertyOn,
    setMapType,
    setCadastralMap,
    setPropertyOn,
    setRealPriceOn,
    setAuctionOn,
    setNonPropertyOn,
    zoomLevel,
    setZoomLevel,
    geomData,
    is_exist,
    setIs_exist,
  } = useMapContext();

  const { setSaleType } = useFilter();

  const [mapMoveYN, setMapMoveYN] = useState<boolean>(false);

  //클러스터 선택 시 클러스터에 있는 좌표로 이동 시키기 위해
  //데이터 타입(매물,실거래,경공매)을 저장하는 변수
  const [dataType, setDataType] = useState<string[]>([]);
  useEffect(() => {
    const handleResize = () =>
      setWidth(window.innerWidth > 720 ? 720 : window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updatedSaleType = [];

    if (propertyOn) {
      updatedSaleType.push('매물');
    }

    if (realPriceOn) {
      updatedSaleType.push('실거래');
    }

    if (auctionOn) {
      updatedSaleType.push('경공매');
    }
    setDataType(updatedSaleType);
    setSaleType(updatedSaleType);
  }, [propertyOn, realPriceOn, auctionOn]);

  const handleMarkerClick = (data: SaleData) => {
    setSelectedMarkerInfo(data);
    setListOn(false);
    setNonPropertyOn(false);
    setPensionModalOn(true);
  };

  const getCenterOfRegion = async (code: string, dataType: string[]) => {
    setNonPropertyOn(false);
    try {
      const res = await Sales.center(code, dataType);
      const centerPosition = new window.naver.maps.LatLng(
        res.result.lat,
        res.result.lng,
      );

      // 지도 중심 좌표 설정 및 줌 레벨 설정
      mapRef.current.setCenter(centerPosition);
      mapRef.current.panTo(centerPosition);
      mapRef.current.setZoom(16);
      setZoomLevel(16);
      setLatLng({ lat: res.result.lat, lng: res.result.lng });
    } catch (error) {
      console.error('error', error);
    }
  };

  const getNonSaleList = async (lat: string, lng: string) => {
    setListOn(false);
    setNonPropertyOn(false);
    setPensionModalOn(false);

    try {
      const res = await Sales.nonSaleList(lat, lng);

      //res.result.list.land, res.result.list.house 값이 없을 경우 예외처리
      if (
        res.result.list.land.length === 0 &&
        res.result.list.house.length === 0
      ) {
        setNoDataLand(true);

        setTimeout(() => {
          setNoDataLand(false);
        }, 1000);
      } else {
        setNoDataLand(false);

        setNonPropertyList(res.result.list);

        // 비매물 선택 시 매물 마커가 존재할 경우 기본 마커가 뜨지 않게 하기 위해 처리
        // 주택이 존재할 경우 주택(house)에서 is_sale이 Y인 데이터가 있을 경우 is_exist를 N으로 처리
        // 농지가 존재할 경우 농지(land)에서 is_sale이 Y인 데이터가 있을 경우 is_exist를 N으로 처리
        if (
          res.result.list.house.length > 0 &&
          res.result.list.house[0].is_sales === 'N'
        ) {
          setIs_exist('N');
        } else if (
          res.result.list.land.length > 0 &&
          res.result.list.land[0].is_sales === 'N'
        ) {
          setIs_exist('N');
        } else {
          setIs_exist('Y');
        }
        const newPathData = res.result.list.land[0].geom;

        const polygon = new window.naver.maps.Polygon({
          map: mapRef.current,
          paths: newPathData,
          fillColor: 'rgba(0, 233, 137)',
          fillOpacity: 0.15,
          strokeColor: '#00E989',
          strokeOpacity: 0.8,
          strokeWeight: 1,
        });

        polygonRef.current = polygon;
        setLatLng({
          lat: res.result.list.land[0].lat,
          lng: res.result.list.land[0].lng,
        });
        localStorage.setItem('lat', res.result.list.land[0].lat);
        localStorage.setItem('lng', res.result.list.land[0].lng);
        if (
          (res.result.list.land.length > 0 &&
            res.result.list.land[0].is_sales === 'N') ||
          (res.result.list.house.length > 0 &&
            res.result.list.house[0].is_sales === 'N')
        ) {
          // return;
        } else {
          const newMarker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(
              res.result.list.land[0].lat,
              res.result.list.land[0].lng,
            ),
            map: mapRef.current,
            icon: {
              url: '/asset/images/marker/tf-marker.svg',
              anchor: new window.naver.maps.Point(20, 46),
            },
          });
          mapRef.current.setCenter(newMarker.getPosition());
          clickedMarkerRef.current = newMarker;
        }

        navigate(
          `/?lat=${res.result.list.land[0].lat}&lng=${res.result.list.land[0].lng}`,
        );
        setNonPropertyOn(true);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    if (!markerList) return;

    setPropertyData(
      markerList.filter((data) =>
        ['인터넷 매물', '매물', '지자체 물건'].includes(data.sale_type),
      ),
    );
    setRealDealData(markerList.filter((data) => data.sale_type === '실거래'));
    setAuctionData(
      markerList.filter((data) => ['경매', '공매'].includes(data.sale_type)),
    );
  }, [markerList]);

  const updateBounds = () => {
    const bounds = mapRef.current.getBounds();
    setBounds({
      swLat: bounds.getSW().lat(),
      swLng: bounds.getSW().lng(),
      neLat: bounds.getNE().lat(),
      neLng: bounds.getNE().lng(),
    });
  };
  useEffect(() => {
    const mapOptions = {
      center: new window.naver.maps.LatLng(latLng?.lat, latLng?.lng),
      zoom: zoomLevel,
      minZoom: 11,
      mapTypeId: window.naver.maps.MapTypeId[mapType],
      size: new window.naver.maps.Size(
        window.innerWidth > 720 ? 720 : window.innerWidth,
        height,
      ),
    };

    mapRef.current = new window.naver.maps.Map(mapElement.current, mapOptions);

    window.naver.maps.Event.addListener(mapRef.current, 'zoom_changed', () => {
      const newZoom = mapRef.current.getZoom();
      setZoomLevel(newZoom);
    });

    window.naver.maps.Event.addListener(mapRef.current, 'idle', () => {
      updateBounds();
    });

    window.naver.maps.Event.addListener(mapRef.current, 'click', (e: any) => {
      sessionStorage.setItem('direct', 'N');

      const lat = e.coord.lat();
      const lng = e.coord.lng();

      // Remove previous polygon
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
        polygonRef.current = null;
      }
      if (clickedMarkerRef.current) {
        clickedMarkerRef.current.setMap(null);
        clickedMarkerRef.current = null;
      }
      if (searchedMarkerRef.current) {
        searchedMarkerRef.current.setMap(null);
        searchedMarkerRef.current = null;
      }

      mapRef.current.setCenter(e.coord);

      getNonSaleList(lat, lng);
    });

    // 드래그 시작 이벤트
    window.naver.maps.Event.addListener(mapRef.current, 'dragstart', () => {
      //지도 이동 시 중개사 리스트 이미지 변경
      setMapMoveYN(true);
    });

    // 화면이 움직일때마다
    // 드래그 중단
    window.naver.maps.Event.addListener(mapRef.current, 'dragend', () => {
      setMapMoveYN(false);

      if (nonPropertyOn) {
        setNonPropertyOn(false);
      }

      //중심좌표 가져오기
      const center = mapRef.current.getCenter();
      setLatLng({ lat: center.lat(), lng: center.lng() });
      localStorage.setItem('lat', center.lat());
      localStorage.setItem('lng', center.lng());
      getAddressFromLatLng(center.lat(), center.lng());
      updateBounds();
      navigate(`/?lat=${center.lat()}&lng=${center.lng()}`);
    });

    cadastralLayerRef.current = new window.naver.maps.CadastralLayer();
  }, []);

  useEffect(() => {
    mapRef.current.setSize(
      new window.naver.maps.Size(width > 720 ? 720 : width, height),
    );
  }, [width, height]);

  // 접속 위치로 이동하는 함수
  const getUserLocation = () => {
    setNonPropertyOn(false);
    setPnu('');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude });
          navigate(`/?lat=${latitude}&lng=${longitude}`);
        },
        (error) => {
          navigate('/?lat=37.4640137&lng=127.0361695');
          setLatLng({ lat: 37.4640137, lng: 127.0361695 });
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      navigate('/?lat=37.4640137&lng=127.0361695');
      setLatLng({ lat: 37.4640137, lng: 127.0361695 });
    }
  };

  useEffect(() => {
    if (location.search === '') {
      if (localStorage.getItem('lat') && localStorage.getItem('lng')) {
        setLatLng({
          lat: parseFloat(localStorage.getItem('lat') as string),
          lng: parseFloat(localStorage.getItem('lng') as string),
        });
        navigate(
          `/?lat=${localStorage.getItem('lat') || latLng?.lat}&lng=${localStorage.getItem('lng') || latLng?.lat}`,
        );
      } else {
        getUserLocation();
      }
    }
    if (location.search !== '') {
      const params = new URLSearchParams(location.search);
      const lat = params.get('lat');
      const lng = params.get('lng');
      if (lat && lng) {
        setLatLng({ lat: parseFloat(lat), lng: parseFloat(lng) });
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (latLng && mapRef.current) {
      // 지도 중심 및 위치 이동 설정
      const newCenter = new window.naver.maps.LatLng(latLng.lat, latLng.lng);
      mapRef.current.setCenter(newCenter);
      mapRef.current.panTo(newCenter);

      // 주어진 위치에 대한 주소를 가져옵니다.
      getAddressFromLatLng(latLng.lat, latLng.lng);
      updateBounds();
    }
  }, [latLng, zoomLevel]);

  useEffect(() => {
    if (mapRef.current && geomData && geomData.length > 0 && latLng) {
      // 지도 로드 후 약간의 지연을 둔 후 폴리곤을 그립니다.
      const timer = setTimeout(() => {
        // 이전에 생성된 폴리곤과 마커 제거
        if (polygonRef.current) {
          polygonRef.current.setMap(null);
          polygonRef.current = null; // 참조 초기화
        }
        if (searchedMarkerRef.current) {
          searchedMarkerRef.current.setMap(null);
          searchedMarkerRef.current = null; // 참조 초기화
        }

        // 새 폴리곤 생성
        const polygon = new window.naver.maps.Polygon({
          map: mapRef.current,
          paths: geomData.map(
            (coord) => new window.naver.maps.LatLng(coord[1], coord[0]),
          ),
          fillColor: 'rgba(0, 233, 137)',
          fillOpacity: 0.15,
          strokeColor: '#00E989',
          strokeOpacity: 0.8,
          strokeWeight: 1,
        });
        polygonRef.current = polygon;
        //is_exist가 Y일 경우에만 마커 생성
        //is_exist가 N일 경우에는 경공매, 매물, 실거래 마크가 있는 경우
        if (is_exist === 'Y') {
          // 새 마커 생성
          const newMarker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(latLng.lat, latLng.lng),
            map: mapRef.current,
            icon: {
              url: '/asset/images/marker/tf-marker.svg',
              anchor: new window.naver.maps.Point(20, 46),
            },
            zIndex: 100,
          });
          searchedMarkerRef.current = newMarker;

          // getNonSaleList(String(latLng.lat), String(latLng.lng));
          // 마커 클릭 시 이벤트 리스너 설정
          window.naver.maps.Event.addListener(newMarker, 'click', () => {
            getNonSaleList(String(latLng.lat), String(latLng.lng));
          });
        }
      }, 100);
      mapRef.current.setZoom(18);
      return () => {
        clearTimeout(timer);
        if (polygonRef.current) {
          polygonRef.current.setMap(null);
          polygonRef.current = null;
        }
        if (searchedMarkerRef.current) {
          searchedMarkerRef.current.setMap(null);
          searchedMarkerRef.current = null;
        }
      };
    }
  }, [geomData]);

  // 마커 만드는 함수
  // 중개사 사무소 위치를 나타내는 마커
  const BrokerMarker = (map: any) => {
    if (!brokerCompany) return;
    const markerContent = makeBrokerCompanyMarkers(brokerCompany);
    const marker = new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(
        brokerCompany.lat,
        brokerCompany.lng,
      ),
      map: map,
      icon: {
        content: markerContent,
        anchor: new window.naver.maps.Point(40, 50),
      },
    });
    window.naver.maps.Event.addListener(marker, 'click', () => {
      localStorage.setItem('lat', brokerCompany.lat);
      localStorage.setItem('lng', brokerCompany.lng);

      navigate(`/?lat=${brokerCompany.lat}&lng=${brokerCompany.lng}`);
      mapRef.current.setZoom(17);
    });
    markersRef.current.push(marker);
  };

  const createMarkers = (map: any) => {
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];
    if (propertyOn) {
      propertyData.forEach((data) => {
        const markerContent =
          mode === '가격정보'
            ? makePropertyforPriceMarkers(data)
            : makePropertyforPensionMarkers(data);

        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(data.lat, data.lng),
          map: map,
          icon: {
            content: markerContent,
            anchor:
              mode === '가격정보'
                ? new window.naver.maps.Point(35, 43)
                : new window.naver.maps.Point(67, 26),
          },
        });

        if (mode === '농지연금') {
          window.naver.maps.Event.addListener(marker, 'click', () =>
            handleMarkerClick(data),
          );
        } else {
          window.naver.maps.Event.addListener(marker, 'click', () =>
            navigate(
              `/detail?c_idx=${data.c_idx}&lat=${data?.lat}&lng=${data?.lng}`,
              { state: { beforeType: 'map' } },
            ),
          );
          localStorage.setItem('lat', String(data?.lat));
          localStorage.setItem('lng', String(data?.lng));
        }
        markersRef.current.push(marker);
      });
    }

    if (realPriceOn) {
      realDealData.forEach((data) => {
        const markerContent = makeReaPriceMarkers(data);
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(data.lat, data.lng),
          map: map,
          icon: {
            content: markerContent,
            anchor: new window.naver.maps.Point(40, 50),
          },
        });

        window.naver.maps.Event.addListener(marker, 'click', () => {
          // 마커 클릭 시 위도와 경도를 로컬스토리지에 저장
          if (latLng) {
            localStorage.setItem('lat', String(data.lat));
            localStorage.setItem('lng', String(data.lng));
          }

          // React Router의 navigate로 이동
          navigate(
            `/detail?c_idx=${data.c_idx}&lat=${data?.lat}&lng=${data?.lng}`,
            { state: { beforeType: 'map' } },
          );
        });

        markersRef.current.push(marker);
      });
    }
    if (auctionOn) {
      auctionData.forEach((data) => {
        const markerContent =
          mode === '가격정보'
            ? makeAuctionforPriceMarkers(data)
            : makeAuctionforPensionMarkers(data);

        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(data.lat, data.lng),
          map: map,
          icon: {
            content: markerContent,
            anchor: new window.naver.maps.Point(40, 50),
          },
        });

        if (mode === '농지연금') {
          window.naver.maps.Event.addListener(marker, 'click', () =>
            handleMarkerClick(data),
          );
        } else {
          window.naver.maps.Event.addListener(marker, 'click', () =>
            navigate(
              `/detail?c_idx=${data.c_idx}&lat=${data?.lat}&lng=${data?.lng}`,
              { state: { beforeType: 'map' } },
            ),
          );
          localStorage.setItem('lat', String(data?.lat));
          localStorage.setItem('lng', String(data?.lng));
        }

        markersRef.current.push(marker);
      });
    }
  };

  const underLevel16Maker = (map: any) => {
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    markerList.forEach((data) => {
      const markerContent = makeUnderlevel16(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        icon: {
          content: markerContent,
        },
      });
      window.naver.maps.Event.addListener(marker, 'click', () => {
        getCenterOfRegion(data.code, dataType);
      });
      markersRef.current.push(marker);
    });
  };
  useEffect(() => {
    if (mapRef.current && zoomLevel >= 14) {
      createMarkers(mapRef.current);
      BrokerMarker(mapRef.current); //전담 중개사의 사무실을 나타내는 마커
    } else {
      underLevel16Maker(mapRef.current);
    }
  }, [
    propertyOn,
    realPriceOn,
    auctionOn,
    mode,
    propertyData,
    realDealData,
    auctionData,
    zoomLevel,
    pnu,
    latLng,
  ]);
  useEffect(() => {
    if (cadastralLayerRef.current) {
      cadastralLayerRef.current.setMap(cadastralMap ? mapRef.current : null);
    }
  }, [cadastralMap]);
  // 위도, 경도로 주소를 가져오는 함수
  const getAddressFromLatLng = (lat: number, lng: number) => {
    window.naver.maps.Service.reverseGeocode(
      {
        coords: new window.naver.maps.LatLng(lat, lng),
        orders: [window.naver.maps.Service.OrderType.ADDR].join(','),
      },
      (status: any, response: any) => {
        if (status === window.naver.maps.Service.Status.OK) {
          //오류
          if (response.v2.status.name === 'no results') {
            //네이버 api에서 주소와 pnu를 가져오지 못하는 경우
            //필터에서 선택된 값을 지도 및 전국매물에 보여짐
          } else {
            //네이버 api에서 주소와 pnu를 가지고 오는 경우 처리
            setPropertyPnu(response.v2.results[0]?.code?.id.slice(0, -2));
            setAddress({
              sido: response.v2.results[0]?.region?.area1.name || '',
              sigungu: response.v2.results[0]?.region?.area2.name || '',
              eupmd: response.v2.results[0]?.region?.area3.name || '',
              ri: response.v2.results[0]?.region?.area4.name || '',
            });
          }

          // setPnu(response.v2.results[0]?.code?.id.slice(0, -2));
        } else {
          // setPropertyPnu('');
          setAddress({
            sido: '',
            sigungu: '',
            eupmd: '',
            ri: '',
          });
          console.error('Reverse Geocoding failed:', status);
        }
      },
    );
  };

  const ChangeMapType = (type: string) => {
    if (mapRef.current) {
      mapRef.current.setMapTypeId(window.naver.maps.MapTypeId[type]);
    }
  };

  // 각 탭의 상태 관리
  const [priceAnalysisState, setPriceAnalysisState] = useState({
    sale: true, // 기본값: sale on
    realDeal: true, // 기본값: 실거래가 on
    auction: false,
  });

  const [farmlandPensionState, setFarmlandPensionState] = useState({
    sale: true, // 기본값: 매물 off
    auction: true, // 기본값: 경공매 on
    realDeal: false,
  });

  // 로컬스토리지에서 상태 로드
  useEffect(() => {
    const storedPriceAnalysisState = localStorage.getItem('priceAnalysisState');
    const storedFarmlandPensionState = localStorage.getItem(
      'farmlandPensionState',
    );

    if (storedPriceAnalysisState) {
      setPriceAnalysisState(JSON.parse(storedPriceAnalysisState));
    }
    if (storedFarmlandPensionState) {
      setFarmlandPensionState(JSON.parse(storedFarmlandPensionState));
    }
  }, []);

  // 가격분석 상태 변경 핸들러
  const handleToggle = (mode: string, key: 'sale' | 'realDeal' | 'auction') => {
    if (mode === '가격정보') {
      const updatedState = {
        ...priceAnalysisState,
        [key]: !priceAnalysisState[key],
      };
      setPriceAnalysisState(updatedState);
      localStorage.setItem('priceAnalysisState', JSON.stringify(updatedState));
    } else {
      if (key === 'realDeal') {
        return;
      }
      const updatedState = {
        ...farmlandPensionState,
        [key]: !farmlandPensionState[key],
      };
      setFarmlandPensionState(updatedState);
      localStorage.setItem(
        'farmlandPensionState',
        JSON.stringify(updatedState),
      );
    }
  };
  console.log('zoomLevel', zoomLevel);

  return (
    <S.NaverMap ref={mapElement} height={height} width={width}>
      {/* 위성, 지도,지적도 토글 버튼 */}
      <S.MapItemLeft>
        <S.MapTypeBoxTop>
          <S.MapTypeButton
            style={{ borderBottom: `1px solid ${colors.lineNormal}` }}
            onClick={() => {
              setMapType('HYBRID');
              ChangeMapType('HYBRID');
            }}
            active={mapType === 'HYBRID'}
          >
            <img
              src={`/asset/images/icon/${mapType === 'HYBRID' ? 'satellite-on' : 'satellite-off'}.svg`}
              alt=""
            />
            <span>위성</span>
          </S.MapTypeButton>
          <S.MapTypeButton
            onClick={() => {
              setMapType('NORMAL');
              ChangeMapType('NORMAL');
            }}
            active={mapType === 'NORMAL'}
          >
            <img
              src={`/asset/images/icon/${mapType === 'NORMAL' ? 'normal-on' : 'normal-off'}.svg`}
              alt=""
            />
            <span>지도</span>
          </S.MapTypeButton>
        </S.MapTypeBoxTop>
        <S.MapTypeButtonSingle
          onClick={() => setCadastralMap(!cadastralMap)}
          active={cadastralMap}
        >
          <img
            src={`/asset/images/icon/${cadastralMap ? 'Intellectual-on' : 'Intellectual-off'}.svg`}
            alt=""
          />
          <span>지적도</span>
        </S.MapTypeButtonSingle>
      </S.MapItemLeft>
      {/* 매물, 실거래, 경공매, 위치 버튼 */}
      <S.MapItemRight>
        <S.MapMarkerBox>
          <S.MapTypeButtonSingle
            onClick={() => {
              handleToggle(mode, 'sale');
              setPropertyOn(!propertyOn);
            }}
            active={propertyOn}
          >
            <img
              src={`/asset/images/icon/${propertyOn ? 'propertyOn' : 'propertyOff'}.svg`}
              alt=""
            />
          </S.MapTypeButtonSingle>
          {mode === '가격정보' && (
            <S.MapTypeButtonSingle
              onClick={() => {
                handleToggle(mode, 'realDeal');
                setRealPriceOn(!realPriceOn);
              }}
              active={realPriceOn}
            >
              <img
                src={`/asset/images/icon/${realPriceOn ? 'realPriceOn' : 'realPriceOff'}.svg`}
                alt=""
              />
            </S.MapTypeButtonSingle>
          )}

          <S.MapTypeButtonSingle
            onClick={() => {
              handleToggle(mode, 'auction');
              setAuctionOn(!auctionOn);
            }}
            active={auctionOn}
          >
            <img
              src={`/asset/images/icon/${auctionOn ? 'auctionOn' : 'auctionOff'}.svg`}
              alt=""
            />
          </S.MapTypeButtonSingle>
        </S.MapMarkerBox>
        <S.MapTypeButtonSingle
          onClick={() => {
            getUserLocation();
          }}
        >
          <img src="/asset/images/icon/rocation.svg" alt="" />
        </S.MapTypeButtonSingle>
      </S.MapItemRight>
      <S.PriceModeButtonBox>
        <S.PriceModeButton
          onClick={() => {
            setMode('가격정보');
            if (localStorage.getItem('priceAnalysisState')) {
              const storedPriceAnalysisState = JSON.parse(
                localStorage.getItem('priceAnalysisState') as string,
              );
              setRealPriceOn(storedPriceAnalysisState.realDeal);
              setPropertyOn(storedPriceAnalysisState.sale);
              setAuctionOn(storedPriceAnalysisState.auction);
            } else {
              setRealPriceOn(true);
              setPropertyOn(true);
              setAuctionOn(false);
            }
          }}
          active={mode === '가격정보'}
          backgroundcolor={colors.primary500}
        >
          <img
            src={`/asset/images/icon/${mode === '가격정보' ? 'priceModeOn' : 'priceModeOff'}.svg`}
            alt=""
          />
          <span>가격정보</span>
        </S.PriceModeButton>
        <S.PriceModeButton
          onClick={() => {
            setMode('농지연금');
            if (localStorage.getItem('farmlandPensionState')) {
              const storedfarmlandPensionState = JSON.parse(
                localStorage.getItem('farmlandPensionState') as string,
              );
              setRealPriceOn(false);
              setPropertyOn(storedfarmlandPensionState.sale);
              setAuctionOn(storedfarmlandPensionState.auction);
            } else {
              setRealPriceOn(false);
              setAuctionOn(true);
              setPropertyOn(true);
            }

            setMenu('찾아줘 토지');
            setFilterOption({
              ...filterOption,
              target: '토지',
              sale_type: [] as string[],
              category: [] as string[],
              purpose: [] as string[],
              year: ['', ''] as [string, string],
              theme: [] as string[],
            });
          }}
          active={mode === '농지연금'}
          backgroundcolor={colors.orange}
        >
          <img
            src={`/asset/images/icon/${mode === '농지연금' ? 'pensionModeOn' : 'pensionModeOff'}.svg`}
            alt=""
          />
          <span>농지연금</span>
        </S.PriceModeButton>
      </S.PriceModeButtonBox>
      {address.sigungu !== undefined && address.eupmd !== undefined && (
        <S.LocationRegion
          onClick={() => {
            setRegionModalOn(true);
          }}
          isListData={isListData}
        >
          <img
            src="/asset/images/icon/marker.svg"
            alt=""
            style={{ width: '20px', height: '20px' }}
          />
          <S.RegionTextBox>
            <S.RegionText>
              {address.sido === '세종특별자치시' ? '세종시' : address.sigungu}
            </S.RegionText>
            <img src="/asset/images/arrow/arrow-right-gray-region.svg" alt="" />
            <S.RegionText>{address.eupmd}</S.RegionText>
          </S.RegionTextBox>
        </S.LocationRegion>
      )}
      <S.CustomScale mapType={mapType} isListData={isListData}>
        <S.CustomScaleLeft mapType={mapType} />
        <S.CustomScaleRight mapType={mapType} />
        <S.CustomScaleText mapType={mapType}>
          {calculateScale(zoomLevel)}
        </S.CustomScaleText>
      </S.CustomScale>
      {nonPropertyOn && nonPropertyList && (
        <NonPropertyModal data={nonPropertyList} setListOn={setListOn} />
      )}
      {pensionModalOn && (
        <PensionInfoModal
          data={selectedMarkerInfo}
          setPensionModalOn={setPensionModalOn}
        />
      )}
      {noDataLand && (
        <S.NoDataLand>
          <div>일시적으로 분석을 제공하지 않는 지역입니다.</div>
        </S.NoDataLand>
      )}
      {brokerList && (
        <S.BrokerModal isListData={isListData}>
          <div
            onClick={() => {
              setBrokerOn(true);
            }}
            style={{
              width: '120px',
              height: '40px',
              display: 'flex',
              justifyContent: mapMoveYN ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            {mapMoveYN ? (
              <img
                src="/asset/images/icon/map_broker_green.png"
                style={{ position: 'relative' }}
                alt=""
              />
            ) : (
              <img
                src="/asset/images/icon/map_broker.png"
                style={{ position: 'relative' }}
                alt=""
              />
            )}
          </div>
        </S.BrokerModal>
      )}
    </S.NaverMap>
  );
};

export default Map;
