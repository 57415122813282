import { createInstance } from '@hackler/react-sdk';

export const hackleClient = createInstance(
  process.env.REACT_APP_HACKLE_API_KEY || '',
);
const memeberTypes = [
  { label: 'N', value: 'naver' },
  { label: 'K', value: 'kakao' },
  { label: 'C', value: 'general' },
  { label: 'G', value: 'google' },
  { label: 'F', value: 'facebook' },
];

const jobOptions = [
  { label: 'W', value: '농업인' },
  { label: 'F', value: '귀농 희망자' },
  { label: 'N', value: '귀촌 희망자' },
  { label: 'E', value: '농산업기업체' },
  { label: 'O', value: '지자체 담당자' },
  { label: 'P', value: '정부부처(공공기관)' },
  { label: 'C', value: '농산물 유통' },
  { label: 'B', value: '부동산 중개' },
  { label: 'G', value: '기타(해당사항 없음)' },
];

const getSignUpMethodType = (category: string) => {
  const foundOption = memeberTypes.find((option) => option.label === category);
  return foundOption ? foundOption.value : '';
};

const getMemberJob = (category: string) => {
  const foundOption = jobOptions.find((option) => option.label === category);
  return foundOption ? foundOption.value : '';
};

export const hackleSignUptrack = (
  method: string,
  memberType: string,
  email: string,
) => {
  const event = {
    key: 'signup',
    properties: {
      created_at: new Date().toISOString().split('T')[0],
      method: getSignUpMethodType(method),
      memberType: getMemberJob(memberType),
      userId: email,
    },
  };
  hackleClient.track(event);
};
