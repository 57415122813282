import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState } from 'react';
import NoLoginMoreInfo from './NoLoginMoreInfo';
import LoginMoreInfo from './LoginMoreInfo';
import Notice from './Notice';
import { isLogin } from '../../../utilities/auth';
import { Link } from 'react-router-dom';

const MoreInfo = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [shownTooltip, setShownTooltip] = useState(true);

  useEffect(() => {
    if (isLogin()) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner === 0 ? 1 : 0));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <MoreInfoContainer
      isLogin={isLogin()}
      showButton={showButton}
      shownTooltip={shownTooltip}
    >
      {showButton ? (
        <NoLoginMoreInfo
          shownTooltip={shownTooltip}
          setShownTooltip={setShownTooltip}
        />
      ) : (
        <LoginMoreInfo />
      )}
      <Notice />
      {/* <MyPoint myPoint={myPoint} isLogin={showButton} />
      <BottomNavBarContainer>
        <BottomNavBtn>
          <img src={`/asset/images/icon/myteraOff.svg`} alt="" />
          <span>관심 주소지</span>
        </BottomNavBtn>
        <BottomNavBtn>
          <img src={`/asset/images/icon/gift.svg`} alt="" />
          <span>땅줍</span>
        </BottomNavBtn>
        <BottomNavBtn>
          <img src={`/asset/images/icon/brokerageOff.svg`} alt="" />
          <span>맞춤중개</span>
        </BottomNavBtn>
      </BottomNavBarContainer> */}
      {currentBanner === 0 ? (
        <Link to="/landMarket/apply">
          <BannerImg src="/asset/images/popup/more-banner-01.png" />
        </Link>
      ) : (
        <Link to="/landMarket/product">
          <BannerImg src="/asset/images/popup/more-banner-02.png" />
        </Link>
      )}
    </MoreInfoContainer>
  );
};
export default MoreInfo;

interface MoreInfoLoginProps {
  isLogin: boolean;
  showButton: boolean;
  shownTooltip: boolean;
}

const MoreInfoContainer = styled.div<MoreInfoLoginProps>`
  width: 100%;
  padding: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  background-color: ${colors.white};
`;

const BannerImg = styled.img`
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
`;
