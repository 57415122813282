import styled from 'styled-components';

const Broker = ({ brokerData }: any) => {
  return (
    <BrokerWrapper>
      <BrokerTitle>중개사 정보</BrokerTitle>
      <BrokerBox>
        <BrokerInfoBox>
          <img
            src={
              brokerData.img
                ? brokerData.img
                : '/asset/images/icon/broker_default_img.png'
            }
          ></img>
          <BrokerInfo>
            <div>{brokerData.companyName}</div>
            <BrokerAddress>{brokerData.address}</BrokerAddress>
          </BrokerInfo>
        </BrokerInfoBox>
        <BrokerDescriptionBox>
          <div>대표자</div>
          <DescriptionText>{brokerData.brokerName}</DescriptionText>
        </BrokerDescriptionBox>
        <BrokerDescriptionBox>
          <div>대표번호</div>
          <DescriptionText>{brokerData.brokerNumber}</DescriptionText>
        </BrokerDescriptionBox>
        <BrokerDescriptionBox>
          <div>등록번호</div>
          <DescriptionText>{brokerData.RegistrationNumber}</DescriptionText>
        </BrokerDescriptionBox>
      </BrokerBox>
    </BrokerWrapper>
  );
};
export default Broker;

const DescriptionText = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const BrokerDescriptionBox = styled.div`
  display: flex;
  flex-direction: row;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  gap: 8px;
`;

const BrokerAddress = styled.div`
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;
const BrokerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const BrokerInfoBox = styled.div`
  display: flex;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  gap: 8px;
  img {
    width: 51px;
    height: 50px;
  }
`;

const BrokerBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 8px;
`;

const BrokerTitle = styled.div`
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const BrokerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  background-color: #fff;
  gap: 16px;
`;
