import { styled } from 'styled-components';
import { transNum } from '../../../../../function/function';
import { useEffect, useState } from 'react';
import PointAlert from '../../point/PointAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../../../color';

interface RoadSurfaceProps {
  roadSurface: string;
  analysisYN: boolean;
}

const PensionPreview = ({
  pension,
  usedPoint,
  high_avg,
  low_avg,
  avg,
  roadSurface,
  isLogin2,
  address,
  c_idx,
  itemType,
  pnu,
  housekye,
  analysisYN,
}: {
  pension: number;
  usedPoint: boolean;
  c_idx: string;
  high_avg: number;
  low_avg: number;
  avg: number;
  roadSurface: string;
  isLogin2: boolean;
  address: string;
  itemType: string;
  pnu: string;
  housekye: string;
  analysisYN: boolean;
}) => {
  const location = useLocation();
  const nav = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const [pointModal, setPointModal] = useState(false);
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (!analysisYN) {
      setShowTooltip(true);
    }

    if (avg) {
      setUrl(`/pension/input`);
      setShowTooltip(
        roadSurface ? (roadSurface === '맹지' ? true : false) : false,
      );
    }
  }, [high_avg, low_avg, avg, roadSurface, analysisYN]);

  useEffect(() => {
    if (pointModal && usedPoint) {
      nav(`/pension/input`, {
        state: {
          high_avg: high_avg,
          low_avg: low_avg,
          avg: avg,
          address: address,
          c_idx: c_idx,
          itemType: itemType,
          pnu: pnu,
          housekye: housekye,
        },
      });
    }
  }, [pointModal, usedPoint, high_avg, low_avg, avg, address, nav]);

  const handleClose = () => {
    setShowTooltip(false);
  };
  return (
    <Container>
      {pointModal ? (
        usedPoint ? null : (
          <PointAlert
            url={url}
            setModal={setPointModal}
            state={{
              high_avg: high_avg,
              low_avg: low_avg,
              avg: avg,
              address: address,
              c_idx: c_idx,
              itemType: itemType,
              pnu: pnu,
              housekye: housekye,
            }}
          />
        )
      ) : null}
      <Title>농지연금</Title>
      <InfoBox>
        <InfoContent>
          <InfoText>작년 농지연금 가입자는</InfoText>
          <HighlightText>월 평균 50만원 수령했어요</HighlightText>
          <HighlightBox>
            <HighlightText2>{`최대 300만원 수령 가능`}</HighlightText2>
          </HighlightBox>
        </InfoContent>
      </InfoBox>
      <Button
        roadSurface={roadSurface ? roadSurface : 'Default Label'}
        analysisYN={analysisYN}
        onClick={() => {
          if (roadSurface === '맹지' || !analysisYN) {
            return; // 클릭 시 아무것도 하지 않음
          }
          !isLogin2
            ? nav(`/login`)
            : sessionStorage.setItem('ReportCategory', '농지연금분석');
          setPointModal((prevPointModal) => !prevPointModal);
        }}
      >
        <ButtonText>내 농지연금 알아보기</ButtonText>
      </Button>
      <Disclaimer>
        ※ 예상 농지연금 지급액은 트랜스파머 인공지능이 자동 산출한 것으로
        실제값과 일부 차이가 있을 수 있습니다.
        <br /> 해당 주소지의 분석 데이터가 부족할 때는 더 넓은 범위 값으로
        농지가치를 분석 제공할 수 있습니다.
      </Disclaimer>
      {!analysisYN && showTooltip ? (
        <TooltipContainer>
          <TooltipTextBox>
            <img src="/asset/images/icon/error.svg" alt="" />
            <p>분석을 위한 데이터가 충분하지 않아요.</p>
          </TooltipTextBox>
          <img
            src="/asset/images/close/close-white.svg"
            alt=""
            onClick={handleClose}
          />
        </TooltipContainer>
      ) : (
        showTooltip && (
          <TooltipContainer>
            <TooltipTextBox>
              <img src="/asset/images/icon/error.svg" alt="" />
              <p>맹지는 농지연금 분석이 불가능해요.</p>
            </TooltipTextBox>
            <img
              src="/asset/images/close/close-white.svg"
              alt=""
              onClick={handleClose}
            />
          </TooltipContainer>
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: white;
  position: relative;
`;

const Title = styled.div`
  font-family: 'Pretendard';
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #222222;
`;

const InfoBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #70737c0d;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const InfoText = styled.div`
  font-family: 'Pretendard';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #37383c9c;
`;

const HighlightBox = styled.div`
  padding: 4px 16px;
  border-radius: 8px;
  background: #222222;
`;

const HighlightText = styled.div`
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #222222;
`;

const HighlightText2 = styled(HighlightText)`
  color: #ffffff;
`;

const Button = styled.div<RoadSurfaceProps>`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
  cursor: ${(props) =>
    props.roadSurface === '맹지' || !props.analysisYN ? 'default' : 'pointer'};
  background-color: ${(props) =>
    props.roadSurface === '맹지' || !props.analysisYN ? '#F7F7F8' : '#1e9b6b'};
  color: ${(props) =>
    props.roadSurface === '맹지' || !props.analysisYN
      ? 'rgba(55, 56, 60, 0.16)'
      : '#ffffff'};
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
`;

const Disclaimer = styled.div`
  font-family: Pretendard-medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #171e268f;
`;

const TooltipContainer = styled.div`
  width: calc(100% - 28px);

  position: absolute;
  padding: 12px 16px;
  background-color: #5e6263;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  top: 296px;
  right: 15px;
  z-index: 200;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust as needed to position the triangle */
    left: 50%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #5e6263; /* Triangle color matching the background */
  }
  @media (min-width: 360px) {
    right: 15px;
  }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
`;

export default PensionPreview;
