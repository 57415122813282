import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class BoardService {
  static list = async (boardCode: string, limit: number, offset: number) => {
    const result = await request.get(
      `board/article/list?boardCode=${boardCode}&limit=${limit}&offset=${offset}`,
    );
    return result.data;
  };
  static detail = async (articleIdx: number) => {
    const result = await request.get(`board/article/${articleIdx}`);
    return result.data;
  };
}
