import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MapData } from '../../detail/data';
import {
  makeAuctionforPriceMarkers,
  makePropertyforPriceMarkers,
  makeReaPriceMarkers,
} from '../../main/components/map/markerData';

interface DetailMapProps {
  data: any;
  saleType: string;
}

const ListMap = ({ data, saleType }: DetailMapProps) => {
  const mapElement = useRef<HTMLDivElement>(null);
  const roadViewElement = useRef<HTMLDivElement>(null);
  const roadViewElement2 = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>(null);
  const roadViewRef = useRef<any>(null);
  const markersRef = useRef<any[]>([]);
  const [roadView, setRoadView] = useState(true);
  const [roadVisible, setRoadVisible] = useState(false);
  const createMarkers = (map: any) => {
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    if (
      saleType === '인터넷 매물' ||
      saleType === '매물' ||
      saleType === '지자체 물건'
    ) {
      const markerContent = makePropertyforPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(40, 50),
        },
      });

      markersRef.current.push(marker);
    }
    if (saleType === '경매' || saleType === '공매') {
      const markerContent = makeAuctionforPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(35, 50),
        },
      });
      markersRef.current.push(marker);
    }
    if (saleType === '실거래') {
      const markerContent = makeReaPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(35, 50),
        },
      });

      markersRef.current.push(marker);
    }
    if (saleType === '비매물') {
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: mapRef.current,
        icon: {
          url: '/asset/images/marker/tf-marker.svg',
          anchor: new window.naver.maps.Point(25, 50),
        },
      });

      markersRef.current.push(marker);
    }
  };

  const initializeMap = () => {
    if (!mapElement.current) return;
    const mapOptions = {
      center: new window.naver.maps.LatLng(data.lat, data.lng),
      zoom: 17,
      mapTypeId: window.naver.maps.MapTypeId['SATELLITE'],
      logoControl: false,
      mapDataControl: false,
      mapTypeControl: false,
      draggable: false,
      scrollWheel: false,
      disableDoubleClickZoom: true,
      disableDoubleTapZoom: true,
      size: new window.naver.maps.Size(
        window.innerWidth > 720 ? 720 : window.innerWidth,
        166,
      ),
    };

    // 지도 초기화
    if (mapRef.current) {
      mapRef.current.destroy();
    }
    mapRef.current = new window.naver.maps.Map(mapElement.current, mapOptions);

    const polygon = new window.naver.maps.Polygon({
      map: mapRef.current,
      paths: data.geom,
      fillColor: 'rgba(0, 177, 85, 0.25)',
      fillOpacity: 0.3,
      strokeColor: '#00B155',
      strokeOpacity: 0.6,
      strokeWeight: 3,
    });

    createMarkers(mapRef.current);
  };

  useEffect(() => {
    if (mapElement.current) {
      initializeMap();
    }
  }, [data]);

  useEffect(() => {
    if (roadView) {
      const initialPov = { pan: -135, tilt: 29, fov: 100 };

      const pano2 = new window.naver.maps.Panorama(roadViewElement2.current, {
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        pov: initialPov,
        zoomControl: false,
        draggable: false, // 드래그 방지 옵션
        visible: false,
      });

      new window.naver.maps.Event.addListener(
        pano2,
        'pano_status',
        (status: string) => {
          if (status === 'ERROR') {
            setRoadView(false);
            setRoadVisible(false);
            initializeMap();
          } else {
            const pano = new window.naver.maps.Panorama(
              roadViewElement.current,
              {
                position: new window.naver.maps.LatLng(data.lat, data.lng),
                pov: initialPov,
                zoomControl: false,
                draggable: false,
                visible: true,
              },
            );

            // 조작 이벤트 방지
            const disableControls = () => {
              pano.setOptions({ pov: initialPov, draggable: false });
            };

            // 드래그 및 확대/축소 방지 이벤트 리스너
            new window.naver.maps.Event.addListener(
              pano,
              'mousedown',
              disableControls,
            );
            new window.naver.maps.Event.addListener(
              pano,
              'mousemove',
              disableControls,
            );
            new window.naver.maps.Event.addListener(
              pano,
              'mousewheel',
              (e: any) => {
                e.preventDefault();
                disableControls();
              },
            );
            new window.naver.maps.Event.addListener(
              pano,
              'touchmove',
              (e: any) => {
                e.preventDefault();
                disableControls();
              },
            );

            setRoadVisible(true);
            roadViewRef.current = pano;
          }
        },
      );
    } else {
      if (roadViewRef.current) {
        roadViewRef.current.setVisible(false);
        roadViewRef.current = null;
      }
      if (mapElement.current) {
        mapElement.current.style.display = 'block';
      }

      initializeMap();
    }
  }, [roadView, data]);

  return (
    <DetailMapContainer>
      {/* 지도와 로드뷰를 조건에 따라 토글 */}
      <div
        ref={roadView ? roadViewElement2 : mapElement}
        style={{ display: 'none' }}
      ></div>
      <NaverMap
        ref={roadView ? roadViewElement : mapElement}
        style={{ width: '100%', height: '166px' }}
      ></NaverMap>
    </DetailMapContainer>
  );
};

export default ListMap;

const DetailMapContainer = styled.div`
  width: 100%;
  height: 192px;
  display: flex;
  position: relative;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */

  //   margin-top: 56px;
`;

const NaverMap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  border-top-left-radius: 8px; /* 왼쪽 위 모서리 */
  border-top-right-radius: 8px; /* 오른쪽 위 모서리 */
`;

const RoadViewButton = styled.div`
  width: 103px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.68);
  position: absolute;
  bottom: 24px;
  right: 12px;
  border-radius: 9999px;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  z-index: 2;
  gap: 8px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08);
  color: white;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;
