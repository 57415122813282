import styled from 'styled-components';
import colors from '../../color';

interface TabButtonProps {
  active: boolean;
}

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
`;

const TopSearchBox = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const LandMarketTabBox = styled.div`
  width: 218px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
`;
const TabButton = styled.span<TabButtonProps>`
  flex: 1;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${({ active }) => (active ? '#fff' : colors.labelAlternative)};
  background-color: ${({ active }) =>
    active ? colors.primary500 : 'transparent'};
  border-radius: 8px;
  border: ${({ active }) => (active ? '1px solid #E1E2E4' : 'none')};
  cursor: pointer;
`;

const TopButton = styled.div`
  position: sticky;
  bottom: 70px;
  right: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  margin-right: 16px;
  pointer-events: none; /* 아래 div 클릭 가능 */

  & > * {
    pointer-events: auto; /* 자식 요소는 여전히 클릭 가능 */
  }
`;

const ContenBox = styled.div`
  width: 100%;
  background-image: url('/asset/images/landMarket/apply-main-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 72px 16px 128px 16px;
  display: flex;
  flex-direction: column;
  gap: 42px;
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  font-family: Paperlogy-ExtraBold;
  font-size: 40px;
  line-height: normal;
  color: #d4d6dd;
`;
const TitleGreen = styled.div`
  color: #09ce7d;
`;

const DescriptionBox = styled.div`
  width: 100%;
  font-family: Paperlogy-Medium;
  font-size: 18px;
  line-height: normal;
  color: white;
  letter-spacing: -0.18px;
  text-align: center;
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const InputLabel = styled.div`
  font-family: Paperlogy-Medium;
  font-size: 14px;
  color: white;
`;
const Input = styled.input`
  width: 100%;
  height: 48px;
  border: none;
  background-color: white;
  border-radius: 10px;
  padding: 12px 14px;
  font-family: Pretendard-Medium;
  font-size: 16px;
`;
const Must = styled.span`
  color: rgba(9, 206, 125, 1);
`;
const SearchAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const SearchButton = styled.button`
  width: 87px;
  height: 50px;
  border-radius: 10px;
  background-color: #09ce7d;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const TermBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CheckTerm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
`;
const Check = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: white;
  cursor: pointer;
`;
const TermText = styled.div`
  width: 100%;
  padding: 0 16px;
  text-align: left;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 16px;
`;
const GuideText = styled.div`
  width: 100%;
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: white;
`;
const SubmitButton = styled.button`
  width: 100%;
  padding: 16px 32px;
  border-radius: 100px;
  font-family: Pretendard-Bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #04b452;
  color: white;
  border: none;
  cursor: pointer;
`;

export const S = {
  Container,
  TopSearchBox,
  LandMarketTabBox,
  TabButton,
  TopButton,
  ContenBox,
  TitleBox,
  TitleGreen,
  DescriptionBox,
  InputBox,
  InputRow,
  InputLabel,
  Input,
  Must,
  SearchAddress,
  SearchButton,
  TermBox,
  CheckTerm,
  Check,
  TermText,
  GuideText,
  SubmitButton,
};
