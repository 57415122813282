import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HousePrice from './input/new/Price';
import Pyeong from './input/new/Pyeong';
import './Main.css';
import { styled } from 'styled-components';
import { useMapContext } from '../../../../../../../contexts/MapContext';

const houseType = {
  A: '목조',
  B: '철근콘크리트',
  C: '철골조',
  D: '조적조',
  E: '기타',
};

const HouseInput = ({}: any) => {
  const location = useLocation();
  const nav = useNavigate();
  const { c_idx, address, itemType, pnu, housekye, landArea, land_BCR } =
    location.state || {};
  const [selectedDesignFloorMo, setSelectedDesignFloorMo] = useState(1);

  const [type1, setType1] = useState('N');
  const [type5, setType5] = useState('N'); //N:신축, R:리모델링
  const [selectedDesignIdx, setSelectedDesignIdx] = useState(0);

  const [selectedPyeong, setSelectedPyeong] = useState(0);
  const [onDesignList, setOnDesignList] = useState(false);

  const locationHook = useLocation();
  const [query, setQuery] = useState('');
  const [landInfo, setLandInfo] = useState({
    area: 0,
    pnuCode: '',
    pyeong: 1,
    stdBuildingToLandRatio: 0,
    houseArea: 40.3,
    housePyeong: 12.2,
    stdFloorAreaRatio: 0,
    totalFloorAreaPyeong: 0,
  });

  const protoHouse = {
    authFile:
      'houseDesign/rural/농림-10-27-나-2/[2010] 농림-10-27-나-2(인허가용).zip',
    buildingArea: 27.4,
    buildingToLandRatio: 0.08,
    drawingFile: null,
    floor: 1,
    floorAreaRatio: 0.08,
    floorPlan: 'houseDesign/rural/농림-10-27-나-2/floorPlan.JPG',
    landPyeong: 316.1,
    mainImg: 'houseDesign/rural/농림-10-27-나-2/image1.jpg',
    readingFile:
      'houseDesign/rural/농림-10-27-나-2/[2010] 농림-10-27-나-2(열람용).zip',
    structure: 'E',
    subImg1: 'houseDesign/rural/농림-10-27-나-2/image2.jpg',
    subImg2: '',
    designName: '',
    designIdx: 0,
    totalArea: 78,
    stdBuildingToLandRatio: 20,
  };
  const [currentHouse, setCurrentHouse] = useState(protoHouse);
  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
  }, [locationHook]);
  const [landPyeong, setLandPyeong] = useState(0);
  const [selectedStdBuildingToLandRatio, setSelectedStdBuildingToLandRatio] =
    useState(0);

  const [houseSelected, setHouseSelected] = useState(false);
  const [pyeongValue, setPyeongValue] = useState(23.6);
  const [minPyeongValue, setMinPyeongValue] = useState(23.6);
  const [maxPyeongValue, setMaxPyeongValue] = useState(81);

  const [selectCunsult, setSelectCunsult] = useState('Y');

  useEffect(() => {
    setPyeongValue(
      Math.round(Number(currentHouse.totalArea * 0.3025) * 10) / 10,
    );
    //landArea가 제곱미터로 들어와서 평으로 변경 (landArea* 0.3025)
    if (selectedDesignFloorMo === 1) {
      setMinPyeongValue(selectedPyeong);
      setMaxPyeongValue(
        (landArea *
          0.3025 *
          (currentHouse.stdBuildingToLandRatio ??
            land_BCR ??
            sessionStorage.land_BCR ??
            40)) /
          100,
      );
    } else if (selectedDesignFloorMo === 2) {
      setMinPyeongValue(selectedPyeong);
      setMaxPyeongValue(
        ((landArea *
          0.3025 *
          (currentHouse.stdBuildingToLandRatio ??
            land_BCR ??
            sessionStorage.land_BCR ??
            40)) /
          100) *
          2,
      );
    }
  }, [selectCunsult, type5, selectedDesignIdx]);
  const { latLng } = useMapContext();

  const [sliderstdBuildingToLandRatio, setSliderstdBuildingToLandRatio] =
    useState(0);
  const [sliderstdFloorAreaRatio, setSliderstdFloorAreaRatio] = useState(0);

  /**주택의 유형을 결과로 보내는 코드 */
  const structure =
    (currentHouse.structure as 'A' | 'B' | 'C' | 'D' | 'E') || 'A';
  const structureName = houseType[structure];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '720px',
        backgroundColor: '#f7f8f9',
        position: 'relative',
      }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}
        onClick={() => {
          if (c_idx === '') {
            if (housekye !== '') {
              nav(
                `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
              );
            } else {
              nav(
                `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
              );
            }
          } else {
            nav(`/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`);
          }
        }}
      >
        <img src="/asset/images/close/close-gray.svg" alt="" />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingInline: '16px',
          gap: '24px',
        }}
      >
        <Title>주택 견적 내기</Title>
        <InfoBox>
          <InfoContent>
            설계안, 평형을 선택하면 해당 주소지에서 건축 가능한 맞춤 견적을
            조회할 수 있습니다
          </InfoContent>
        </InfoBox>
      </div>
      {/* {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            save2={save}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )} */}
      <div className="flex-column border-b-171E2610 h-100per padding-b-100 ">
        <div className="flex-column w-100per h-100per gap-16 ">
          <div className="flex-column gap-6 padding-24-16-8-16 ">
            <div className="font-bold f-size-20 line-h-29 color-text-primary">
              맞춤 조건 입력하기
            </div>
            <div className="flex-row space-between center-y tablet">
              <div className="flex-row gap-5 no-wrap center-y">
                <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                  {address || '전북특별자치도 순창군 순창읍 옥천로 12-2'}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-column padding-x-16 gap-16 ">
            <>
              <HousePrice
                title={(type1 === 'Y' ? '3.' : '6.') + ' 건축 설계안 선택 여부'}
                onDesignList={onDesignList}
                setOnDesignList={setOnDesignList}
                landInfo={landInfo}
                currentHouse={currentHouse}
                setCurrentHouse={setCurrentHouse}
                setPyeongValue={setPyeongValue}
                setMaxPyeongValue={setMaxPyeongValue}
                setMinPyeongValue={setMinPyeongValue}
                setLandPyeong={setLandPyeong}
                type5={type5}
                setHouseSelected={setHouseSelected}
                houseSelected={houseSelected}
                selectCunsult={selectCunsult}
                setSelectCunsult={setSelectCunsult}
                setSelectedDesignFloor={
                  // (window.innerWidth)
                  //   ?
                  setSelectedDesignFloorMo
                  // : setSelectedDesignFloor
                }
                setSelectedDesignIdx={setSelectedDesignIdx}
                setSelectedPyeong={setSelectedPyeong}
                setSelectedStdBuildingToLandRatio={
                  setSelectedStdBuildingToLandRatio
                }
                address={query}
              />
              <Pyeong
                title={type1 === 'Y' ? '4. 평형' : '7. 평형'}
                selectedDesignFloor={
                  // (window.innerWidth)
                  //   ?
                  selectedDesignFloorMo
                  // : selectedDesignFloor
                }
                pyeongValue={pyeongValue}
                landPyeong={landPyeong}
                setLandPyeong={setLandPyeong}
                setPyeongValue={setPyeongValue}
                minPyeongValue={minPyeongValue}
                maxPyeongValue={maxPyeongValue}
                currentHouse={currentHouse}
                landInfo={landInfo}
                stdBuildingToLandRatio={landInfo.stdBuildingToLandRatio}
                stdFloorAreaRatio={landInfo.stdFloorAreaRatio}
                selectedDesignIdx={selectedDesignIdx}
                sliderstdBuildingToLandRatio={sliderstdBuildingToLandRatio}
                setSliderstdBuildingToLandRatio={
                  setSliderstdBuildingToLandRatio
                }
                sliderstdFloorAreaRatio={sliderstdFloorAreaRatio}
                setSliderstdFloorAreaRatio={setSliderstdFloorAreaRatio}
              />
            </>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: 'inherit',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#f7f8f9',
          zIndex: 100,
          padding: '16px',
        }}
      >
        <Button
          onClick={() => {
            if (houseSelected) {
              sessionStorage.setItem('pyeongValue', pyeongValue.toString());
              sessionStorage.setItem('c_idx', c_idx);
              sessionStorage.setItem('address', address);
              sessionStorage.setItem('itemType', itemType);
              sessionStorage.setItem('pnu', pnu);
              sessionStorage.setItem('housekye', housekye);
              sessionStorage.setItem('landArea', landArea);
              sessionStorage.setItem('land_BCR', land_BCR);
              sessionStorage.setItem(
                'sliderstdBuildingToLandRatio',
                String(sliderstdBuildingToLandRatio),
              );
              sessionStorage.setItem(
                'sliderstdFloorAreaRatio',
                String(sliderstdFloorAreaRatio),
              );
              sessionStorage.setItem(
                'structureName',
                structureName || '철골조',
              );
              nav('/house/report?pnu=' + query);
            } else {
              alert('건축 설계안을 선택해주세요');
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <ButtonText>내 집 건축보고서 보기</ButtonText>
        </Button>
      </div>
    </div>
  );
};

const Button = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const Title = styled.div`
  font-family: Pretendard-bold;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: left;
`;
const InfoBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #eaf8f2;
`;
const InfoContent = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #1e9b6b;
`;

export default HouseInput;
