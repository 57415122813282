import { useEffect, useState } from 'react';
import { S } from './FindInfo.styles';
import AuthService from '../../service/auth';
import NoMember from './NoMember';
import { useNavigate } from 'react-router-dom';
import { RedirectHandler } from '../../controller/Redirect';
import UserService from '../../service/Users';
interface Account {
  email: string;
  create_time: string;
  login_type: string;
}

const FindId = ({
  type,
  tab,
  setTab,
  setType,
}: {
  type: string;
  tab: string;
  setTab: Function;
  setType: Function;
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [openAuthCodeInput, setOpenAuthCodeInput] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<Number>(0);
  const [loginType, setLoginType] = useState('');
  const [accountList, setAccountList] = useState<Account[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setName(localStorage.getItem('name') || '');
    setPhone(localStorage.getItem('phone') || '');
  }, []);

  const sendSms = async () => {
    if (name === '' || phone === '') {
      alert('이름과 휴대폰 번호를 입력해주세요');
    }
    try {
      const data = {
        phone: phone,
        type: 'D',
        name: name,
      };
      const res = await AuthService.sendSms(data);
      alert('인증번호가 발송되었습니다');
    } catch (error) {
      console.error(error);
    }
  };

  const checkVerifyCode = async (code: string) => {
    try {
      const data = {
        phone: phone,
        type: type,
        name: name,
        authCode: code,
      };
      await AuthService.verifySms(data);
      alert('인증되었습니다');
    } catch (error) {
      console.error(error);
      alert('인증번호가 일치하지 않습니다');
    }
  };
  const GetAccountList = async () => {
    try {
      const res = await UserService.findId(name, phone);
      setAccountList(res.result);
      setStep(1);
    } catch (error) {
      console.error(error);
      setStep(2);
    }
  };

  useEffect(() => {
    if (name !== '' && phone !== '' && code !== '') {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, phone, code]);

  const handleLogin = () => {
    const selectedEmail = accountList[Number(selectedAccount)].email;

    if (loginType === 'K') {
      RedirectHandler('Kakao');
    } else if (loginType === 'N') {
      RedirectHandler('Naver');
    } else if (loginType === 'F') {
      RedirectHandler('Facebook');
    } else if (loginType === 'G') {
      RedirectHandler('Google');
    } else {
      localStorage.setItem('email', selectedEmail);
      navigate('/login');
    }
  };

  const renderIcon = (type: string) => {
    if (type === 'K') {
      return <img src="/asset/images/logo/id_kakao.svg" alt="" />;
    } else if (type === 'N') {
      return <img src="/asset/images/logo/id_naver.svg" alt="" />;
    } else if (type === 'F') {
      return <img src="/asset/images/logo/id_facebook.svg" alt="" />;
    } else if (type === 'G') {
      return <img src="/asset/images/logo/id_google.svg" alt="" />;
    } else {
      return null;
    }
  };

  const renderContent = () => {
    if (step === 0) {
      return (
        <S.VerifyInfoBox>
          <S.TitleBox>
            <span>휴대폰 본인 확인</span>
          </S.TitleBox>
          <S.InputBox>
            <S.InputItem>
              <S.Label>이름</S.Label>
              <S.Input
                type="text"
                placeholder="홍길동"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </S.InputItem>
            <S.InputItem>
              <S.Label>휴대폰 번호</S.Label>
              <S.PhoneNumberBox>
                <S.Input
                  type="tel"
                  placeholder="010 0000 0000"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  style={{ flex: 1 }}
                  maxLength={11}
                />
                <S.SendNumberButton
                  onClick={() => {
                    sendSms();
                    setOpenAuthCodeInput(true);
                  }}
                >
                  {openAuthCodeInput ? '재전송' : '인증번호 받기'}
                </S.SendNumberButton>
              </S.PhoneNumberBox>
            </S.InputItem>
            {openAuthCodeInput && (
              <S.InputItem>
                <S.Label>인증번호</S.Label>
                <S.Input
                  type="text"
                  placeholder="인증번호 6자리 입력를 입력해주세요"
                  onChange={(e) => {
                    setCode(e.target.value);
                    if (e.target.value.length === 6) {
                      checkVerifyCode(e.target.value);
                    } else {
                      return null;
                    }
                  }}
                  maxLength={6}
                />
              </S.InputItem>
            )}
          </S.InputBox>
        </S.VerifyInfoBox>
      );
    }
    if (step === 1) {
      return (
        <S.VerifyInfoBox>
          <S.TitleBox>
            <span>아이디 찾기 완료</span>
          </S.TitleBox>
          <S.AccountList>
            {accountList.map((item, index) => (
              <S.AccountItem
                key={index}
                onClick={() => {
                  setSelectedAccount(index);
                  setLoginType(item.login_type);
                }}
              >
                <S.AccountItemInner active={selectedAccount === index}>
                  {selectedAccount === index ? (
                    <img src={`/asset/images/check/check_on.svg`} alt="" />
                  ) : (
                    <img src={`/asset/images/check/check_off.svg`} alt="" />
                  )}

                  <S.AccountTextBox>
                    <S.EmailBox>
                      {renderIcon(item.login_type)}
                      <span>{item.email}</span>
                    </S.EmailBox>
                    <S.SignUpInfo>
                      <S.TextGreen
                        style={{
                          visibility: index === 0 ? 'visible' : 'hidden',
                        }}
                      >
                        최근사용
                      </S.TextGreen>
                      <span>{item.create_time} 가입</span>
                    </S.SignUpInfo>
                  </S.AccountTextBox>
                </S.AccountItemInner>
                {item.login_type === 'C' && selectedAccount === index && (
                  <S.FindPasswordBtn
                    onClick={() => {
                      localStorage.setItem('email', item.email);
                      navigate('/find?tab=pw&type=B');
                      setTab('pw');
                      setType('B');
                    }}
                  >
                    비밀번호 찾기
                  </S.FindPasswordBtn>
                )}
              </S.AccountItem>
            ))}
          </S.AccountList>
        </S.VerifyInfoBox>
      );
    } else {
      return <NoMember tab={tab} />;
    }
  };
  const renderButton = () => {
    if (step === 0) {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color: isFormValid ? '#ffffff' : 'rgba(55, 56, 60, 0.16)',
              backgroundColor: isFormValid ? '#1E9B6B' : '#F0F0F0',
            }}
            onClick={() => {
              GetAccountList();
            }}
          >
            아이디 찾기
          </S.FindBtn>
        </S.FindBtnBox>
      );
    }
    if (step === 1) {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color: '#ffffff',
              backgroundColor: '#1E9B6B',
            }}
            onClick={() => {
              handleLogin();
            }}
          >
            로그인
          </S.FindBtn>
        </S.FindBtnBox>
      );
    } else {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color: '#ffffff',
              backgroundColor: '#1E9B6B',
            }}
            onClick={() => {
              GetAccountList();
            }}
          >
            다시시도
          </S.FindBtn>
        </S.FindBtnBox>
      );
    }
  };

  return (
    <S.Wrapper>
      {renderContent()}
      {renderButton()}
    </S.Wrapper>
  );
};
export default FindId;
