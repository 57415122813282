import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HackleProvider } from '@hackler/react-sdk';
import { hackleClient } from './hackler';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HackleProvider hackleClient={hackleClient}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HackleProvider>,
);
