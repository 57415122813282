import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

interface LoginData {
  userId: string;
  password: string;
}
interface SmsData {
  email?: string;
  phone: string;
  authCode?: string;
  type?: string;
  name?: string;
}

export default class AuthService {
  static login = async (data: LoginData) => {
    const result = await request.post('auth/login', data);
    return result.data;
  };
  static sendSms = async (data: SmsData) => {
    const result = await request.post('auth/sms', data);
    return result.data;
  };
  static verifySms = async (data: SmsData) => {
    const result = await request.post('auth/sms/verify', data);
    return result.data;
  };

  static withDraw = async (data: any) => {
    const result = await request.patch('users/withdraw', data);
    return result.data;
  };
}
