import styled from 'styled-components';
import { transNum } from '../../../function/function';
interface Category {
  imgSrc: string;
  text: string;
}

interface SummaryCategoryProps {
  saleType?: string;
  type?: string;
  unit?: string;
  category?: string;
  pyeong?: number;
  area?: number;
  zoningBadge: string;
  bidCount?: number;
  approvalDate: number | 1993;
  option?: string;
  road?: string;
}

const ListCategory = ({
  saleType,
  type,
  unit,
  category,
  pyeong,
  area,
  zoningBadge,
  bidCount,
  approvalDate,
  option,
  road,
}: SummaryCategoryProps) => {
  const getAdditionalCategory = (
    type: string,
    approvalDate: number,
    zoningBadge: string,
  ) => {
    if (type !== '토지') {
      return {
        imgSrc: '/asset/images/icon/house-off.svg',
        text: approvalDate
          ? ` 노후 ${new Date().getFullYear() - Number(String(approvalDate).substring(0, 4))}년`
          : ' 정보없음',
      };
    } else {
      return {
        imgSrc: '/asset/images/icon/useState-off.svg',
        text: zoningBadge,
      };
    }
  };

  const RenderCategory = (saleType: string, type: string) => {
    let categories: Category[] = [];
    if (saleType === '경매' || saleType === '공매') {
      categories = [
        {
          imgSrc: '/asset/images/icon/categoryImg-off.svg',
          text: category || '',
        },
        {
          imgSrc: '/asset/images/icon/area-off.svg',
          text: `${transNum(pyeong || 0, false, false)}평`,
        },
        getAdditionalCategory(type, approvalDate, zoningBadge),
        {
          imgSrc: '/asset/images/icon/scale-off.svg',
          text: `유찰 ${bidCount}회`,
        },
      ];
    } else if (saleType === '매물') {
      categories = [
        {
          imgSrc: '/asset/images/icon/categoryImg-off.svg',
          text: category || '',
        },
        {
          imgSrc: '/asset/images/icon/area-off.svg',
          text: `${transNum(pyeong || 0, false, false)}평`,
        },
        getAdditionalCategory(type, approvalDate, zoningBadge),
        {
          imgSrc:
            option === 'y'
              ? '/asset/images/icon/option-on.svg'
              : '/asset/images/icon/option-off.svg', // 옵션 여부에 따른 이미지 변경
          text: option === 'y' ? '옵션있음' : '옵션없음', // 옵션 여부에 따른 텍스트 변경
        },
      ];
    } else {
      categories = [
        {
          imgSrc: '/asset/images/icon/categoryImg-off.svg',
          text: category || '',
        },
        {
          imgSrc: '/asset/images/icon/area-off.svg',
          text: `${transNum(pyeong || 0, false, false)}평`,
        },
        getAdditionalCategory(type, approvalDate, zoningBadge),
        {
          imgSrc: '/asset/images/icon/road-off.svg',
          text: road === '맹지' ? '맹지' : '도로인접',
        },
      ];
    }

    // 최종적으로 렌더링
    return categories.map((item, index) => (
      <SummaryCategoryBox key={index}>
        <SummaryCategoryImg>
          <img src={item.imgSrc} alt="" />
        </SummaryCategoryImg>
        <div>{item.text}</div>
      </SummaryCategoryBox>
    ));
  };

  return (
    <SummaryCategoryInfoBox>
      {RenderCategory(saleType || '', type || '')}
    </SummaryCategoryInfoBox>
  );
};
export default ListCategory;

const SummaryCategoryInfoBox = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  // background-color: #f5f5f5;
`;

const SummaryCategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const SummaryCategoryImg = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  //   background-color: rgba(112, 115, 124, 0.05);
`;
