import { useEffect, useState } from 'react';
import { S } from './FindInfo.styles';
import AuthService from '../../service/auth';
import NoMember from './NoMember';
import UserService from '../../service/Users';
import { useNavigate } from 'react-router-dom';

const FindPassword = ({ type, tab }: { type: string; tab: string }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [openAuthCodeInput, setOpenAuthCodeInput] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setEmail(localStorage.getItem('email') || '');
    setName(localStorage.getItem('name') || '');
    setPhone(localStorage.getItem('phone') || '');
  }, []);

  const sendSms = async () => {
    if (email === '' || phone === '') {
      alert('이메일과 휴대폰 번호를 입력해주세요');
    }
    try {
      const data = {
        email: email,
        phone: phone,
        type: type,
        name: name,
      };
      const res = await AuthService.sendSms(data);
      alert('인증번호가 발송되었습니다');
      setOpenAuthCodeInput(true);
    } catch (error) {
      setStep(2);
    }
  };

  const checkVerifyCode = async (code: string) => {
    try {
      const data = {
        email: email,
        phone: phone,
        type: type,
        name: name,
        authCode: code,
      };
      await AuthService.verifySms(data);
      alert('인증되었습니다');
    } catch (error) {
      console.error(error);
      alert('인증번호가 일치하지 않습니다');
    }
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleConfirmPasswordChange = (e: any) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword);
  };

  const resetPassword = async () => {
    if (password === '' || confirmPassword === '') {
      alert('비밀번호를 입력해주세요');
    }

    try {
      const data = {
        type: type,
        email: email,
        name: name,
        phone: phone,
        password: password,
      };
      const res = await UserService.resetPassword(data);
      alert('비밀번호가 변경되었습니다');
      if (type === 'B') {
        localStorage.setItem('email', '');
        localStorage.setItem('name', '');
        localStorage.setItem('phone', '');
      }
      navigate(0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (email !== '' && name !== '' && phone !== '' && code !== '') {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [email, name, phone, code]);

  const renderContent = () => {
    if (step === 0) {
      return (
        <S.VerifyInfoBox>
          <S.TitleBox>
            <span>휴대폰 본인 확인</span>
          </S.TitleBox>
          <S.InputBox>
            <S.InputItem>
              <S.Label>이메일</S.Label>
              <S.Input
                type="text"
                placeholder="이메일 주소를 입력해 주세요"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  localStorage.setItem('email', e.target.value);
                }}
              />
            </S.InputItem>
            <S.InputItem>
              <S.Label>이름</S.Label>
              <S.Input
                type="text"
                placeholder="홍길동"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  localStorage.setItem('name', e.target.value);
                }}
              />
            </S.InputItem>
            <S.InputItem>
              <S.Label>휴대폰 번호</S.Label>
              <S.PhoneNumberBox>
                <S.Input
                  type="tel"
                  placeholder="010 0000 0000"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    localStorage.setItem('phone', e.target.value);
                  }}
                  style={{ flex: 1 }}
                  maxLength={11}
                />
                <S.SendNumberButton
                  onClick={() => {
                    sendSms();
                  }}
                >
                  {openAuthCodeInput ? '재전송' : '인증번호 받기'}
                </S.SendNumberButton>
              </S.PhoneNumberBox>
            </S.InputItem>
            {openAuthCodeInput && (
              <S.InputItem>
                <S.Label>인증번호</S.Label>
                <S.Input
                  type="text"
                  placeholder="인증번호 6자리 입력를 입력해주세요"
                  onChange={(e) => {
                    setCode(e.target.value);
                    if (e.target.value.length === 6) {
                      checkVerifyCode(e.target.value);
                    } else {
                      return null;
                    }
                  }}
                  maxLength={6}
                />
              </S.InputItem>
            )}
          </S.InputBox>
        </S.VerifyInfoBox>
      );
    }
    if (step === 1) {
      return (
        <S.VerifyInfoBox>
          <S.TitleBox>
            <span>비밀번호 재설정</span>
          </S.TitleBox>
          <S.InputBox>
            <S.InputItem>
              <S.Label>비밀번호</S.Label>
              <S.InputRow>
                <S.Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  placeholder="비밀번호를 입력해주세요"
                  onChange={handlePasswordChange}
                  maxLength={16}
                />
                <img
                  src={
                    showPassword
                      ? '/asset/images/icon/eye-on.svg'
                      : '/asset/images/icon/eye-off.svg'
                  }
                  alt="Toggle Password Visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </S.InputRow>
            </S.InputItem>
            {!isPasswordValid && (
              <S.WarningFont>
                영문/숫자/특수문자를 사용하여 8자~16자로 입력
              </S.WarningFont>
            )}
            <S.InputItem>
              <S.Label>비밀번호 확인</S.Label>
              <S.InputRow>
                <S.Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="비밀번호 확인"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  maxLength={16}
                />
                <img
                  src={
                    showConfirmPassword
                      ? '/asset/images/icon/eye-on.svg'
                      : '/asset/images/icon/eye-off.svg'
                  }
                  alt=""
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </S.InputRow>
            </S.InputItem>
            {!isPasswordMatch && (
              <S.WarningFont>비밀번호가 일치하지 않습니다.</S.WarningFont>
            )}
          </S.InputBox>
        </S.VerifyInfoBox>
      );
    } else {
      return <NoMember tab={tab} />;
    }
  };
  const renderButton = () => {
    if (step === 0) {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color: isFormValid ? '#ffffff' : 'rgba(55, 56, 60, 0.16)',
              backgroundColor: isFormValid ? '#1E9B6B' : '#F0F0F0',
            }}
            onClick={() => {
              isFormValid && setStep(1);
            }}
          >
            비밀번호 재설정
          </S.FindBtn>
        </S.FindBtnBox>
      );
    }
    if (step === 1) {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color:
                password !== '' && confirmPassword !== '' && isPasswordMatch
                  ? '#ffffff'
                  : 'rgba(55, 56, 60, 0.16)',
              backgroundColor:
                password !== '' && confirmPassword !== '' && isPasswordMatch
                  ? '#1E9B6B'
                  : '#F0F0F0',
            }}
            onClick={() => {
              resetPassword();
            }}
          >
            변경하기
          </S.FindBtn>
        </S.FindBtnBox>
      );
    } else {
      return (
        <S.FindBtnBox>
          <S.FindBtn
            style={{
              color: '#ffffff',
              backgroundColor: '#1E9B6B',
            }}
            onClick={() => {
              setStep(0);
            }}
          >
            다시시도
          </S.FindBtn>
        </S.FindBtnBox>
      );
    }
  };

  return (
    <S.Wrapper>
      {renderContent()}
      {renderButton()}
    </S.Wrapper>
  );
};
export default FindPassword;
